import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { OrderAction, PaymentAction } from "../../../store/action";
import { Row } from "react-bootstrap";
import { Successfull } from "../../../constants/ImageConstants";
import axios from "axios";
import ENV from "../../../server/environment";
import { PaymentFail, PaymentSuccessfull } from "../../../view/desktop/screens";

const PaymentSuccess = (props) => {
  const { getPaymentStatus, paymentData, updateMemberEnrollment, enrollSuccessData } = props;
  console.log(getPaymentStatus, paymentData, updateMemberEnrollment, enrollSuccessData)
  const { statusResponse } = paymentData;
  const [searchParams] = useSearchParams();
  const order_id = searchParams.get("myorder");
  const GetPaymentStatusView = () => {
    return statusResponse.data.status === "PAID" ? <PaymentSuccessfull code={enrollSuccessData.memberEnrollCode?.code || '####'} /> : <PaymentFail />;
  };
  useEffect(() => {
    if (statusResponse) return;
    const params = {
      task: "getpaymentGatewayOrderStatus",
      order_id,
    };
    getPaymentStatus(params);
    axios
        .post(ENV.WAVE_URL, {
        
          payment_status: "success",
          order_id
        })
        .then((response) => {
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          // console.log(error);
        });
  }, [order_id, getPaymentStatus, statusResponse]);
  useEffect(() => {
    if (enrollSuccessData.memberEnrollCode === null && statusResponse?.data.status === "PAID") {
      const updateParams = {
        task: "updateMemberEnrollment",
        id: statusResponse.data.customer.customer_id,
      };
      updateMemberEnrollment(updateParams);
    }
  }, [enrollSuccessData, statusResponse, updateMemberEnrollment]);
  return statusResponse ? (
    <GetPaymentStatusView />
  ) : (
    <Row>
      <section className="error-section">
        <div className="auto-container">
          <div className="content">
            <img src={Successfull} alt="Payment Successfull" className="mb-4 w-25" />
            <h2>Payment Successfull</h2>
            <div className="text">Thank you for your payment</div>
            <a href="/" className="theme-btn btn-style-nine">
              <span className="txt">Go to Home Page</span>
            </a>
          </div>
        </div>
      </section>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.paymentData = state.default.paymentReducer;
  stateObj.enrollSuccessData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  updateMemberEnrollment: (params) => OrderAction.updateMemberEnrollment(params, dispatch),
  getPaymentStatus: (params) => PaymentAction.getPaymentStatus(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
