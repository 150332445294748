import React from "react";
import { Row, Col} from "react-bootstrap";
import "./index.scss";
import { TrackManage  } from "../../../../../constants/ImageConstants";


const TrackManageClaims = () => {
  return (
    <Row>
      <Col>

      <div className="auto-container">

        <div className="sec-title centered">
          <div className="title">Track and Manage Claims</div>
          <h2>Along each step of claims process</h2>
        </div>
        
        <div className="inner-container">
          <div className="row clearfix">
            
           
            <img src={ TrackManage } alt="Track And Manage Claim Process" />
            
          </div>
        </div>

      </div>

      </Col>
    </Row>
  );
};

export default TrackManageClaims;
