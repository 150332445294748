import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "font-awesome/css/font-awesome.min.css";
import { createStore, applyMiddleware, combineReducers } from "redux";

import thunk from "redux-thunk";
import App from "./DesktopApp";
import * as reducer from "./store/reducer";
import "./assets/css/flaticon.css";
import "./assets/css/responsive.css";
import PageLoader from "./components/PageLoader";
import ScrollToTop from "./scrollToTop";
import Notification from "./Notification";

const rootReducer = combineReducers(reducer);

export const store = createStore(rootReducer, applyMiddleware(thunk));
/**
 * This is the web client referring to all enclosed components
 *
 * @returns
 */
const ClientDesktopApp = () => {
  const newVersion = "2.0.0";
  const currentVersion = window.localStorage.getItem("app_version");
  if (!(currentVersion && currentVersion === newVersion)) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    window.localStorage.setItem("app_version", newVersion);
    window.location.reload(true);
  }
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <ScrollToTop />
          <Notification />
          <App />
          <PageLoader />
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default ClientDesktopApp;
