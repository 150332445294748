import { SERVICE_BOOKING, SERVICE_QUOTE, MEMBER_ENROLL, MEMBER_ENROLL_CODE, ORDER_DATA } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { serviceBookingResponse: null, serviceQuoteResponse: null, memberEnrollData: null, memberEnrollCode: null, orderData: null }, action = {}) => {
  switch (action.type) {
    case SERVICE_BOOKING:
      return { ...state, serviceBookingResponse: action.payload };
    case SERVICE_QUOTE:
      return { ...state, serviceQuoteResponse: action.payload };
    case MEMBER_ENROLL:
      return { ...state, memberEnrollData: action.payload };
    case MEMBER_ENROLL_CODE:
      return { ...state, memberEnrollCode: action.payload };
    case ORDER_DATA:
      return { ...state, orderData: action.payload };
    default:
      return state;
  }
};
