import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Pattern1, Pattern2 } from "../../../../../constants/ImageConstants";
import BannerSection from "./BannerSection";
import Header from "../../../layout/NavigationBar/Header";
import Footer from "../../../layout/NavigationBar/Footer";
import BlogMultiImageCarousel from "../../../../../components/common/blogMultiImageCarouselMobile";
import { Helmet } from "react-helmet";

const Oem = (props) => {
  const source = props.source || "OEM";
  return (
    <>

      <Helmet>
        <title>Future-ready EV repair solution - VehicleCare</title>
        <meta
          name="description"
          content="We have partnered with high-tech, EV-qualified repairers that will ensure quality EV repair service for your customers."
        />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        <Col>
          {/* Banner Section */}
          <section className="banner-section-three style-two">
            <div className="main-slider-carousel">
              <div className="slide">
                <BannerSection source={source} />
              </div>
            </div>
          </section>

          {/* Efficient After-Sales Infrastructure */}
          <section className="about-section pt-5" style={{ backgroundColor: "whitesmoke" }}>
            <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
            <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>

            <div className="auto-container">
              <div className="sec-title centered">
                <h2>Efficient After-Sales Infrastructure</h2>
              </div>

              <div className="detail">
                <div className="d-flex justify-content-center col inner-box">
                  <div className="col-lg-10 col-md-10 col-sm-12 lower-content">
                    <p>
                      <b>
                        <font color="#ed1c24" size="5">
                          1.
                        </font>
                      </b>
                      Our platform allows you to quickly expand your business to new markets by connecting you with our network of high-tech, EV-qualified repairers. Additionally, you have the
                      flexibility to onboard other service providers of your choice to provide customers with a wide range of options.
                    </p>

                    <p>
                      <b>
                        <font color="#ed1c24" size="5">
                          2.
                        </font>
                      </b>
                      Our platform centralizes communication with customers, repairers, suppliers, and other service providers. You can easily manage and track all interactions through a single
                      digital platform, streamlining the entire process and saving valuable time.
                    </p>

                    <p>
                      <b>
                        <font color="#ed1c24" size="5">
                          3.
                        </font>
                      </b>
                      You can reach customers directly, ensuring optimal journeys and retaining full control over warranties. Our platform offers a personalized experience for your customers, which
                      leads to high levels of satisfaction and retention.
                    </p>

                    <p>
                      <b>
                        <font color="#ed1c24" size="5">
                          4.
                        </font>
                      </b>
                      You can track the service status of all your vehicles, gain real-time insights into service provider performance, and make data-driven decisions. Our platform offers powerful
                      data analytics capabilities that allow you to identify areas of improvement, optimize workflows, and ensure the highest level of quality and efficiency in your after-sales
                      service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Build Your Own Ecosystem */}
          <section className="about-section pb-1 pt-5">
            <div className="auto-container">
              <div className="sec-title centered">
                <h2>Build Your Own Ecosystem</h2>
              </div>

              <div className="detail">
                <div className="d-flex justify-content-center col inner-box">
                  <div className="col-lg-10 col-md-10 col-sm-12 lower-content">
                    <p>
                      <b>
                        <font color="#ed1c24" size="5">
                          1.
                        </font>
                      </b>
                      By creating your own ecosystem, you can avoid dealing with local regulations and cultural differences while creating a seamless brand experience.
                    </p>

                    <p>
                      <b>
                        <font color="#ed1c24" size="5">
                          2.
                        </font>
                      </b>
                      Our platform also enables you to maintain full control of your vehicles throughout their entire lifecycle.
                    </p>

                    <p>
                      <b>
                        <font color="#ed1c24" size="5">
                          3.
                        </font>
                      </b>
                      This approach provides a faster and more transparent way of expanding your business, without having to adapt to third-party requirements.
                    </p>

                    <p>
                      <b>
                        <font color="#ed1c24" size="5">
                          4.
                        </font>
                      </b>
                      With our solution, you won't have to start from scratch as we provide a ready-made network of qualified repairers, suppliers, and other service providers, giving you the
                      flexibility to onboard your chosen service providers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Blog Section */}
          <div className="blog-section">
            <BlogMultiImageCarousel />
          </div>

          {/* <div className="acordian-section pt-5" style={{ backgroundImage: `url(${Citybg})`, backgroundSize: `inherit`, backgroundColor: `#f5f1ee` }}>
            <CityList />
          </div> */}
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default Oem;
