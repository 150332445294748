import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import {
  DentingPaintOffer,
  CarWashOffer,
  MechanicalOffer,
  Appbg,
  CarServiceOffer,
  banner,
} from "../../../../constants/ImageConstants";
import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import "bootstrap/dist/css/bootstrap.min.css";
import OurApp from "../../../../components/common/ourApp";
import Testimonial from "../../../../components/common/Testimonial";

import { connect } from "react-redux";
import { OrderAction } from "../../../../store/action";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import Banner from "./Banner";
import InnerHeader from "../../layout/NavigationBar/InnerHeader";
import BottomTabs from "../../layout/NavigationBar/BottomsTabs";

const Warranty = (props) => {
  const { cityData, userData } = props;
  const {is_user_logged_in} = userData
  return (
    <>
      <Helmet>
        <title>
          Can You Search The Extended Warranty Plan - Basic Plan Standard Plan
        </title>
        <meta
          name="description"
          content="Save UpTo 50% With Extended Warranty Plan - VehicleCare provides the Best Discount And Offers"
        />

        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      {/* <Header isStatic={true} /> */}
      <InnerHeader title={'Warranty'} />

      <Row>
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner />
            </div>
          </div>
        </section>

        <section className="about-section pt-5 pb-5">
          <div className="auto-container CustomisablePlatform">
            <div className="upper-section">
              <div className="sec-title centered">
                <h1 className="title">Warranty Plan</h1>
                <h2 className="text-left">
                  {" "}
                  Extended Warranty Plan, Tailored for you…{" "}
                </h2>
              </div>
              <div>
                <div className="row clearfix mb-4">
                  <div className="content-column col-lg-12 col-md-12 col-sm-12 my-auto">
                    <div className="inner-column px-2">
                      <div className="text">
                        Drive with confidence with VehicleCare's extended
                        warranty plan, taking care of all your car worries right
                        from the beginning along with your budget. We give you
                        the freedom to choose the plan that suits you the best.
                        At VehicleCare we take pride in understanding your
                        concerns and thus offer you personalised solutions as
                        per your unique requirements.Whether you are a low
                        mileage driver or frequently on road, purchased a
                        pre-owned car, we got you covered no matter what.
                      </div>

                      <div className="text">
                        VehicleCare pre-paid plans brings to you the promise to
                        enjoy substantial savings compared to pay-as-you-go
                        services. Your smart choice can keep your car running
                        smoothly while maintaining a happy wallet. Don’t miss
                        the opportunity to choose the plan that’s right for you
                        and hit the road with peace of mind.
                      </div>

                      <hr />

                      <div className="text">
                        <h4 className="fw-bold text-dark">
                          <font>Save Upto 50% with Extended Warranty Plan</font>
                        </h4>
                      </div>

                      <div className="text">
                        VehicleCare Extended warranty plan is available at most
                        of the used car dealership and online, protecting your
                        vehicle no matter where you shop. Our comprehensive
                        coverage plans provides you the ultimate peace of mind.
                        All the works are provided either at your doorstep or at
                        our workshops at anytime as per your convenience. With
                        VehicleCare, drive safe and worry-free.
                      </div>
                      <hr />
                      <div style={{textAlign: "center"}}>
                        <img src={banner} className="w-75" />
                      </div>
                      <hr />
                      <div className="text">
                        <h4 className="fw-bold text-dark">
                          <font>Plan Details:</font>
                        </h4>
                      </div>

                      <div className="text">
                        <font color="#ed1c24">
                          <b>BASIC PLAN -</b>{" "}
                        </font>{" "}
                        VehicleCare’s basic plan offers protection for your
                        vehicle’s critical components. This includes key part of
                        your engine, manual and automatic gearboxes. From oil
                        pumps and crackshafts to turbo units and radiators, our
                        coverage ensures that your car stays in top condition
                        always. With our basic plan, you can drive confidently
                        knowing that the essential repairs are covered, helping
                        you to avoid unexpected expenses and keep your vehicle
                        running smoothly.
                      </div>

                      <div className="text">
                        <font color="#ed1c24">
                          <b>STANDARD PLAN -</b>{" "}
                        </font>{" "}
                        The standard plan includes all the benefits of basic
                        plan and many additional features. It provides an
                        extensive coverage of your vehicle, focusing on crucial
                        components like engine, transmission and electrical
                        system of your car.The plan also includes protection of
                        parts such as oil pumps, crankshaft, pistons, valves,
                        water pumps, radiators, turbo units and various sensors.
                        Additionally, it covers both manual and automatic
                        gearbox components, ensuring comprehensive for internal
                        gears, shafts, bearings, and more. Choose VehicleCare
                        standard plan for peace of mind and smooth ride
                        everytime.
                      </div>

                      <div className="text">
                        <font color="#ed1c24">
                          <b>COMPREHENSIVE PLAN -</b>{" "}
                        </font>{" "}
                        The most upgraded plan offering unparallel coverage and
                        peace of mind. The plan offers extensive coverage along
                        with all the benefits of the basic plan. All the
                        critical components of the vehicle are covered under
                        this plan, like, front wheel drive, rear axle, propeller
                        shaft, steering, braking system, Diesel Injection
                        System, Fuel system, air conditioning, vehicle’s
                        electric system and many more. Choosing VehicleCare
                        comprehensive plan ensures that your vehicle remains in
                        top condition, minimizing unexpected repair cost and
                        maximizing reliability on the road.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonial Section  */}
        <div className="testimonial-section-four d-none d-md-block">
          <Testimonial city_name={cityData.selectedCity} />
        </div>

        {/* Our App */}
        <div
          className="testimonial-section-one bg-size pb-5"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
          }}
        >
          <OurApp />
        </div>
      </Row>

      {isMobile && (
        <a className="fab" href="tel:9911-020-209">
          <img src={require("../../../../assets/images/phone-call.png")} />
        </a>
      )}
      <Footer bottomTabs={true}  />
      <BottomTabs route_name={"home"} user={is_user_logged_in} />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) =>
    OrderAction.createServiceQuote(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Warranty);
