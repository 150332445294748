import React , { useState } from "react";
import { Row,Col } from "react-bootstrap";
import "./index.scss";
import { Appbg, Pattern1, Pattern2 ,Faqbg } from "../../../../constants/ImageConstants";

import OurApp from "../../../../components/common/ourApp";

import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import Testimonial from "../../../../components/common/Testimonial";
import BlogMultiImageCarousel from "../../../../components/common/blogMultiImageCarouselMobile";
import ServicesWeOffer from "../Landing/ServicesWeOffer";
import VcBenifits from "../Landing/VcBenifits";
import WhyChooseVc from "../Landing/WhyChooseVc";
import ServiceSteps from "../Landing/ServiceSteps";
import Banner from "./Banner";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CarWorkshopPriceList from "./CarWorkshopPriceList";

const CarWorkshopNearMe = (props) => {
  const selectedCityName = props.cityListReducer.selectedCity;

  const faqList =[
    {
        id:1,
        title:"How many car workshop near by ?",
        summary:"There are plenty of vehiclecare workshop  near you that will provide excellent car services , instant repair and dentining and paining."   
    },
    {
        id:2,
        title:"Do car workshop has online payment ?",
        summary:"Yes VehicleCare workshop has online payment. You can Paytm or GooglePay." 
       
    },
    {
        id:3,
        title:"Do vehiclecare workshop has waiting area?",
        summary:"yes we do have a waiting area in our workshop." 
       
    },
    {
        id:4,
        title:"How many mechanic are there in vehiclecare workshop ?",
        summary:"There are plenty of Mechanics in VehicleCare workshops and our expert mechanics have more than ten years of experience." 
       
    },
    {
      id:5,
      title:"Do vehiclecare use authentic oils for cars ?",
      summary:"All products used in car services are authentic and top-rated." 
     
  },
  {
    id:6,
    title:"Do vehiclecare provide road side asistance like towing and jumpstart ?",
    summary:"Yes we provide road asistance like towing and jumpstart.You just have to call us 9911020209 if any problem arises with your car." 
   
},
{
  id:7,
  title:"Can i book my appointment thorugh mobile APP ?",
  summary:"Yes you can book your appointment through Mobile App. You can also book via email." 
 
},
{
  id:8,
  title:"What all kind of services are available in VehicleCare workshop?",
  summary:"There’s a wide range of car services which are available.We offer Car service, Wheel Care, Car AC Repair, Custom Repair and much more." 
 
}
];

const [toggle, setToggle] = useState(0);
let handleToggle = (id) => {
  if (toggle === id) {
    setToggle(null);
    return false;
  }
  setToggle(id);
};
const active = { backgroundColor: "black", color: "white" };
  const inactive = {};

  return (
    <>
      <Helmet>
        <title>Find The Trusted Car Workshop Near Me - VehicleCare</title>
        <meta name="description" content="Find The Premium Nearby Car Workshop - From Servicing, Repairs, And Accident Claims To Meticulous Wash, Denting, Painting, And More." data-react-helmet="true" />
     
        <meta property="og:title" content="Find The Trusted Car Workshop Near Me - VehicleCare"/>
        <meta property="og:description" content="Find The Premium Nearby Car Workshop - From Servicing, Repairs, And Accident Claims To Meticulous Wash, Denting, Painting, And More."/>
        <meta property="og:url" content={`https://vehiclecare.in/car-workshop-near-me`}/>
        <meta property="og:type" content="website"/>
        <meta property="og:site_name" content="VehicleCare"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"/>
        <meta property="twitter:title" content="Find The Trusted Car Workshop Near Me - VehicleCare"/>
        <meta property="twitter:description" content="Find The Premium Nearby Car Workshop - From Servicing, Repairs, And Accident Claims To Meticulous Wash, Denting, Painting, And More."/>
        <meta property="twitter:url" content={`https://vehiclecare.in/car-workshop-near-me`}/>
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:site" content="@vehiclecare1"/>
        <meta property="twitter:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"/>

      </Helmet>

      <Header isStatic={true} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner />
            </div>
          </div>
        </section>

        <div className="services-section-four pt-0 pb-lg-5 pb-sm-4">
          <Row>
            <Col>
              <div className="auto-container">
                <div className="sec-title centered">
                  <h2>Services We Offer</h2>
                  <div className="text col-8 mx-auto d-none d-md-block">
                  Find the best service packages for your car. Select from a wide range of car services, from general service to
                  car wash, 
                  accidental repair to custom repair, cashless insurance claims, and much more.
                  </div>
                </div>

                <ServicesWeOffer selectedCityName={selectedCityName} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="services-section-four pt-0">
          <ServiceSteps />
        </div>

        {/* price list */}
        <div className="blog-section pt-0">
          <CarWorkshopPriceList />
        </div>

        <div className="price-section " style={{ background: "rgb(251, 251, 251)" }}>
          <VcBenifits />
        </div>

        <div className="services-section-four">
          <div className="auto-container">
            <div className="sec-title centered">
              <h2>Why Choose VehicleCare </h2>
            </div>
          </div>
          <WhyChooseVc />
        </div>

        {/*Car Workshop Near Me? */}
        <section className="about-section pt-5 pb-5" style={{ backgroundColor: "rgb(251, 251, 251)" }}>
          <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
          <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>

          <div className="auto-container">
            <Row className="justify-content-md-center">
              <Col xs={12} lg={11}>
                <div className="sec-title">
                  <h2 className="text-center">Looking For Car Workshop Nearby?</h2>
                  <div className="text">
                    Keep your vehicle in top condition with our trustworthy nearby VehicleCare car workshop. Whether you require routine car servicing or if your vehicle is experiencing problems and you're uncertain about the issue, we've got you covered. Our expertise extends to repairing all types of cars, ensuring your vehicle runs smoothly on the road.
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-md-center">
              <Col xs={12} lg={11}>
                <div className="sec-title mb-4">
                  <h2 className="text-left fs-4">Benefits of VehicleCare Workshops</h2>
                </div>
                <Col>
                  <p className="mb-1">
                    <b>
                      <font color="#ed1c24" size="3">
                        Get Rewarded -{" "}
                      </font>
                    </b>
                    Save money on car service & repair. Get cashback & rewards on every car service with VehicleCare
                  </p>
                </Col>

                <Col>
                  <p className="mb-1">
                    <b>
                      <font color="#ed1c24" size="3">
                      Convenient Service -{" "}
                      </font>
                    </b>
                    Easily schedule car service, 
                    <a href="https://vehiclecare.in/car-repair-near-me" target="_blank" rel="noreferrer"><font color="#ed1c24"> car repair </font></a>
                    appointments at our workshops, and we'll handle all the necessary work.
                  </p>
                </Col>

                <Col>
                  <p className="mb-1">
                    <b>
                      <font color="#ed1c24" size="3">
                      Stay Informed -{" "}
                      </font>
                    </b>
                    Receive real time update through whatsapp and Mobile APP for your car service or repair status
                  </p>
                </Col>
              </Col>
            </Row>

            <Row className="justify-content-md-center">
              <Col xs={12} lg={11}>
                <div className="sec-title my-4">
                  <h2 className="text-left fs-4">Features of VehicleCare Workshops</h2>
                </div>
                <Col>
                  <p className="mb-1">
                    <b>
                      <font color="#ed1c24" size="3">
                        Skilled Mechanics -{" "}
                      </font>
                    </b>
                    VehicleCare has got trained service technicians ready to diagnose, service, repair, and maintain all vehicle models - whether new or old.
                  </p>
                </Col>

                <Col>
                  <p className="mb-1">
                    <b>
                      <font color="#ed1c24" size="3">
                        100% Quality Repairs -{" "}
                      </font>
                    </b>
                    Our automotive specialists boast over a decade of experience, ensuring top-quality solutions for your vehicle.
                  </p>
                </Col>

                <Col>
                  <p className="mb-1">
                    <b>
                      <font color="#ed1c24" size="3">
                        Wide Range of Services -{" "}
                      </font>
                    </b>
                    Whether it's  repair, brake service, or engine replacement, VehicleCare covers a variety of services in the workshop.
                  </p>
                </Col>
              </Col>
            </Row>

          </div>
        </section>

        {/* FAQ Section  */}
        <div className={`acordian-section  ${faqList?.length < 1 && "d-none"}`} style={{ backgroundImage: `url(${Faqbg})`, backgroundSize: "cover" }}>
          <Col  className={`auto-container`}>
            <div className="row clearfix">
              <div className="accordian-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    {/* <div className="title">Contact Support</div> */}
                    <h2>Frequently Asked Questions</h2>
                  </div>
                  <ul className="accordion-box">
                  {faqList?.map((value, index) => {
                    return (
                      <div className="accordion block mb-1" id="acc_faq" key={index}>
                        <div
                          className="acc-btn"
                          onClick={() => handleToggle(index)}
                          style={index === toggle ? active : inactive}
                        >
                          <h2>{value.title}{" "}</h2>
                          <div className="icon-outer">
                            <b className="icon">{index === toggle ? "-" : "+"} </b>{" "}
                          </div>{" "}
                        </div>
                        {index === toggle ? (
                          <div className="content px-2 py-0">
                            <div className="accordian-text"> {value.summary} </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}

                  </ul>
                </div>
              </div>

              <div className="title-column col-lg-5 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title">Contact Support</div>
                    <h2>Have Any Questions?</h2>
                  </div>
                  <div className="inquiry-box">
                    <div className="box-inner">
                      <div className="upper-box">
                        <div className="icon flaticon-telephone"></div>
                        <div className="inquiry-title">For Any Inquiries Call Now!</div>
                        <a href="tel:9911-020-209" className="phone">
                          9911-020-209
                        </a>
                      </div>
                      <div className="lower-box">
                        <a className="emailed" href="mailto: support@vehiclecare.in?subject=Need Help">
                          support@vehiclecare.in
                        </a>
                      </div>
                    </div>
                    <Link to={"/getintouch"} className="get-tech">
                      Get You Tech Now!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
        {/* FAQ Section  */}

        {/* Testimonial Section  */}
        <div className="d-none d-md-block">
          <Testimonial city_name="Gurgaon" />
        </div>

        {/* Blog Section */}
        <div className="blog-section">
          {/* <IBlog /> */}
          <BlogMultiImageCarousel />
        </div>

        {/* Our App */}
        <div
          className="testimonial-section-one bg-size pb-5"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
          }}
        >
          <OurApp />
        </div>



        {/* City List */}
        {/* <div
          className="acordian-section"
          style={{
            backgroundImage: `url(${Citybg})`,
            backgroundSize: `inherit`,
            backgroundColor: `#f5f1ee`, 
          }}
        >
          <CityList />
        </div> */}
      </Row>
      <Footer />
    </>
  );
};

function mapStateToProps(state) {
  return {
    cityListReducer: state.default.cityListReducer,
  };
}
export default connect(mapStateToProps)(CarWorkshopNearMe);
