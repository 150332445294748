import { SERVICE_LIST, CATEOGARY_ID } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    serviceList: [],
    selected_cateogary: "periodic-car-services",
  },
  action = {}
) => {
  switch (action.type) {
    case SERVICE_LIST:
      return { ...state, serviceList: action.payload };
    case CATEOGARY_ID:
      return { ...state, selected_cateogary: action.payload };
    default:
      return state;
  }
};
