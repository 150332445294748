import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import { BannerAI, Appbg } from "../../../../constants/ImageConstants";
import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import OurApp from "../../../../components/common/ourApp";
import { isMobile } from "react-device-detect";

const VehicleAI = () => {
  return (
    <>
      <Helmet>
        <title>VehicleCare Provides AI Inspection Cutting-Edge Technology</title>
        <meta name="description" content="VehicleCare Provides AI Inspection Revolutionizing Vehicle Care In India, We Are Check Every Car Problem At Home, And Hassle Free Inspection" />
      </Helmet>

      <Header isStatic={true} />

      <Row>

        <section className="banner-section-three style-two" id="vi-banner">
          <div className="main-slider-carousel">
            <div className={`slide ${!isMobile ? "mt-0 pb-0" : "pt-4"}`}>
              <div className="auto-container">
                <div className="content-boxed">
                  <div className="">
                    <div className="row clearfix">

                      <div className="image-column col-lg-6 col-md-12 col-sm-12 mb-0 d-md-none">
                        <div className="inner-column">
                          <div className="image">
                            <h2 className="coming-soon">Coming soon</h2>
                            <img src={BannerAI} className="img-responsive" alt="Car" />
                          </div>
                        </div>
                      </div>

                      <div className="image-column col-lg-6 col-md-12 col-sm-12 mb-0">
                        <div className="inner-column">
                          <div className="image">
                            <div className="sec-title mb-2">
                              <h1 className="fs-2 text-capitalize mt-0">
                                
                                Welcome to <font color="#ed1c24">VehicleAI Labs: </font> Revolutionizing Vehicle Care in India
                                
                              </h1>
                            </div>

                            <p className="mb-3">
                              At VehicleAI, we're pioneering a transformative initiative aimed at revolutionizing the entire lifecycle of vehicles. Our comprehensive solution addresses the myriad challenges faced by millions of car owners across India. Our mission? To seamlessly enhance your car ownership experience by providing proactive care akin to having a dedicated 'car buddy' by your side. We're committed to keeping you well-informed about your vehicle's health and seamlessly connecting you with the finest workshops when necessary.

                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="image-column col-lg-6 col-md-12 col-sm-12 mb-0 d-none d-md-block">
                        <div className="inner-column">
                          <div className="image">
                            <h2 className="fs-1 text-capitalize mt-0 mb-0 coming-soon"><b>Coming soon</b></h2>
                            <img src={BannerAI} className="img-responsive" alt="Car" />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="about-section pt-0">
          <div className="pattern-layer-one"></div>

          <div className="auto-container">
            <div className="upper-section">
              <div className="row clearfix">
                <div className="content-column col-lg-12 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <div className="detail px-2">
                      <div className="d-flex justify-content-center col inner-box">
                        <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                         
                          <div className="sec-title mb-2">
                            <h4 className="text-left text-dark">
                              <b>
                              Leading the Charge with Cutting-Edge Technology
                              </b>
                            </h4>
                          </div>

                          <p className="mb-3">
                          Leveraging state-of-the-art technology, including Artificial Intelligence and the prowess of mobile computing, we're constructing a groundbreaking platform. This platform, named the Digital Car Service eXperience (DcSX), is designed to automate the entire car service and repair process. Additionally, our Digital Claim eXperience (DCX) simplifies and streamlines insurance claims, ensuring a hassle-free process for our users.

                          </p>

                          <div className="sec-title mb-2">
                            <h4 className="text-left text-dark">
                              <b>
                              Introducing VehicleCareAI Labs
                              </b>
                            </h4>
                          </div>

                          <p className="mb-1">
                          Under the umbrella of VehicleCareAI Labs, we're introducing an unparalleled initiative. This initiative aims to craft an end-to-end automation solution catered specifically for vehicle owners. It's our commitment to provide a seamless, intuitive, and comprehensive suite of services that will redefine how car owners interact with and maintain their vehicles.

                          </p>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/* Our App */}
        <div
          className="testimonial-section-one bg-size pb-5"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
          }}
        >
          <OurApp />
        </div>



      </Row>

      <Footer />
    </>
  );
};

export default VehicleAI;
