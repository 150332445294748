import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { GetinTouch, Appbg, Citybg, Successful } from "../../../../../constants/ImageConstants";
import OurApp from "../../../../../components/common/ourApp";
import CityList from "../../../../../components/common/cityList";
import "./index.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomModal from "../../../../../components/common/CustomModal";
import Footer from "../../../layout/NavigationBar/Footer";
import Header from "../../../layout/NavigationBar/Header";
import { OrderAction } from "../../../../../store/action";
import { SERVICE_BOOKING, SHOW_HIDE_LOADER } from "../../../../../store/types";
import { auth, generateRecaptcha } from "../../../../../server/firebase";
import { signInWithPhoneNumber, signOut } from "firebase/auth";
import { getNavigateUrl } from "../../../../../utils/customHelper";

const GetInTouch = (props) => {
  const [searchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isCaptchaVerifier, setIsCaptchaVerifier] = useState(false);
  const [optCode, setOptCode] = useState("");
  const [optVerified, setOptVerified] = useState(false);
  const [final, setfinal] = useState("");
  const [data, setData] = useState({
    userName: "",
    userNumber: "",
    userPincode: "",
    userCompany: "",
    userCity: "",
    userMsg: "",
  });
  const { customDispatch, createCustomServiceBooking, serviceBookingResponse } = props;
  const handleClose = () => {
    if (optVerified) {
      navigate(getNavigateUrl("/"));
    }
    setShow(false);
  };
  const handleChange = (e) => setData({ ...data, [e.target.name]: e.target.value });
  const navigate = useNavigate();
  const ErrorOutput = (props) => {
    let name = props.name;
    let inputValue = props.case;
    if (name === "userName") {
      if (!inputValue?.match(/^[a-z A-Z\s]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Letters only</span>;
      }
      return <span className="text-danger"></span>;
    }
    if (name === "userNumber") {
      if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Numbers only</span>;
      }
      return <span className="text-danger"></span>;
    }
    if (name === "userEmail") {
      if (!inputValue?.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i) && inputValue?.length > 0) {
        return <span className="text-danger">Invalid email address</span>;
      }
      return <span className="text-danger"></span>;
    }
    if (name === "userCompany") {
      if (!inputValue?.match(/^[a-zA-Z]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Letters only</span>;
      }
      return <span className="text-danger"></span>;
    }
    if (name === "userCity") {
      if (!inputValue?.match(/^[a-z A-Z]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Letters only</span>;
      }
      return <span className="text-danger"></span>;
    }
    if (name === "userPincode") {
      if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Letters only</span>;
      }
      return <span className="text-danger"></span>;
    }
    return <></>;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      // sendOtp();
      serviceQuoteCall();
    }
    setValidated(true);
  };
  const GetModalContent = () => {
    return !optVerified ? (
      <Row>
        <Col id="otpCodeContainer" className="px-4 text-center">
          <div className="sec-title mb-3">
            <h2 className="font-weight-bold">
              <font size="4">Enter OTP Recieved on {data.userPhone} </font>{" "}
            </h2>
          </div>

          <div className="styled-form login-form">
            <div className="form-group">
              <input type="text" className="form-control" maxLength={6} placeholder="Enter OTP Code" value={optCode} onChange={(event) => setOptCode(event.target.value)} />
            </div>
          </div>
          <button onClick={onCodeSubmit} className="theme-btn btn-style-seven btn-sm py-2 mt-2 mb-4 px-4">
            <span className="txt">Submit</span>
          </button>
        </Col>
      </Row>
    ) : (
      <Row>
        <div className="col-12">
          <div className="row h-100">
            <div className="col-4 text-center">
              <img src={Successful} alt="" className="w-100" />
            </div>

            <div className="col-8 text-left my-auto">
              <h4>
                <b>
                  <font color="black">Congratulations!!</font>
                </b>
              </h4>
              <h5 style={{ color: "#40c057" }}>
                <b>Your Enquiry is successfully Registered.</b>
              </h5>
              <h6>Our Executive will contact you soon.</h6>
              <button type="button" className="theme-btn btn-style-nine my-3 mx-5" onClick={() => navigate(getNavigateUrl("/"))}>
                <span className="txt">Home</span>
              </button>
            </div>
          </div>
        </div>
      </Row>
    );
  };
  // eslint-disable-next-line no-unused-vars
  const sendOtp = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    const mynumber = `+91${data.userNumber}`;
    if (!isCaptchaVerifier) generateRecaptcha(setIsCaptchaVerifier);
    const verify = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, mynumber, verify)
      .then((result) => {
        setfinal(result);
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        setShow(true);
      })
      .catch((err) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(err);
      });
  };
  const serviceQuoteCall = () => {
    const apiParam = {
      task: "createSegmentQueries",
      name: data.userName,
      email: data.userEmail,
      phone: data.userNumber,
      company: data.userPincode,
      city: data.userCompany,
      zip: data.userCity,
      message: data.userMsg,
      segment: searchParams.get("source") || "Fleet",
    };
    createCustomServiceBooking(apiParam);
  };
  const onCodeSubmit = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    final
      .confirm(optCode)
      .then((result) => {
        signOut(auth);
        serviceQuoteCall();
      })
      .catch((error) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(error);
      });
  };
  useEffect(() => {
    if (serviceBookingResponse.serviceBookingResponse !== null) {
      setShow(true);
      setOptVerified(true);
      customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
      customDispatch({ type: SERVICE_BOOKING, payload: null });
    }
  }, [serviceBookingResponse, customDispatch]);
  return (
    <>
      <Header isStatic={true} />

      <Row>
        <Col className="price-section aboutus mt-5 col pb-0">
          <section className="contact-form-section">
            <div className="auto-container getintouch">
              <div className="row clearfix">
                <div className="form-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <div className="sec-title">
                      <div className="title">Want to get in touch? We'd love to hear from you.</div>
                      <h2>Get in touch</h2>
                    </div>
                    <div className="contact-form">
                      <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                          <Form.Group className="column col-md-6 form-group">
                            <Form.Label className="d-none d-md-block d-lg-block">Name</Form.Label>
                            <Form.Control required name="userName" type="text" placeholder="Enter Your name" onChange={(e) => handleChange(e)} value={data.userName} />
                            <Form.Control.Feedback type="invalid">Please enter your Name</Form.Control.Feedback>
                            <ErrorOutput case={data.userName} name={"userName"} />
                          </Form.Group>
                          <Form.Group className="column col-md-6 form-group" controlId="validationCustom02">
                            <Form.Label className="d-none d-md-block d-lg-block">Email</Form.Label>
                            <Form.Control required type="email" name="userEmail" placeholder="Enter Your email" onChange={(e) => handleChange(e)} value={data.userEmail} />
                            <Form.Control.Feedback type="invalid">Please enter your Email</Form.Control.Feedback>
                            <ErrorOutput case={data.userEmail} name={"userEmail"} />
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group className="column col-md-6 form-group" controlId="validationCustom03">
                            <Form.Label className="d-none d-md-block d-lg-block">Phone</Form.Label>
                            <Form.Control required type="text" maxLength={10} name="userNumber" placeholder="Enter Your Number" onChange={(e) => handleChange(e)} value={data.userNumber} />
                            <Form.Control.Feedback type="invalid">Please enter your Phone</Form.Control.Feedback>
                            <ErrorOutput case={data.userNumber} name={"userNumber"} />
                          </Form.Group>
                          <Form.Group className="column col-md-6 form-group" controlId="validationCustom04">
                            <Form.Label className="d-none d-md-block d-lg-block">Company</Form.Label>
                            <Form.Control required type="text" name="userCompany" placeholder="Enter Your Company" onChange={(e) => handleChange(e)} value={data.userCompany} />
                            <Form.Control.Feedback type="invalid">Please enter your Company</Form.Control.Feedback>
                            <ErrorOutput case={data.userCompany} name={"userCompany"} />
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group className="column col-md-6 form-group">
                            <Form.Label className="d-none d-md-block d-lg-block">City</Form.Label>
                            <Form.Control required type="text" list="citylist" value={data.userCity} placeholder="Select City" name="userCity" onChange={(e) => handleChange(e)} />
                            <Form.Control.Feedback type="invalid">Please enter your City</Form.Control.Feedback>
                            <ErrorOutput case={data.userCity} name={"userCity"} />
                          </Form.Group>
                          <Form.Group className="column col-md-6 form-group">
                            <Form.Label className="d-none d-md-block d-lg-block">Zip</Form.Label>
                            <Form.Control required type="text" placeholder="Enter Zip Code" name="userPincode" onChange={(e) => handleChange(e)} value={data.userPincode} />
                            <Form.Control.Feedback type="invalid">Please enter your ZIP Code</Form.Control.Feedback>
                            <ErrorOutput case={data.userPincode} name={"userPincode"} />
                          </Form.Group>
                          <Form.Group className="column col-md-12 form-group">
                            <Form.Label className="d-none d-md-block d-lg-block">Message For Us</Form.Label>
                            <Form.Control as="textarea" name="userMsg" value={data.userMsg} placeholder="Type your message here..." onChange={(e) => handleChange(e)} />
                            <Form.Control.Feedback type="invalid">Please enter your Message</Form.Control.Feedback>
                          </Form.Group>
                          <div className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3">
                            <button className="theme-btn btn-style-nine getQuote" type="submit" name="submit-form">
                              <span className="txt">Contact Now</span>
                            </button>
                          </div>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </div>

                <div className="blocks-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <div className="row clearfix">
                      <img src={GetinTouch} alt="get in touch" />
                    </div>
                    <div className="info-block col-lg-10 col-md-10 col-sm-10 mx-auto">
                      <div className="inner-box py-0 bg-transparent">
                        <h3>Make A Call</h3>
                        <a href="tel:+91-991-102-0209" className="phone">
                          +91-991-102-0209
                        </a>
                        <br />
                        <a href="mailto:support@vehiclecare.in" className="email">
                          support@vehiclecare.in
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Our App */}
          <div className="testimonial-section-two bg-size" style={{ backgroundImage: `url(${Appbg})`, backgroundSize: `inherit` }}>
            <OurApp />
          </div>

          {/* City List */}
          <div className="acordian-section" style={{ backgroundImage: `url(${Citybg})`, backgroundSize: `inherit`, backgroundColor: `#f5f1ee` }}>
            <CityList />
          </div>
        </Col>
      </Row>
      <Footer />
      <div id="custom-captcha-verifier"></div>
      <CustomModal show={show} modalSize={optVerified ? "lg" : "md"} content={<GetModalContent />} isCloseButton={true} handleClose={handleClose} />
    </>
  );
};
const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.cityList = state.default.cityListReducer.cityList;
  stateObj.serviceBookingResponse = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  createCustomServiceBooking: (params) => OrderAction.createCustomServiceBooking(params, dispatch),
  customDispatch: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(GetInTouch);
