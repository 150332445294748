import React from "react";
import "./index.scss";

const FaqList = ({ faqList, handleToggle, toggle }) => {
  const active = { backgroundColor: "black", color: "white" };
  const inactive = {};
  return (
    <>
      {faqList?.map((value, index) => {
        return (
          <div className="accordion block mb-1" id="acc_faq" key={index}>
            
            <div
              className="acc-btn"
              onClick={() => handleToggle(index)}
              style={index === toggle ? active : inactive}
            >
              <h2>{value.title}{" "}</h2>
              <div className="icon-outer">
                <b className="icon">{index === toggle ? "-" : "+"} </b>{" "}
              </div>{" "}
            </div>
            {index === toggle ? (
              <div className="content px-2 py-0">
                <div className="accordian-text"> {value.summary} </div>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </>
  );
};


export default FaqList;