import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";

const TrustedCompany = (props) => {
  return (
    <Row>

      {/* Documents Required: */}
      <div className="auto-container">
        <div className="upper-section">
          <div className="row clearfix">
            <div className="content-column col-lg-12 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title text-left px-2">
                  {/* <div className="title">Digital Claim eXperience </div> */}
                  <h2 className="">Documents Required:</h2>
                </div>
              </div>

              <div className="detail px-2">
                <div className="d-flex justify-content-center col inner-box">
                  <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                    <p className="mb-1">
                      <b><font color="#ed1c24" size="4">1. </font></b>
                      Driving Licence
                    </p>

                    <p className="mb-1"><b><font color="#ed1c24" size="4">2. </font></b>
                    Insurance Policy Paper
                    </p>

                    <p className="mb-1"><b><font color="#ed1c24" size="4">3. </font></b>
                    A copy of the Vehicle Registration Certificate
                    </p>

                    <p className="mb-1"><b><font color="#ed1c24" size="4">4. </font></b>
                    FIR Copy (for specific cases)
                    </p>

                    <p className="mb-1"><b><font color="#ed1c24" size="4">5. </font></b>
                    Medical Report, If any 
                    </p>

                    <p className="mb-1"><b><font color="#ed1c24" size="4">6. </font></b>
                    Photographs/ Video of the Damage
                    </p>

                    <p className="mb-1"><b><font color="#ed1c24" size="4">7. </font></b>
                    Other Documents
                    </p>

                    
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* <Col className="auto-container">
        <div className="sec-title centered">
          <h2 className="text-capitalize">Trusted by 200+ companies internationally</h2>
        </div>
        <div className="d-flex justify-content-center mb-5 col">
          <div className="col-lg-8 col-md-10 col-sm-12">
            <div className="row">
              <div className="row mb-4">
                <div className="col-lg-3 col-md-4 col-sm-6 pt-3 pb-3 text-center">
                  <div className="col-12">
                    <img src={Insurance3} alt="" className="w-75" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 pt-3 pb-3 text-center">
                  <div className="col-12">
                    <img src={Insurance10} alt="" className="w-75" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 pt-3 pb-3 text-center">
                  <div className="col-12">
                    <img src={Insurance4} alt="" className="w-75" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 pt-3 pb-3 text-center">
                  <div className="col-12">
                    <img src={Insurance5} alt="" className="w-75" />
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-lg-3 col-md-4 col-sm-6 pt-3 pb-3 text-center">
                  <div className="col-12">
                    <img src={Insurance2} alt="" className="w-75" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 pt-3 pb-3 text-center">
                  <div className="col-12">
                    <img src={Insurance1} alt="" className="w-75" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 pt-3 pb-3 text-center">
                  <div className="col-12">
                    <img src={Insurance11} alt="" className="w-75" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 pt-3 pb-3 text-center my-auto">
                  <div className="col-12">
                    <img src={Insurance8} alt="" className="w-75" />
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-3 col-md-4 col-sm-6 pt-3 pb-3 text-center">
                    <div className="col-12">
                      <img src={Insurance6} alt="" className="w-75" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 pt-3 pb-3 text-center my-auto">
                    <div className="col-12">
                      <img src={Insurance9} alt="" className="w-100" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 pt-3 pb-3 text-center my-auto">
                    <div className="col-12 border-0">
                      <img src={Insurance7} alt="" className="w-75 border-0" style={{ borderRadius: "0" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col> */}

     

    </Row>
  );
};

export default TrustedCompany;
