import React from "react";
import "./index.scss";
import TabsContent from "./TabsContent";

const ServicePlan = (props) => {
  const data = props.passingData;
  return (
    <div className="shop-page product-details price-section pt-0">
      <div className="product-info-tabs prod-tabs">
        <div className="container clearfix nav">
          <div className="tab-content">
            <TabsContent passingData={data} id={data?.id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicePlan;
