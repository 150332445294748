import { preLoginApiRequest } from "../../server";
import { REVIEW_LIST } from "../types";

// eslint-disable-next-line import/prefer-default-export
const getReviewList = (params, dispatch) => {
  preLoginApiRequest(params, (responseData) => {
    dispatch({type: REVIEW_LIST, payload: responseData.data});
  });
};
export { getReviewList };
