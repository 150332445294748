import React, { useEffect, useState } from "react";
import InnerHeader from "../../layout/NavigationBar/InnerHeader";
import { connect } from "react-redux";
import {
  ServicePackagesListAction,
  UserAction,
} from "../../../../store/action";
import {
  CaretRightFill,
  Trash2,
  Trash2Fill,
  Trash3,
} from "react-bootstrap-icons";
import { CART_LIST } from "../../../../store/types";
import { useNavigate } from "react-router-dom";
import { Checkbox, colors } from "@mui/material";
import VehicleSelector from "../../../../components/common/vehicleSelectorMobile";
import { BottomSheet } from "react-spring-bottom-sheet";
import { logFirebaseEvent } from "../../../../utils/customHelper";

const Checkout = (props) => {
  const [bottomSheet, setBottomSheet] = useState(false);
  const [inspectionCost, setInspectionCost] = useState(250);
  const [inspection, setInspection] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(null);
  const cartList = props?.cartList?.cartList;
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [selectedMethod, setSelectedMethod] = useState("Online");
  const [openModal, setOpenModal] = useState(false);
  const services = props?.servicePackageList?.servicePackageList;
  const { is_user_logged_in } = props?.userData;
  const vehicles = props?.userData?.full_profile?.user?.vehicles
    ? props?.userData?.full_profile?.user?.vehicles
    : props?.userData?.full_profile?.vehicles;

  useEffect(() => {
    logFirebaseEvent("mobile_web_payment_option_" + selectedMethod);
  }, [selectedMethod]);

  useEffect(() => {
    if (total == 0) {
      navigate("/");
    }
  }, [total]);

  useEffect(() => {
    inspection && logFirebaseEvent("mobile_web_inspection_checkout");
  }, [inspection]);

  useEffect(() => {
    logFirebaseEvent("mobile_web_view_cart");
    if (subTotal >= 5000) {
      setInspectionCost(0);
    } else {
      setInspectionCost(250);
    }
  }, [subTotal]);
  useEffect(() => {
    const discounts = window.sessionStorage.getItem("discounts");
    const items = [];
    let calculate = 0;
    services?.map((item) => {
      if (cartList?.includes(item?.id)) {
        const discount = JSON.parse(discounts)?.hasOwnProperty(item?.id)
          ? JSON.parse(discounts)[item?.id]
          : false;
        const discounted_price = discount
          ? Math.ceil(
              parseInt(item?.price) - (parseInt(item?.price) * discount) / 100
            )
          : false;
        items?.push({
          ...item,
          discount,
        });
        calculate += parseInt(
          discounted_price ? discounted_price : item?.price
        );
      }
    });
    if (services?.length > 0) {
      setSubTotal(calculate);
      setTotal(inspection ? calculate + inspectionCost : calculate);
      setCartItems([...items]);
    }
  }, [services, cartList]);

  const removeCart = (id) => {
    let cart = cartList.filter((elem) => elem !== id);
    props.updateCart({ type: CART_LIST, payload: cart });
  };

  const navigate = useNavigate();

  function fetchData() {
    // if (props?.userData?.is_user_logged_in) {
    //   const [selectedCar] =
    //     vehicles?.filter(
    //       (item) => item?.is_prefered === "1"
    //     );
    //   const params = {
    //     task: "getServiceData",
    //     variant_id: selectedCar?.variant_id,
    //   };
    //   props?.getServiceData(params);
    //   setVehicleDetails(selectedCar);
    // } else {
    let vehicleDetails = window.sessionStorage.getItem("vehicleDetails");
    if (vehicleDetails) {
      let parse = JSON.parse(window.sessionStorage.getItem("vehicleDetails"));
      const obj = {
        brand_name: parse?.brand?.name,
        model_name: parse?.model?.name,
        variant_name: parse?.variant?.variant,
        icon: parse?.model?.icon,
      };
      setVehicleDetails(obj);
      const params = {
        task: "getServiceData",
        variant_id: parse?.variant?.id,
      };
      props?.getServiceData(params);
    } else {
      setVehicleDetails(null);
    }
    // }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const makeDefaultVehicle = (id) => {
    props?.updateUserVehicle({
      task: "setUserVehiclesPreffered",
      id: id,
    });
  };
  const removeVehicle = (id) => {
    props?.updateUserVehicle({
      task: "removeUserVehicles",
      id: id,
    });
  };
  // useEffect(() => {
  //   const [selectedCar] = vehicles?.filter(
  //     (item) => item?.is_prefered === "1"
  //   );
  //   setVehicleDetails(selectedCar);
  // }, [props?.userData?.full_profile])

  return (
    <div>
      <InnerHeader
        title={"Checkout"}
        selected_vehicle={vehicleDetails}
        setSheet={(boolean) => setOpenModal(boolean)}
      />
      {openModal && (
        <VehicleSelector
          navigate={true}
          hideBar={true}
          open={true}
          setModal={(bool) => {
            fetchData();
            setOpenModal(bool);
          }}
        />
      )}
      <BottomSheet open={bottomSheet} onDismiss={() => setBottomSheet(false)}>
        <h4
          style={{
            color: colors.common.black,
            fontWeight: "bold",
            marginLeft: "15px",
          }}
        >
          My Garage
        </h4>
        <div
          style={{
            height: "40vh",
            overflowY: "scroll",
          }}
        >
          {vehicles?.map((item) => (
            <div
              style={{
                border: `1px solid ${
                  item?.is_prefered === "1" ? "red" : "lightgrey"
                }`,
                margin: "10px",
                padding: "20px 10px 20px 10px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => makeDefaultVehicle(item?.id)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={item?.icon} width={90} />
                <div style={{ marginLeft: "10px" }}>
                  <a style={{ color: colors.common.black, fontWeight: "bold" }}>
                    {item?.model_name}
                  </a>
                  <br />
                  <a>{item?.variant_name}</a>
                </div>
              </div>
              <Trash3
                size={20}
                color={"red"}
                onClick={() => removeVehicle(item?.id)}
              />
            </div>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            style={{ width: "93%", borderRadius: "5px" }}
            class="theme-btn btn-style-four btn-sm px-2 py-2 my-2"
          >
            <span class="txt">Add New Car +</span>
          </button>
        </div>
      </BottomSheet>
      {/* <div
        className="mt-3"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          style={{
            width: "95%",
            maxHeight: "150px",
            border: "1px solid red",
            borderRadius: "5px",
            padding: '5px 0 5px 0'
          }}
        >
          <div className="d-flex justify-content-between pt-2 px-2">
            <div>
              <img
                src={require("../../../../assets/images/bottom-tab-icons/prime_icon.png")}
                width={20}
                height={15}
              />
              <a className="fw-bold text-dark">Get Prime for 1 year</a>
            </div>
            <div
              className="d-flex justify-content-center"
              style={{
                backgroundColor: "red",
                padding: "4px",
                borderRadius: "5px",
                width: "70px",
                position: "relative",
              }}
            >
              <a className="fw-bold text-white" type="button">
                ADD
              </a>
              <a
                className="fw-bold"
                style={{
                  position: "absolute",
                  color: "white",
                  right: 5,
                  top: 0,
                }}
              >
                +
              </a>
            </div>
          </div>
          <div className="d-flex justify-content-between px-2 pt-1">
            <a style={{ width: "200px", lineHeight: "15px" }}>
              Get upto 55% off with Prime Membership.
            </a>
            <a className="fw-bold text-dark">₹699</a>
          </div>
          <div className="px-2 mt-1 d-flex align-items-center">
            <a style={{ color: "red" }}>Learn more</a>
            <CaretRightFill size={14} color={"red"} />{" "}
          </div>
        </div>
      </div> */}
      <div className="mx-3" style={{ marginTop: "90px" }}>
        {cartItems?.map((item) => {
          const discounted_price = item?.discount
            ? Math.ceil(
                parseInt(item?.price) -
                  (parseInt(item?.price) * item?.discount) / 100
              )
            : false;
          item["discounted_price"] = discounted_price;
          return (
            <div className="d-flex justify-content-between align-items-center mt-3">
              <a className="text-dark" style={{ fontWeight: "600" }}>
                <i className="fa fa-dot-circle-o" style={{ color: "grey" }}></i>{" "}
                {item?.name}
              </a>
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  {discounted_price && (
                    <span className="text-dark">
                      ₹{discounted_price} <br />
                    </span>
                  )}
                  <span
                    style={{
                      textDecoration: discounted_price
                        ? "line-through"
                        : "auto",
                      color: discounted_price ? "auto" : "black",
                    }}
                  >
                    ₹{Math.ceil(item?.price)}
                  </span>
                </div>
                <Trash2Fill
                  onClick={() => removeCart(item?.id)}
                  color="red"
                  size={15}
                  style={{ marginBottom: "2px", marginLeft: "10px" }}
                />
              </div>
            </div>
          );
        })}
        <div
          className="d-flex align-items-center justify-content-between px-2"
          style={{
            backgroundColor: "#f9e2e2",
            marginTop: "20px",
            borderRadius: "5px",
          }}
        >
          <div className="d-flex align-items-center py-2">
            <img
              src={require("../../../../assets/images/inspection_icon.png")}
              width={50}
              height={50}
            />
            <a className="text-dark" style={{ fontWeight: "600" }}>
              Vehicle Inspection
            </a>
          </div>
          <div
            onClick={() => {
              inspection
                ? setTotal(total - inspectionCost)
                : setTotal(total + inspectionCost);
              setInspection(!inspection);
            }}
            className="d-flex justify-content-center"
            style={{
              backgroundColor: "white",
              border: "1px solid red",
              width: "85px",
              borderRadius: "5px",
              position: "relative",
            }}
          >
            {!inspection ? (
              <div>
                <a style={{ color: "red" }} className=" fw-bold">
                  ₹{inspectionCost}{" "}
                  {inspectionCost == 0 && (
                    <a style={{ textDecoration: "line-through" }}>250</a>
                  )}
                </a>
                <a
                  className="position-absolute fw-bold"
                  style={{ bottom: "4px", right: "2px", color: "red" }}
                >
                  +
                </a>
              </div>
            ) : (
              <a style={{ color: "red" }} className=" fw-bold">
                Remove
              </a>
            )}
          </div>
        </div>
        <div
          className="p-3"
          style={{
            boxShadow: "0px 0px 5px 1px lightgrey",
            marginBottom: "20px",
            marginTop: "20px",
            borderRadius: "5px",
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <a className="text-dark">Sub Total</a>
            <a className="text-dark">₹{subTotal}</a>
          </div>
          {inspection && (
            <div className="d-flex justify-content-between align-items-center">
              <a className="text-dark">Inspection</a>
              <a className="text-dark">₹{inspectionCost}</a>
            </div>
          )}

          <hr style={{ margin: "5px 0px" }} />
          <div className="d-flex justify-content-between align-items-center">
            <a className="text-dark fw-bold">Total</a>
            <a style={{ color: "red", fontWeight: "bold" }}>₹{total}</a>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div onClick={() => setSelectedMethod("Online")}>
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: "red",
                },
              }}
              style={{ width: "30px" }}
              size="small"
              checked={selectedMethod == "Online"}
            />
            <span className="fw-bold" style={{ fontSize: "10px" }}>
              Pay Online
            </span>
          </div>
          <div onClick={() => setSelectedMethod("100")}>
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: "red",
                },
              }}
              style={{ width: "30px", marginLeft: "10px" }}
              size="small"
              checked={selectedMethod == "100"}
            />
            <span className="fw-bold" style={{ fontSize: "10px" }}>
              Pay ₹100
            </span>
          </div>
          <div onClick={() => setSelectedMethod("Cash")}>
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: "red",
                },
              }}
              style={{ width: "30px", marginLeft: "10px" }}
              size="small"
              checked={selectedMethod == "Cash"}
            />
            <span className="fw-bold" style={{ fontSize: "10px" }}>
              Pay Later
            </span>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          onClick={() => {
            logFirebaseEvent("mobile_web_pay_button");
            navigate("/appointment", {
              state: {
                amount: total,
                inspection,
                inspection_cost: inspectionCost,
                cart_items: cartItems,
                payment_option: selectedMethod,
                vehicle_details: vehicleDetails,
              },
            });
          }}
        >
          <button
            style={{ width: "100%", borderRadius: "5px" }}
            className="theme-btn btn-style-four btn-sm px-2 py-2 my-2"
          >
            <span class="txt">
              Pay{" "}
              {selectedMethod === "Cash"
                ? "Later"
                : selectedMethod === "Online"
                ? "₹" + total
                : "₹100"}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.cartList = state.default.cartListReducer;
  stateObj.servicePackageList = state.default.servicePackagesListReducer;
  return stateObj;
};

const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  updateCart: (params) => dispatch(params),
  updateUserVehicle: (params) => UserAction.updateUserVehicle(params, dispatch),
  getServiceData: (params) =>
    ServicePackagesListAction.getServiceData(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
