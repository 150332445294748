import { colors } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const InnerHeader = ({ title, selected_vehicle, setSheet }) => {
    const navigate = useNavigate();
  return (
    <div
      className="d-flex justify-content-between align-items-center px-2 position-fixed"
      style={{ boxShadow: "0 0 10px 1px lightgrey", height: '8vh', zIndex: 10, backgroundColor: 'white', width: '100vw', top: '0px' }}
    >
      <div
        className="d-flex justify-content-between align-items-center"
        // style={{ width: "47%" }}
      >
        <img
          src={require("../../../../../assets/images/Blackback_icon.png")}
          width={25}
          height={25}
          onClick={() => navigate('/')}
        />{" "}
        <a
          style={{
            fontSize: "16px",
            color: "black",
            fontWeight: "bold",
            marginLeft: '10px'
          }}
        >
          {title}
        </a>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => setSheet(true)}
      >
        <img src={selected_vehicle?.icon} width={40} />
        <a
          style={{
            fontWeight: "bold",
            color: colors.common.black,
            fontSize: "12px",
          }}
        >
          {selected_vehicle?.model_name}
        </a>
      </div>
    </div>
  );
};

export default InnerHeader;
