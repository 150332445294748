import React, { useEffect, useState } from "react";
import { Row, Form } from "react-bootstrap";
import "./index.scss";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { da, enGB } from "date-fns/locale";
import Cart from "../../Services/Cart";
import CustomModal from "../../../../../components/common/CustomModal";
import { addDays } from "date-fns";
import { OrderAction, PaymentAction } from "../../../../../store/action";
import { Successful } from "../../../../../constants/ImageConstants";
import { isMobile } from "react-device-detect";
import moment from "moment";
import {
  CART_LIST,
  SERVICE_BOOKING,
  SHOW_HIDE_LOADER,
} from "../../../../../store/types";
import { getNavigateUrl, logFirebaseEvent } from "../../../../../utils/customHelper";
import { initializePayment } from "../../../../../utils/paymentHelper";
import axios from "axios";

registerLocale("en", enGB);
setDefaultLocale("en");

const ErrorOutput = (props) => {
  let name = props.name;
  let inputValue = props.case;
  if (name === "carNumber") {
    if (!inputValue?.match(/^[a-zA-Z0-9]+$/) && inputValue?.length > 0) {
      return <span className="text-danger">Invalid Registration Number</span>;
    }
    return <span></span>;
  }
  // if (name === "userPincode") {
  //   if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
  //     return <span className="text-danger">Numbers only</span>;
  //   }
  //   return <span></span>;
  // }
  return <></>;
};

const VehicleInfo = (props) => {
  const paymentReturnUrl = "servicePaymentresponse";
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState({
    userState: "",
    userCity: "",
    carNumber: "",
    carYear: "",
    visitType: "2",
    paymentMode: "ONLINE",
    paymentType: "2",
  });
  const update = (e) => {
    if (e.target.name === "carNumber" && e.target.value) {
      e.target.value = e.target.value.toUpperCase();
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const [show, setShow] = useState(false);
  const [cartShow, setCartShow] = useState(false);
  const [params, setParams] = useState(null);
  const [validated, setValidated] = useState(false);
  const [value, onChange] = useState("10:00");

  useEffect(() => {
    if (!props?.params?.state?.amount) {
      navigate("/");
    }
  }, [props?.params]);
  const {
    customDispatch,
    vehicleData,
    userData,
    serviceBookingData,
    cartList: cartListData,
    createPaymentSession,
    paymentSessionData,
    servicePackageList,
  } = props;
  const { cartList } = cartListData;
  const { user_profile } = userData;
  const serviceListData = servicePackageList.servicePackageList;
  const servicePackageData = serviceListData.filter(
    (data) => cartList.indexOf(data.id) > -1
  );
  const { brand, model, variant } = vehicleData;
  const { serviceBookingResponse } = serviceBookingData;
  const navigate = useNavigate();
  const handleClose = () => navigate(getNavigateUrl("/"));
  const handleCartClose = () => setCartShow(false);
  const submitData = (e) => {
    logFirebaseEvent('mobile_web_place_order');
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
      const apiParam = {
        task: "createServiceBooking",
        brand_id: props?.params?.state?.vehicle_details?.brand_id,
        model_id: props?.params?.state?.vehicle_details?.model_id,
        variant_id: props?.params?.state?.vehicle_details?.variant_id,
        car_reg: data.carNumber,
        visit_type: data.visitType,
        service_ids: cartList,
        payment_mode: "CASH",
        visit_date: moment(selectedDate).format("DD MMMM, YYYY"),
        user_address: {
          city: data.userCity,
          state: data.userState,
          address: "",
          pincode: "",
        },
        coupon_code: "",
      };
      props.createServiceBooking(apiParam);
    }
    setValidated(true);
  };

  useEffect(() => {
    if (props?.params?.state?.payment_option == "Cash") {
      setData({ ...data, paymentType: "1" });
    } else setData({ ...data, paymentType: "2" });
  }, [props?.params?.state?.payment_option]);
  const CartModalContent = () => {
    return (
      <Row>
        <div className="sidebar-side left-sidebar col-lg-4 col-md-12 col-sm-12">
          <Cart hideOrderBtn={true} />
        </div>
      </Row>
    );
  };
  useEffect(() => {
    // const getTotalPrice = () => {
    //   let price = 0;
    //   servicePackageData.forEach((element) => {
    //     price += parseInt(element.price);
    //   });
    //   return price;
    // };
    if (
      paymentSessionData.sessionResponse !== null &&
      paymentSessionData.sessionResponse.data.payment_session_id
    ) {
      initializePayment(
        paymentSessionData.sessionResponse.data.payment_session_id,
        paymentReturnUrl
      );
      customDispatch({ type: CART_LIST, payload: [] });
      customDispatch({ type: SERVICE_BOOKING, payload: null });
    }
    if (serviceBookingResponse !== null) {
      axios.post(
        `https://api.vehiclecare.app/api/app/order-booking-data?lead_id=${
          serviceBookingResponse.bookings.booking_id
        }&user_id=${user_profile.id}&data=${JSON.stringify({
          extra_discount: { inspection: props?.params?.state?.inspection },
          total_price: props?.params?.state?.amount?.toString(),
          products: props?.params?.state?.cart_items,
          prime_member: null,
          rsa: null,
          prime_cost: null,
          inspection_cost: props?.params?.state?.inspection_cost,
          payment_option: props?.params?.state?.payment_option,
        })}`
      );
      if (data.paymentType === "1") {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        customDispatch({ type: CART_LIST, payload: [] });
        customDispatch({ type: SERVICE_BOOKING, payload: null });
        const custparams = {};
        custparams.content = (
          <Row>
            <div className="col-12">
              <div className="row h-100">
                <div className="col-12 text-center">
                  <img src={Successful} alt="" className="w-25" />
                </div>

                <div className="col-12 text-center my-auto">
                  <h4>
                    <b>
                      <font color="black">
                        Congratulations {user_profile.first_name} !!
                      </font>
                    </b>
                  </h4>
                  <h6>+91-{user_profile.phone}</h6>
                  <h5 style={{ color: "#40c057" }}>
                    <b>Your Booking is successfully Created.</b>
                  </h5>
                  <p>
                    Our Executive will shortly join on your service journey.
                  </p>
                  <button
                    type="button"
                    className="theme-btn btn-style-nine my-3 mx-5"
                    onClick={() => navigate(getNavigateUrl("/"))}
                  >
                    <span className="txt">Home</span>
                  </button>
                </div>
              </div>
            </div>
          </Row>
        );
        setParams(custparams);
        setShow(true);
      } else {
        const custom_id = serviceBookingResponse.bookings.booking_id;
        const params = {
          task: "getpaymentGatewayOrderDetails",
          order_id: `VC-ServiceBooking-${moment().format(
            "DD-MM-YY-HH-mm-ss"
          )}-${custom_id}`,
          // order_amount: getTotalPrice(),
          order_amount:
            user_profile.phone == "8888888888"
              ? "1"
              : props?.params?.state?.payment_option == "100"
              ? "100"
              : props?.params?.state?.amount?.toString(),
          customer_id: user_profile.id,
          customer_phone: user_profile.phone,
        };
        createPaymentSession(params);
      }
    }
  }, [
    serviceBookingResponse,
    navigate,
    customDispatch,
    data,
    createPaymentSession,
    paymentSessionData,
    servicePackageData,
    user_profile,
    cartList,
  ]);
  return (
    <>
      <div className="auto-container custom-appointment-container">
        {/* {isMobile ? <i className="fa fa-shopping-cart text-danger custom-mobile-cart pull-right" onClick={() => setCartShow(true)} aria-hidden="true"></i> : ""} */}

        {/* <div className="sec-title centered">
          <div className="title">Appointment</div>
          <h2>Service Booking</h2>
        </div> */}

        <div className="row clearfix">
          <div className="right-sidebar col-lg-8 col-md-12 col-sm-12 shop-form">
            <div className="lower-section">
              <div className="appointment-form login-form">
                <Form noValidate validated={validated} onSubmit={submitData}>
                  <div>
                    <div className="row clearfix">
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <div
                          className="radio-box bg-transparent"
                          style={{ width: "50vw" }}
                        >
                          <input
                            type="radio"
                            name="visitType"
                            value="2"
                            checked={data.visitType === "2"}
                            onChange={update}
                            id="pickup"
                          />
                          <Form.Label
                            style={{
                              fontWeight: "normal",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                            className="bg-transparent"
                            for="pickup"
                          >
                            Pickup from home
                          </Form.Label>
                        </div>
                        <div className="radio-box">
                          <input
                            type="radio"
                            name="visitType"
                            value="1"
                            checked={data.visitType === "1"}
                            onChange={update}
                            id="drop"
                          />
                          <Form.Label
                            style={{
                              fontWeight: "normal",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                            className="bg-transparent"
                            for="drop"
                          >
                            Self Drop
                          </Form.Label>
                        </div>
                      </div>

                      {/* <div className="form-group col-lg-6 col-md-6 col-sm-12">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="userName" placeholder="Please enter Name" value={data.userName} aria-describedby="inputGroupPrepend" required onChange={update} />
                        <Form.Control.Feedback type="invalid">Enter Name</Form.Control.Feedback>
                        <ErrorOutput case={data.userName} name={"userName"} />
                      </div> */}
                      <div className="form-group col-lg-6 col-md-6 col-sm-12">
                        <Form.Label>Pickup Date</Form.Label>
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          // showTimeSelect
                          style={{ height: "40px" }}
                          minDate={new Date()}
                          maxDate={addDays(new Date(), 7)}
                          dateFormat="MMMM d, yyyy"
                        />
                      </div>
                      {/* <div className="form-group col-lg-4 col-md-6 col-sm-12">
                        <Form.Label>Car Registration Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="carNumber"
                          placeholder="Enter Registration"
                          value={data.carNumber}
                          aria-describedby="inputGroupPrepend"
                          required
                          onChange={update}
                          style={{height: '40px'}}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter Registration Number
                        </Form.Control.Feedback>
                        <ErrorOutput case={data.carNumber} name={"carNumber"} />
                      </div> */}
                      {/* <div className="form-group col-lg-4 col-md-6 col-sm-12">
                        <Form.Label>Car Model Year</Form.Label>
                        <Form.Control
                          type="text"
                          name="carYear"
                          placeholder="Enter Car Model Year"
                          aria-describedby="inputGroupPrepend"
                          required
                          maxLength={4}
                          onChange={update}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your Car Model Year
                        </Form.Control.Feedback>
                        <ErrorOutput case={data.carYear} name={"carYear"} />
                      </div> */}
                      {/* <div className="form-group col-lg-6 col-md-6 col-sm-12">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          name="userAddress"
                          placeholder="Enter Your Address"
                          aria-describedby="inputGroupPrepend"
                          required
                          onChange={update}
                          style={{height: '40px'}}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your Address.
                        </Form.Control.Feedback>
                        <ErrorOutput
                          case={data.userAddress}
                          name={"userAddress"}
                        />
                      </div> */}
                      {/* <div className="form-group col-lg-4 col-md-6 col-sm-12">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          name="userState"
                          placeholder="Enter State"
                          aria-describedby="inputGroupPrepend"
                          required
                          onChange={update}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your State
                        </Form.Control.Feedback>
                        <ErrorOutput case={data.userState} name={"userState"} />
                      </div> */}
                      {/* <div className="form-group col-lg-6 col-md-6 col-sm-12">
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" name="userCity" placeholder="Type Your City here" aria-describedby="inputGroupPrepend" required onChange={update} />
                        <Form.Control.Feedback type="invalid">Please enter your City</Form.Control.Feedback>
                        <ErrorOutput case={data.userCity} name={"userCity"} />
                      </div> */}
                      {/* <div className="form-group col-lg-6 col-md-6 col-sm-12">
                        <Form.Label>City</Form.Label>
                        <input
                          type="text"
                          list="citylist"
                          value={data.userCity}
                          placeholder="Select City"
                          aria-describedby="inputGroupPrepend"
                          name="userCity"
                          onChange={update}
                        />
                        <datalist id="citylist">
                          {props?.cityList?.cityList.map((city) => {
                            return (
                              <option value={city.name} key={city.id}>
                                {city.name}
                              </option>
                            );
                          })}
                        </datalist>
                        <Form.Control.Feedback type="invalid">
                          Please select city
                        </Form.Control.Feedback>
                        <ErrorOutput case={data.userCity} name={"userCity"} />
                      </div> */}
                      {/* <div className="form-group col-lg-6 col-md-6 col-sm-12">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control type="text" name="userPincode" placeholder="Enter Your Pincode here" aria-describedby="inputGroupPrepend" required maxLength={6} onChange={update} />
                        <Form.Control.Feedback type="invalid">Please enter your Pincode</Form.Control.Feedback>
                        <ErrorOutput case={data.userPincode} name={"userPincode"} />
                      </div> */}

                      {/* <div className="form-group col-lg-6 col-md-6 col-sm-12">
                        <Form.Label>Payment Option</Form.Label>
                        <div className="radio-box">
                          <input
                            type="radio"
                            name="paymentType"
                            value="1"
                            checked={data.paymentType === "1"}
                            onChange={update}
                            id="cod"
                          />
                          <Form.Label className="bg-transparent" for="cod">
                            {" "}
                            Cash
                          </Form.Label>
                        </div>
                        <div className="radio-box bg-transparent">
                          <input
                            type="radio"
                            name="paymentType"
                            value="2"
                            checked={data.paymentType === "2"}
                            onChange={update}
                            id="online"
                          />
                          <Form.Label className="bg-transparent" for="online">
                            {" "}
                            Online
                          </Form.Label>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="form-group mt-3">
                    <button
                      style={{ width: "100%", borderRadius: "5px" }}
                      disabled={!cartList.length}
                      className="theme-btn btn-style-four"
                      type="submit"
                      name="submit-form"
                    >
                      Continue
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          {!isMobile && (
            <div className="sidebar-side left-sidebar col-lg-4 col-md-12 col-sm-12">
              <Cart passingData={props.vehicleData} hideOrderBtn={true} />
            </div>
          )}
        </div>
      </div>

      <CustomModal
        show={show}
        {...params}
        isCloseButton={true}
        handleClose={handleClose}
      />
      {/* <CustomModal show={show} modalSize="lg" {...params} isCloseButton={true} handleClose={handleClose} /> */}
      <CustomModal
        show={cartShow}
        title="Cart"
        content={<CartModalContent />}
        isCloseButton={true}
        handleClose={handleCartClose}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.cartList = state.default.cartListReducer;
  stateObj.cityList = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  stateObj.paymentSessionData = state.default.paymentReducer;
  stateObj.servicePackageList = state.default.servicePackagesListReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceBooking: (params) =>
    OrderAction.createServiceBooking(params, dispatch),
  createPaymentSession: (params) =>
    PaymentAction.createPaymentSession(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(VehicleInfo);
