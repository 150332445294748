import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./index.scss";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import { VehicleAction } from "../../../../../store/action";
import { CreateInsuranceLeadAction, InsuranceCompanyList, UploadInsuranceMediaAction } from "../../../../../store/action";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../../../layout/NavigationBar/Footer";
import Header from "../../../layout/NavigationBar/Header";
import { CLEAR_DATA, CLEAR_FILE_URL_ARRAY, SHOW_HIDE_LOADER } from "../../../../../store/types";
import { Successful } from "../../../../../constants/ImageConstants";
import CustomModal from "../../../../../components/common/CustomModal";
import { useNavigate } from "react-router-dom";
import { getNavigateUrl } from "../../../../../utils/customHelper";

const ErrorOutput = (props) => {
  let name = props.name;
  let inputValue = props.case;
  if (name === "insured_name") {
    if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
      return <span className="text-danger">Letters only</span>;
    }
    return <span></span>;
  }
  if (name === "insured_phone") {
    if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
      return <span className="text-danger">Numbers only</span>;
    }
    return <span></span>;
  }
  if (name === "insured_email") {
    if (!inputValue?.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) && inputValue?.length > 0) {
      return <span className="text-danger">Invalid email address</span>;
    }
    return <span></span>;
  }
  if (name === "car_year") {
    if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
      return <span className="text-danger">Numbers only</span>;
    }
    return <span></span>;
  }
  if (name === "car_reading") {
    if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
      return <span className="text-danger">Numbers only</span>;
    }
    return <span></span>;
  }
  if (name === "car_registration") {
    if (!inputValue?.match(/^[A-Z0-9._%+-]+$/) && inputValue?.length > 0) {
      return <span className="text-danger">Invalid Registration Number</span>;
    }
    return <span></span>;
  }
  // if (name === "insured_city") {
  //   if (!inputValue?.match(/^[a-z 0-9A-Z]+$/) && inputValue?.length > 0) {
  //     return <span className="text-danger">Letters only</span>;
  //   }
  //   return <span></span>;
  // }
  // if (name === "insured_pincode") {
  //   if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
  //     return <span className="text-danger">Numbers only</span>;
  //   }
  //   return <span></span>;
  // }
  // if (name === "insurance_type") {
  //   if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
  //     return <span className="text-danger">Letters only</span>;
  //   }
  //   return <span></span>;
  // }
  // if (name === "cause_of_damage") {
  //   if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
  //     return <span className="text-danger">Letters only</span>;
  //   }
  //   return <span></span>;
  // }
  // if (name === "insured_type") {
  //   if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
  //     return <span className="text-danger">Letters only</span>;
  //   }
  //   return <span></span>;
  // }
  return <></>;
};

const MakeClaim = (props) => {
  const navigate = useNavigate();
  const claimType = [
    { id: 1, name: "Theft" },
    { id: 2, name: "Windshield" },
    { id: 3, name: "Collision" },
    { id: 4, name: "Storm/Flood" },
    { id: 5, name: "Loss/Theft of Key" },
    { id: 6, name: "Malicious Damage" },
  ];
  const { userData, vehicleData: vehicleInfo } = props;
  const { is_user_logged_in, user_profile } = userData;
  const { brandList, modelList } = vehicleInfo;
  const [validated, setValidated] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [show, setShow] = useState(false);
  const [params, setParams] = useState(null);
  const [data, setData] = useState({
    // claimType: "",
    insurance_company: "",
    //assistance: "no",
    car_brand: "",
    car_model: "",
    car_variant: "",
    insured_name: `${user_profile?.first_name} ${user_profile?.last_name}` || "",
    insured_email: `${user_profile?.email}` || "",
    insured_phone: `${user_profile.phone}` || "",
    car_registration: "",
    //insured_type: "Insured",
    car_year: "",
    car_reading: "",
    // insured_address: "",
    // insured_city: props.cityList.selectedCity,
    // insured_pincode: "",
    // insurance_type: "zeroDept",
    // cause_of_damage: "",
    policy_pdf: "",
    images: "",
    //claim_date: "",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [variantList, setVariant] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const insuranceCompanyList = props.insuranceCompanyList;
  const handleClose = () => navigate(getNavigateUrl("/"));
  const handleClick = (e) => {
    if (e.target.name === "car_registration" && e.target.value) {
      e.target.value = e.target.value.toUpperCase();
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const changeImageHandler = (event) => {
    setSelectedImageFile(event.target.files);
  };
  const changeHandler = (event) => {
    setSelectedFile(event.target.files);
  };
  const customFormCheck = () => {
    let responseIsInvalid = false;
    if (!claimType?.filter((e) => e.name === data.claimType).length) {
      console.log("ata");
      data.claimType = "";
      responseIsInvalid = true;
    }
    if (!insuranceCompanyList?.filter((e) => e.name === data.insurance_company).length) {
      data.insurance_company = "";
      responseIsInvalid = true;
    }
    if (!brandList?.filter((e) => e.name === data.car_brand).length) {
      data.car_brand = "";
      responseIsInvalid = true;
    }
    if (!modelList?.filter((e) => e.name === data.car_model).length) {
      data.car_model = "";
      responseIsInvalid = true;
    }
    if (!variantList?.filter((e) => e.name === data.car_variant).length) {
      data.car_variant = "";
      responseIsInvalid = true;
    }
    console.log(data);
    return responseIsInvalid;
  };
  const submitForm = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    customFormCheck();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      props.customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
      data.claim_date = startDate;
      props.getInsuranceLead({
        task: "createInsuranceLead",
        insured_name: data.insured_name,
        insured_email: data.insured_email,
        insured_phone: data.insured_phone,
        car_brand: data.car_brand,
        car_model: data.car_model,
        car_year: data.car_year,
        car_variant: data.car_variant,
        car_reading: data.car_reading,
        car_registration: data.car_registration,
        // claim_date: data.claim_date,
        // insured_address: data.insured_address,
        // insured_city: data.insured_city,
        // insured_pincode: data.insured_pincode,
        //insurance_type: data.insurance_type,
        insurance_company: data.insurance_company,
        // cause_of_damage: data.cause_of_damage,
        // insured_type: data.insured_type,
        // rsa: data.assistance,
        // claim_type: data.claimType,
        user_id: user_profile.id,
      });
    }
    setValidated(true);
  };
  const getModelList = (val) => {
    const selectedBrandName = val;
    const newBrandList = vehicleInfo.brandList.filter((elemn) => elemn.name === selectedBrandName);
    if (newBrandList[0]?.id?.length > 0) {
      setData({ ...data, car_brand: selectedBrandName, car_model: "", car_variant: "" });
      const selectedBrandId = newBrandList[0]?.id;
      const params = { task: "getCardModels", brand_id: selectedBrandId };
      props.getModelNames(params);
    }
  };
  const getVariantList = (val) => {
    const modelName = val;
    const newModelList = vehicleInfo.modelList.filter((elemn) => elemn.model_name === modelName);
    setData({ ...data, car_model: modelName, car_variant: "" });
    setVariant(newModelList?.[0].variants);
  };
  const setSelectedVariant = (val) => {
    const selectedVariantName = val;
    setData({ ...data, car_variant: selectedVariantName });
  };
  const getFileNames = (data) => {
    const names = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      names.push(
        <>
          <br />
          <span>{element.name}</span>
        </>
      );
    }
    return names;
  };
  useEffect(() => {
    if (!is_user_logged_in) {
      document.getElementsByClassName("userprofile_icon")[0].click();
    }
    if (!props.insuranceCompanyList.length) props.getInsuranceCompanies({ task: "getInsuranceCompanies" });
  }, [is_user_logged_in, props]);
  useEffect(() => {
    const handleSubmission = () => {
      if (isUploading) return false;
      setIsUploading(true);
      props.sendInsuranceMedia({
        task: "uploadInsuranceMedia",
        proposal_id: props.proposalID?.proposal_id,
        type: "document",
        file: selectedFile,
      });
      if (selectedImageFile.length) handleImageSubmission();
    };
    const handleImageSubmission = () => {
      props.sendInsuranceMedia({
        task: "uploadInsuranceMedia",
        proposal_id: props.proposalID?.proposal_id,
        type: "image",
        file: selectedImageFile,
      });
    };
    if (props.proposalID?.proposal_id) {
      if (!isUploading && selectedFile.length) handleSubmission();
      if (!isUploading && !selectedFile.length && selectedImageFile.length) handleImageSubmission();
      if (
        (!selectedFile.length && !selectedImageFile.length) ||
        (!selectedImageFile.length && props.uploadedMedia.file_url_array?.Urls?.length) ||
        (selectedFile.length && selectedImageFile.length && props.uploadedMedia.image_url_array?.Urls?.length)
      ) {
        props.customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        props.customDispatch({ type: CLEAR_FILE_URL_ARRAY });
        props.customDispatch({ type: CLEAR_DATA });
        const custparams = {};
        custparams.content = (
          <Row>
            <div className="col-12">
              <div className="row h-100">
                <div className="col-4 text-center">
                  <img src={Successful} alt="" className="w-100" />
                </div>

                <div className="col-8 text-left my-auto">
                  <h4>
                    <b>
                      <font color="black">Congratulations!!</font>
                    </b>
                  </h4>
                  <h5 style={{ color: "#40c057" }}>
                    <b>Your Claim is successfully Created.</b>
                  </h5>
                  <h6>Our Executive will shortly join on your Claim journey.</h6>
                  <button type="button" className="theme-btn btn-style-nine my-3 mx-5" onClick={() => navigate(getNavigateUrl("/"))}>
                    <span className="txt">Home</span>
                  </button>
                </div>
              </div>
            </div>
          </Row>
        );
        setParams(custparams);
        setShow(true);
      }
    }
  }, [props, selectedFile, selectedImageFile, isUploading, navigate]);
  return (
    <>
      <Header isStatic={true} isNavigate={true} />

      <Row className="price-section aboutus mt-5 custom-claim-form">
        <Col className="">
          <div className="auto-container">
            <div className="sec-title centered">
              <div className="title">Make a Claim</div>
              <h2>Information</h2>
            </div>

            <div className="row clearfix">
              <div className="appointment-form login-form">
                <div className="right-sidebar col-lg-12 col-md-12 col-sm-12">
                  <div className="lower-section">
                    <div className="appointment-form login-form">
                      <Form noValidate validated={validated} onSubmit={submitForm}>
                        <div className="row clearfix">
                          {/* <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <Form.Label>Select Claim Type</Form.Label>
                            <input
                              type="text"
                              list="claimType"
                              placeholder="Select Claim Type"
                              aria-describedby="inputGroupPrepend"
                              name="claimType"
                              onChange={(e) => handleClick(e)}
                              value={data.claimType}
                              autoComplete="off"
                              required
                            />
                            <datalist id="claimType">
                              {claimType?.map((claim) => {
                                return (
                                  <option value={claim.name} key={claim.id}>
                                    {claim.name}
                                  </option>
                                );
                              })}
                            </datalist>
                            <Form.Control.Feedback type="invalid">Please select claim type</Form.Control.Feedback>
                          </div> */}

                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <Form.Label>Insurance Company</Form.Label>
                            <input
                              type="text"
                              list="insuranceCompanyList"
                              placeholder="Select Insurance Company"
                              aria-describedby="inputGroupPrepend"
                              name="insurance_company"
                              onChange={(e) => handleClick(e)}
                              value={data.insurance_company}
                              autoComplete="off"
                              required
                            />
                            <datalist id="insuranceCompanyList">
                              {insuranceCompanyList?.map((company) => {
                                return (
                                  <option value={company.name} key={company.id}>
                                    {company.name}
                                  </option>
                                );
                              })}
                            </datalist>
                            <Form.Control.Feedback type="invalid">Please select Insurance Company</Form.Control.Feedback>
                          </div>

                          {/* <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Do You Need Emergency Road Side Assistance</Form.Label>
                            <div className="radio-box">
                              <input type="radio" name="assistance" value="yes" checked={data.assistance === "yes"} onChange={(e) => handleClick(e)} id="yes" />
                              <Form.Label className="bg-transparent" for="yes">
                                Yes
                              </Form.Label>
                            </div>
                            <div className="radio-box bg-transparent">
                              <input type="radio" name="assistance" value="no" checked={data.assistance === "no"} onChange={(e) => handleClick(e)} id="no" />
                              <Form.Label className="bg-transparent" for="no">
                                No
                              </Form.Label>
                            </div>
                          </div> */}

                          <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Car Brand</Form.Label>
                            <input
                              type="text"
                              list="brandlist"
                              placeholder="Select Car Brand"
                              aria-describedby="inputGroupPrepend"
                              name="car_brand"
                              required
                              onChange={(e) => {
                                handleClick(e);
                                getModelList(e.target.value);
                              }}
                              value={data.car_brand}
                              autoComplete="off"
                            />
                            <datalist id="brandlist">
                              {brandList?.map((brand) => {
                                return (
                                  <option value={brand.name} key={brand.id}>
                                    {brand.name}
                                  </option>
                                );
                              })}
                            </datalist>
                            <Form.Control.Feedback type="invalid">Please select car brand</Form.Control.Feedback>
                            <ErrorOutput case={data.car_brand} name={"car_brand"} />
                          </div>
                          <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Car Model</Form.Label>
                            <input
                              type="text"
                              list="modellist"
                              placeholder="Select Car Model"
                              aria-describedby="inputGroupPrepend"
                              name="car_model"
                              required
                              onChange={(e) => {
                                handleClick(e);
                                getVariantList(e.target.value);
                              }}
                              value={data.car_model}
                              autoComplete="off"
                            />
                            <datalist id="modellist">
                              {modelList?.map((model) => {
                                return (
                                  <option value={model.model_name} key={model.model_id}>
                                    {model.model_name}
                                  </option>
                                );
                              })}
                            </datalist>
                            <Form.Control.Feedback type="invalid">Please select car model</Form.Control.Feedback>
                            <ErrorOutput case={data.car_model} name={"car_model"} />
                          </div>
                          <div className="form-group col-lg-2 col-md-6 col-sm-12">
                            <Form.Label>Car Variant</Form.Label>
                            <input
                              type="text"
                              list="variantlist"
                              placeholder="Select Car Variant"
                              aria-describedby="inputGroupPrepend"
                              name="car_variant"
                              required
                              onChange={(e) => {
                                handleClick(e);
                                setSelectedVariant(e.target.value);
                              }}
                              value={data.car_variant}
                              autoComplete="off"
                            />
                            <datalist id="variantlist">
                              {variantList?.map((variant) => {
                                return (
                                  <option value={variant.variant} key={variant.id}>
                                    {variant.variant}
                                  </option>
                                );
                              })}
                            </datalist>
                            <Form.Control.Feedback type="invalid">Please select car variant</Form.Control.Feedback>
                            <ErrorOutput case={data.car_variant} name={"car_variant"} />
                          </div>

                          <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Insured Name</Form.Label>
                            <input type="text" name="insured_name" value={data.insured_name} aria-describedby="inputGroupPrepend" required onChange={(e) => handleClick(e)} />
                            <Form.Control.Feedback type="invalid">Please enter your name</Form.Control.Feedback>
                            <ErrorOutput case={data.insured_name} name={"insured_name"} />
                          </div>

                          <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Insured Email </Form.Label>
                            <input type="text" name="insured_email" value={data.insured_email} aria-describedby="inputGroupPrepend" required onChange={(e) => handleClick(e)} />
                            <Form.Control.Feedback type="invalid">Please enter your email</Form.Control.Feedback>
                            <ErrorOutput case={data.insured_email} name={"insured_email"} />
                          </div>

                          <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Insured Phone Number</Form.Label>
                            <input type="text" maxLength={10} name="insured_phone" value={data.insured_phone} aria-describedby="inputGroupPrepend" required onChange={(e) => handleClick(e)} />
                            <Form.Control.Feedback type="invalid">Please enter your phone number</Form.Control.Feedback>
                            <ErrorOutput case={data.insured_phone} name={"insured_phone"} />
                          </div>

                          <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Registration Number</Form.Label>
                            <input type="text" name="car_registration" required onChange={(e) => handleClick(e)} />
                            <Form.Control.Feedback type="invalid">Please enter your car registration number</Form.Control.Feedback>
                            <ErrorOutput case={data.car_registration} name={"car_registration"} />
                          </div>

                          {/* <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Insured Type</Form.Label>
                            <div className="radio-box">
                              <input type="radio" name="insured_type" value="Insured" checked={data.insured_type === "Insured"} onChange={(e) => handleClick(e)} id="Insured" />
                              <Form.Label className="bg-transparent" for="Insured">
                                Insured
                              </Form.Label>
                            </div>
                            <div className="radio-box bg-transparent">
                              <input type="radio" name="insured_type" value="Claimant" checked={data.insured_type === "Claimant"} onChange={(e) => handleClick(e)} id="Claimant" />
                              <Form.Label className="bg-transparent" for="Claimant">
                                Claimant
                              </Form.Label>
                            </div>
                          </div> */}

                          <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Car Model Year</Form.Label>
                            <input type="text" name="car_year" aria-describedby="inputGroupPrepend" required onChange={(e) => handleClick(e)} />
                            <Form.Control.Feedback type="invalid">Please enter car model year</Form.Control.Feedback>
                            <ErrorOutput case={data.car_year} name={"car_year"} />
                          </div>

                          <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Car Reading(In KM)</Form.Label>
                            <input type="text" name="car_reading" aria-describedby="inputGroupPrepend" required onChange={(e) => handleClick(e)} />
                            <Form.Control.Feedback type="invalid">Please enter your car reading</Form.Control.Feedback>
                            <ErrorOutput case={data.car_reading} name={"car_reading"} />
                          </div>

                          {/* <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Claim Date</Form.Label>
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              timeCaption="time"
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
                          </div>

                          <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Address</Form.Label>
                            <input type="text" name="insured_address" aria-describedby="inputGroupPrepend" required onChange={(e) => handleClick(e)} />
                            <Form.Control.Feedback type="invalid">Please enter your address</Form.Control.Feedback>
                            <ErrorOutput case={data.insured_address} name={"insured_address"} />
                          </div> */}

                          {/* <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>City</Form.Label>
                            <input
                              type="text"
                              list="citylist"
                              value={data.insured_city}
                              placeholder="Select City"
                              aria-describedby="inputGroupPrepend"
                              name="insured_city"
                              onChange={(e) => handleClick(e)}
                            />
                            <datalist id="citylist">
                              {props?.cityList?.cityList.map((city) => {
                                return (
                                  <option value={city.name} key={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </datalist>
                            <Form.Control.Feedback type="invalid">Please select city</Form.Control.Feedback>
                            <ErrorOutput case={data.insured_city} name={"insured_city"} />
                          </div>

                          <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Pincode</Form.Label>
                            <input type="text" maxLength={6} name="insured_pincode" aria-describedby="inputGroupPrepend" required onChange={(e) => handleClick(e)} />
                            <Form.Control.Feedback type="invalid">Please enter pincode</Form.Control.Feedback>
                            <ErrorOutput case={data.insured_pincode} name={"insured_pincode"} />
                          </div>

                          <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Insurance Type</Form.Label>
                            <div className="radio-box">
                              <input type="radio" name="insurance_type" value="comprehensive" checked={data.insurance_type === "comprehensive"} onChange={(e) => handleClick(e)} id="comprehensive" />
                              <Form.Label className="bg-transparent" for="comprehensive">
                                comprehensive
                              </Form.Label>
                            </div>
                            <div className="radio-box bg-transparent">
                              <input type="radio" name="insurance_type" value="zeroDept" checked={data.insurance_type === "zeroDept"} onChange={(e) => handleClick(e)} id="zeroDept" />
                              <Form.Label className="bg-transparent" for="zeroDept">
                                Zero Dept.
                              </Form.Label>
                            </div>
                          </div>

                          <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Cause Of Damage</Form.Label>
                            <input type="text" name="cause_of_damage" aria-describedby="inputGroupPrepend" required onChange={(e) => handleClick(e)} />
                            <Form.Control.Feedback type="invalid">Please enter Cause Of Damage</Form.Control.Feedback>
                            <ErrorOutput case={data.cause_of_damage} name={"cause_of_damage"} />
                          </div> */}

                          <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Insurance (Policy)</Form.Label>
                            <input type="file" name="policy_pdf" placeholder="Insurance (Policy)" accept="application/pdf" onChange={changeHandler} />
                            {getFileNames(selectedFile)}
                          </div>

                          <div className="form-group col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Add Images</Form.Label>
                            <input type="file" name="images" onChange={changeImageHandler} placeholder="Add Images" accept="image/*" multiple />
                            {getFileNames(selectedImageFile)}
                          </div>

                          <div className="form-group col-lg-12 col-md-12 col-sm-12">
                            <button className="theme-btn btn-style-seven" type="submit" name="submit-form">
                              Submit Now
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Footer />
      <CustomModal show={show} modalSize="lg" {...params} isCloseButton={true} handleClose={handleClose} />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityList = state.default.cityListReducer;
  stateObj.insuranceCompanyList = state.default.insuranceCompanyReducer.insuranceCompanyList;
  stateObj.proposalID = state.default.createInsuranceLeadReducer.proposal_id;
  stateObj.uploadedMedia = state.default.uploadInsuranceMediaReducer;
  stateObj.userData = state.default.userReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  getInsuranceCompanies: (params) => InsuranceCompanyList.getInsuranceCompanies(params, dispatch),
  getInsuranceLead: (params) => CreateInsuranceLeadAction.getInsuranceLead(params, dispatch),
  sendInsuranceMedia: (params) => UploadInsuranceMediaAction.sendInsuranceMedia(params, dispatch),
  getModelNames: (params) => VehicleAction.getModelNames(params, dispatch),
  customDispatch: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(MakeClaim);
