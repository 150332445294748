import React from "react";
import { Col, Row } from "react-bootstrap";
import './index.scss';
import { WebsiteTech, AppDesign  } from "../../../constants/ImageConstants";

const threePillars = () => {
    return (
        <Row>
            <Col>
			<div className="auto-container">
				<div className="upper-section">
					<div className="sec-title centered">
						{/* <h2 className="title">Look At Our Quality Features</div> */}
						<h2>The Future of Cohesive Repair Handling Solution</h2>
					</div>
				
					<div className="row clearfix h-100 mb-2">
						
						<div className="content-column col-lg-6 col-md-12 col-sm-12 my-auto">
							<div className="inner-column">
								<div className="sec-title">
									<h2><font color="#ed1c24">Digital Driver Journey </font></h2>
								</div>
								
								<div className="text"> 
								Our user-friendly web interface offers a fully customizable environment for drivers to manage every aspect of their repair process. With just a few clicks, they can submit damage reports, plan appointments based on their schedule, and share feedback after the repair is completed. </div>
							</div>
						</div>
						
						<div className="image-column col-lg-6 col-md-12 col-sm-12">
							<div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
								<div className="image text-center">
									<img src={ AppDesign } alt="" className="w-25" />
								</div>  
							</div>
						</div>
					</div>

					{/* second */}
					<div className="row clearfix h-100">
						
						<div className="image-column col-lg-6 col-md-12 col-sm-12">
							<div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
								<div className="image">
									<img src={WebsiteTech} alt="" />
								</div>  
							</div>
						</div>
						<div className="content-column col-lg-6 col-md-12 col-sm-12 my-auto">
							<div className="inner-column">
								<div className="sec-title">
									<h2> <font color="#ed1c24">Insightful Repair Management</font></h2>
									
								</div>
								
								<div className="text"> Fleet managers have access to a cohesive dashboard that handles repairs from start to finish. Our advanced platform enables a 1-to-1 handling approach and matches each damage with the right repair shop. This ensures that repairs are optimally allocated and managed to minimize costs and cycle times. </div>

							</div>
						</div>
						
					</div>

					{/* third */}
					<div className="row clearfix h-100">
						
						<div className="content-column col-lg-6 col-md-12 col-sm-12 my-auto">
							<div className="inner-column">
								<div className="sec-title">
									<h2> <font color="#ed1c24">Dynamic Repair Handling</font></h2>
									
								</div>
								
								<div className="text"> Our digital marketplace guarantees the best repair solution by connecting partner-specific requirements with the repairer’s expertise. We have a vast and high-quality network of repair shops, making it easy to adopt partner-preferred repairers and quickly expand to any location. With our platform, you can be sure that your repair needs are being handled efficiently and cost-effectively. </div>

							</div>
						</div>
						
						<div className="image-column col-lg-6 col-md-12 col-sm-12 my-auto">
							<div className="inner-column wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
								<div className="image text-center">
									<img src={ WebsiteTech } alt="" />
								</div>  
							</div>
						</div>
					</div>
				</div>
			</div>

			
            </Col>
        </Row>
  );
};





export default threePillars;
