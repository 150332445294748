import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./index.scss";
import {
  CarService,
  DentPaint,
  InsuranceBroker,
  InsuranceCompany,
  MechnicalRepair,
  BusinessFleet,
  LeasingComapny,
  InsuranceClaim,
  AMC,
} from "../../../../../constants/ImageConstants";
import { Link } from "react-router-dom";
import { getNavigateUrl } from "../../../../../utils/customHelper";
import {
  VehiclecareB1,
  VehiclecareB2,
  VehiclecareB3,
  VehiclecareB4,
  VehiclecareB5,
  VehiclecareB6,
  VehiclecareB7,
  VehiclecareB8,
  VehiclecareB9,
  VehiclecareB10,
  VehiclecareB11,
  VehiclecareB12,
  VehiclecareB13,
  VehiclecareB14,
  Pattern19,
  Appbg,
  TotalEnergiesVC,
} from "./../../../../../constants/ImageConstants";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Cursor } from "react-bootstrap-icons";
import { isMobile } from "react-device-detect";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 100) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "... read more" : " show less"}
      </span>
    </p>
  );
};

const IOurBestServices = ({ city }) => {
  return (
    <Row>
      <Col>
        <div className="auto-container" id="IOurService">
          <div className="sec-title centered">
            <div className="title">Solutions & Services</div>
            <h2>Explore How VC Can Support Your Business </h2>
          </div>
          <div className="carousel-outer">
            <Splide
              options={{
                updateOnMove: true,
                type: "loop",
                drag: "free",
                perPage: isMobile ? 3 : 7,
                perMove: 1,
                focus: 0,
                interval: 2000,
                speed: 400,
                pagination: false,
                autoplay: "play",
                autoScroll: {
                  speed: 1,
                },
              }}
            >
              <SplideSlide>
                <a href={`/${city?.toLowerCase()}/car-service`}>
                  <div className="text-center">
                    <img
                      className="w-35 mx-auto pt-4"
                      src={CarService}
                      alt="Car Service"
                    />
                    <p className="pt-2 fontbold text-dark">Car Service</p>
                  </div>
                </a>
              </SplideSlide>
              <SplideSlide>
                <a href={`/${city?.toLowerCase()}/dent-paint`}>
                  <div className="text-center">
                    <img
                      className="w-35 mx-auto pt-4"
                      src={DentPaint}
                      alt="Dent Paint"
                    />
                    <p className="pt-2 fontbold text-dark">Dent Paint</p>
                  </div>
                </a>
              </SplideSlide>
              <SplideSlide>
                <a href={`/car-insurance-claim`}>
                  <div className="text-center">
                    <img
                      className="w-35 mx-auto pt-4"
                      src={InsuranceClaim}
                      alt="Insurance claim"
                    />
                    <p className="pt-2 fontbold text-dark">Insurance</p>
                  </div>
                </a>
              </SplideSlide>
              <SplideSlide>
                <a href="/car-repair-near-me">
                  <div className="text-center">
                    <img
                      className="w-35 mx-auto pt-4"
                      src={MechnicalRepair}
                      alt="Mechanical Repair"
                    />
                    <p className="pt-2 fontbold text-dark">Car Repair</p>
                  </div>
                </a>
              </SplideSlide>
              <SplideSlide>
                <a href="/ev">
                  <div className="text-center">
                    <img
                      className="w-35 mx-auto pt-4"
                      src={BusinessFleet}
                      alt="Buisness Fleet"
                    />
                    <p className="pt-2 fontbold text-dark">EV</p>
                  </div>
                </a>
              </SplideSlide>
              <SplideSlide>
                <a href="/warranty">
                  <div className="text-center">
                    <img
                      className="w-35 mx-auto pt-4"
                      src={InsuranceCompany}
                      alt="Insurance Company"
                    />
                    <p className="pt-2 fontbold text-dark">Warranty</p>
                  </div>
                </a>
              </SplideSlide>
              <SplideSlide>
                <a href="/amc">
                  <div className="text-center">
                    <img
                      className="w-35 mx-auto pt-4"
                      src={AMC}
                      alt="Insurance Broker"
                    />
                    <p className="pt-2 fontbold text-dark">AMC</p>
                  </div>
                </a>
              </SplideSlide>
              {/*
               <SplideSlide>
                <div className="text-center">
                  <img
                    className="w-35 mx-auto pt-4 d-none d-md-block"
                    src={LeasingComapny}
                    alt="Leasing Company"
                  />
                  <p className="pt-2 fontbold text-dark">Leasing Company</p>
                </div>
              </SplideSlide>  
              */}
            </Splide>
          </div>

          {/*
           <div className="d-none d-md-block">
            <div className="row clearfix">
              <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                <Link to={getNavigateUrl("/car-service")}>
                  <div className="image">
                    
                      <img className="w-50 mx-auto pt-4 d-none d-md-block" src={CarService} alt="Car Service" />
                      <img className="w-25 mx-auto pt-4 d-md-none" src={CarService} alt="Car Service" />
                  
                  </div>
                  </Link>
                  <div className="lower-content">
                    <h5 className="theme_color">
                    <Link to={getNavigateUrl("/car-service")}>   Car Service </Link>
                    </h5>
                    <div className="text">
                      <ReadMore>
                      Comprehensive car services include several important services like oil changes, wheel care and other mechanical repairs to make sure that your car is road-ready.
                      </ReadMore>
                    </div>
                  </div>
                  
                </div>
              </div>

              <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                <Link to={getNavigateUrl("/dent-paint")}>
                  <div className="image">
                  
                      <img className="w-50 mx-auto pt-4 d-none d-md-block" src={DentPaint} alt="Dent & Paint" />
                      <img className="w-25 mx-auto pt-4 d-md-none" src={DentPaint} alt="Dent & Paint" />
                    
                  </div>
                  </Link>
                  <div className="lower-content">
                    <h5>
                    <Link to={getNavigateUrl("/dent-paint")}> Dent & Paint</Link>
                    </h5>
                    <div className="text">
                      <ReadMore>
                      VehicleCare excels in Car denting and painting services to restore your car to its pristine condition. We only use high-grade high-quality paints that are approved by brands.

                      </ReadMore>
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to={"/car-insurance-claim"}>
                      <img className="w-50 mx-auto pt-4 d-none d-md-block" src={InsuranceClaim} alt="Car Insurance Claim" />
                      <img className="w-25 mx-auto pt-4 d-md-none" src={InsuranceClaim} alt="Car Insurance Claim" />
                    </Link>
                  </div>
                  <div className="lower-content">
                    <h5>
                      <Link to={"/car-insurance-claim"}> Insurance Claim </Link>
                    </h5>
                    <div className="text">
                      <ReadMore>
                      Our team of skilled professionals assist you with the entire claim process so that you get the claim that you are entitled to. We streamline the car repair process with hassle-free cashless insurance claims.

                      </ReadMore>
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                  
                  <Link to={"/car-mechanic-near-me"}>
                      <img className="w-50 mx-auto pt-4 d-none d-md-block" src={MechnicalRepair} alt="Mechanical Repair" />
                      <img className="w-25 mx-auto pt-4 d-md-none" src={MechnicalRepair} alt="Mechanical Repair" />
                      </Link>
                  </div>
                  <div className="lower-content">
                    <h5>
                    <Link to={"/car-mechanic-near-me"}>
                      Mechanical Repair 
                      </Link>
                    </h5>
                    <div className="text">
                      <ReadMore>
                      We boats a team of highly skilled mechanics who hold expertise in dealing with every make and model of cars. our technicians possess the right combination of expertise to ensure that your vehicle runs smoothly.
                      </ReadMore>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row clearfix">
              <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to={"/segments/fleet"}>
                      <img className="w-50 mx-auto pt-4 d-none d-md-block" src={BusinessFleet} alt="Business Fleet" />
                      <img className="w-25 mx-auto pt-4 d-md-none" src={BusinessFleet} alt="Business Fleet" />
                    </Link>
                  </div>
                  <div className="lower-content">
                    <h5>
                      <Link to={"/segments/fleet"}> Businesses & Fleets </Link>
                    </h5>
                    <div className="text">
                      <ReadMore>
                      Vehiclecare caters to businesses and fleets with tailored car repair and maintenance services. Our comprehensive solutions ensure smooth operations for our corporate partners.

                      </ReadMore>
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to={"/segments/insurer"}>
                      <img className="w-50 mx-auto pt-4 d-none d-md-block" src={InsuranceCompany} alt="Insurance Company" />
                      <img className="w-25 mx-auto pt-4 d-md-none" src={InsuranceCompany} alt="Insurance Company" />
                    </Link>
                  </div>
                  <div className="lower-content">
                    <h5>
                      <Link to={"/segments/insurer"}> Insurance Companies </Link>
                    </h5>
                    <div className="text">
                      <ReadMore>
                        We work with insurance companies to provide high-quality repair services for their customers. Our team of experts can assist with the claims process and ensure that repairs are
                        completed to the highest standards.
                      </ReadMore>
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to={"/segments/broker"}>
                      <img className="w-50 mx-auto pt-4 d-none d-md-block" src={InsuranceBroker} alt="Insurance Broker" />
                      <img className="w-25 mx-auto pt-4 d-md-none" src={InsuranceBroker} alt="Insurance Broker" />
                    </Link>
                  </div>
                  <div className="lower-content">
                    <h5>
                      <Link to={"/segments/broker"}> Insurance Broker </Link>
                    </h5>
                    <div className="text">
                      <ReadMore>
                      VehicleCare simplifies Car insurance with its online broker service.  Easily compare and choose the best insurance plans tailored to your needs. Our seamless and transparent experience makes sure that you get the best coverage.
                      </ReadMore>
                    </div>
                  </div>
                </div>
              </div>

              <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image">
                    <Link to={"/segments/lease"}>
                      <img className="w-50 mx-auto pt-4 d-none d-md-block" src={LeasingComapny} alt="Leasing Comapny" />
                      <img className="w-25 mx-auto pt-4 d-md-none" src={LeasingComapny} alt="Leasing Comapny" />
                    </Link>
                  </div>
                  <div className="lower-content">
                    <h5>
                      <Link to={"/segments/lease"}> Leasing Companies</Link>
                    </h5>
                    <div className="text">
                      <ReadMore>
                      VehicleCare provides special repair and maintenance for leased vehicles. Our skilled professionals provide quality automotive solutions to ensure that your vehicle remains in optimal condition, offering peace of mind to both the lessors and lessees.

                      </ReadMore>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 

          <div className="d-md-none">
              <Splide  options={ {
                    type         : 'loop',
                    gap          : '1rem',
                    autoplay     : true,
                    pauseOnHover : false,
                    resetProgress: false,
                    
                } }
              hasTrack={ true }>
              <SplideSlide>
                <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                  <div className="inner-box">
                  <Link to={getNavigateUrl("/car-service")}>
                    <div className="image">
                      
                        <img className="w-50 mx-auto pt-4 d-none d-md-block" src={CarService} alt="Car Service" />
                        <img className="w-25 mx-auto pt-4 d-md-none" src={CarService} alt="Car Service" />
                    
                    </div>
                    </Link>
                    <div className="lower-content">
                      <h5 className="theme_color">
                      <Link to={getNavigateUrl("/car-service")}>   Car Service </Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                        Comprehensive car services include several important services like oil changes, wheel care and other mechanical repairs to make sure that your car is road-ready.
                        </ReadMore>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                  <div className="inner-box">
                  <Link to={getNavigateUrl("/dent-paint")}>
                    <div className="image">
                    
                        <img className="w-50 mx-auto pt-4 d-none d-md-block" src={DentPaint} alt="Dent & Paint" />
                        <img className="w-25 mx-auto pt-4 d-md-none" src={DentPaint} alt="Dent & Paint" />
                      
                    </div>
                    </Link>
                    <div className="lower-content">
                      <h5>
                      <Link to={getNavigateUrl("/dent-paint")}> Dent & Paint</Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                        VehicleCare excels in Car denting and painting services to restore your car to its pristine condition. We only use high-grade high-quality paints that are approved by brands.

                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={"/car-insurance-claim"}>
                        <img className="w-50 mx-auto pt-4 d-none d-md-block" src={InsuranceClaim} alt="Car Insurance Claim" />
                        <img className="w-25 mx-auto pt-4 d-md-none" src={InsuranceClaim} alt="Car Insurance Claim" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                        <Link to={"/car-insurance-claim"}> Insurance Claim </Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                        Our team of skilled professionals assist you with the entire claim process so that you get the claim that you are entitled to. We streamline the car repair process with hassle-free cashless insurance claims.

                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                    
                    <Link to={"/car-mechanic-near-me"}>
                        <img className="w-50 mx-auto pt-4 d-none d-md-block" src={MechnicalRepair} alt="Mechanical Repair" />
                        <img className="w-25 mx-auto pt-4 d-md-none" src={MechnicalRepair} alt="Mechanical Repair" />
                        </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                      <Link to={"/car-mechanic-near-me"}>
                        Mechanical Repair 
                        </Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                        We boats a team of highly skilled mechanics who hold expertise in dealing with every make and model of cars. our technicians possess the right combination of expertise to ensure that your vehicle runs smoothly.
                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={"/segments/fleet"}>
                        <img className="w-50 mx-auto pt-4 d-none d-md-block" src={BusinessFleet} alt="Business Fleet" />
                        <img className="w-25 mx-auto pt-4 d-md-none" src={BusinessFleet} alt="Business Fleet" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                        <Link to={"/segments/fleet"}> Businesses & Fleets </Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                        Vehiclecare caters to businesses and fleets with tailored car repair and maintenance services. Our comprehensive solutions ensure smooth operations for our corporate partners.

                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={"/segments/insurer"}>
                        <img className="w-50 mx-auto pt-4 d-none d-md-block" src={InsuranceCompany} alt="Insurance Company" />
                        <img className="w-25 mx-auto pt-4 d-md-none" src={InsuranceCompany} alt="Insurance Company" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                        <Link to={"/segments/insurer"}> Insurance Companies </Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                          We work with insurance companies to provide high-quality repair services for their customers. Our team of experts can assist with the claims process and ensure that repairs are
                          completed to the highest standards.
                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={"/segments/broker"}>
                        <img className="w-50 mx-auto pt-4 d-none d-md-block" src={InsuranceBroker} alt="Insurance Broker" />
                        <img className="w-25 mx-auto pt-4 d-md-none" src={InsuranceBroker} alt="Insurance Broker" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                        <Link to={"/segments/broker"}> Insurance Broker </Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                        VehicleCare simplifies Car insurance with its online broker service.  Easily compare and choose the best insurance plans tailored to your needs. Our seamless and transparent experience makes sure that you get the best coverage.
                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="service-block-four col-lg-3 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={"/segments/lease"}>
                        <img className="w-50 mx-auto pt-4 d-none d-md-block" src={LeasingComapny} alt="Leasing Comapny" />
                        <img className="w-25 mx-auto pt-4 d-md-none" src={LeasingComapny} alt="Leasing Comapny" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                        <Link to={"/segments/lease"}> Leasing Companies</Link>
                      </h5>
                      <div className="text">
                        <ReadMore>
                        VehicleCare provides special repair and maintenance for leased vehicles. Our skilled professionals provide quality automotive solutions to ensure that your vehicle remains in optimal condition, offering peace of mind to both the lessors and lessees.

                        </ReadMore>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              
            </Splide>
          </div> */}

          {/* <div className="lower-box text-center">
						<div className="text">Want to Explore <Link to={(getNavigateUrl("/services/periodic-car-services">More Sevices</Link></div>
					</div> */}
        </div>
      </Col>
    </Row>
  );
};

export default IOurBestServices;
