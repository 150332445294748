import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
// import { Citybg, Appbg, Pattern1, Pattern2 } from "./../../../constants/ImageConstants";
import { useParams } from "react-router-dom";
import { toTitleCase } from "../../../../../utils/customHelper";

import Toast from 'react-bootstrap/Toast';

const BestPrice = () => {
  const urlParam = useParams();
  const selectedCityName = toTitleCase(urlParam.city.replace(/-/g, " "));
  return (
    <>
      
      <Row>
        <div className="auto-container">
          <Row className="justify-content-center">
            <Col xs={11} lg={11}>
              <div className="sec-title centered">
                <h2>Enjoy The Best Prices Without Any Hidden Charges</h2>
                <div className="text">
                Get the best quote in all car workshops in {selectedCityName}. There are no hidden charges and every inclusion in your car service is presented to you in complete transparency. Pay only what your car requires with
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} lg={11}>
              <Row>
                <Col xs={12} lg={6} className="mb-4">
                  <Toast className="border-0 w-100">
                    <Toast.Header className="bg-danger" closeButton={false}>
                      <Row className="align-items-center">
                        {/* <Col xs={12}><img className="mx-auto w-25" src={GenuineParts} alt="Genuine Parts" /></Col> */}
                        <p className="me-auto mb-1 text-white"><b>Reliability - Same Day Delivery</b></p>
                      </Row>
                    </Toast.Header>
                    <Toast.Body className="bg-light">
                      <Col xs={12}>
                        <p className="lh-sm mb-1">In addition to adherence to quality car service, we also care for your time. With VehicleCare you can never expect to be disappointed. We promise same-day delivery on all car
                    services and assure you the most top-notch service every time.</p>
                      </Col>
                    </Toast.Body>
                  </Toast>

                  {/* <p>
                    <b>
                      <font color="#ed1c24" size="3">
                        Reliability - Same Day Delivery
                      </font>
                    </b>{" "}
                    <br />
                    In addition to adherence to quality car service, we also care for your time. With VehicleCare you can never expect to be disappointed. We promise same-day delivery on all car
                    services and assure you the most top-notch service every time.
                  </p> */}
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <Toast className="border-0 w-100">
                    <Toast.Header className="bg-danger" closeButton={false}>
                      <Row className="align-items-center">
                        {/* <Col xs={12}><img className="mx-auto w-25" src={GenuineParts} alt="Genuine Parts" /></Col> */}
                        <p className="me-auto mb-1 text-white"><b>Convenience - No Hassles</b></p>
                      </Row>
                    </Toast.Header>
                    <Toast.Body className="bg-light">
                      <Col xs={12}>
                        <p className="lh-sm mb-1">We have simplified the process of car service for you in easy steps. Now, servicing cars in {selectedCityName} is not a hassle. Simply book your car service from the comfort of
                    your home and our expert will take on the full procedure on your behalf.</p>
                      </Col>
                    </Toast.Body>
                  </Toast>

                  {/* <p>
                    <b>
                      <font color="#ed1c24" size="3">
                        Convenience - No Hassles
                      </font>
                    </b>{" "}
                    <br />
                    We have simplified the process of car service for you in easy steps. Now, servicing cars in {selectedCityName} is not a hassle. Simply book your car service from the comfort of
                    your home and our expert will take on the full procedure on your behalf.
                  </p> */}
                </Col>

                <Col xs={12} lg={6} className="mb-2">
                  <Toast className="border-0 w-100">
                    <Toast.Header className="bg-danger" closeButton={false}>
                      <Row className="align-items-center">
                        {/* <Col xs={12}><img className="mx-auto w-25" src={GenuineParts} alt="Genuine Parts" /></Col> */}
                        <p className="me-auto mb-1 text-white"><b>Genuine Spares - Guaranteed</b></p>
                      </Row>
                    </Toast.Header>
                    <Toast.Body className="bg-light">
                      <Col xs={12}>
                        <p className="lh-sm mb-1">All our workshops guarantee genuine spares for car repairing. We maintain a supply of genuine spare through a network sourcing from OEM/OES.</p>
                      </Col>
                    </Toast.Body>
                  </Toast>
                  {/* <p>
                    <b>
                      <font color="#ed1c24" size="3">
                        Genuine Spares - Guaranteed
                      </font>
                    </b>{" "}
                    <br />
                    All our workshops guarantee genuine spares for car repairing. We maintain a supply of genuine spare through a network sourcing from OEM/OES.
                  </p> */}
                </Col>

                <Col xs={12} lg={6} className="mb-2">
                  <Toast className="border-0 w-100">
                    <Toast.Header className="bg-danger" closeButton={false}>
                      <Row className="align-items-center">
                        {/* <Col xs={12}><img className="mx-auto w-25" src={GenuineParts} alt="Genuine Parts" /></Col> */}
                        <p className="me-auto mb-1 text-white"><b>Stay Assured - 1 Month Warranty</b></p>
                      </Row>
                    </Toast.Header>
                    <Toast.Body className="bg-light">
                      <Col xs={12}>
                        <p className="lh-sm mb-1">With VehicleCare you can be assured of a long and untrampled drive. We provide a full one-month warranty on car service and promise unconditional money back for that time period.</p>
                      </Col>
                    </Toast.Body>
                  </Toast>
                  {/* <p>
                    <b>
                      <font color="#ed1c24" size="3">
                        Stay Assured - 1 Month Warranty
                      </font>
                    </b>{" "}
                    <br />
                    With VehicleCare you can be assured of a long and untrampled drive. We provide a full one-month warranty on car service and promise unconditional money back for that time period.
                  </p> */}
                </Col>
              </Row>                  
            </Col>
          </Row>
        </div>
      </Row>
    
    </>
  );
};

export default BestPrice;
