import { preLoginApiRequest } from "../../server";
import { SERVICE_PACKAGES_LIST } from "../types";

// eslint-disable-next-line import/prefer-default-export
const getServiceData = (params, dispatch) => {
  preLoginApiRequest(params, (responseData) => {
    dispatch({ type: SERVICE_PACKAGES_LIST, payload: responseData.data });
  });
};
export { getServiceData };
