import React , { useState } from "react";
import { Row,Col } from "react-bootstrap";
import "./index.scss";
import { Appbg, Pattern1, Pattern2 ,Faqbg} from "../../../../constants/ImageConstants";

import OurApp from "../../../../components/common/ourApp";

import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import Testimonial from "../../../../components/common/Testimonial";
import BlogMultiImageCarousel from "../../../../components/common/blogMultiImageCarousel";
import ServicesWeOffer from "../Landing/ServicesWeOffer";
import VcBenifits from "../Landing/VcBenifits";
import WhyChooseVc from "../Landing/WhyChooseVc";
import ServiceSteps from "../Landing/ServiceSteps";
import Banner from "./Banner";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import CarMechanicPriceList from "./CarMechanicPriceList";

const CarMechanicNearMe = (props) => {
  const selectedCityName = props.cityListReducer.selectedCity;
  const faqList =[
    {
        id:1,
        title:"What all do you check during inspection of our car?",
        summary:"We first start with a document check and test drive and then use the latest tools to assess the exterior, engine, tire, transmission, electrical, and interior. We also inspect the suspension, steering mechanism, brakes, and other accessories of your car during the test drive."   
    },
    {
        id:2,
        title:"Will you charge for our car inspection?",
        summary:"Yes, the charges for our car inspection is Rs 399." 
       
    },
    {
        id:3,
        title:"Do VehicleCare provide home inspection for car?",
        summary:"We provide Home inspection for you your car, you just have to call or WhtsApp us and our mechanic will reach you within 15 minutes." 
       
    },
    {
        id:4,
        title:"How long will it take to repair my car?",
        summary:"There is time when you could not see your car for 5-6 hours. But in some cases the repair could take anywhere from 5-6 hours to a week or more." 
       
    },
    {
      id:5,
      title:"Can i book my appointment thorugh mobile APP ?",
      summary:"Yes you can book your appointment through Mobile App. You can also book via email." 
     
  },
  {
    id:6,
    title:"How many mechanic are there in vehiclecare ?",
    summary:"There are plenty of Mechanics in VehicleCare and our expert mechanics have more than ten years of experience." 
   
},
{
  id:7,
  title:"What all kind of services do VehicleCare provide?",
  summary:"There's a wide range of car services which are available.We offer Car service, Wheel Care, Car AC Repair, Custom Repair and much more." 
 
},
{
  id:7,
  title:"what sort of spare parts and engine oils are used in the servicing?",
  summary:"We use only genuine spares sourced from OEM/OES across all our workshops. Further, all products used in car services are authentic and top-rated." 
 
}
];

const [toggle, setToggle] = useState(0);
let handleToggle = (id) => {
  if (toggle === id) {
    setToggle(null);
    return false;
  }
  setToggle(id);
};
const active = { backgroundColor: "black", color: "white" };
  const inactive = {};
  return (
    <>
      <Helmet>
        <title>Find Expert Car Mechanic Near Me 24 Hours - VehicleCare</title>
        <meta name="description" content="Discover Premium Car Mechanic Near Me - From Repairs, And Accident Claims, Denting, Painting, And More. Enjoy Hassle-Free Service With Free Pickup And Drop." data-react-helmet="true" />
      
        <meta property="og:title" content="Find Expert Car Mechanic Near Me 24 Hours - VehicleCare"/>
        <meta property="og:description" content="Discover Premium Car Mechanic Near Me - From Repairs, And Accident Claims, Denting, Painting, And More. Enjoy Hassle-Free Service With Free Pickup And Drop."/>
        <meta property="og:url" content={`https://vehiclecare.in/car-mechanic-near-me`}/>
        <meta property="og:type" content="website"/>
        <meta property="og:site_name" content="VehicleCare"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"/>
        <meta property="twitter:title" content="Find Expert Car Mechanic Near Me 24 Hours - VehicleCare"/>
        <meta property="twitter:description" content="Discover Premium Car Mechanic Near Me - From Repairs, And Accident Claims, Denting, Painting, And More. Enjoy Hassle-Free Service With Free Pickup And Drop."/>
        <meta property="twitter:url" content={`https://vehiclecare.in/car-mechanic-near-me`}/>
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:site" content="@vehiclecare1"/>
        <meta property="twitter:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"/>
      </Helmet>

      <Header isStatic={true} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner />
            </div>
          </div>
        </section>

        <div className="services-section-four pt-0 pb-lg-5 pb-sm-4">
          <Row>
            <Col>
              <div className="auto-container">
                <div className="sec-title centered">
                  <h2>Services We Offer</h2>
                  <div className="text col-8 mx-auto d-none d-md-block">
                  Find the best service packages for your car. Select from a wide range of car services, from general service to
                  car wash, 
                  accidental repair to custom repair, cashless insurance claims, and much more.
                  </div>
                </div>

                <ServicesWeOffer selectedCityName={selectedCityName} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="services-section-four pt-0">
          <ServiceSteps />
        </div>

        {/* price list */}
        <div className="blog-section pt-0">
          <CarMechanicPriceList />
        </div>

        <div className="price-section " style={{ background: "rgb(251, 251, 251)" }}>
          <VcBenifits />
        </div>

        <div className="services-section-four">
          <div className="auto-container">
            <div className="sec-title centered">
              <h2>Why Choose VehicleCare </h2>
            </div>
          </div>
          <WhyChooseVc />
        </div>

        {/*Car Workshop Near Me? */}
        <section className="about-section pt-5 pb-5" style={{ backgroundColor: "rgb(251, 251, 251)" }}>
          <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
          <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>

          <div className="auto-container">
            <div className="upper-section">
              <div className="row clearfix d-flex justify-content-center px-2">
                <div className="content-column col-lg-12 col-md-12 col-sm-12 mb-2">
                  <div className="inner-column">
                    <div className="sec-title mb-4">
                      <h2 className="text-center">Looking For Nearby <font color="#ed1c24">Car Mechanic?</font></h2>
                      <div className="text text-left mb-0">
                        At VehicleCare, we understand the importance of maintaining your vehicle's health. Your car is more than just a means of transportation; it's an investment, a dependable companion, and an integral part of your daily life.</div>

                      <div className="text text-left mt-2 mb-0">Regular maintenance ensures that your vehicle runs smoothly, extends its lifespan, and keeps you and your loved ones safe on the road. </div>
                      
                      <div className="text text-left mt-2 mb-0">When questions like “ Which is the best car mechanic service near me “ or “ find the best car repair service near me”, popps your head, be rest assured that VehicleCare is just a few clicks away for all your car related repair issues.
                      </div>
                    </div>

                    <div className="detail">
                      <div className="d-flex justify-content-center col inner-box">
                        <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                          <div className="sec-title mb-4 ">
                            <h2 className="text-left fs-4 text-capitalize">What makes VehicleCare the top choice for the best Mechanic services?</h2>
                          </div>

                          <div className="text mb-0">
                            The mechanics at vehiclecare are highly skilled and with an experience of over a decade. Our mechanics are well equipped to use advanced state of the art facilities and diagnostic tools and ensuring accurate issue identification and swift resolution. </div>
                            
                          <div className="text text text-left mt-2 mb-0">We understand the value of your car, hence we give it in the expert hands so that your vehicle receives top- tier care regardless of the make or model car. VehicleCare promises you to provide the best mechanic service near you in the most hassle-free manner.
                          </div>

                          {/* <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                1. Earn Rewards:
                              </font>
                            </b>
                            <span> Save money on car services, car repairs and maintenance. Enjoy cashback and rewards on every service with VehicleCare Mechanics.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                2. Convenient Scheduling:
                              </font>
                            </b>
                            <span> Book car services, car service appointments at our workshops anytime, anywhere. We'll take care of the rest, so your car gets the attention it needs when you need it.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                3. Stay Informed:
                              </font>
                            </b>
                            <span>
                              {" "}
                              Receive real time update through whatsapp and Mobile APP for your car service or repair status.
                            </span>
                          </p> */}

                          <div className="sec-title mb-4 mt-4">
                            <h2 className="text-left fs-4 text-capitalize">What we deliver to our customers at VehicleCare?</h2>
                          </div>
                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                1. Transparency and Trust:
                              </font>
                            </b>
                            <span>
                              {" "}
                              At VehicleCare, transparency is a core value. We provide honest assessments and accurate estimates before commencing any work, so that you know exactly what to expect. No hidden costs, no surprises – just straightforward, reliable service.
                            </span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                2. High quality genuine parts:
                              </font>
                            </b>
                            <span> When the customers comes at a VehicleCare workshop near to them, they are always delivered the best. We use genuine, high-quality parts to guarantee your vehicle's longevity and performance. Your vehicle deserves the best, and that's precisely what we deliver.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                3. 24x7 open car workshops with skilled professionals:
                              </font>
                            </b>
                            <span> When you are wondering, ‘where can i find 24x7 car mechanic near me’, VehicleCare auto shops are the best solution for you. We pride ourselves on providing the best mechanic service near you.<br/> 24x7 open auto repair 
                            workshop 
                            nearby, is a VehicleCare’s promise to its customers. Our team of highly skilled and certified technicians are dedicated to delivering high-quality service in time, regardless of your vehicle's make or model. We take care of everything from routine maintenance to complex repairs, ensuring your vehicle stays in optimal condition.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                4. 24x7 Customer support:
                              </font>
                            </b>
                            <span> VehicleCare's 24x7 customer support promises round-the-clock assistance, addressing queries, and providing guidance at any hour. Our dedicated team is committed to delivering prompt and reliable support to you for an enhanced overall experience with all our auto 
                            <a href="https://vehiclecare.in/car-workshop-near-me" target="_blank" rel="noreferrer"><font color="#ed1c24"> workshops </font></a> nearby.</span>
                          </p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section  */}
        <div className={`acordian-section  ${faqList?.length < 1 && "d-none"}`} style={{ backgroundImage: `url(${Faqbg})`, backgroundSize: "cover" }}>
          <Col  className={`auto-container`}>
            <div className="row clearfix">
              <div className="accordian-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    {/* <div className="title">Contact Support</div> */}
                    <h2>Frequently Asked Questions</h2>
                  </div>
                  <ul className="accordion-box">
                    {faqList?.map((value, index) => {
                      return (
                        <div className="accordion block mb-1" id="acc_faq" key={index}>
                          <div
                            className="acc-btn" id="acc_faq"
                            onClick={() => handleToggle(index)}
                            style={index === toggle ? active : inactive}
                          >
                            <h2>{value.title}{" "}</h2>
                            <div className="icon-outer">
                              <b className="icon">{index === toggle ? "-" : "+"} </b>{" "}
                            </div>{" "}
                          </div>
                          {index === toggle ? (
                            <div className="content px-2 py-0">
                              <div className="accordian-text"> {value.summary} </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="title-column col-lg-5 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title">Contact Support</div>
                    <h2>Have Any Questions?</h2>
                  </div>
                  <div className="inquiry-box">
                    <div className="box-inner">
                      <div className="upper-box">
                        <div className="icon flaticon-telephone"></div>
                        <div className="inquiry-title">For Any Inquiries Call Now!</div>
                        <a href="tel:9911-020-209" className="phone">
                          9911-020-209
                        </a>
                      </div>
                      <div className="lower-box">
                        <a className="emailed" href="mailto: support@vehiclecare.in?subject=Need Help">
                          support@vehiclecare.in
                        </a>
                      </div>
                    </div>
                    <Link to={"/getintouch"} className="get-tech">
                      Get You Tech Now!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
        {/* FAQ Section  */}


        {/* Testimonial Section  */}
        <div className="d-none d-md-block">
        <Testimonial city_name="Gurgaon" />
        </div>

        {/* Blog Section */}
        <div className="blog-section pb-0">
          {/* <IBlog /> */}
          <BlogMultiImageCarousel />
        </div>

        
        {/* Our App */}
        <div
          className="testimonial-section-one bg-size pb-5"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
          }}
        >
          <OurApp />
        </div>



      </Row>
      <Footer />
    </>
  );
};

function mapStateToProps(state) {
  return {
    cityListReducer: state.default.cityListReducer,
  };
}
export default connect(mapStateToProps)(CarMechanicNearMe);
