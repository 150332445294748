import React from 'react';
import PropTypes from 'prop-types';

/** import custom styles */
import { Avatar } from './IconImageStyle';

const IconImage = (props) => {
  const {
    status, className, image, onClick,
  } = props;
  return (
    <Avatar
      className={`picture ${status} ${className}`}
      onClick={onClick}
      src={image}
      {...props}
    />
  );
};

export default IconImage;

IconImage.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  status: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

IconImage.defaultProps = {
  width: '40px',
  height: '40px',
  status: '',
  className: '',
  onClick: null,
};
