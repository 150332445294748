import { CITY_LIST, FAQ_CITY_ID, SET_CITY_NAME } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    cityList: [],
    faqCityId: null,
    selectedCity: null,
  },
  action = {}
) => {
  let selectedCity = window.sessionStorage.getItem("selectedCity");
  if (state.faqCityId === null) {
    if (!selectedCity) {
      state.faqCityId = "3";
      state.selectedCity = "Gurgaon";
      window.sessionStorage.setItem("selectedCity", JSON.stringify({ id: "3", name: "Gurgaon" }));
    } else {
      selectedCity = JSON.parse(selectedCity);
      state.faqCityId = selectedCity.id;
      state.selectedCity = selectedCity.name;
    }
  }
  switch (action.type) {
    case CITY_LIST:
      return { ...state, cityList: action.payload };
    case FAQ_CITY_ID:
      return { ...state, faqCityId: action.payload };
    case SET_CITY_NAME:
      return { ...state, selectedCity: action.payload };
    default:
      return state;
  }
};
