import React from "react";
import "./index.scss";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { UserAction } from "../../../../../store/action";
import { Sign_Out } from "../../../../../store/types";
import { GooglePlayQR } from "../../../../../constants/ImageConstants";
import { useNavigate } from "react-router-dom";
import { getNavigateUrl } from "../../../../../utils/customHelper";

const UserSignup = (props) => {
  const navigate = useNavigate();
  const logout = () => {
    props.updateReducer({ type: Sign_Out });
    navigate(getNavigateUrl("/"));
  };
  const { user_profile } = props.userReducer;
  return (
    <>
      <Row>
        <Col className="px-4 text-center">
          <div className="sec-title mb-3">
            <h6 className="font-weight-bold">
              Welcome {user_profile.first_name} {user_profile.last_name} {"("} {user_profile.phone} {")"}
            </h6>
            <h2 className="font-weight-bold">
              <font size="3">Login on Mobile App for Profile & Order Details.</font>{" "}
            </h2>
          </div>

          <div className="row text-center mb-4">
            <img src={GooglePlayQR} alt="App Background" className="w-25 mx-auto" />
          </div>

          <button className="theme-btn btn-style-seven btn-sm py-2 mt-2 mb-4 px-4" onClick={logout}>
            <span className="txt">Logout</span>
          </button>
        </Col>
      </Row>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userReducer: state.default.userReducer,
    cityListReducer: state.default.cityListReducer,
  };
}
const mapDispatchToProps = (dispatch) => ({
  userLogin: (params) => UserAction.userLogin(params, dispatch),
  updateReducer: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserSignup);
