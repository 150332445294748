import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./index.scss";
import { connect } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getNavigateUrl, toTitleCase, getUrlSearchParams } from "../../../../../utils/customHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import { OrderAction } from "../../../../../store/action";
import { CarRepairLanding, Successful, CommonBanner, CommonBanner2 } from "../../../../../constants/ImageConstants";
import VehicleSelector from "../../../../../components/common/vehicleSelectorMobile";
import { SERVICE_QUOTE, SHOW_HIDE_LOADER } from "../../../../../store/types";
import CustomModal from "../../../../../components/common/CustomModal";
import { auth, generateRecaptcha } from "../../../../../server/firebase";
import { signInWithPhoneNumber, signOut } from "firebase/auth";
import { checkValidMobile } from "../../../../../utils/validations";

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const Banner = (props) => {
  const urlParam = useParams();
  const numberInput = useRef(null);
  const [searchParams] = useSearchParams();
  const customParams = getUrlSearchParams(searchParams);
  console.log(customParams);
  const { cityList, vehicleData, customDispatch, createServiceQuote, serviceBookingData } = props;
  const [data, setData] = useState({
    userPhone: "",
    userCity: "",
    userVehicle: "",
  });
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isCaptchaVerifier, setIsCaptchaVerifier] = useState(false);
  const [optCode, setOptCode] = useState("");
  const [optVerified, setOptVerified] = useState(false);
  const [final, setfinal] = useState("");
  const { brand, model, variant } = vehicleData;
  const navigate = useNavigate();
  const handleClose = () => {
    if (optVerified) {
      navigate(getNavigateUrl("/"));
    }
    setShow(false);
  };
  const handleClick = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const ErrorOutput = (props) => {
    let name = props.name;
    let inputValue = props.case;
    if (name === "userPhone") {
      if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Numbers only</span>;
      }
      return <span></span>;
    }
    if (name === "userCity") {
      if (!inputValue?.match(/^[a-zA-Z]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Letters only</span>;
      }
      return <span></span>;
    }
    if (name === "userVehicle") {
      if (data.userPhone && data.userCity && vehicleData?.variant === null) {
        return <span className="text-danger">Select Your Vehicle</span>;
      }
      return <span></span>;
    }

    return <></>;
  };
  const GetModalContent = () => {
    console.log("repeating");
    return !optVerified ? (
      <Row>
        <Col id="otpCodeContainer" className="px-4 text-center">
          <div className="sec-title mb-3">
            <h2 className="font-weight-bold">
              <font size="4">Enter OTP Recieved on {data.userPhone} </font>{" "}
            </h2>
          </div>

          <div className="styled-form login-form">
            <div className="form-group">
              <input type="text" className="form-control" maxLength={6} placeholder="Enter OTP Code" value={optCode} onChange={(event) => setOptCode(event.target.value)} />
            </div>
          </div>
          <button onClick={onCodeSubmit} className="theme-btn btn-style-seven btn-sm py-2 mt-2 mb-4 px-4">
            <span className="txt">Submit</span>
          </button>
        </Col>
      </Row>
    ) : (
      <Row>
        <div className="col-12">
          <div className="row h-100">
            <div className="text-center">
              <img src={Successful} alt="Congratulations" className="w-25" />
            </div>

            <div className="text-center my-auto">
              <p className="fw-bold mb-0 fs-4">
                  <font color="black">Congratulations!!</font>
              </p>
              <p className="fw-bolder mb-0 fs-5" style={{ color: "#40c057" }}>
                Your Enquiry is successfully Registered.
              </p>
              <p className="text-dark fst-normal mb-0">Our Executive will contact you soon.</p>
              <button type="button" className="theme-btn btn-style-nine my-3 mx-5 px-4 rounded-pill"  onClick={() => getServicePage()}>
                <span className="txt">Get Services</span>
              </button>
            </div>
          </div>
        </div>
      </Row>
    );
  };
  // call service page
  const getServicePage = () =>{

    navigate(getNavigateUrl("/services/periodic-car-services"));
    return () => {
      clearTimeout(window.interval);
    };
  }
  // eslint-disable-next-line no-unused-vars
  const sendOtp = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    const mynumber = `+91${data.userPhone}`;
    if (!isCaptchaVerifier) generateRecaptcha(setIsCaptchaVerifier);
    const verify = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, mynumber, verify)
      .then((result) => {
        setfinal(result);
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        setShow(true);
      })
      .catch((err) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(err);
      });
  };
  const makeVehicleChange = () => {
    document.getElementById("custom-vehicle-selector-brand").click();
  };
  const serviceQuoteCall = () => {
    if (!variant) {
      makeVehicleChange();
      return false;
    }
    if (!checkValidMobile(data.userPhone)) {
      numberInput.current.focus();
      // alert("Please enter your phone number");
      ErrorOutput();
      return false;
    }
    const apiParam = {
      task: "getServiceQuote",
      brand_id: brand.id,
      model_id: model.id,
      variant_id: variant.id.toString(),
      mobile: data.userPhone,
      city: data.userCity,
      source: "VC-CarRepairNearme",
    };
    if (customParams) {
      let sourceName = "";
      let sourceId = "";
      let medium = "";
      customParams.forEach((element) => {
        if (element.name === "merchant_name") {
          sourceName = element.value;
        }
        if (element.name === "merchant_id") {
          sourceId = element.value;
        }
        if (element.name === "utm_source") {
          sourceName = element.value;
        }
        if (element.name === "utm_medium") {
          medium = element.value;
        }
        if (element.name === "utm_campaign") {
          sourceId = element.value;
        }
      });
      if (sourceId) {
        apiParam.source = `${sourceId}-`;
      }
      if (sourceName) {
        apiParam.source += `${sourceName}`;
      }
      if (medium) {
        apiParam.source += `(${medium})`;
      }
    }
    if (urlParam.city) {
      apiParam.city = toTitleCase(urlParam.city.replace(/-/g, " "));
    }
    createServiceQuote(apiParam);
  };
  const onCodeSubmit = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    final
      .confirm(optCode)
      .then((result) => {
        signOut(auth);
        serviceQuoteCall();
      })
      .catch((error) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(error);
      });
  };
  const submitForm = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      // sendOtp();
      serviceQuoteCall();
    }
    setValidated(true);
  };
  useEffect(() => {
    if (serviceBookingData.serviceQuoteResponse !== null) {
      setShow(true);
      setOptVerified(true);
      customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
      customDispatch({ type: SERVICE_QUOTE, payload: null });
      window.interval = setTimeout(() => {
        navigate(getNavigateUrl("/services/periodic-car-services"));
      }, 3000);
    }
  }, [serviceBookingData, navigate, customDispatch]);
  return (
    <>
      <Row>
        <Col>
          <div className="auto-container pb-2">
            <div className="content-boxed">
              <div className="">
                <div className="row clearfix">

                  <div className="image-column col-lg-7 col-md-12 col-sm-12 mb-3 d-md-none" id="homeBannerSplide">
                    <div className="inner-column">
                      <div className="image">
                        
                        <Splide  options={ {
                               type         : 'loop',
                               gap          : '1rem',
                               autoplay     : true,
                               pauseOnHover : false,
                               resetProgress: false,
                            } }
                          hasTrack={ true }>
                          <SplideSlide>
                            <img src={CarRepairLanding} alt="car garage near me" />
                          </SplideSlide>
                          <SplideSlide>
                            <img src={CommonBanner} alt="We can fix Everything and any where any Time " />
                          </SplideSlide>
                          <SplideSlide>
                            <img src={CommonBanner2} alt="20% off on dent paint" />
                          </SplideSlide>
                        </Splide>
                      </div>
                    </div>
                  </div>

                  <div id="landingForm" className="content-column appointment-form banner-form col-lg-5 col-md-12 col-sm-12 text-left px-4">
                    <Form noValidate validated={validated} onSubmit={submitForm}>

                      <div className="px-1 mb-3">
                        <font color="black" className="heading-font d-inline">Your search for </font> <h1 className="fs-2 text-capitalize d-inline"> <font color="#ed1c24">Car Repair</font> Near Me Ends Here</h1>
                      </div>
                      
                      {/* <div className="d-md-none px-2 mb-3">
                        <font color="black" className="heading-font-mobile d-inline">Your search for </font>
                        <h1 className="fs-2 text-capitalize d-inline"> <font color="#ed1c24">Car Repair</font> Near Me Ends Here</h1>
                      </div> */}

                      <div className="px-3">
                        <div className="d-none d-md-block">
                          <VehicleSelector navigate={true} />
                        </div>
                      </div>

                      <div className="px-1">
                        <div className="d-md-none">
                          <VehicleSelector navigate={true} />
                        </div>
                      </div>

                      <ErrorOutput case={data.userVehicle} name={"userVehicle"} />

                      <div className="d-none d-md-block px-3">
                        <div className="row clearfix">
                          <div className={`form-group mt-3 px-0 col-sm-12 ${urlParam.city ? "col-lg-6 col-md-6" : "col-lg-6 col-md-6"}`}>
                            <input
                              type="text"
                              name="userPhone"
                              maxLength={10}
                              pattern="[6-9]{1}[0-9]{9}"
                              value={data.userPhone}
                              aria-describedby="inputGroupPrepend"
                              className="form-control border-0 phoneNo"
                              placeholder="Enter Your Mobile"
                              onChange={(e) => handleClick(e)}
                              ref={numberInput}
                              required
                            />
                            <Form.Control.Feedback type="invalid">Please enter your phone number</Form.Control.Feedback>
                            <ErrorOutput case={data.userPhone} name={"userPhone"} />
                          </div>
                          {!urlParam.city && (
                            <div className="form-group mt-3 col-lg-6 col-md-6 col-sm-12 pr-0">
                              <input
                                type="text"
                                list="citylist"
                                value={data.userCity}
                                placeholder="Select City"
                                aria-describedby="inputGroupPrepend"
                                name="userCity"
                                className="form-control border-0 phoneNo"
                                onChange={(e) => handleClick(e)}
                                required
                              />
                              <datalist id="citylist">
                                {cityList?.cityList.map((city) => {
                                  return (
                                    <option value={city.name} key={city.id}>
                                      {city.name}
                                    </option>
                                  );
                                })}
                              </datalist>
                              <Form.Control.Feedback type="invalid">Please select city</Form.Control.Feedback>
                              <ErrorOutput case={data.userCity} name={"userCity"} />
                            </div>
                          )}
                          <div className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3 px-0">
                            <button className="theme-btn btn-style-nine getQuote" type="submit" name="submit-form">
                              <span className="txt">Get Quote</span>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="d-md-none">
                        <div className="row clearfix px-2">
                          <div className={`form-group col-lg-12 col-md-12 col-sm-12 border mt-1 ${urlParam.city ? "col-lg-6 col-md-6" : "col-lg-12 col-md-12"}`}>
                            <input
                              type="text"
                              name="userPhone"
                              maxLength={10}
                              pattern="[6-9]{1}[0-9]{9}"
                              value={data.userPhone}
                              aria-describedby="inputGroupPrepend"
                              className="custom-select-box border-0 px-0"
                              placeholder="Enter Your Mobile"
                              onChange={(e) => handleClick(e)}
                              ref={numberInput}
                              required
                            />
                            <Form.Control.Feedback type="invalid">Please enter your phone number</Form.Control.Feedback>
                            <ErrorOutput case={data.userPhone} name={"userPhone"} />
                          </div>
                          {!urlParam.city && (
                            <div className="form-group col-lg-12 col-md-12 col-sm-12 border">
                              <input
                                type="text"
                                list="citylist"
                                value={data.userCity}
                                placeholder="Select City"
                                aria-describedby="inputGroupPrepend"
                                name="userCity"
                                className="custom-select-box border-0 px-0"
                                onChange={(e) => handleClick(e)}
                                required
                              />
                              <datalist id="citylist">
                                {cityList?.cityList.map((city) => {
                                  return (
                                    <option value={city.name} key={city.id}>
                                      {city.name}
                                    </option>
                                  );
                                })}
                              </datalist>
                              <Form.Control.Feedback type="invalid">Please select city</Form.Control.Feedback>
                              <ErrorOutput case={data.userCity} name={"userCity"} />
                            </div>
                          )}

                          <div className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3">
                            <button className="theme-btn btn-style-nine getQuote" type="submit" name="submit-form">
                              <span className="txt">Get Quote</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>

                  <div className="image-column col-lg-7 col-md-12 col-sm-12 mb-0 d-none d-md-block">
                    <div className="inner-column">
                      <div className="image">
                        
                        <Splide  options={ {
                               type         : 'loop',
                               gap          : '1rem',
                               autoplay     : true,
                               pauseOnHover : false,
                               resetProgress: false,
                            } }
                          hasTrack={ true }>
                          <SplideSlide>
                            <img src={CarRepairLanding} alt="car garage near me" />
                          </SplideSlide>
                          <SplideSlide>
                            <img src={CommonBanner} alt="We can fix Everything and any where any Time " />
                          </SplideSlide>
                          <SplideSlide>
                            <img src={CommonBanner2} alt="20% off on dent paint" />
                          </SplideSlide>
                        </Splide>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div id="custom-captcha-verifier"></div>
      <CustomModal show={show} modalSize={optVerified ? "md" : "sm"} content={<GetModalContent />} isCloseButton={true} handleClose={handleClose} />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityList = state.default.cityListReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) => OrderAction.createServiceQuote(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
