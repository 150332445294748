import { preLoginApiRequest } from "../../server";
import { CITY_LIST } from "../types";

const getCityList = (params, dispatch) => {
  preLoginApiRequest(params, (responseData) => {
    dispatch({ type: CITY_LIST, payload: responseData.data });
  });
};

export { getCityList };
