import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import {
  ServiceBg,
  AMC,
  BusinessFleet,
  InsuranceCompany,
} from "../../../../constants/ImageConstants";
import DigitalBenifit from "../Solutions/DigitalBenifit";
import Testimonial from "./../../../../components/common/TestimonialMobile";
import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import { ArrowRight, Trash3 } from "react-bootstrap-icons";
import BlogMultiImageCarousel from "../../../../components/common/blogMultiImageCarouselMobile";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import "firebase/analytics";
import {
  DiscountAction,
  OrderAction,
  UserAction,
} from "../../../../store/action";
import { Link, useNavigate, useParams } from "react-router-dom";
import { colors } from "@mui/material";
import BottomTabs from "../../layout/NavigationBar/BottomsTabs";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import FaqQuery from "./faqQuery";
import VehicleSelector from "../../../../components/common/vehicleSelectorMobile";
import LazyLoad from "react-lazy-load";
import { getAnalytics, logEvent } from "firebase/analytics";
import { logFirebaseEvent } from "../../../../utils/customHelper";

const Home = (props) => {
  const {
    cityData,
    userData,
    updateUserVehicle,
    getUserProfile,
    cartList: { cartList },
  } = props;
  const { full_profile, is_user_logged_in } = userData;
  let profileData = full_profile?.user || full_profile;
  useEffect(() => {
    if (!full_profile && is_user_logged_in) {
      getUserProfile({ task: "getUserCompleteProfile" });
    }
  }, [full_profile, getUserProfile]);
  const [bottomSheet, setBottomSheet] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [disableBottomTabs, setDisableBottomTabs] = useState(false);
  const [openLink, setOpenLink] = useState("");
  useEffect(() => {
    logFirebaseEvent('mobile_web_home_screen');
    props?.getDiscounts();
  }, []);

  function fetchData() {
    if (full_profile) {
      setSelectedVehicle(
        profileData?.vehicles?.filter((item) => item?.is_prefered === "1")[0]
      );
    } else if (window.sessionStorage.getItem("vehicleDetails")) {
      let parse = JSON.parse(window.sessionStorage.getItem("vehicleDetails"));
      const obj = {
        brand_name: parse?.brand?.name,
        model_name: parse?.model?.name,
        variant_name: parse?.variant?.variant,
        icon: parse?.model?.icon,
      };
      setSelectedVehicle(obj);
    }
  }
  useEffect(() => {
    fetchData();
  }, [full_profile]);
  const banners = [
    "https://storage.googleapis.com/vc-wave/website-media/doorstep-banner1.png",
    "https://storage.googleapis.com/vc-wave/website-media/car_service.png",
    "https://storage.googleapis.com/vc-wave/website-media/oil-website.jpg",
    "https://storage.googleapis.com/vc-wave/website-media/vc-prime-web-banner.png",
    "https://vehiclecare.in/vendor/service_offers/car-claim-home-banner.jpg",
    "https://storage.googleapis.com/vc-wave/website-media/mobikwik-banner-250.jpg",
  ];
  const faqScemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: `How can i book my car service with Vehiclecare in ${cityData.selectedCity}?`,
        acceptedAnswer: {
          "@type": "Answer",
          text: `<p>Your car service is just around the corner near your location in ${cityData.selectedCity} .Book directly from our website. Want a more human experience ?call or whatsapp us on 9911020209</p>`,
        },
      },
      {
        "@type": "Question",
        name: `How many VehicleCare car service are there in ${cityData.selectedCity}?`,
        acceptedAnswer: {
          "@type": "Answer",
          text: "We have more than 50 car service centres offering a variety of services such as car wash, car repair, and other mehcanical repair at affordable price.",
        },
      },
      {
        "@type": "Question",
        name: "How much time it will take to deliver our car?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "There is time when you could not see your car for 5-6 hours. In some cases, it will be delivered instantaneously.",
        },
      },
    ],
  };
  const navigate = useNavigate();
  const { selectedCity } = cityData;
  const Cityid = cityData.selectedCity.toLowerCase();
  const urlParam = useParams();
  const metaTagData = { title: "", description: "" };
  const getMetaTagData = (city) => {
    switch (Cityid) {
      case "gurgaon":
        metaTagData.title = `Can You Search The Best Car Service In Gurgaon - VehicleCare`;
        metaTagData.description = `Can You Search Car Service In Gurgaon - From Servicing, Repairs, And Accident Claims To Meticulous Wash, Denting, Painting, And More. Affordable Price In India`;

        break;
      case "mumbai":
        metaTagData.title = `Best Car Service In Mumbai, and Car Accidental Claim Repair`;
        metaTagData.description = `Discover Premium Car Service In Mumbai - From Servicing, Repairs, And Accident Claims To Meticulous Wash, Denting, Painting, And More.Affordable Price In India`;
        break;
      case "ahmedabad":
        metaTagData.title = `Book Top-Notch Car Services In Ahmedabad And Accidental Claim`;
        metaTagData.description = `Best Top-Notch Car Care Services In Ahmedabad  With VehicleCare. From Maintenance,  Enjoy Hassle-Free Service With Complimentary Pickup And Drop`;
        break;
      case "delhi":
        metaTagData.title = `Find The Best Car Service And Repair In Delhi - VehicleCare`;
        metaTagData.description = `Can You Search Car Service And Repair In Delhi - From Servicing, And Accident Claims To Meticulous Wash, Denting, Painting, And More.Affordable Price In India`;
        break;
      case "chennai":
        metaTagData.title = `Find The Trusted Car Service In Chennai With Affordable Price`;
        metaTagData.description = `Find The Trusted Car Service And Repair In Chennai - From Servicing, And Accident Claims To Meticulous Wash, Denting, Painting, And More.Affordable Price In India`;
        break;
      case "bengaluru":
        metaTagData.title = `Find The Trusted Car Service In Bengaluru With Lowest Price`;
        metaTagData.description = `Find The Best Car Service In Bengaluru - From Servicing, And Accident Claims To Meticulous Wash, Denting, Painting, And More. Lowest Price In India`;
        break;

      default:
        metaTagData.title = `Book Top-Notch Car Services In ${cityData.selectedCity} And Accidental Claim`;
        metaTagData.description = `Best Top-Notch Car Care Services In ${cityData.selectedCity} With VehicleCare. From Maintenance,  Enjoy Hassle-Free Service With Complimentary Pickup And Drop`;
        break;
    }
    return metaTagData;
  };

  if (cityData && cityData.selectedCity) getMetaTagData(cityData.selectedCity);

  // useEffect(() => {
  //   axios
  //     .get("https://api.vehiclecare.app/api/web/get-city")
  //     .then(
  //       (res) =>
  //         res?.data?.status == "success" &&
  //         navigate("/" + res?.data?.city?.toLowerCase())
  //     )
  //     .catch((err) => {});
  // }, []);

  const services = [
    {
      name: "Periodic Car Services",
      icon: require("../../../../assets/images/home/periodic_service.png"),
      route: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/${
        selectedVehicle?.brand_name?.toLowerCase() +
        "-" +
        selectedVehicle?.model_name?.toLowerCase() +
        "-" +
        selectedVehicle?.variant_name?.toLowerCase()
      }`,
      offer: {
        name: "Doorstep",
      },
      force_car_selection: true,
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
    },
    {
      name: "Denting & Painting",
      icon: require("../../../../assets/images/home/dent_paint.png"),
      route: `/${selectedCity?.toLowerCase()}/services/denting-painting/${
        selectedVehicle?.brand_name?.toLowerCase() +
        "-" +
        selectedVehicle?.model_name?.toLowerCase() +
        "-" +
        selectedVehicle?.variant_name?.toLowerCase()
      }`,
      force_car_selection: true,
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
    },
    {
      name: "AC Service",
      icon: require("../../../../assets/images/home/ac_repair.png"),
      route: `/${selectedCity?.toLowerCase()}/services/car-ac-services/${
        selectedVehicle?.brand_name?.toLowerCase() +
        "-" +
        selectedVehicle?.model_name?.toLowerCase() +
        "-" +
        selectedVehicle?.variant_name?.toLowerCase()
      }`,
      force_car_selection: true,
      path: `/${selectedCity?.toLowerCase()}/services/car-ac-services/`,
    },

    {
      name: "Car Cleaning",
      icon: require("../../../../assets/images/home/cleaning.png"),
      route: `/${selectedCity?.toLowerCase()}/services/car-cleaning/${
        selectedVehicle?.brand_name?.toLowerCase() +
        "-" +
        selectedVehicle?.model_name?.toLowerCase() +
        "-" +
        selectedVehicle?.variant_name?.toLowerCase()
      }`,
      force_car_selection: true,
      path: `/${selectedCity?.toLowerCase()}/services/car-cleaning/`,
    },
    {
      name: "Car Detailing & Spa",
      icon: require("../../../../assets/images/home/ac_repair.png"),
      route: `/${selectedCity?.toLowerCase()}/services/car-detailing-&-spa/${
        selectedVehicle?.brand_name?.toLowerCase() +
        "-" +
        selectedVehicle?.model_name?.toLowerCase() +
        "-" +
        selectedVehicle?.variant_name?.toLowerCase()
      }`,
      force_car_selection: true,
      path: `/${selectedCity?.toLowerCase()}/services/car-detailing-&-spa/`,
    },
    {
      name: "Mechanical Repairs",
      icon: require("../../../../assets/images/home/periodic_service.png"),
      route: `/${selectedCity?.toLowerCase()}/services/mechanical-repairs/${
        selectedVehicle?.brand_name?.toLowerCase() +
        "-" +
        selectedVehicle?.model_name?.toLowerCase() +
        "-" +
        selectedVehicle?.variant_name?.toLowerCase()
      }`,
      force_car_selection: true,
      path: `/${selectedCity?.toLowerCase()}/services/mechanical-repairs/`,
    },
    {
      name: "Wheel Care",
      icon: require("../../../../assets/images/home/wheel_care.png"),
      route: `/${selectedCity?.toLowerCase()}/services/wheel-care/${
        selectedVehicle?.brand_name?.toLowerCase() +
        "-" +
        selectedVehicle?.model_name?.toLowerCase() +
        "-" +
        selectedVehicle?.variant_name?.toLowerCase()
      }`,
      force_car_selection: true,
      path: `/${selectedCity?.toLowerCase()}/services/wheel-care/`,
    },
    {
      name: "Custom Repairs",
      icon: require("../../../../assets/images/car_repair_icon.png"),
      route: `/${selectedCity?.toLowerCase()}/services/custom-repairs/${
        selectedVehicle?.brand_name?.toLowerCase() +
        "-" +
        selectedVehicle?.model_name?.toLowerCase() +
        "-" +
        selectedVehicle?.variant_name?.toLowerCase()
      }`,
      force_car_selection: true,
      path: `/${selectedCity?.toLowerCase()}/services/custom-repairs/`,
    },
    {
      name: "Warranty",
      icon: InsuranceCompany,
      route: `/warranty`,
      force_car_selection: true,
      path: `/warranty`,
    },
    {
      name: "AMC",
      icon: AMC,
      route: `/amc`,
      force_car_selection: true,
      path: "/amc",
    },
    {
      name: "EV",
      icon: BusinessFleet,
      route: `/ev`,
      force_car_selection: false,
    },
  ];
  const makeDefaultVehicle = (id) => {
    updateUserVehicle({
      task: "setUserVehiclesPreffered",
      id: id,
    });
  };
  const removeVehicle = (id) => {
    updateUserVehicle({
      task: "removeUserVehicles",
      id: id,
    });
  };

  const addNewCar = (vehicleDetails) => {
    updateUserVehicle({
      task: "addUserVehicles",
      brand_id: vehicleDetails.brand.id,
      model_id: vehicleDetails.model.id,
      variant_id: vehicleDetails.variant.id,
    });
  };

  return (
    <>
      <Helmet>
        <title>{metaTagData.title}</title>
        <meta name="description" content={metaTagData.description} />
        <meta
          property="og:url"
          content={`https://vehiclecare.in/${cityData.selectedCity.toLowerCase()}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="VehicleCare" />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:image"
          content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"
        />
        <meta
          property="twitter:title"
          content={`Book Top-Notch Car Services In ${cityData.selectedCity} And Accidental Claim`}
        />
        <meta
          property="twitter:description"
          content={`Best Top-Notch Car Care Services In ${cityData.selectedCity} With VehicleCare. From Maintenance,  Enjoy Hassle-Free Service With Complimentary Pickup And Drop`}
        />
        <meta
          property="twitter:url"
          content={`https://vehiclecare.in/${cityData.selectedCity.toLowerCase()}`}
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@vehiclecare1" />
        <meta
          property="twitter:image"
          content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"
        />

        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(faqScemaData)}
        </script>
        <link
          rel="canonical"
          href={`https://vehiclecare.in/${cityData.selectedCity
            .toLowerCase()
            ?.replace(" ", "-")}`}
        />
      </Helmet>
      {!bottomSheet && cartList?.length > 0 && !disable && !openModal && (
        <div className={`footer-fix my-2 mb-5 pb-4`}>
          <div className="no-gutters">
            <button
              style={{
                width: "93%",
                backgroundColor: "red",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              onClick={() => {
                navigate("/checkout");
              }}
              className="mx-auto py-2 text-white rounded d-flex justify-content-between align-items-center"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <a className="fw-bold text-white">
                  {" "}
                  {cartList?.length}{" "}
                  {cartList?.length === 1 ? "service" : "services"} added
                </a>
                <a className="fw-bold text-white"></a>
              </div>
              <div className="d-flex align-items-center">
                <a className="fw-bold text-white">View Cart </a>{" "}
                <ArrowRight size={16} />
              </div>
            </button>
          </div>
        </div>
      )}
      <Header
        setSheet={(boolean) =>
          is_user_logged_in ? setBottomSheet(boolean) : setOpenModal(boolean)
        }
        selected_vehicle={selectedVehicle}
        disableBottomTabs={(bool) => setDisableBottomTabs(bool)}
      />
      {openModal && (
        <VehicleSelector
          navigate={true}
          hideBar={true}
          open={true}
          setModal={(bool) => {
            setOpenModal(bool);
            fetchData();
          }}
          navigFun={!is_user_logged_in ? false : addNewCar}
          openLink={openLink}
        />
      )}
      <BottomSheet open={bottomSheet} onDismiss={() => setBottomSheet(false)}>
        <h4
          style={{
            color: colors.common.black,
            fontWeight: "bold",
            marginLeft: "15px",
          }}
        >
          My Garage
        </h4>
        <div
          style={{
            height: "40vh",
            overflowY: "scroll",
          }}
        >
          {profileData?.vehicles?.map((item) => (
            <div
              style={{
                border: `1px solid ${
                  item?.is_prefered === "1" ? "red" : "lightgrey"
                }`,
                margin: "10px",
                padding: "20px 10px 20px 10px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => makeDefaultVehicle(item?.id)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={item?.icon} width={90} alt="img" />
                <div style={{ marginLeft: "10px" }}>
                  <a style={{ color: colors.common.black, fontWeight: "bold" }}>
                    {item?.model_name}
                  </a>
                  <br />
                  <a>{item?.variant_name}</a>
                </div>
              </div>
              <Trash3
                size={20}
                color={"red"}
                onClick={() => removeVehicle(item?.id)}
              />
            </div>
          ))}
        </div>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          onClick={() => {
            let vehicleDetails =
              window.sessionStorage.getItem("vehicleDetails");
            // if(!is_user_logged_in && !vehicleDetails){
            setOpenModal(true);
            setBottomSheet(false);
            // }
          }}
        >
          <button
            style={{ width: "93%", borderRadius: "5px" }}
            class="theme-btn btn-style-four btn-sm px-2 py-2 my-2"
          >
            <span class="txt">Add New Car +</span>
          </button>
        </div>
      </BottomSheet>
      <section>
        <div
          onClick={() => navigate("/search")}
          style={{
            border: "1px solid lightgrey",
            width: "90%",
            height: "40px",
            marginTop: selectedVehicle?.brand_id ? "35%" : "32%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 0px 3px 1px lightgrey",
          }}
        >
          <img
            src={require("../../../../assets/images/home/Searchicon.png")}
            style={{ marginLeft: "10px" }}
            width={20}
            height={20}
            alt="img"
          />
          <span style={{ marginLeft: "5px" }}>Search car services...</span>
        </div>
      </section>
      <section
        style={{
          display: "flex",
          overflowX: "scroll",
          marginTop: "20px",
          marginLeft: "20px",
          marginRight: "20px",
          scrollbarWidth: "thin",
        }}
      >
        {banners.map((item) => (
          <img
          alt="img"
            src={item}
            style={{
              marginRight: "20px",
              borderRadius: "10px",
              width: "75vw",
              height: "22vh",
            }}
          />
        ))}
      </section>
      <h1
        className="text-center header1"
        style={{
          fontWeight: "bold",
          color: "black",
          margin: "15px 15px 0px 15px",
        }}
      >
        Experience Premier Car Care Services in {cityData.selectedCity}.
      </h1>
      <Row>
        <section
          style={{
            display: "grid",
            width: "90%",
            gridTemplateColumns: "auto auto auto auto",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "space-between",
            gap: "10px",
            marginTop: "30px",
          }}
        >
          {services.map((item) => (
            <Link
              onClick={() => {
                let vehicleDetails =
                  window.sessionStorage.getItem("vehicleDetails");
                if (
                  !is_user_logged_in &&
                  item.force_car_selection &&
                  !vehicleDetails
                ) {
                  setOpenModal(true);
                  setOpenLink(item?.path);
                } else setOpenLink("");
              }}
              to={
                (is_user_logged_in ||
                  window.sessionStorage.getItem("vehicleDetails") ||
                  !item.force_car_selection) &&
                item?.route
              }
            >
              <div
                style={{
                  width: "18vw",
                  height: "8vh",
                  boxShadow: "0px 0px 3px 1px lightgrey",
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                {item?.offer && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      backgroundColor: "#33B740",
                      borderTopRightRadius: 10,
                      borderTopLeftRadius: 10,
                      padding: 1,
                      width: "100%",
                      height: "1.5vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "8px",
                        color: "white",
                      }}
                    >
                      Doorstep
                    </span>
                  </div>
                )}

                <img src={item.icon} style={{ width: "12vw" }} alt="img"/>
              </div>
              <span
                style={{
                  display: "block",
                  width: "18vw",
                  fontSize: "9px",
                  textAlign: "center",
                  lineHeight: "10px",
                  marginTop: "5px",
                  color: colors.common.black,
                  fontWeight: "bold",
                }}
              >
                {item?.name}
              </span>
            </Link>
          ))}
        </section>
        {/* <section className="banner-section-two style-two">
          <div className="main-slider-carousel active">
            <div>
              <Banner />
            </div>
          </div>
        </section> */}

        {/* About Section */}
        {/* <div className="about-section pt-0">
          <div className="auto-container">
            <IAboutUs />
          </div>
        </div> */}

        {/* Our Best Service Section */}
        <div
          className="services-section-four pb-4"
          style={{
            backgroundImage: `url(${ServiceBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
          }}
        >
          {/* <IOurBestServices city={cityData?.selectedCity} /> */}
        </div>

        {/* <section className="about-section pt-0 pb-4">
          <img src={VC2024} alt="" />
        </section> */}

        {/* Extra Section */}
        {/*Premium Car Service */}
        <section className="about-section pt-0">
          <div className="pattern-layer-one"></div>

          <div className="auto-container">
            <div className="upper-section">
              <div className="row clearfix">
                <div className="content-column col-lg-12 col-md-12 col-sm-12">
                  <div className="inner-column mt-lg-5">
                    {/* <div
                      className="sec-title mb-4"
                      style={{ textAlign: "justify" }}
                    >
                      <h6 className="text-center text-dark fw-bold">
                        Why Choose <font color="#ed1c24">VehicleCare</font> for
                        Car Services in {cityData.selectedCity}?
                      </h6>
                      <div className="text text-left">
                        <ul>
                          <li>
                          We deliver high-quality work at competitive prices. Our
                        skilled professionals ensure your car receives the care
                        it deserves.
                          </li>
                          <li>
                          We use 100% authentic OEM parts, maintaining your car’s
                        integrity and performance without compromising on
                        quality.
                          </li>
                        </ul>
                        
                        
              
                        From routine maintenance to extensive repairs, our wide
                        range of services caters to all your car needs at
                        affordable rates.
                        With over 1000 workshops in 200+ cities, VehicleCare is always nearby. 
                        We offer a 1000 km or 1-month warranty across India.
                        Whether it’s Maruti Suzuki or luxury brands like Mercedes and BMW, our experts handle all makes and models with expertise.
                        Utilising AI and ML, we provide a robust, customer-focused service platform revolutionising car care.
                        Our round-the-clock customer service is available via WhatsApp, email, chat, or phone to assist you anytime
                        
                      </div>
                        
                    </div> */}

                    <div className="detail">
                      <div className="d-flex justify-content-center col inner-box">
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 lower-content"
                          style={{ textAlign: "justify" }}
                        >
                          <div className="sec-title mb-2">
                            <h6 className="text-center text-dark">
                              <b>
                                Why Choose{" "}
                                <font color="#ed1c24">VehicleCare</font> for Car
                                Services in {cityData.selectedCity}?
                                {/* <font color="#ed1c24">{cityData.selectedCity}</font> */}
                              </b>
                            </h6>
                          </div>

                          <p className="mb-1" style={{ marginTop: "18px" }}>
                            <b>
                              <font color="#ed1c24">
                                Reliable & Affordable -{" "}
                              </font>
                            </b>
                            We deliver high-quality work at competitive prices.
                            Our skilled professionals ensure your car receives
                            the care it deserves.
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24">Genuine Parts Only - </font>
                            </b>
                            We use 100% authentic OEM parts, maintaining your
                            car’s integrity and performance without compromising
                            on quality.
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24">
                                Comprehensive Services -{" "}
                              </font>
                            </b>
                            From routine maintenance to extensive repairs, our
                            wide range of services caters to all your car needs
                            at affordable rates.
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24">
                                Easy Booking & Free Pickup -{" "}
                              </font>
                            </b>
                            Schedule services effortlessly through our app or
                            website. Enjoy free car pickup and drop-off with
                            timely service.
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24">
                                Nationwide Presence -{" "}
                              </font>
                            </b>
                            With over 1000 workshops in 200+ cities, VehicleCare
                            is always nearby. We offer a 1000 km or 1-month
                            warranty across India.
                            {/* We provide centralised service support, so even if you live in {cityData.selectedCity} and take service in Noida, that’s all right, we got you covered! */}
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24">
                                All Brands Serviced -{" "}
                              </font>
                            </b>
                            Whether it’s Maruti Suzuki or luxury brands like
                            Mercedes and BMW, our experts handle all makes and
                            models with expertise.
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24">
                                Advanced Technology -{" "}
                              </font>
                            </b>
                            Utilising AI and ML, we provide a robust,
                            customer-focused service platform revolutionising
                            car care.
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24">24/7 Support - </font>
                            </b>
                            Our round-the-clock customer service is available
                            via WhatsApp, email, chat, or phone to assist you
                            anytime.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="about-section pt-0 pb-0">
          <TrafficChallanCars24 />
        </section> */}

        {/* Faq Section  */}
        <LazyLoad>
          <FaqQuery />
        </LazyLoad>

        {/* Testimonial Section  */}
        <div className="">
          <LazyLoad>
            <Testimonial city_name={cityData.selectedCity} />
          </LazyLoad>
        </div>

        {/* VcRepairs */}
        {/* <section className="features-section pt-0">
          <VcRepairs />
        </section> */}

        {/* Happy Client Section */}
        <div className="pt-2" style={{ background: "whitesmoke" }}>
          <DigitalBenifit />
        </div>

        {/* Blog Section */}
        <div className="blog-section m-0 pt-2">
          {/* <IBlog /> */}
          <LazyLoad>
            <BlogMultiImageCarousel />
          </LazyLoad>
        </div>

        {/* Our App */}
        {/* <div
          className="testimonial-section-one bg-size"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
            paddingBottom: "110px",
          }}
        >
          <OurApp />
        </div> */}
      </Row>

      {/* {isMobile && (
        <a className="fab" href="tel:9911-020-209">
          <LazyLoad>
            <img src={require("../../../../assets/images/phone-call.png")} />
          </LazyLoad>
        </a>
      )} */}
      {/* <div className="d-lg-none d-md-none d-block mt-5">
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="challan_box_mobile">
            <div>
              <h5 className="text-white" style={{ fontWeight: "bold" }}>
                Check Traffic Challan Status & Pay Online
              </h5>
              <div>
                <li className="challan_desc_mobile">
                  ✅ Free access to your traffic eChallans.
                </li>
                <li className="challan_desc_mobile">✅ Pay effortlessly.</li>
                <li className="challan_desc_mobile">
                  ✅ No hassle of court visits.
                </li>
              </div>
            </div>
            <a
              href="https://cars24.com/traffic-challan?utm_source=VEHICLECARE&utm_medium=APP&utm_campaign=LISTING"
              className="btn check-btn inactive p-0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="button"
                class="btn btn-outline-light hover-btn-mobile"
                style={{ fontWeight: "bold", marginTop: "20px" }}
              >
                Check now
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="d-lg-block d-md-block d-none">
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="challan_box">
            <div>
              <h5 className="text-white" style={{ fontWeight: "bold" }}>
                Check Traffic Challan Status & Pay Online
              </h5>
              <div style={{ display: "flex", marginTop: "15px" }}>
                <li className="challan_desc">
                  ✅ Free access to your traffic eChallans.
                </li>
                <li className="challan_desc">✅ Pay effortlessly.</li>
                <li className="challan_desc">✅ No hassle of court visits.</li>
              </div>
            </div>
            <a
              href="https://cars24.com/traffic-challan?utm_source=VEHICLECARE&utm_medium=APP&utm_campaign=LISTING"
              className="btn check-btn inactive"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="button"
                class="btn btn-outline-light hover-btn"
                style={{ fontWeight: "bold" }}
              >
                Check now
              </button>
            </a>
          </div>
        </div>
      </div> */}
      {!bottomSheet && !disableBottomTabs && (
        <BottomTabs
          route_name={"home"}
          user={is_user_logged_in}
          disable={(bool) => setDisable(bool)}
        />
      )}
      <Footer bottomTabs={true} />
    </>
  );
};
const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  stateObj.cartList = state.default.cartListReducer;
  return stateObj;
};

const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) =>
    OrderAction.createServiceQuote(params, dispatch),
  getUserProfile: (params) => UserAction.getUserProfile(params, dispatch),
  getDiscounts: (params) => DiscountAction.getDiscounts(params, dispatch),
  updateUserVehicle: (params) => UserAction.updateUserVehicle(params, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
