import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Pattern1, Pattern2, ServiceBg, Faqbg, Appbg } from "../../../../../constants/ImageConstants";
import BannerSection from "./BannerSection";
import Header from "../../../layout/NavigationBar/Header";
import Footer from "../../../layout/NavigationBar/Footer";
import InsuranceAbout from "../../Insurance/InsuranceAbout";
import VcClaimProcess from "../../Insurance/VcClaimProcess";
import OurServices from "../../Insurance/OurServices";
import TrackManageClaims from "../../Insurance/TrackManageClaims";
import TrustedCompany from "../../Insurance/trustedCompany";
import HowWorks from "../../Insurance/HowWorks";
import OurApp from "../../../../../components/common/ourApp";
import Testimonial from "../../../../../components/common/Testimonial";
import FaqQuery from "../../../../../components/common/faqQuery";
import BlogMultiImageCarousel from "../../../../../components/common/blogMultiImageCarouselMobile";
import { Helmet } from "react-helmet";

const Insurer = (props) => {
  const source = props.source || "Insurer";
  return (
    <>

      <Helmet>
        <title>End to end car maintenance and claim service -  VehicleCare</title>
        <meta
          name="description"
          content="Using AI, We provide a seamless onboarding process for car insurance claims. With more than 650 workshops, a robust platform and Intelligent technology can make your claim process smooth."
        />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        <Col>
          {/* Banner Section */}
          <section className="banner-section-three style-two">
            <div className="main-slider-carousel">
              <div className="slide">
                <BannerSection source={source} />
              </div>
            </div>
          </section>

          {/* Car Damage Section */}
          <section className="about-section" style={{ background: "rgb(251, 251, 251)" }}>
            <InsuranceAbout />
          </section>

          {/* Trusted Company */}
          <section className="about-section pt-5 pb-1">
            <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
            <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>
            <TrustedCompany />
          </section>

          {/* vc claim process Section */}
          <section className="services-section-two pt-3">
            {/* <div className="image-layer" style={{ backgroundImage: `url(${Bg4})` }}></div> */}
            <VcClaimProcess />
          </section>

          {/* Our Services Section */}
          <section
            className="services-section-four pb-5"
            style={{
              backgroundImage: `url(${ServiceBg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
            }}
          >
            <OurServices />
          </section>

          {/* Process Section */}
          <section className="process-section pt-0 pb-5">
            <TrackManageClaims />
          </section>

          {/* How it works */}
          <section className="process-section pt-5" style={{ backgroundColor: "#F5F1EE" }}>
            <HowWorks />
          </section>

          <div className="acordian-section" style={{ backgroundImage: `url(${Faqbg})`, backgroundSize: "cover" }}>
            <FaqQuery />
          </div>

          <div className="testimonial-section-four">
            <Testimonial />
          </div>

          {/* Blog Section */}
          <div className="blog-section">
            <BlogMultiImageCarousel />
          </div>

          {/* Our App */}
          <div
            className="testimonial-section-one bg-size pb-5"
            style={{
              backgroundImage: `url(${Appbg})`,
              backgroundSize: `cover`,
              backgroundColor: `#f5f1ee`,
            }}
          >
            <OurApp />
          </div>



          {/* <div className="acordian-section pt-5" style={{ backgroundImage: `url(${Citybg})`, backgroundSize: `inherit`, backgroundColor: `#f5f1ee` }}>
            <CityList />
          </div> */}
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default Insurer;
