import React, { useState } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { FAQ_CITY_ID, SET_CITY_NAME } from "../../../store/types";
import "./index.scss";
import { useNavigate, useParams } from "react-router-dom";
import { colors } from "@mui/material";

const ChooseCity = (props) => {
  const navigate = useNavigate();
  const urlParam = useParams();
  let updatedCityList = props.cityList;
  const [searchedCity, setSearchedCity] = useState("");
  const isCloseButton = props.isCloseButton;
  const handleChange = (e) => {
    setSearchedCity(e.target.value);
  };
  updatedCityList = updatedCityList.filter((elem) =>
    elem.name.toUpperCase().includes(searchedCity.toUpperCase())
  );
  const updateCityList = (data) => {
    window.sessionStorage.setItem("selectedCity", JSON.stringify(data));
    props.updateCityReducer({ type: FAQ_CITY_ID, payload: data.id });
    props.updateCityReducer({ type: SET_CITY_NAME, payload: data.name });
    props.handleClose();
    const basePath = window.location.pathname.replace(
      `${urlParam.city.replace(/ /g, "-")}`,
      ""
    );
    navigate(
      `/${data.name.replace(/ /g, "-").toLowerCase()}${basePath}`.replace(
        /([^:]\/)\/+/g,
        "$1"
      )
    );
    window.location.reload();
  };
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      keyboard={isCloseButton}
      backdrop="static"
      centered
      size="lg"
    >
      <Modal.Header
        closeButton={isCloseButton}
        style={{ borderBottom: "none" }}
      >
        {/* <Modal.Title>Choose Your City</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className=" form-control borderBtm" style={{ marginRight: "5px" }}>
          <img
            src={require("../../../assets/images/home/Searchicon.png")}
            style={{ marginRight: "10px" }}
            width={20}
            height={20}
          />
          <input
            type="search"
            placeholder="Search here..."
            value={searchedCity}
            className=""
            onChange={(e) => handleChange(e)}
            style={{ fontSize: "15px" }}
          />
        </div>
        <Row style={{ height: "60vh", overflowY: "auto" }}>
          {!updatedCityList.length ? (
            <Col>
              <h3 className="text-center text-warning mt-5">
                Kindly wait while fetching the list ...
              </h3>
            </Col>
          ) : (
            <section
              style={{
                display: "grid",
                width: "100%",
                gridTemplateColumns: "auto auto auto auto",
                marginLeft: "auto",
                marginRight: "auto",
                justifyContent: "space-evenly",
                gap: "20px",
                marginTop: "30px",
              }}
            >
              {updatedCityList.map((city) => {
                return (
                  <div onClick={() => updateCityList(city)}>
                    <div
                      style={{
                        width: "17vw",
                        height: "8vh",
                        boxShadow: "0px 0px 3px 1px lightgrey",
                        border: "1px solid lightgrey",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ borderRadius: "50px", width: "12vw" }}
                        src={city.icon}
                      />
                    </div>
                    <a
                      style={{
                        display: "block",
                        width: "15vw",
                        fontSize: "10px",
                        textAlign: "center",
                        lineHeight: "10px",
                        marginTop: "5px",
                        color: colors.common.black,
                        fontWeight: "bold",
                      }}
                    >
                      {city?.name}
                    </a>
                  </div>
                );
              })}
            </section>
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

function mapStateToProps(state) {
  return state.default.cityListReducer;
}
const mapDispatchToProps = (dispatch) => ({
  updateCityReducer: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChooseCity);
