import React , { useState } from "react";
import { Row,Col } from "react-bootstrap";
import "./index.scss";
import { Appbg, Pattern1, Pattern2 ,Faqbg} from "../../../../constants/ImageConstants";

// import CityList from "./../../../../components/common/cityList";
import OurApp from "../../../../components/common/ourApp";

import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import Testimonial from "../../../../components/common/Testimonial";
import BlogMultiImageCarousel from "../../../../components/common/blogMultiImageCarousel";
import ServicesWeOffer from "../Landing/ServicesWeOffer";
import VcBenifits from "../Landing/VcBenifits";
import WhyChooseVc from "../Landing/WhyChooseVc";
import ServiceSteps from "../Landing/ServiceSteps";
import Banner from "./Banner";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CarGaragePriceList from "./CarGaragePriceList";


const CarGarageNearMe = (props) => {
  const selectedCityName = props.cityListReducer.selectedCity;

  const faqList =[
    {
        id:1,
        title:"Do VehicleCare garage offer doorstep pickup and delivery service?",
        summary:"Yes, we provide free Pickup and Drop service at your doorstep so you dont have to worry about about that."   
    },
    {
        id:2,
        title:"How can we find the nearest car garage?",
        summary:"It is simple you can log on to our website www.vehiclecare.in, and fill in the basic details that are mentioned above. You can also simply call, WhatsApp, talk to me." 
       
    },
    {
        id:3,
        title:"What are the various mode of Payment accepted here?",
        summary:"You can make payment via Cash, GooglePay or Paytm." 
       
    },
    {
        id:4,
        title:"What services are offered at a car garage?",
        summary:"We provide a wide range of services which are available.We offer Car service, Wheel Care, Car AC Repair, Custom Repair,dent paint, insurance claim and much more." 
       
    },
    {
      id:5,
      title:"How long does a car service take at the garage?",
      summary:"There is time when you could not see your car for 5-6 hours. But in some cases the repair could take anywhere from 5-6 hours to a week or more." 
     
  },
  {
    id:6,
    title:"What does garage mechanic check when they service your car?",
    summary:"We first start with a document check and test drive and then use the latest tools to assess the exterior, engine, tire, transmission, electrical, and interior. We also inspect the suspension, steering mechanism, brakes, oil and oil filters, battery and other accessories of your car during the test drive." 
   
},
{
  id:7,
  title:"Do you offer pickup and drop service?",
  summary:"Yes, we provide free Pickup and Drop service at your doorstep so you dont have to worry about about that." 
 
}
];

const [toggle, setToggle] = useState(0);
let handleToggle = (id) => {
  if (toggle === id) {
    setToggle(null);
    return false;
  }
  setToggle(id);
};
const active = { backgroundColor: "black", color: "white" };
  const inactive = {};


  return (
    <>
      <Helmet>
        <title>Premium Car Garage Near Me Off All Time - VehicleCare</title>
        <meta name="description" content="In Search of Trusted Nearby Car Garages Look No Further! We Are Here To Help You Out With Your Query - We've Got Garages And Service Centres Throughout India" data-react-helmet="true" />
      
        <meta property="og:title" content="Premium Car Garage Near Me Off All Time - VehicleCare"/>
        <meta property="og:description" content="In Search of Trusted Nearby Car Garages Look No Further! We Are Here To Help You Out With Your Query - We've Got Garages And Service Centres Throughout India"/>
        <meta property="og:url" content={`https://vehiclecare.in/car-garage-near-me`}/>
        <meta property="og:type" content="website"/>
        <meta property="og:site_name" content="VehicleCare"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"/>
        <meta property="twitter:title" content="Premium Car Garage Near Me Off All Time - VehicleCare"/>
        <meta property="twitter:description" content="In Search of Trusted Nearby Car Garages Look No Further! We Are Here To Help You Out With Your Query - We've Got Garages And Service Centres Throughout India"/>
        <meta property="twitter:url" content={`https://vehiclecare.in/car-garage-near-me`}/>
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:site" content="@vehiclecare1"/>
        <meta property="twitter:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"/>
      </Helmet>

      <Header isStatic={true} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner />
            </div>
          </div>
        </section>

        <div className="services-section-four pt-0 pb-lg-5 pb-sm-4">
          <Row>
            <Col>
              <div className="auto-container">
                <div className="sec-title centered">
                  <h2>Services We Offer</h2>
                  <div className="text col-8 mx-auto d-none d-md-block">
                  Find the best service packages for your car. Select from a wide range of car services, from general service to
                    car wash, 
                  accidental repair to custom repair, cashless insurance claims, and much more.
                  </div>
                </div>

                <ServicesWeOffer selectedCityName={selectedCityName} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="services-section-four pt-0">
          <ServiceSteps />
        </div>

        {/* price list */}
        <div className="blog-section pt-0">
          <CarGaragePriceList />
        </div>

        <div className="price-section " style={{ background: "rgb(251, 251, 251)" }}>
          <VcBenifits />
        </div>

        <div className="services-section-four">
          <div className="auto-container">
            <div className="sec-title centered">
              <h2>Why Choose VehicleCare </h2>
            </div>
          </div>
          <WhyChooseVc />
        </div>

        {/*Car Workshop Near Me? */}
        <section className="about-section pt-5 pb-5" style={{ backgroundColor: "rgb(251, 251, 251)" }}>
          <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
          <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>

          <div className="auto-container">
            <div className="upper-section">
              <div className="row clearfix d-flex justify-content-center">
                <div className="content-column col-lg-12 col-md-12 col-sm-12 mb-2">
                  <div className="inner-column">

                    <div className="sec-title mb-4">
                      <h2 className="text-center">Looking For <font color="#ed1c24">Car Garage</font> Nearby?</h2>
                        <div className="text text-left mb-0">
                          Hello there! Welcome to VehicleCare, where exceptional car repair services meet the convenience of online accessibility. Say goodbye to the hassle of searching for a "car garage near me" or “nearby car garage”.
                        </div>

                        <div className="text text-left mt-2 mb-0">
                          VehicleCare is your trusted online platform for professional multi-brand car repair and services. We bring to you the best auto shop near you for a wonderful car repair experience. We are your 24x7 nearby car garage. Discover the simplicity and excellence of VehicleCare's online car repair services today. Your quest for the perfect "garage near me for car" ends here.
                        </div>
                    </div>

                    <div className="detail">
                      <div className="d-flex justify-content-center col inner-box">
                        <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                          
                          <div className="sec-title mb-4"><h2 className="text-left fs-4 text-capitalize">Why are VehicleCare Car Garages the Best Car Garage Nearby?</h2></div>
                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                              1. Skilled technicians:
                              </font>
                            </b>
                            <span>At VehicleCare, we understand that each car is unique and each car needs special care. That’s why we employ our garages, the best and the most skilled professionals so that your car gets the treatment it deserves. </span>
                          </p>
                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                              2. Use of state of the art diagnosis:
                              </font>
                            </b>
                            <span> At VehicleCare, we prioritize precision and excellence in every aspect of car repair, and this begins with our commitment to providing our certified technicians with the best state-of-the-art tools to carry out diagnostic work.
                            <br/>Our technicians are equipped with cutting-edge diagnostic equipment that enables them to conduct thorough assessments of your vehicle's condition before initiating any repair and pinpoint any issue with precision, ensuring an accurate diagnosis of your car's problems before servicing.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                              3. Quality repair everytime:
                              </font>
                            </b>
                            <span>VehicleCare is dedicated to providing top-quality 
                            <a href="https://vehiclecare.in/car-repair-near-me" target="_blank" rel="noreferrer"><font color="#ed1c24"> car repair services </font></a>
                             . We are your 24x7 nearby car garage and your satisfaction is our priority. We promise to guarantee quality repairs that stand the test of time so that you always end up searching VehicleCare nearest car garage.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                              4. Environment friendly approach:
                              </font>
                            </b>
                            <span> By choosing VehicleCare you not get top-notch repair services but also get the opportunity to contribute to a cleaner environment. Our dedication to eco-conscious practices and commitment to recycling and waste management is reflected in our energy-efficient practices and environment friendly automotive product. </span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                              5. Transparent and upfront pricing:
                              </font>
                            </b>
                            <span> We believe in transparency and accountability. We never leave you wondering with unanswered questions. With VehicleCare, you get clear and upfront pricing for all your repairs & services. No hidden fees, no surprises – we give you only honest and competitive rates and absolute peace of mind.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                              6. Easy online booking &Time based delivery:
                              </font>
                            </b>
                            <span> With our easy booking and time-based delivery system, VehicleCare ensures a seamless and hassle-free experience for its valued customers. Our innovative time-based delivery model means that our skilled technicians arrive at your doorstep precisely when scheduled, eliminating the need for unnecessary waiting or disruptions to your day. We understand the ease of effortless online booking and the value of  punctual delivery services. So, wherever you think of “garage near me for my car”, we are always there.</span>
                          </p>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section  */}
        <div className={`acordian-section ${faqList?.length < 1 && "d-none"}`} style={{ backgroundImage: `url(${Faqbg})`, backgroundSize: "cover" }}>
          <Col  className={`auto-container`}>
            <div className="row clearfix">
              <div className="accordian-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    {/* <div className="title">Contact Support</div> */}
                    <h2>Frequently Asked Questions</h2>
                  </div>
                  <ul className="accordion-box">
                  {faqList?.map((value, index) => {
                    return (
                      <div className="accordion block mb-1" id="acc_faq" key={index}>
                        <div
                          className="acc-btn"
                          onClick={() => handleToggle(index)}
                          style={index === toggle ? active : inactive}
                        >
                          <h2>{value.title}{" "}</h2>
                          <div className="icon-outer">
                            <b className="icon">{index === toggle ? "-" : "+"} </b>{" "}
                          </div>{" "}
                        </div>
                        {index === toggle ? (
                          <div className="content px-2 py-0">
                            <div className="accordian-text"> {value.summary} </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                  </ul>
                </div>
              </div>

              <div className="title-column col-lg-5 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title">Contact Support</div>
                    <h2>Have Any Questions?</h2>
                  </div>
                  <div className="inquiry-box">
                    <div className="box-inner">
                      <div className="upper-box">
                        <div className="icon flaticon-telephone"></div>
                        <div className="inquiry-title">For Any Inquiries Call Now!</div>
                        <a href="tel:9911-020-209" className="phone">
                          9911-020-209
                        </a>
                      </div>
                      <div className="lower-box">
                        <a className="emailed" href="mailto: support@vehiclecare.in?subject=Need Help">
                          support@vehiclecare.in
                        </a>
                      </div>
                    </div>
                    <Link to={"/getintouch"} className="get-tech">
                      Get You Tech Now!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
        {/* FAQ Section  */}

        {/* Testimonial Section  */}
        <div className="d-none d-md-block">
        <Testimonial city_name="Gurgaon" />
        </div>

        {/* Blog Section */}
        <div className="blog-section">
          {/* <IBlog /> */}
          <BlogMultiImageCarousel />
        </div>

        
        {/* Our App */}
        <div
          className="testimonial-section-one bg-size pb-5"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
          }}
        >
          <OurApp />
        </div>


      </Row>
      <Footer />
    </>
  );
};

function mapStateToProps(state) {
  return {
    cityListReducer: state.default.cityListReducer,
  };
}
export default connect(mapStateToProps)(CarGarageNearMe);
