import React, { useState } from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import {
  Appbg,
  VehiclecareB1,
  VehiclecareB2,
  VehiclecareB3,
  VehiclecareB4,
  VehiclecareB5,
  VehiclecareB6,
  VehiclecareB7,
  VehiclecareB8,
  VehiclecareB9,
  VehiclecareB10,
  VehiclecareB11,
  VehiclecareB12,
  VehiclecareB13,
  VehiclecareB14,
  ACRepair,
  Battery,
  CarWash,
  CustumRepair,
  DentingPainting,
  InsuranceClaimLanding,
  PeriodicService,
  WheelCare,
} from "../../../../constants/ImageConstants";

import OurApp from "../../../../components/common/ourApp";

import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import Testimonial from "../../../../components/common/AgentTestimonial";
import VcBenifits from "../Landing/VcBenefitsOneAssist";

import Banner from "./Banner";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import LazyLoad from "react-lazy-load";
import DigitalBenifit from "../Solutions/DigitalBenifit";

const Agent = (props) => {
  return (
    <>
      <Helmet>
        <title>Experience The High Quality Service Off All Time In India</title>
        <meta
          name="description"
          content="Can You Serach The Best High Quality Service Off All Time - VehicleCare Provide The Best Discount And Offer On Services"
          data-react-helmet="true"
        />

        <meta name="robots" content="noindex,nofollow" />

        <meta
          property="og:title"
          content="Can You Search For A Car Service Near Me - Vehiclecare"
        />
        <meta
          property="og:description"
          content="Can You Serach The Best High Quality Service Off All Time - VehicleCare Provide The Best Discount And Offer On Services"
        />
        <meta
          property="og:url"
          content={`https://vehiclecare.in/car-mechanic-near-me`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="VehicleCare" />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:image"
          content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"
        />
        <meta
          property="twitter:title"
          content="Can You Search For A Car Service Near Me - Vehiclecare"
        />
        <meta
          property="twitter:description"
          content="Can You Serach The Best High Quality Service Off All Time - VehicleCare Provide The Best Discount And Offer On Services"
        />
        <meta
          property="twitter:url"
          content={`https://vehiclecare.in/car-mechanic-near-me`}
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@vehiclecare1" />
        <meta
          property="twitter:image"
          content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"
        />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner />
            </div>
          </div>
        </section>

        <div className="services-section-four pt-0 pb-5">
          <div className="auto-container mb-5">
            <div className="sec-title centered">
              <h2>Why Partner with VC</h2>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <div className="col-lg-10 col-md-11 col-sm-12">
                <div className="row">
                  <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6 border-right">
                    <div className="inner-box text-center border-0">
                      <div className="">
                        <img
                          className="w-35 mx-auto pt-4"
                          src={CarWash}
                          alt="Car Service"
                        />
                      </div>
                      <div className="lower-content">
                        <h5 className="fs-6 text-dark">
                          Highest claim settlement ratio
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6 border-right">
                    <div className="inner-box text-center border-0">
                      <div className="">
                        <img
                          className="w-35 mx-auto pt-4"
                          src={WheelCare}
                          alt="Wheel Care"
                        />
                      </div>
                      <div className="lower-content">
                        <h5 className="fs-6 text-dark">
                          Largest workshop network
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6 border-right">
                    <div className="inner-box text-center border-0">
                      <div className="">
                        <img
                          className="w-35 mx-auto pt-4"
                          src={ACRepair}
                          alt="Car AC Repair"
                        />
                      </div>
                      <div className="lower-content">
                        <h5 className="fs-6 text-dark">
                          Highest Commission & Digital process
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="service-block-four mb-0 col-lg-3 col-md-6 col-6">
                    <div className="inner-box text-center border-0">
                      <div className="">
                        <img
                          className="w-35 mx-auto pt-4"
                          src={Battery}
                          alt="Car Battery"
                        />
                      </div>
                      <div className="lower-content">
                        <h5 className="fs-6 text-dark">
                          Additional Incentive & more benefits
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <DigitalBenifit />
        </div>

        {/*brand Section*/}
        <section className="sponsors-section style-three">
          <div className="container-fluid text-center" id="splideCarousel">
            <div className="carousel-outer pb-5 d-none d-md-block">
              <Splide
                options={{
                  updateOnMove: true,
                  type: "loop",
                  drag: "free",
                  perPage: 7,
                  perMove: 1,
                  focus: 0,
                  interval: 2000,
                  speed: 400,
                  pagination: false,
                  autoplay: "play",
                  autoScroll: {
                    speed: 1,
                  },
                }}
              >
                <SplideSlide>
                  <img src={VehiclecareB1} alt="" />
                </SplideSlide>
                <SplideSlide>
                  <img src={VehiclecareB2} alt="" />
                </SplideSlide>
                <SplideSlide>
                  <img src={VehiclecareB3} alt="" />
                </SplideSlide>
                <SplideSlide>
                  <img src={VehiclecareB4} alt="" />
                </SplideSlide>
                <SplideSlide>
                  <img src={VehiclecareB5} alt="" />
                </SplideSlide>
                <SplideSlide>
                  <img src={VehiclecareB6} alt="" />
                </SplideSlide>
                <SplideSlide>
                  <img src={VehiclecareB7} alt="" />
                </SplideSlide>
                <SplideSlide>
                  <img src={VehiclecareB8} alt="" />
                </SplideSlide>
                <SplideSlide>
                  <img src={VehiclecareB9} alt="" />
                </SplideSlide>
                <SplideSlide>
                  <img src={VehiclecareB10} alt="" />
                </SplideSlide>
                <SplideSlide>
                  <img src={VehiclecareB11} alt="" />
                </SplideSlide>
                <SplideSlide>
                  <img src={VehiclecareB12} alt="" />
                </SplideSlide>
                <SplideSlide>
                  <img src={VehiclecareB13} alt="" />
                </SplideSlide>
                <SplideSlide>
                  <img src={VehiclecareB14} alt="" />
                </SplideSlide>
              </Splide>
            </div>

            <div className="carousel-outer d-md-none">
              <Splide
                options={{
                  type: "loop",
                  drag: "free",
                  focus: "center",
                  interval: 2000,
                  speed: 200,
                  autoplay: "play",
                  pagination: false,
                  perPage: 3,
                  autoScroll: {
                    speed: 1,
                  },
                }}
              >
                <SplideSlide>
                  <img src={VehiclecareB1} alt="" />
                </SplideSlide>
                <SplideSlide>
                  <LazyLoad>
                    <img src={VehiclecareB2} alt="" />
                  </LazyLoad>
                </SplideSlide>
                <SplideSlide>
                  <LazyLoad>
                    <img src={VehiclecareB3} alt="" />
                  </LazyLoad>
                </SplideSlide>
                <SplideSlide>
                  <LazyLoad>
                    <img src={VehiclecareB4} alt="" />
                  </LazyLoad>
                </SplideSlide>
                <SplideSlide>
                  <LazyLoad>
                    <img src={VehiclecareB5} alt="" />
                  </LazyLoad>
                </SplideSlide>
                <SplideSlide>
                  <LazyLoad>
                    <img src={VehiclecareB6} alt="" />
                  </LazyLoad>
                </SplideSlide>
                <SplideSlide>
                  <LazyLoad>
                    <img src={VehiclecareB7} alt="" />
                  </LazyLoad>
                </SplideSlide>
                <SplideSlide>
                  <LazyLoad>
                    <img src={VehiclecareB8} alt="" />
                  </LazyLoad>
                </SplideSlide>
                <SplideSlide>
                  <LazyLoad>
                    <img src={VehiclecareB9} alt="" />
                  </LazyLoad>
                </SplideSlide>
                <SplideSlide>
                  <LazyLoad>
                    <img src={VehiclecareB10} alt="" />
                  </LazyLoad>
                </SplideSlide>
                <SplideSlide>
                  <LazyLoad>
                    <img src={VehiclecareB11} alt="" />
                  </LazyLoad>
                </SplideSlide>
                <SplideSlide>
                  <LazyLoad>
                    <img src={VehiclecareB12} alt="" />
                  </LazyLoad>
                </SplideSlide>
                <SplideSlide>
                  <LazyLoad>
                    <img src={VehiclecareB13} alt="" />
                  </LazyLoad>
                </SplideSlide>
                <SplideSlide>
                  <LazyLoad>
                    <img src={VehiclecareB14} alt="" />
                  </LazyLoad>
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </section>
        {/* End brand Section */}
        {/* Testimonial Section  */}
        <div className="d-none d-md-block">
          <Testimonial
            city_name="Gurgaon"
            agents={[
              {
                review:
                  "The joining process was quick and smooth, and the commission rates are the best I’ve come across. Highly rewarding!",
                review_user_city_name: "Gurgaon",
                review_user_name: "Rajesh Kumar",
                review_user_service_name: "Car Service",
              },
              {
                review:
                  "VehicleCare offers the highest commissions, and the benefits are incredible. Joining was completely hassle-free!",
                review_user_city_name: "Mumbai",
                review_user_name: "Pooja Sharma",
                review_user_service_name: "Car Service",
              },
              {
                review:
                  "Great benefits and outstanding commissions! The joining process was fast and efficient.",
                review_user_city_name: "Bangalore",
                review_user_name: "Amit Verma",
                review_user_service_name: "Car Service",
              },
              {
                review:
                  "Signing up with VehicleCare was simple, and the benefits, along with the commissions, are unmatched.",
                review_user_city_name: "Hyderabad",
                review_user_name: "Neha Gupta",
                review_user_service_name: "Car Service",
              },
              {
                review:
                  "The easiest onboarding experience I’ve had, plus the top commissions and agent benefits. VehicleCare is fantastic!",
                review_user_city_name: "Pune",
                review_user_name: "Vikram Singh",
                review_user_service_name: "Car Service",
              },
            ]}
          />
        </div>
      </Row>
      <Footer />
    </>
  );
};

function mapStateToProps(state) {
  return {
    cityListReducer: state.default.cityListReducer,
  };
}
export default connect(mapStateToProps)(Agent);
