import { load } from "@cashfreepayments/cashfree-js";
import ENV from "../server/environment";

export const initializePayment = async (val, paymentReturnUrl) => {
  let checkoutOptions = {
    paymentSessionId: val,
    returnUrl: `https://vehiclecare.in/${paymentReturnUrl}?myorder={order_id}`,
  };
  if (ENV.IS_TESTING) {
    checkoutOptions.returnUrl = `http://localhost:3000/${paymentReturnUrl}?myorder={order_id}`;
  }
  const cashfree = await load({
    mode: ENV.PAYMENT_MODE,
  });
  cashfree.checkout(checkoutOptions).then(function (result) {
    if (result.error) {
      alert(result.error.message);
    }
    if (result.redirect) {
      console.log("Redirection");
    }
  });
};
