import { preLoginApiRequest } from "../../server";
import { FAQ_LIST } from "../types";

// eslint-disable-next-line import/prefer-default-export
const getFaqList = (params, dispatch) => {
  preLoginApiRequest(params, (responseData) => {
    dispatch({type: FAQ_LIST, payload: responseData.data});
  });
};
export { getFaqList };
