import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

const WhyChoose = (props) => {
    const data = props.vehicleData;
    const { vehicleData, cityData } = props;
  const { selectedCity } = cityData;
  const urlParam = useParams();
  const cgID = urlParam.id;
  const { brand, model, variant } = vehicleData;
  const getCustomHelmetDetails = (cgID) => {
    let returnVal = [];
    if (variant === null || variant === undefined) return returnVal;
    switch (cgID) {
      case "periodic-car-services":
        returnVal = [
          {
            MainTitle: `Scheduled Periodic Service offered for ${brand?.name} ${model?.name} ${variant?.variant} Car in ${ selectedCity }`,
            title1: `Why is periodic car service an absolute requirement for your ${brand?.name} ${model?.name} ${variant?.variant} Car?`,
            description1: <>
            <ul className="list-group-numbered px-3 mt-2">
                <li className="list-group-item">Periodic car services are important for your {brand?.name} {model?.name} {variant?.variant} Car to maintain optimal performance and longevity of your vehicle.</li>
                <li className="list-group-item">Periodic car services help identify and address potential issues that may arise in the future, preventing costly repairs down the road.</li>
                <li className="list-group-item">During periodic service various crucial components of your car like, engine, brake pads, tyre etc are thoroughly inspected.</li>
                <li className="list-group-item">Checking and maintaining lights, brakes and tyres ensures safety of the road for the vehicle, driver and the passengers.</li>
                <li className="list-group-item">Periodic maintenance of the car minimises the risk of unexpected breakdown, thus avoiding unnecessary troubles.</li>
                <li className="list-group-item">Routine servicing of your car may enhance your car's resale value. A properly maintained and well-cared vehicle with a comprehensive service history is more attractive to the potential buys.</li>
            </ul>
            </>,
          },
          {
            title2: `Choose services from our top-rated service packages for your ${brand?.name} ${model?.name} Car in ${ selectedCity }`,
            description2: <>
                <font color="#ed1c24" size="4"><b>Basic Car Service -</b> </font>This package provides all the basic essential car services to keep your {brand?.name} {model?.name} up and running. <br/>

                <font color="#ed1c24" size="4"><b>Standard Car Service -</b> </font>This service package is the most popular among {brand?.name} {model?.name} {data.variant?.variant} Car owners because it gives the benefits of the basic package and also other important additional services. <br/>

                <font color="#ed1c24" size="4"><b>Comprehensive Car Service -</b> </font>This is VehicleCare’s premium signature package as it includes comprehensive inspection of the vehicle in order to ensure optimal performance and safety. Experience the best with VehicleCare always. <br/>
            </> ,
          },
          {
            title3: `What do you get at VehicleCare ${ selectedCity } workshops?`,
            description3: `We get you the ultimate satisfaction of handing over your prized possession, your ${brand?.name} ${model?.name} Car to the team of most skilled professionals who are present at VehicleCare Workshops where we use only top-quality, industry-rated, genuine car parts.`,
          },
          {
            title4: `VehicleCare delivery guarantee on customer happiness and warranty on your ${brand?.name} ${model?.name} Car Service`,
            description4: `We use top-notch cutting edge industry level genuine equipment and employ the best professionals and equip them with high tech automated tools for best inspection of your vehicles.From Car AC gas recharging to automated wheel balancing, Car Spa etc, VehicleCare is here to deliver happiness with care and quality.`,
          },
        ];
        break;
      case "denting-painting":
        returnVal = [
            {
              MainTitle: <>Scheduled Denting- Painting service offered for {brand?.name} {model?.name} {variant?.variant} Car in {selectedCity} <br/>
              With the increasing number of cars on Indian roads and growing traffic problems in cities like {selectedCity}, a dent in a car has become like a daily affair. That is why VehicleCare is the best option for any denting or painting service in {selectedCity} for your {brand?.name} {model?.name} Car.</>
              ,
              title1: <>Why should you never leave your Car with a dent?</>,
              description1: <>
              <ul className="list-group-numbered px-3 mt-2">
                <li className="list-group-item">A car with a dent and scratches losses its resale value, especially for high end cars like {brand?.name} {model?.name}</li>
                <li className="list-group-item">Leaving a dent on car can invite rusting problem which will further damage your car and increase repair cost</li>
                <li className="list-group-item">Repairing dents and painting damaged areas of a car restores its appearance, maintaining its aesthetic and visual appeal.</li>
                <li className="list-group-item">Dents and damages can compromise the structural integrity of a car.</li>
              </ul>
              </>,
            },
            {
              title2: <>Why VehicleCare Denting-Painting Service are better from the rest</>,
              description2: <>VehicleCare offers denting-painting service for all models in all makes of car in {selectedCity}. We use genuine industry level hermetically sealed premium paints with the combination of automatic equipment. We offer:<br/>
              <ul className="list-group-numbered px-3 mt-2">
                <li className="list-group-item">Per Panel/ part based localised denting- painting services</li>
                <li className="list-group-item">Full body denting & painting services</li>
                <li className="list-group-item">Customised denting and painting for cars</li>
                <li className="list-group-item">Fibre and Plastic dent removal</li>
                <li className="list-group-item">100% colour match for showroom like finish</li>
                <li className="list-group-item">Warranty on all paints across every VehicleCare workshop</li>
              </ul>
              </>,
            },
            {
              description3: `VehicleCare's denting and painting service stands out as the best choice for your car due to its commitment to excellence, cutting-edge facilities, and customer-centric approach. While painting your car, we pay meticulous attention to every detail. We utilise state-of-the-art facilities and genuine manufacturer-approved A- grade paints, guaranteeing a flawless finish that matches the original colour of your car.  Quality and durability of paint and service is a promise of VehicleCare every single time on all car denting and painting services in ${ selectedCity }. `,
            },
        ];
        break;
      case "car-ac-services":
        returnVal = [
            {
              title1: <>Choosing VehicleCare Car AC Services for {brand?.name} {model?.name} in {selectedCity} can help maintain good health of your Car AC and therefore increases its life. It will ensure:</>,
              description1: <>
              <ul className="list-group-numbered px-3 mt-2">
                <li className="list-group-item">A comfortable driving experience </li>
                <li className="list-group-item">Reduces concentration of allergens, pollutants and contaminants.</li>
                <li className="list-group-item">Helps preserve the interior components of the car, including the dashboard and upholstery, by preventing damage caused by prolonged exposure to high temperatures and sunlight</li>
                <li className="list-group-item">During colder weather, the car AC system is essential for defogging and demisting windows. This enhances visibility, reducing the risk of accidents caused by obscured windows.</li>
                <li className="list-group-item">Regular maintenance helps extend the life of AC components, reducing the likelihood of major breakdowns.</li>
                <li className="list-group-item">A properly functioning AC system is more energy-efficient, contributing to overall fuel efficiency.</li>
                <li className="list-group-item">Regular checks and maintenance help identify and address refrigerant leaks promptly.</li>
                <li className="list-group-item">Routine checkup and maintenance and timely lubrication reduces the risk of premature compressor failure, therefore extending its life.</li>
              </ul>
              </>,
            },
            {
                title2: `What VehicleCare Car AC Service offers:`,
                description2: <>
                <ul className="list-group-numbered px-3 mt-2">
                    <li className="list-group-item">Comprehensive Car AC service for optimal functioning of your {brand?.name} {model?.name} Car.</li>
                    <li className="list-group-item">At VehicleCare we conduct detailed inspection of refrigerant level, pressure testing,high pressure and low pressure leakage identification, cabin air filter checking.</li>
                    <li className="list-group-item">At VehicleCare, we do  car AC condenser cleaning, car AC cooling coil and evaporator cleaning, AC blower and vent cleaning, car AC filter cleaning with high pressure jet washer and other sophisticated equipment. We use best quality specialised agents and car safe chemical reagents and conduct all cleaning activities based on manufacturer recommendations.</li>
                    <li className="list-group-item">We provide Car AC gas recharge or replacement in which we use an environmentally friendly refrigerant and AC gas charging machine.</li>
                </ul>
                </>,
              },
            {
              title3: <>How do we do it?</>,
              description3: <>We employ the best and most sophisticated and automated industry level equipment to conduct inspection and perform servicing or replacement. Our team of professionals are trained to effectively use state of the art apparatus for all kinds of Car AC repair and replacements. From automatic car AC recharging tools to AC gas leak detection tools and other specialised equipment, VehicleCare uses the best and most advanced machinery to deliver the best Car AC service for your {brand?.name} {model?.name} in {selectedCity}.</>,
            },
            {
                title4: <>Warranty On {brand?.name} {model?.name} Car AC services</>,
                description4: <>With VehicleCare get assured warranty on your AC Car service in all our workshops in {selectedCity}. </>,
            },
        ];
        break;
      case "mechanical-repairs":
        returnVal = [
            {
              description1: <>
              <ul className="list-group-numbered px-3 mt-2">
                <li className="list-group-item">Highly skilled professionals </li>
                <li className="list-group-item">Best prices offered with rewards</li>
                <li className="list-group-item">100% genuine OEM/OES Car Spares</li>
                <li className="list-group-item">State of the art Diagnostic</li>
              </ul>
              </>,
            },
            {
                title2: `Best Mechanical Repairs for ${brand?.name} ${model?.name} Car anywhere in ${selectedCity}`,
                description2: `VehicleCare offers a plethora of car services in all its workshops across ${selectedCity} at the tap of your finger. VehicleCare mechanical services are affordable and convenient. We employ the most skilled mechanics that take care of all your car needs. You can easily schedule a mechanical repair booking online through VehicleCare App or Website. We offer pick-up and drop for all services and assign a VehicleCare representative to assist you throughout your service journey with us. `,
              },
            {
              title3: <>Why does VehicleCare offer the best Mechanical Repair for your {brand?.name} {model?.name} Car?</>,
              description3: <>VehicleCare offers denting-painting service for all models in all makes of car in {selectedCity}. We use genuine industry level hermetically sealed premium paints with the combination of automatic equipment. We offer:<br/>
              <ul className="list-group-numbered px-3 mt-2">
                <li className="list-group-item">{brand?.name} Expertise - VehicleCare specializes in {brand?.name} vehicles, ensuring a deep understanding of the brand's intricacies and providing specialized care for your {brand?.name} {model?.name} Petrol.</li>
                <li className="list-group-item">Skilled Technicians - Certified and skilled technicians at VehicleCare possess expertise in diagnosing and repairing mechanical issues specific to {brand?.name} models, ensuring precision in every repair.</li>
                <li className="list-group-item">Transparent Pricing - VehicleCare is committed to transparent pricing, providing clear and detailed estimates upfront, eliminating surprises in the final billing for your {brand?.name} {model?.name} Petrol repairs.</li>
                <li className="list-group-item">Timely Service - Recognizing the importance of timely repairs, VehicleCare aims to complete mechanical repairs promptly, minimizing inconvenience and downtime for your {brand?.name} {model?.name} Petrol.</li>
                <li className="list-group-item">Warranty Assurance - VehicleCare stands behind the quality of its mechanical repairs, offering warranties on both parts and repairs. This commitment ensures your peace of mind regarding the durability of the repairs for your Vehicle.</li>
                <li className="list-group-item">Genuine Spare parts - VehicleCare only delivers genuine and best industry level spare parts for all its replacement services. Quality of work and quality of product is never compromised at VehicleCare.</li>
                <li className="list-group-item">100% satisfaction - Our commitment is to give our customers 100% satisfaction on every mechanical repair service.</li>
              </ul>
              </>,
            },
        ];
        break;
      case "wheel-care":
        returnVal = [
            {
              title1: <>VehicleCare Tyre Service </>,
              description1: <>
                Wheels are crucial for car health as they directly impact safety, handling, and overall performance of the vehicle. They are the only point of contact on the road, so their good condition becomes extremely crucial for road safety. Properly maintained tires ensure optimal traction, stability, and braking. Regular inspection and adequate tire pressure prevent accidents, enhance fuel efficiency, and contribute to the longevity of the vehicle's suspension and steering components.<br/><br/>

                Regular wheel checkups are vital for a safe drive as they ensure proper tire pressure, alignment, and tread depth. Well-maintained wheels enhance vehicle stability, steering control, and braking efficiency, reducing the risk of accidents. Timely checks prevent issues, promoting a safe driving experience and extending the life of the tires.

              </>,
            },
            {
                title2: `VehicleCare is the ultimate Wheel Health Manager of your Car`,
                description2: <>
                <ul className="list-group-numbered px-3 mt-2">
                    <li className="list-group-item">VehicleCare offers a comprehensive range of wheel services tailored for optimal performance. This includes precise laser assisted wheel balancing to eliminate vibrations, wheel alignment for proper tire positioning, nitrogen inflation, tire rotation to promote even tread wear, and tire replacement using genuine parts for longevity and safety.</li>
                    <li className="list-group-item">We also offer complementary tire fitting for every tire purchased through VehicleCare Workshops.</li>
                    <li className="list-group-item">We offer tyres from leading brands like, MRF, Bridgestone, Apollo etc.</li>
                    <li className="list-group-item">With state-of-the-art equipment, skilled technicians at VehicleCare deliver these services with precision, guaranteeing the health and safety of your vehicle's wheels, alignment, and tires, especially beneficial for high-performance vehicles like the {brand?.name} {model?.name} Petrol.</li>
                    <li className="list-group-item">We employ only cutting edge technology wheel replacement, wheel alignment/balancing, tyre rotation services. </li>
                    <li className="list-group-item">Wheel care services at VehicleCare are tailored to the specific needs of your {brand?.name} {model?.name} Petrol, ensuring that the service meets the highest standards set by {brand?.name}.</li>
                </ul>
                </>,
            },
        ];
        break;
      case "car-cleaning":
        returnVal = [
            {
              title1: <>Car Cleaning Service by VehicleCare in {selectedCity} </>,
              description1: <>
                Car cleaning services are crucial for maintaining your car's appearance, preserving its resale value, and preventing corrosion. Regular cleaning removes dirt, contaminants, and corrosive elements, protecting the paint and ensuring a well-kept, presentable vehicle. It also contributes to a healthier and more pleasant driving experience.<br/><br/>

                Interior car care is a crucial aspect for a comfortable and healthy driving experience.Regular cleaning and maintenance prevent the buildup of dirt, allergens, and odors. It preserves the interior's aesthetic appeal, enhances air quality, and contributes to an enjoyable driving experience for both you and your passengers.<br/><br/>

                VehicleCare's car cleaning service stands out for its meticulous attention to detail and use of high-quality, eco-friendly products. Specializing in luxury vehicles like the {brand?.name} {model?.name}, the service goes beyond surface cleaning, addressing intricate interior components.<br/><br/>

                The use of advanced equipment and techniques guarantees a showroom finish. Whether it's exterior detailing, interior care, or specialized cleaning for luxury cars, VehicleCare's service combines expertise, precision, and eco-conscious practices for an unparalleled car cleaning experience.
              </>,
            },
            {
                title2: `What does VehicleCare offer in its Car Cleaning service in ${selectedCity}?`,
                description2: `VehicleCare comprehensive Car cleaning service is like a Spa for your Car. Whether you drive a Maruti, Hyundai or a luxury car like ${brand?.name}, we offer the finest car cleaning service in ${selectedCity} for your vehicle. `,
            },
            {
                title3: `Services included:`,
                description3: <>
                <ul className="list-group-numbered px-3 mt-2">
                    <li className="list-group-item">Interior and exterior car cleaning</li>
                    <li className="list-group-item">Car washing and waxing</li>
                    <li className="list-group-item">Ceramic and teflon coating</li>
                    <li className="list-group-item">Car polishing and buffing</li>
                    <li className="list-group-item">Anti-rust treatment</li>
                    <li className="list-group-item">Tyre dressing and alloy cleaning</li>
                    <li className="list-group-item">Car paint protection Film</li>
                    <li className="list-group-item">Stain removal and odor elimination</li>
                    <li className="list-group-item">vacuuming</li>
                    <li className="list-group-item">Leather Upholstery Treatment</li>
                    <li className="list-group-item">Specialized cleaning and luxury car detailing</li>
                    <li className="list-group-item">Dry cleaning and interior car detailing</li>
                </ul>
                </>,
            },
        ];
        break;
      case "car-detailing-&-spa":
        returnVal = [
            {
              title1: <>VehicleCare Car Detailing </>,
              description1: <>
                Car detailing is a thorough and meticulous cleaning, restoration, and protection process for both the interior and exterior of a vehicle. It goes beyond a standard car wash, addressing paint imperfections, interior surfaces, and other intricate details. Detailing enhances the vehicle's appearance, maintains its condition, and preserves its value.<br/><br/>

                VehicleCare offers Car detailing and spa service for cars of all makes and models. Get your vehicle detailing and coating hassle-free with VehicleCare workshops in {selectedCity}. We use 100% genuine industry grade and eco-friendly products for all our detailing services.

              </>,
            },
            {
                title2: `Services offered under Car detailing:`,
                description2: <>
                <ul className="list-group-numbered px-3 mt-2">
                    <li className="list-group-item">Teflon Coating</li>
                    <li className="list-group-item">Ceramic Coating</li>
                    <li className="list-group-item">Anti-rust Underbody Coating</li>
                    <li className="list-group-item">Paint Protection Film</li>
                    <li className="list-group-item">Silencer Coating</li>
                    <li className="list-group-item">Waxing/Sealant Application</li>
                    <li className="list-group-item">Seat Leather Treatment</li>
                </ul>
                </>,
            },
            {
                description3: <>VehicleCare prioritizes the use of eco-friendly and safe detailing products to ensure effective and responsible car care. Customers can inquire about the specific products used during the detailing process. All the VehicleCare workshops in {selectedCity} have state of the art equipment and specialized tools to deliver best-in-class detailing service to its customers. <br/><br/>

                VehicleCare specializes in detailing of car brands like {brand?.name} etc. We also give warranty on our detailing services, however, the warranty period varies from service to service.<br/><br/>
                
                VehicleCare's Car Spa typically includes a comprehensive package featuring exterior services like washing, waxing, and wheel cleaning. Interior services cover vacuuming, dashboard cleaning, and window polishing. Additional offerings may include headlight restoration, trim treatment, and optional fragrance application, providing a thorough rejuvenation for both the exterior and interior of your car.
                </>,
            },
            
        ];
        break;
      case "custom-repairs":
        returnVal = [
            {
              title1: <>Experience the Best Custom Repair at VehicleCare</>,
              description1: <>
                VehicleCare Custom repair services are solutions tailored to individual customer needs ranging from bodywork to specialized modifications. VehicleCare conducts detailed car scanning and runs other diagnostics and informs you about the possible repairs that would be required in the future.

                VehicleCare custom repair package includes clutch and suspension works and other important mechanical works that are crucial for your car’s health and safety.
                Under the VehicleCare custom repair work, a detailed mechanical diagnostic is run for your car which is crucial for your car’s health as it identifies underlying issues, preventing potential breakdown and ensuring optimal performance.
              </>,
            },
            {
                title2: `Services included:`,
                description2: <>
                <ul className="list-group-numbered px-3 mt-2">
                    <li className="list-group-item">Engine diagnostic</li>
                    <li className="list-group-item">Brake system diagnostic</li>
                    <li className="list-group-item">Clutch and suspension work</li>
                    <li className="list-group-item">Electrical system Diagnostic</li>
                </ul>
                </>,
            },
            {
                description3: <>VehicleCare's custom repairs stand out for their excellence due to a combination of factors. Skilled technicians with brand-specific expertise ensure precise diagnostics and repairs. The use of genuine parts, cutting-edge equipment, and a commitment to transparency in service and pricing contribute to the high-quality standards. VehicleCare prioritizes customer satisfaction.
                </>,
            },
            
        ];
        break;

      default:
        break;
    }
    return returnVal;
  };
  const faqArr = getCustomHelmetDetails(cgID);
  return (
    <Row>
      <Col>
        <div className="auto-container" id="whychoose">
            <div className="upper-section">
                <div className="row clearfix d-flex justify-content-center">
                    <div className="content-column col-lg-12 col-md-12 col-sm-12 mb-2">
                        <div className="inner-column">
                            <div className="sec-title mb-2">
                            <h2 className="text-left fs-3">Why Choose VehicleCare for {" "}   
                                {cgID
                                    .toLowerCase()
                                    .split("-")
                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(" ")} 
                                    
                                    {" "} in {selectedCity} for your {data.brand?.name} {data.model?.name} {data.variant?.variant} Car
                                </h2>
                            </div>

                            {faqArr?.map((value, index) => (
                                <div eventKey={index}>
                                    <div className="text mt-3 mb-4">{value.MainTitle}</div>

                                    <div className="sec-title mb-2">
                                        <h2 className="text-left fs-5 text-capitalize">{value.title1}</h2>
                                    </div>
                                    <div className="text text-left mb-4">{value.description1}</div>

                                    <div className="sec-title mb-2">
                                        <h2 className="text-left fs-5 text-capitalize">{value.title2}</h2>
                                    </div>
                                    <div className="text text-left mt-2 mb-4">{value.description2}</div>

                                    <div className="sec-title mb-2">
                                        <h2 className="text-left fs-5 text-capitalize">{value.title3}</h2>
                                    </div>
                                    <div className="text text-left mt-2 mb-4">{value.description3}</div>

                                    <div className="sec-title mb-2">
                                        <h2 className="text-left fs-5 text-capitalize">{value.title4}</h2>
                                    </div>
                                    <div className="text text-left mt-2 mb-4">{value.description4}</div>
                                </div>

                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </div>
      </Col>
    </Row>
  );
};


const mapStateToProps = (state) => {
    const stateObj = {};
    stateObj.cartList = state.default.cartListReducer.cartList;
    stateObj.passingData = state.default.vehicleReducer;
    stateObj.servicePackageList = state.default.servicePackagesListReducer;
    stateObj.serviceList = state.default.serviceListReducer;
    stateObj.vehicleData = state.default.vehicleReducer;
    stateObj.userData = state.default.userReducer;
    stateObj.cityData = state.default.cityListReducer;
    return stateObj;
  };
  export default connect(mapStateToProps)(WhyChoose);
  
  
