import React, { useEffect, useState } from "react";
import "./index.scss";
import { Col, Row } from "react-bootstrap";
import { auth } from "../../../../../server/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber, signOut } from "firebase/auth";
import { connect } from "react-redux";
import { UserAction } from "../../../../../store/action";
import { SHOW_HIDE_LOADER } from "../../../../../store/types";

const UserLogin = (props) => {
  const [final, setfinal] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [isCaptchaVerifier, setIsCaptchaVerifier] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [optCode, setOptCode] = useState("");
  const [seconds, setSeconds] = useState(0);
  // const {
  //   final,
  //   mobileNumber,
  //   isCaptchaVerifier,
  //   otpSent,
  //   optCode,
  //   seconds,
  //   setfinal,
  //   setMobileNumber,
  //   setIsCaptchaVerifier,
  //   setOtpSent,
  //   setOptCode,
  //   setSeconds,
  // } = props;
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });
  const generateRecaptcha = () => {
    console.log("function called");
    setIsCaptchaVerifier(true);
    window.recaptchaVerifier = new RecaptchaVerifier(
      "custom-captcha-verifier",
      {
        size: "invisible",
        callback: (response) => {
          console.log(response);
        },
      },
      auth
    );
  };

  const onLoginSubmit = () => {
    props.updateReducer({ type: SHOW_HIDE_LOADER, payload: true });
    const mynumber = `+91${mobileNumber}`;
    if (!isCaptchaVerifier) generateRecaptcha();
    const verify = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, mynumber, verify)
      .then((result) => {
        props.updateReducer({ type: SHOW_HIDE_LOADER, payload: false });
        setfinal(result);
        setOtpSent(true);
        setSeconds(59);
      })
      .catch((err) => {
        alert(err);
        window.location.reload();
      });
  };
  const onCodeSubmit = () => {
    final
      .confirm(optCode)
      .then((result) => {
        signOut(auth);
        setSeconds(0);
        props.userLogin({ task: "userLogin", phone: mobileNumber, app_source: "web" });
        props.updateReducer({ type: SHOW_HIDE_LOADER, payload: true });
      })
      .catch((error) => {
        alert(error);
      });
  };
  return (
    <>
      <Row>
        {!otpSent ? (
          <Col id="phoneNumberContainer" className="px-4 text-center">
            <div className="sec-title mb-3">
              <h2 className="font-weight-bold">
                <font size="4">Enter Mobile Number To Login </font>{" "}
              </h2>
            </div>

            <div className="styled-form login-form">
              <div className="row">
                <div className="form-group col-3">
                  <input type="text" disabled name="" value="+91" className="form-control" placeholder="" aria-describedby="helpId" />
                </div>

                <div className="form-group col-9">
                  <span className="adon-icon">
                    <span className="fa fa-phone"></span>
                  </span>
                  <input type="text" placeholder="Enter Mobile No. *" maxLength={10} value={mobileNumber} className="form-control" onChange={(event) => setMobileNumber(event.target.value)} />
                </div>
              </div>
            </div>

            <button onClick={onLoginSubmit} className="theme-btn btn-style-seven btn-sm py-2 mt-2 mb-4 px-4">
              <span className="txt">Login</span>
            </button>
          </Col>
        ) : (
          <Col id="otpCodeContainer" className="px-4 text-center">
            <div className="sec-title mb-3">
              <h2 className="font-weight-bold">
                <font size="4">Enter OTP Recieved on {mobileNumber} </font>{" "}
              </h2>
            </div>

            <div className="styled-form login-form">
              <div className="form-group">
                <input type="text" className="form-control" maxLength={6} placeholder="Enter OTP Code" value={optCode} onChange={(event) => setOptCode(event.target.value)} />
              </div>
            </div>

            <button disabled={seconds > 0} className="theme-btn bg-transparent btn-sm mx-4 py-2" onClick={onLoginSubmit}>
              {seconds > 0 ? `Resend : ${seconds}` : "Resend OTP"}
            </button>

            <button onClick={onCodeSubmit} className="theme-btn btn-style-seven btn-sm py-2 mt-2 mb-4 px-4">
              <span className="txt">Submit</span>
            </button>
          </Col>
        )}
        <div id="custom-captcha-verifier"></div>
      </Row>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userReducer: state.default.userReducer,
  };
}
const mapDispatchToProps = (dispatch) => ({
  userLogin: (params) => UserAction.userLogin(params, dispatch),
  updateReducer: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);
