import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Insurer } from "../../../../../../constants/ImageConstants";
import { Link } from "react-router-dom";

const SegmentsBannerSection = (props) => {
  const { source } = props;
  return (
    <Row>
      <Col>
        <div className="auto-container">
          <div className="content-boxed">
            <div className="insurer_banner">
              <div className="row clearfix">
                <div className="content-column col-lg-6 col-md-12 col-sm-12 text-left">
                  <h2>Welcome to Vehiclecare Claimstech</h2>
                  {/* <h1 className="title">Welcomt to Vehiclecare Claimstech</div> */}
                  <div className="text">
                    Our services provide solutions for insurance companies to offer customers. We offer convenient and cost-effective services to maintain your car. Our qualified mechanics use the
                    latest tools and equipment to ensure the highest quality service. We use genuine spare parts with an assured warranty.
                  </div>
                  <div className="text">
                    Our services are designed to meet the unique needs of insurance companies and their customers. We offer comprehensive services, including regular maintenance check-ups and
                    emergency repairs.
                  </div>
                  <div className="btns-box">
                    <Link to={`/getintouch?source=${source}`} className="theme-btn btn-style-nine px-4 me-3">
                      <span className="txt">Get in touch</span>
                    </Link>
                  </div>
                </div>

                <div className="image-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <div className="image">
                      <img src="images/insurance/banner.png" alt="" />
                      <img src={Insurer} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SegmentsBannerSection;
