/* eslint-disable no-undef */
import React , { useEffect } from "react";
import "./index.scss";
import { Testimonialbg } from "../../../constants/ImageConstants";
import { ReviewListAction } from "../../../store/action";
import { connect } from "react-redux";
import TestimonialList from "./TestimonialList";
import { Link } from 'react-router-dom';

// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import LazyLoad from 'react-lazy-load';

const Testimonial = (props) => {

  const reviewList = props?.agents;
  console.log("reviewList==>",reviewList)
  const city_name = props.city_name;
 // const [reviewListNew, setReviewList] = useState(reviewList);
  // useEffect(() => {
  
  //     const params = { task: "getReviewList"  , service : service , city_name : city_name, limit:10 };
  //    props.getReviewList(params);
    
  // }, [city_name]);

  return (
    <div className={`testimonial-section-four mb-5 ${reviewList?.length < 1 && "d-none"}`}>
      <div  className={`auto-container`}>
        <div className="row clearfix">

          <div className="image-column col-lg-4 col-md-12 col-sm-12 d-none d-md-block">

            <div className="inner-column">
              <div className="image">
              <LazyLoad>
                <img src={Testimonialbg} alt="Background-img" className="w-100" />
                </LazyLoad>
              </div>
            </div>
          </div>
        
          <div className="blocks-column col-lg-8 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="sec-title">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-12">
                    {/* <div className="title">What do our clients say?</div> */}
                    <h2>Agent Reviews</h2>
                  </div>

                  {/* <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="btns-box pull-right">
                      <a className="theme-btn btn-style-five" href="/getintouch" />
                      <Link className="theme-btn btn-style-nine" to="/reviews"><span className="txt">Read More</span></Link>
                      </div>
                  </div> */}

                </div>
              </div>
            

              <TestimonialList reviewList={reviewList} city_name={city_name}  />

            
            </div>
          </div>
    
        </div>
      </div>
    </div>
  );
};



const mapStateToProps = (state) => {
  const stateobj = {};
  stateobj.reviewList = state.default.reviewListReducer;
  stateobj.city = state.default.cityListReducer;
  return stateobj;
};
const mapDispatchToProps = (dispatch) => ({
  getReviewList: (params) => ReviewListAction.getReviewList(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Testimonial);
