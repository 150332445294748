import { BLOG_LIST } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
    state = {
      blogList: [],
    },
    action = {}
  ) => {
    switch (action.type) {
      case BLOG_LIST:
        return { ...state, blogList: action.payload };
      default:
        return state;
    }
  };

