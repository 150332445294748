import React from "react";
import { Col, Row } from "react-bootstrap";
import "./index.scss";
import {
  FreePickup,
  ThreeMonths,
  FortyCheckup,
  GenuineSpares,
  Doorstep,
  ExpertMechanic,
  Delivery,
} from "../../../../../constants/ImageConstants";

const VcBenifits = () => {
  return (
    <Row>
      <Col>
        <div className="auto-container" id="vc-benifits">
          <div className="d-flex justify-content-center col">
            <div
              className="col-lg-11 col-md-11 col-sm-12"
              style={{ width: "90vw" }}
            >
              <div className="d-none d-md-block ">
                {/* <div className="row clearfix">
									<div className="price-block col-lg-3 col-sm-6">
										<div><h2 className="fw-bold text-dark lineHeight mb-2">VehicleCare Benefits  </h2></div>
										<div className="price-inner card text-center">
											<div className="card border-0">
												<ul className="list-group list-group-flush">
													<li className="list-group-item">
														<img className="mx-auto" src={FreePickup} alt="Free Pickup and Drop" />
														free pick up and drop </li>
													<li className="list-group-item">
														<img className="mx-auto" src={FortyCheckup} alt="Forty Point Checkup" />
														40 point check up</li>
													<li className="list-group-item">
														<img className="mx-auto" src={ThreeMonths} alt="Three Months Warranty" />
														3 months warranty</li>
													<li className="list-group-item">
														<img className="mx-auto" src={GenuineSpares} alt="Genuine Spares" /> 
														genuine spares</li>
													<li className="list-group-item">
														<img className="mx-auto" src={Doorstep} alt="Doorstep Service" /> 
														doorstep service</li>
													<li className="list-group-item">
														<img className="mx-auto" src={ExpertMechanic} alt="Expert Mechanic" />
														expert mechanics</li>
													<li className="list-group-item">
														<img className="mx-auto" src={Delivery} alt="Same Day Delivery" />
														same day delivery</li>
												</ul>
												
											</div>
										</div>
									</div>
									<div className="price-block col-lg-3 col-sm-6">
										<div className="text-center"><h2 className="bg-secondary text-white mb-3 fw-bold"><font size="4"> Local Mechanic</font> </h2></div>
										<div className="price-inner card  text-center">
											
											<div className="card border-0">
												<ul className="list-group list-group-flush">
													<li className="text-center list-group-item"><i className="fa fa-check"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
												</ul>
												
											</div>
										</div>
									</div>
									<div className="price-block col-lg-3 col-sm-6">
										<div className="text-center"><h2 className="bg-secondary text-white mb-3 fw-bold"><font size="4"> Authorized Service Center</font> </h2></div>
										<div className="price-inner card text-center">
											<div className="card border-0">
												<ul className="list-group list-group-flush">
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-check"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-check"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-check"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-check"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
												</ul>
												
											</div>
										</div>
									</div>
									<div className="price-block col-lg-3 col-sm-6">
										<div className="text-center vcBgColor"><h2 className="text-white mb-3 fw-bold"><font size="4"> Vehiclecare </font> </h2></div>
										<div className="price-inner card text-center">
											<div className="card border-0">
												<ul className="list-group list-group-flush">
													<li className="text-center list-group-item"><font color="#ed1c24"><i className="fa fa-check"></i></font></li>
													<li className="text-center list-group-item"><font color="#ed1c24"><i className="fa fa-check"></i></font></li>
													<li className="text-center list-group-item"><font color="#ed1c24"><i className="fa fa-check"></i></font></li>
													<li className="text-center list-group-item"><font color="#ed1c24"><i className="fa fa-check"></i></font></li>
													<li className="text-center list-group-item"><font color="#ed1c24"><i className="fa fa-check"></i></font></li>
													<li className="text-center list-group-item"><font color="#ed1c24"><i className="fa fa-check"></i></font></li>
													<li className="text-center list-group-item"><font color="#ed1c24"><i className="fa fa-check"></i></font></li>
												</ul>
												
											</div>
										</div>
									</div>
								</div> */}

                <div className="row">
                  <table className="table table-bordered table-responsive benefitsTableFontSize table-hover">
                    <thead className="thead-dark border-light">
                      <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                        <th scope="col">
                          <h3 className="fw-bold text-dark lineHeight mb-2">
                            Prime Benefits
                          </h3>
                        </th>
                        <th scope="col">
                          <h2 className="text-white mb-3 fw-bold"></h2>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      <tr>
                        <th scope="row">
                          <img
                            className=" benefitsIcon"
                            src={FreePickup}
                            alt=""
                          />
                          2 times complete car Washing & Cleaning.
                        </th>

                        <td className="text-center">
                          <font color="#ed1c24">
                            <i className="fa fa-check"></i>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <img
                            className=" benefitsIcon"
                            src={FortyCheckup}
                            alt=""
                          />
                          20% discount on AC Service.
                        </th>

                        <td className="text-center">
                          <font color="#ed1c24">
                            <i className="fa fa-check"></i>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <img
                            className=" benefitsIcon"
                            src={ThreeMonths}
                            alt=""
                          />
                          20% discount on Periodic Service.
                        </th>

                        <td className="text-center">
                          <font color="#ed1c24">
                            <i className="fa fa-check"></i>
                          </font>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">
                          <img
                            className=" benefitsIcon"
                            src={GenuineSpares}
                            alt=""
                          />
                          20% discount on Denting & Painting
                        </th>

                        <td className="text-center">
                          <font color="#ed1c24">
                            <i className="fa fa-check"></i>
                          </font>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">
                          <img
                            className=" benefitsIcon"
                            src={Doorstep}
                            alt=""
                          />
                          20% discount on Clutch & Light Services.
                        </th>

                        <td className="text-center">
                          <font color="#ed1c24">
                            <i className="fa fa-check"></i>
                          </font>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">
                          <img
                            className=" benefitsIcon"
                            src={ExpertMechanic}
                            alt=""
                          />
                          20% discount on PPF, Polish & Coating
                        </th>

                        <td className="text-center">
                          <font color="#ed1c24">
                            <i className="fa fa-check"></i>
                          </font>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row clearfix d-md-none">
                <table className="table table-bordered table-responsive benefitsTableFontSize">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">
                        <h6 className="mb-2 fw-bold">Benefits</h6>
                      </th>
                      <th scope="col">
                        <h6 className="text-white mb-1 fw-bold p-2"></h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        Car Wash
                      </th>

                      <td>
                        <font color="#ed1c24">
                          <i className="fa fa-check"></i>
                        </font>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Brake Pad Cleaning
                      </th>

                      <td>
                        <font color="#ed1c24">
                          <i className="fa fa-check"></i>
                        </font>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Wheel Alignment
                      </th>

                      <td>
                        <font color="#ed1c24">
                          <i className="fa fa-check"></i>
                        </font>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">
                        Throttle Body Cleaning
                      </th>

                      <td>
                        <font color="#ed1c24">
                          <i className="fa fa-check"></i>
                        </font>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">
                        Road Side Assistance
                      </th>

                      <td>
                        <font color="#ed1c24">
                          <i className="fa fa-check"></i>
                        </font>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Door Lubrication
                      </th>

                      <td>
                        <font color="#ed1c24">
                          <i className="fa fa-check"></i>
                        </font>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default VcBenifits;
