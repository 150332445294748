import React from "react";
import { Row,Col } from "react-bootstrap";
import "./index.scss";

import ListGroup from 'react-bootstrap/ListGroup';

const CarServicePriceList = (props) => {

  let getYear = () => {
    let currentYear = new Date().getFullYear();
    return currentYear;
  };
  
  return (
    <>
      <Row>
      
        {/* price list */}
        
        <div className="auto-container">
          <Row className="justify-content-center">
            <Col className="col-11 mb-md-4 mb-sm-0">
              <div className="sec-title centered">
                <h2><font color="#ed1c24">Car Service</font> Price List in India {getYear()} </h2>
              </div>
              <div className="row">
                <Col>
                  <ListGroup >
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start bg-light"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Car Service List</div>
                      </div>
                      <span>
                        Price Starts From (₹)
                      </span>
                    </ListGroup.Item>

                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">1. General Service  </div>
                      </div>
                      <span>
                        Rs. 2199/-
                      </span>
                    </ListGroup.Item>

                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">2. Car Inspection</div>
                      </div>
                      <span>
                        Rs. 499/- 
                      </span>
                    </ListGroup.Item>

                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">3. RSA( Towing service )   </div>
                      </div>
                      <span>
                        Rs.  999/-
                      </span>
                    </ListGroup.Item>

                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">4. RSA( Jump start )  </div>
                      </div>
                      <span>
                        Rs. 499/-
                      </span>
                    </ListGroup.Item>

                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">5. Denting & painting</div>
                      </div>
                      <span>
                        Rs.  2000/- 
                      </span>
                    </ListGroup.Item>

                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">6. Full Body Denting & Painting </div>
                      </div>
                      <span>
                        Rs.  20000/- 
                      </span>
                    </ListGroup.Item>

                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">7. Dry Denting </div>
                      </div>
                      <span>
                        Rs.  300/- 
                      </span>
                    </ListGroup.Item>

                  </ListGroup>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
        
      </Row>

    </>
  );
};


export default CarServicePriceList;
