import React from "react";
import "./index.scss";

const FaqList = ({ faqList, handleToggle, toggle }) => {
  const active = { backgroundColor: "black", color: "white" };
  const inactive = {};
  return (
    <>
      {faqList?.map((value, index) => {
        return (
          <div className="accordion block mb-1" id="acc_faq" key={index}>
            <div
              className="acc-btn"
              onClick={() => handleToggle(index)}
            >
              <h5 style={{fontSize: '16px'}}>{value.title}{" "}</h5>
              <div className="icon-outer">
                <b>{index === toggle ? "-" : "+"} </b>{" "}
              </div>{" "}
            </div>
            {index === toggle ? (
              <div className="content px-2 py-0">
                <div className="accordian-text pt-0" style={{fontSize: '14px'}}> {value.summary} </div>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </>
  );
};


export default FaqList;