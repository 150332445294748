import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Appbg, Pattern1, Pattern2 } from "../../../../constants/ImageConstants";

import BannerSection from "./BannerSection";
import CustomisablePlatform from "./CustomisablePlatform";
import DigitalBenifit from "./DigitalBenifit";
import FaqQuery from "../../../../components/common/faqQuery";
import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import OurApp from "../../../../components/common/ourApp";

import BlogMultiImageCarousel from "../../../../components/common/blogMultiImageCarouselMobile";
import { Helmet } from "react-helmet";

const Solutions = () => {
  return (
    <>

      <Helmet>
        <title>Digital solution for the auto repair and claims industry -  VehicleCare Tech</title>
        <meta
          name="description"
          content="Vehiclecare builds an end-to-end solution for Vehicle Repair and Insurance claims. We provide a customizable platform to fit your repair management needs."
        />
      </Helmet>


      <Header isStatic={true} />

      <Row>
        <Col>
          {/* Banner Section */}
          <section className="banner-section-three style-two">
            <div className="main-slider-carousel">
              <div className="slide">
                <BannerSection />
              </div>
            </div>
          </section>

          {/* Video Section */}
          <div className="about-section pt-5" style={{ background: "#f5f5f580" }}>
            <CustomisablePlatform />
          </div>

          <div className="about-section pt-5">
            <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
            <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>
            <DigitalBenifit />
          </div>

          {/* Faq Section  */}
          <FaqQuery />

          {/* Blog Section */}
          <div className="blog-section">
            <BlogMultiImageCarousel />
          </div>

          {/* Our App */}
          <div
            className="testimonial-section-one bg-size pb-5"
            style={{
              backgroundImage: `url(${Appbg})`,
              backgroundSize: `cover`,
              backgroundColor: `#f5f1ee`,
            }}
          >
            <OurApp />
          </div>

        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default Solutions;
