import { SERVICE_PACKAGES_LIST, SERVICE_PACKAGES_EMPTY } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    servicePackageList: [],
  },
  action = {}
) => {
  switch (action.type) {
    case SERVICE_PACKAGES_LIST:
      return { ...state, servicePackageList: action.payload };
    case SERVICE_PACKAGES_EMPTY:
      return { ...state, servicePackageList: [] };
    default:
      return state;
  }
};
