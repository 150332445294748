import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./index.scss";
import { connect } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  getNavigateUrl,
  toTitleCase,
  getUrlSearchParams,
} from "../../../../../utils/customHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import { OrderAction, PaymentAction } from "../../../../../store/action";
import {
  coupanguruBanner1,
  Successful,
  coupanguruBanner2,
  coupanguruBanner3,
  coupanguruBanner4,
} from "../../../../../constants/ImageConstants";
import VehicleSelector from "../../../../../components/common/vehicleSelector";
import { SERVICE_QUOTE, SHOW_HIDE_LOADER } from "../../../../../store/types";
import CustomModal from "../../../../../components/common/CustomModal";
import { auth, generateRecaptcha } from "../../../../../server/firebase";
import { signInWithPhoneNumber, signOut } from "firebase/auth";
import { checkValidMobile } from "../../../../../utils/validations";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import moment from "moment";
import { initializePayment } from "../../../../../utils/paymentHelper";
import axios from "axios";
import ENV from "../../../../../server/environment";

const Banner = (props) => {
  const urlParam = useParams();
  const numberInput = useRef(null);
  const [data, setData] = useState({
    userPhone: "",
    userCity: "",
    insuranceCompany: "",
    userName: "",
    userPAN: "",
    userEmail: "",
  });
  const [insCompanies, setInsCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [optVerified, setOptVerified] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    if (optVerified) {
      navigate(getNavigateUrl("/"));
    }
    setShow(false);
  };
  const handleClick = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    axios
      .get(
        ENV.WAVE_BASE_URL + "/api/v1/partners/requests/insurance/company/list"
      )
      .then(
        (res) =>
          res?.data?.status === "success" && setInsCompanies(res.data?.data)
      )
      .catch(() => {});
  }, []);

  const GetModalContent = () => (
    <Row>
      <div className="col-12">
        <div className="row h-100">
          <div className="text-center">
            <img src={Successful} alt="Congratulations" className="w-25" />
          </div>

          <div className="text-center my-auto">
            <p className="fw-bold mb-0 fs-4">
              <font color="black">Congratulations!!</font>
            </p>
            <p className="fw-bolder mb-0 fs-5" style={{ color: "#40c057" }}>
              Your Enquiry is successfully Registered.
            </p>
            <p className="text-dark fst-normal mb-0">
              Our Executive will contact you soon.
            </p>
            <button
              type="button"
              className="theme-btn btn-style-nine my-3 mx-5 px-4 rounded-pill"
              onClick={() => getServicePage()}
            >
              <span className="txt">Back To Home</span>
            </button>
          </div>
        </div>
      </div>
    </Row>
  );
  // call service page
  const getServicePage = () => {
    navigate("/");
    return () => {
      clearTimeout(window.interval);
    };
  };

  const submitForm = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const body = {
      name: data.userName,
      mobile: data.userPhone,
      email: data.userEmail,
      company_id: data.insuranceCompany,
      city: data.userCity,
      pan: data.userPAN,
    };
    axios
      .post(ENV.WAVE_BASE_URL + "/api/v1/partners/requests", body)
      .then((res) => {
        if (res?.data?.status == "success") {
          setShow(true);
        } else {
          alert(res?.data?.message);
        }
        setIsLoading(false);
      })
      .catch(() => {
        alert(e?.response?.data?.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Row>
        <Col>
          <div className="auto-container pb-2">
            <div className="content-boxed">
              <div className="">
                <div className="row clearfix">
                  <div
                    className="image-column col-lg-7 col-md-12 col-sm-12 mb-3 d-md-none"
                    id="homeBannerSplide"
                  >
                    <div className="inner-column">
                      <div className="image">
                        <Splide
                          options={{
                            type: "loop",
                            gap: "1rem",
                            autoplay: true,
                            pauseOnHover: false,
                            resetProgress: false,
                            arrows: false,
                            pagination: false,
                          }}
                          hasTrack={true}
                        >
                          <SplideSlide>
                            <img
                              src={require("../../../../../assets/images/agent-banner.png")}
                              alt="Upto 10% off car service"
                            />
                          </SplideSlide>
                        </Splide>
                      </div>
                    </div>
                  </div>

                  <div
                    id="landingForm"
                    className="content-column appointment-form banner-form col-lg-5 col-md-12 col-sm-12 text-left px-4"
                  >
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={submitForm}
                    >
                      <div className="px-1 mb-3">
                        <font color="black" className="heading-font d-inline">
                          Complete the
                        </font>{" "}
                        <h1 className="heading-font d-inline">
                          {" "}
                          <font color="#ed1c24">Agent Form </font> to Get
                          Started
                        </h1>
                      </div>

                      <div className="d-md-none">
                        <div className="row clearfix px-2">
                          <div
                            className={`form-group col-lg-12 col-md-12 col-sm-12 border mt-1 ${
                              urlParam.city
                                ? "col-lg-6 col-md-6"
                                : "col-lg-12 col-md-12"
                            }`}
                          >
                            <input
                              type="text"
                              name="userName"
                              maxLength={10}
                              pattern="[6-9]{1}[0-9]{9}"
                              value={data.userName}
                              aria-describedby="inputGroupPrepend"
                              className="custom-select-box border-0 px-0"
                              placeholder="Enter Your Name"
                              onChange={(e) => handleClick(e)}
                              ref={numberInput}
                              required
                            />
                          </div>
                          <div
                            className={`form-group col-lg-12 col-md-12 col-sm-12 border mt-1 ${
                              urlParam.city
                                ? "col-lg-6 col-md-6"
                                : "col-lg-12 col-md-12"
                            }`}
                          >
                            <input
                              type="email"
                              name="userEmail"
                              pattern="[6-9]{1}[0-9]{9}"
                              value={data.userEmail}
                              aria-describedby="inputGroupPrepend"
                              className="custom-select-box border-0 px-0"
                              placeholder="Enter Your Email"
                              onChange={(e) => handleClick(e)}
                              ref={numberInput}
                              required
                            />
                          </div>
                          <div
                            className={`form-group col-lg-12 col-md-12 col-sm-12 border mt-1 ${
                              urlParam.city
                                ? "col-lg-6 col-md-6"
                                : "col-lg-12 col-md-12"
                            }`}
                          >
                            <input
                              type="text"
                              name="userCity"
                              value={data.userCity}
                              aria-describedby="inputGroupPrepend"
                              className="custom-select-box border-0 px-0"
                              placeholder="Enter Your City"
                              onChange={(e) => handleClick(e)}
                              ref={numberInput}
                              required
                            />
                          </div>
                          <div
                            className={`form-group col-lg-12 col-md-12 col-sm-12 border mt-1 ${
                              urlParam.city
                                ? "col-lg-6 col-md-6"
                                : "col-lg-12 col-md-12"
                            }`}
                          >
                            <input
                              type="text"
                              name="userPhone"
                              maxLength={10}
                              pattern="[6-9]{1}[0-9]{9}"
                              value={data.userPhone}
                              aria-describedby="inputGroupPrepend"
                              className="custom-select-box border-0 px-0"
                              placeholder="Enter Your Phone Number"
                              onChange={(e) => handleClick(e)}
                              ref={numberInput}
                              required
                            />
                          </div>

                          <div className="form-group col-lg-12 col-md-12 col-sm-12 border mt-1 col-lg-12 col-md-12">
                            <input
                              type="text"
                              name="userPAN"
                              maxLength={10}
                              value={data.userPAN}
                              aria-describedby="inputGroupPrepend"
                              className="custom-select-box border-0 px-0 userCoupon"
                              placeholder="Enter Your PAN"
                              onChange={(e) => handleClick(e)}
                              ref={numberInput}
                              required
                            />
                          </div>

                          <div className="form-group col-lg-12 col-md-12 col-sm-12 border mt-1 col-lg-12 col-md-12">
                            {/* <input
                              type="text"
                              name="insuranceCompany"
                              pattern="^[A-Z0-9\W]+$"
                              value={data.insuranceCompany}
                              aria-describedby="inputGroupPrepend"
                              className="custom-select-box border-0 px-0 userCoupon"
                              placeholder="Insurance Company"
                              onChange={(e) => handleClick(e)}
                              ref={numberInput}
                              required
                            /> */}
                            <select
                              name="insuranceCompany"
                              onChange={(e) => handleClick(e)}
                              className="form-control border-0 px-0 select-company"
                            >
                              <option value={""}>{"Select Company"}</option>
                              {insCompanies?.map((item) => (
                                <option
                                  selected={data.insuranceCompany == item?.id}
                                  key={item?.id}
                                  id={item?.id}
                                  value={item?.id}
                                >
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3">
                            <button
                              className="theme-btn btn-style-nine getQuote"
                              type="submit"
                              name="submit-form"
                              disabled={
                                isLoading ||
                                data.userPAN.length != 10 ||
                                data.userName.length < 3 ||
                                data.userCity.length < 3 ||
                                data.userEmail.length == 0 ||
                                data.userPhone.length != 10
                              }
                            >
                              <span className="txt">
                                {isLoading ? "Please wait..." : "Submit"}
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>

                  <div className="image-column col-lg-7 col-md-12 col-sm-12 mb-0 d-none d-md-block">
                    <div className="inner-column">
                      <div className="image">
                        <Splide
                          options={{
                            type: "",
                            gap: "1rem",
                            autoplay: true,
                            pauseOnHover: false,
                            resetProgress: false,
                            arrows: false,
                            pagination: false,
                          }}
                          hasTrack={true}
                        >
                          <SplideSlide>
                            <img
                              src={require("../../../../../assets/images/agent-banner.png")}
                              alt="Upto 10% off car service"
                            />
                          </SplideSlide>
                        </Splide>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div id="custom-captcha-verifier"></div>
      <CustomModal
        show={show}
        modalSize={optVerified ? "md" : "sm"}
        content={<GetModalContent />}
        isCloseButton={false}
        handleClose={handleClose}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityList = state.default.cityListReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  stateObj.paymentSessionData = state.default.paymentReducer;
  stateObj.paymentData = state.default.paymentReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) =>
    OrderAction.createServiceQuote(params, dispatch),
  createPaymentSession: (params) =>
    PaymentAction.createPaymentSession(params, dispatch),
  getPaymentStatus: (params) =>
    PaymentAction.getPaymentStatus(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
