import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Appbg, Pattern1, Pattern2, Faqbg } from "../../../../constants/ImageConstants";

import OurApp from "../../../../components/common/ourApp";

import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import Testimonial from "../../../../components/common/Testimonial";
import BlogMultiImageCarousel from "../../../../components/common/blogMultiImageCarousel";
import ServicesWeOffer from "../Landing/ServicesWeOffer";
import VcBenifits from "../Landing/VcBenifits";
import Banner from "./Banner";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Gallery from "./Gallery";
import CarWashPriceList from "./CarWashPriceList";
import OilProduct from "../Lubricants/OilProduct";

const CarWashNearMe = (props) => {
  const selectedCityName = props.cityListReducer.selectedCity;

  const faqList = [
    {
      id: 1,
      title: "What services are included in the car Wash?",
      summary: "It includes washing, external detailing, vacuuming and deep cleaning, waxing, internal detailing, polishing, and many more.",
    },
    {
      id: 2,
      title: "Do you provide a car wash facility at home?",
      summary: "Yes, we do provide a car wash facility at home. You just have to call or whatsApp us and our skilled mechanics will reach you within 15 minutes.",
    },
    {
      id: 3,
      title: "Can I book my appointment thorugh mobile APP ?",
      summary: "Yes, you can book your appointment through a Mobile App. You can also book via email.",
    },
    {
      id: 4,
      title: "What are the various modes of Payment accepted here?",
      summary: "You, can make payments via Cash, GooglePay, or Paytm.",
    },
    {
      id: 5,
      title: "How long does it take to wash our car?",
      summary: "There is a time when you could not drive your car for 1-2 hours during a car wash. But in some cases it can be done before 1 hour also.",
    },
    {
      id: 6,
      title: "Does VehicleCare provide pickup and drop service?",
      summary: "Yes, we provide free Pickup and Drop service at your doorstep so you don’t have to worry about about that.",
    },
    {
      id: 7,
      title: "How can i get my car vacuuming and cleaning done?",
      summary: "You can visit our website www.VehicleCare.in fill in the details that are mentioned above. You can also book your service by Calling or WhatsApp us.",
    },
    {
      id: 8,
      title: "How many mechanic are there in vehiclecare for car wash?",
      summary: "There are plenty of Mechanics in VehicleCare and our expert mechanics have more than ten years of experience.",
    },
  ];

  const [toggle, setToggle] = useState(0);
  let handleToggle = (id) => {
    if (toggle === id) {
      setToggle(null);
      return false;
    }
    setToggle(id);
  };
  const active = { backgroundColor: "black", color: "white" };
  const inactive = {};

  return (
    <>
      <Helmet>
        <title>Book Top-Notch Car Wash Near Me, Repairs,And Accidental Claims</title>
        <meta name="description" content="Can You Search Car Wash Near Me - We Provide Very Affordable Car Wash In All Over India, Free Pick up And Drop" data-react-helmet="true" />
        <meta property="og:title" content="Book Top-Notch Car Wash Near Me, Repairs,And Accidental Claims" />
        <meta property="og:description" content="Can You Search Car Wash Near Me - We Provide Very Affordable Car Wash In All Over India, Free Pick up And Drop" />
        <meta property="og:url" content={`https://vehiclecare.in/car-workshop-near-me`} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="VehicleCare" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg" />
        <meta property="twitter:title" content="Book Top-Notch Car Wash Near Me, Repairs,And Accidental Claims" />
        <meta property="twitter:description" content="Can You Search Car Wash Near Me - We Provide Very Affordable Car Wash In All Over India, Free Pick up And Drop" />
        <meta property="twitter:url" content={`https://vehiclecare.in/car-workshop-near-me`} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@vehiclecare1" />
        <meta property="twitter:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg" />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner />
            </div>
          </div>
        </section>

        <div className="services-section-four pt-0 pb-lg-5 pb-sm-4">
          <Row>
            <Col>
              <div className="auto-container">
                <div className="sec-title centered">
                  <h2>Services We Offer</h2>
                  <div className="text col-8 mx-auto d-none d-md-block">
                  Find the best service packages for your car. Select from a wide range of car services, from general service to
                   car wash, 
                  accidental repair to custom repair, cashless insurance claims, and much more.
                  </div>
                </div>

                <ServicesWeOffer selectedCityName={selectedCityName} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="blog-section pt-0">
          <CarWashPriceList />
        </div>

        <div className="price-section" style={{ background: "rgb(251, 251, 251)" }}>
          <VcBenifits />
        </div>

        {/*  Gallery Section */}
        <section className="gallery-section pb-0" id="car-wash-gallery">
          <Gallery />
        </section>
        {/* <!-- End Gallery Section --> */}

        <hr />

        <section className="about-section pt-5">
          <OilProduct />
        </section>

        {/*Car Workshop Near Me? */}
        <section className="about-section pt-5 pb-5" style={{ backgroundColor: "rgb(251, 251, 251)" }}>
          <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
          <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>

          <div className="auto-container">
            <div className="upper-section">
              <div className="row clearfix d-flex justify-content-center">
                <div className="content-column col-lg-12 col-md-12 col-sm-12 mb-2">
                  <div className="inner-column">
                    <div className="sec-title mb-3">
                      <h2 className="text-center">
                        Looking For Nearby<font color="#ed1c24"> Car Wash</font>?
                      </h2>
                      <div className="text text-left mb-0">
                        Discover the convenience of a professional car wash at your fingertips with Vehicle Care, your go-to online multi-brand 
                        <a href="https://vehiclecare.in/car-service-near-me" target="_blank" rel="noreferrer"><font color="#ed1c24"> car repair services </font></a> platform. Say goodbye to the hassle
                        of searching endlessly for the "best car wash near me" or “automatic car was near me” – we bring the car wash experience right to your doorstep.
                      </div>
                      <div className="text text-left mt-2 mb-0">
                        Your search for the perfect "car wash near me" ends here. Book your premium car wash now and experience the next level of vehicle care!
                      </div>
                    </div>

                    <div className="detail">
                      <div className="d-flex justify-content-center col inner-box">
                        <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                          <div className="sec-title mb-4">
                            <h2 className="text-left fs-4">Why VehicleCare is the Ultimate Destination for Premium Quality Car Wash?</h2>
                          </div>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                1. Convenience Redefined:{" "}
                              </font>
                            </b>
                            Enjoy the luxury of a car wash without leaving your home or office. With VehicleCare, you can schedule a premium car wash service at your doorstep. Our expert technicians
                            are equipped with state-of-the-art tools and high-quality cleaning products to give your vehicle the care it deserves.
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                2. Quality Guaranteed:{" "}
                              </font>
                            </b>
                            At VehicleCare, we prioritize the quality of service that we offer. Our skilled professionals are trained to handle different brands and models of cars and ensure that your
                            car receives the best treatment possible. Whether it's a sedan, SUV, or a luxury car, trust us to provide the ultimate car wash services that leave your vehicle gleaming.
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                3. Multi-Brand Expertise:{" "}
                              </font>
                            </b>
                            VehicleCare takes pride in being a multi-brand 
                            <a href="https://vehiclecare.in/car-service-near-me" target="_blank" rel="noreferrer"><font color="#ed1c24"> car repair services </font></a>
                            platform. Our technicians are well-versed in the nuances of different car brands, ensuring that your
                            vehicle is in safe hands. From routine washes and specialized treatments to automatic washes, we've got the expertise to cater to your specific car care needs.
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                4. Eco-Friendly Solutions:{" "}
                              </font>
                            </b>
                            We care about the environment just as much as we care about your car. VehicleCare uses and employs eco-friendly cleaning products and practices, ensuring that your car
                            looks like it has just left the showroom and we do it without harming the planet. Choose us for the most awesome car wash experience.
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                5. Transparent Pricing:{" "}
                              </font>
                            </b>
                            Now, no more surprises or hidden charges when it comes to pricing. VehicleCare believes in transparency, and our pricing reflects that. As a VehicleCare customer, you know
                            exactly what you're paying for and experience the peace of mind that comes with fair and competitive rates for a premium car wash experience.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section  */}
        <div className={`acordian-section  ${faqList?.length < 1 && "d-none"}`} style={{ backgroundImage: `url(${Faqbg})`, backgroundSize: "cover" }}>
          <Col className={`auto-container`}>
            <div className="row clearfix">
              <div className="accordian-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    {/* <div className="title">Contact Support</div> */}
                    <h2>Frequently Asked Questions</h2>
                  </div>
                  <ul className="accordion-box">
                    {faqList?.map((value, index) => {
                      return (
                        <div className="accordion block mb-1" id="acc_faq" key={index}>
                          <div className="acc-btn" onClick={() => handleToggle(index)} style={index === toggle ? active : inactive}>
                            <h2>{value.title}{" "}</h2>
                            <div className="icon-outer">
                              <b className="icon">{index === toggle ? "-" : "+"} </b>{" "}
                            </div>{" "}
                          </div>
                          {index === toggle ? (
                            <div className="content px-2 py-0">
                              <div className="accordian-text"> {value.summary} </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="title-column col-lg-5 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title">Contact Support</div>
                    <h2>Have Any Questions?</h2>
                  </div>
                  <div className="inquiry-box">
                    <div className="box-inner">
                      <div className="upper-box">
                        <div className="icon flaticon-telephone"></div>
                        <div className="inquiry-title">For Any Inquiries Call Now!</div>
                        <a href="tel:9911-020-209" className="phone">
                          9911-020-209
                        </a>
                      </div>
                      <div className="lower-box">
                        <a className="emailed" href="mailto: support@vehiclecare.in?subject=Need Help">
                          support@vehiclecare.in
                        </a>
                      </div>
                    </div>
                    <Link to={"/getintouch"} className="get-tech">
                      Get You Tech Now!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
        {/* FAQ Section  */}

        {/* Testimonial Section  */}
        <div className="d-none d-md-block">
          <Testimonial city_name="Gurgaon" />
        </div>

        {/* Blog Section */}
        <div className="blog-section">
          {/* <IBlog /> */}
          <BlogMultiImageCarousel />
        </div>

        {/* Our App */}
        <div
          className="testimonial-section-one bg-size pb-5"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
          }}
        >
          <OurApp />
        </div>

      </Row>
      <Footer />
    </>
  );
};

function mapStateToProps(state) {
  return {
    cityListReducer: state.default.cityListReducer,
  };
}
export default connect(mapStateToProps)(CarWashNearMe);
