import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import About from "./About";
import WeProvide from "./WeProvide";
import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <>

      <Helmet>
        <title>About VehicleCare</title>
        <meta
          name="description"
          content="We provide a one-stop solution for all car repair and maintenance services in India. With more than 650 workshops in India and a dedicated support system, our customers car repair and insurance claim journeys are hassle-free."
        />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        <Col>
          {/* About */}
          <section className="price-section aboutus mt-5">
            <About />
          </section>
          
          {/* We Provide */}
          <section className="quality-section pt-0">
            <WeProvide />
          </section>

          
        </Col>
      </Row>
      
      <Footer />
    </>
  );
};

export default AboutUs;
