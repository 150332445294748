import React from "react";
import { Col, Row } from "react-bootstrap";
import "./index.scss";
import { ACRepair, AMC,  Battery, CarService, CarRepairI, DentPaint, InsuranceClaim, PeriodicService, WheelCare, InsuranceCompany, BusinessFleet } from "../../../../../constants/ImageConstants";
import { Link } from "react-router-dom";
import { CityListAction, VehicleAction } from "../../../../../store/action";
import { connect } from "react-redux";


const ServicesWeOffer = (props) => {

  const selectedCityName = props.cityListReducer.selectedCity;

  return (
    
          <div className="d-flex align-items-center justify-content-center">
            <div className="col-lg-10 col-md-11 col-sm-12">
              <div className="row justify-content-center">
                <div className="service-block-four pe-lg-0 pe-sm-2 mb-0 col-lg-2 col-md-6 col-6">
                  <div className="inner-box text-center">
                    <div className="">
                    <Link className="text-dark" to={`/${selectedCityName.replace(/ /g, "-").toLowerCase()}/services/periodic-car-services/`}>
                        <img className="w-75 mx-auto" src={CarService} alt="Car Service" />
                      </Link>
                    </div>
                    <div className="lower-content pt-0">
                      <h5 className="fs-6">
                      <Link className="text-dark" to={`/${selectedCityName.replace(/ /g, "-").toLowerCase()}/services/periodic-car-services/`}>
                          {" "}
                          Car Service{" "}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="service-block-four pe-lg-0 pe-sm-2 mb-0 col-lg-2 col-md-6 col-6">
                  <div className="inner-box text-center">
                    <div className="">
                    <Link className="text-dark" to={`/${selectedCityName.replace(/ /g, "-").toLowerCase()}/services/wheel-care/`}>
                        <img className="w-75 mx-auto" src={WheelCare} alt="Wheel Care" />
                      </Link>
                    </div>
                    <div className="lower-content pt-0">
                      <h5 className="fs-6">
                      <Link className="text-dark" to={`/${selectedCityName.replace(/ /g, "-").toLowerCase()}/services/wheel-care/`}>
                          {" "}
                          Wheel Care
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="service-block-four pe-lg-0 pe-sm-2 mb-0 col-lg-2 col-md-6 col-6">
                  <div className="inner-box text-center">
                    <div className="">
                    <Link className="text-dark" to={`/${selectedCityName.replace(/ /g, "-").toLowerCase()}/services/car-ac-services/`}>
                        <img className="w-75 mx-auto" src={ACRepair} alt="Car AC Repair" />
                      </Link>
                    </div>
                    <div className="lower-content pt-0 px-0">
                      <h5 className="fs-6">
                      <Link className="text-dark" to={`/${selectedCityName.replace(/ /g, "-").toLowerCase()}/services/car-ac-services/`}>
                          {" "}
                          Car AC Repair{" "}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="service-block-four pe-lg-0 pe-sm-2 mb-0 col-lg-2 col-md-6 col-6">
                  <div className="inner-box text-center">
                    <div className="">
                    <Link className="text-dark" to={`/${selectedCityName.replace(/ /g, "-").toLowerCase()}/services/periodic-car-services/`}>
                        <img className="w-75 mx-auto" src={Battery} alt="Car Battery" />
                      </Link>
                    </div>
                    <div className="lower-content pt-0">
                      <h5 className="fs-6">
                      <Link className="text-dark" to={`/${selectedCityName.replace(/ /g, "-").toLowerCase()}/services/periodic-car-services/`}>
                          {" "}
                          Car Battery{" "}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="service-block-four pe-lg-0 pe-sm-2 mb-0 col-lg-2 col-md-6 col-6">
                  <div className="inner-box text-center">
                    <div className="">
                    <Link className="text-dark" to={`/${selectedCityName.replace(/ /g, "-").toLowerCase()}/services/denting-painting/`}>
                        <img className="w-75 mx-auto" src={DentPaint} alt="Denting & Painting" />
                      </Link>
                    </div>
                    <div className="lower-content pt-0">
                      <h5 className="fs-6">
                      <Link className="text-dark" to={`/${selectedCityName.replace(/ /g, "-").toLowerCase()}/services/denting-painting/`}>
                          {" "}
                          Dent Paint{" "}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="service-block-four pe-lg-0 pe-sm-2 mb-0 col-lg-2 col-md-6 col-6">
                  <div className="inner-box text-center">
                    <div className="">
                    <Link className="text-dark" to={`/${selectedCityName.replace(/ /g, "-").toLowerCase()}/services/custom-repairs/`}>
                        <img className="w-75 mx-auto" src={CarRepairI} alt="Custom Repair" />
                      </Link>
                    </div>
                    <div className="lower-content pt-0 px-0">
                      <h5 className="fs-6">
                      <Link className="text-dark" to={`/${selectedCityName.replace(/ /g, "-").toLowerCase()}/services/custom-repairs/`}>
                          {" "}
                          Custom Repair{" "}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>

                {/* <div className="service-block-four pe-lg-0 pe-sm-2 mb-0 col-lg-2 col-md-6 col-6">
                  <div className="inner-box text-center">
                    <div className="">
                    <Link className="text-dark" to={`/${selectedCityName.replace(/ /g, "-").toLowerCase()}/services/periodic-car-services/`}>
                        <img className="w-75 mx-auto" src={PeriodicService} alt="Periodic Service" />
                      </Link>
                    </div>
                    <div className="lower-content pt-0 px-0">
                      <h5 className="fs-6">
                      <Link className="text-dark" to={`/${selectedCityName.replace(/ /g, "-").toLowerCase()}/services/periodic-car-services/`}>
                          {" "}
                          Periodic Service
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div> */}

                <div className="service-block-four pe-lg-0 pe-sm-2 mb-0 col-lg-2 col-md-6 col-6">
                  <div className="inner-box text-center">
                    <div className="">
                      <Link className="text-dark" to="/car-insurance-claim">
                        <img className="w-75 mx-auto" src={InsuranceClaim} alt="Car Insurance Claim" />
                      </Link>
                    </div>
                    <div className="lower-content pt-0 px-0">
                      <h5 className="fs-6">
                        <Link className="text-dark" to="/car-insurance-claim">
                          {" "}
                          Insurance Claim{" "}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="service-block-four pe-lg-0 pe-sm-2 mb-0 col-lg-2 col-md-6 col-6">
                  <div className="inner-box text-center">
                    <div className="">
                      <Link className="text-dark" to="/amc">
                        <img className="w-75 mx-auto" src={AMC} alt="AMC" />
                      </Link>
                    </div>
                    <div className="lower-content pt-0">
                      <h5 className="fs-6">
                        <Link className="text-dark" to="/amc">
                          {" "}
                          AMC{" "}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="service-block-four pe-lg-0 pe-sm-2 mb-0 col-lg-2 col-md-6 col-6">
                  <div className="inner-box text-center">
                    <div className="">
                      <Link className="text-dark" to="/warranty">
                        <img className="w-75 mx-auto" src={InsuranceCompany} alt="Warranty" />
                      </Link>
                    </div>
                    <div className="lower-content pt-0 px-0">
                      <h5 className="fs-6">
                        <Link className="text-dark" to="/warranty">
                          {" "}
                          Warranty{" "}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="service-block-four pe-lg-0 pe-sm-2 mb-0 col-lg-2 col-md-6 col-6">
                  <div className="inner-box text-center">
                    <div className="">
                      <Link className="text-dark" to="/ev">
                        <img className="w-75 mx-auto" src={BusinessFleet} alt="EV" />
                      </Link>
                    </div>
                    <div className="lower-content pt-0">
                      <h5 className="fs-6">
                        <Link className="text-dark" to="/ev">
                          {" "}
                          EV{" "}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>

                
              </div>
            </div>
          </div>
        
  );
};

function mapStateToProps(state) {
  return {
    vehicleReducer: state.default.vehicleReducer,
    cityListReducer: state.default.cityListReducer,
    userReducer: state.default.userReducer,
  };
}
const mapDispatchToProps = (dispatch) => ({
  getBrandNames: (params) => VehicleAction.getBrandNames(params, dispatch),
  getCityList: (params) => CityListAction.getCityList(params, dispatch),
  updateReducer: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(ServicesWeOffer);