import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import './index.scss';

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text.slice(0, 120) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "... read more" : " show less"}
        </span>
      </p>
    );
  };

const DigitalBenifit = () => {
    return (
        <Row>
            <Col>
            <div className="auto-container">
                <div className="sec-title centered">
                    <h2><font color="#ed1c24">VehicleCare </font> At a Glance </h2>
                </div>
                <div className="fact-counter">
                    <div className="row clearfix">
                        <div className="column col-lg-3 col-md-6 col-sm-12">
                            <div className="inner wow fadeInLeft center" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="content">
                                    <div className="count-outer count-box">
                                        <span className="count-text" data-speed="3000" data-stop="88">200</span>+
                                    </div>
                                    <h4 className="counter-title">Cities</h4>
                                    {/* <div className="text">
                                        <ReadMore>
                                            VehicleCare has a strong presence in over 30 cities across India, including popular cities like Delhi, Mumbai, Chennai, and Jaipur. We aim to make car maintenance a hassle-free and convenient experience for car owners across India.
                                        </ReadMore>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div className="column col-lg-3 col-md-6 col-sm-12">
                            <div className="inner wow fadeInLeft center" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="content">
                                    <div className="count-outer count-box alternate">
                                        <span className="count-text" data-speed="5000" data-stop="1500">1000</span>+
                                    </div>
                                    <h4 className="counter-title">Workshops</h4>
                                    {/* <div className="text">
                                        <ReadMore>
                                            We have a wide network of workshops, manufacturers, suppliers, corporates, and insurers in PAN India. Our partnerships enable us to offer top-notch services and genuine spare parts to our customers, ensuring that their vehicles are always in the best condition.
                                        </ReadMore>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div className="column col-lg-3 col-md-6 col-sm-12">
                            <div className="inner wow fadeInLeft center" data-wow-delay="900ms" data-wow-duration="1500ms">
                                <div className="content">
                                    <div className="count-outer count-box">
                                        <span className="count-text" data-speed="3500" data-stop="180">100</span>+
                                    </div>
                                    <h4 className="counter-title">Partners</h4>
                                    {/* <div className="text">
                                        <ReadMore>
                                            Partnered with 100+ B2B and insurance companies, vehicleCare leads the market, offering comprehensive car service solutions. Our end-to-end services cater to business partners, extending our market reach through a diverse range of insurance collaborations.
                                        </ReadMore>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div className="column col-lg-3 col-md-6 col-sm-12">
                            <div className="inner wow fadeInLeft center" data-wow-delay="600ms" data-wow-duration="1500ms">
                                <div className="content">
                                    <div className="count-outer count-box">
                                        <span className="count-text " data-speed="2000" data-stop="838">1.5 Lac</span>
                                    </div>
                                    <h4 className="counter-title">Vehicles Served</h4>
                                    {/* <div className="text">
                                        <ReadMore>
                                            We have served a wide range of vehicles across different industries, including passenger cars, commercial vehicles, and fleets. Our goal is to offer cost-effective and efficient solutions for car maintenance to all our customers, regardless of the type of vehicle they own.
                                        </ReadMore>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            </Col>
        </Row>
  );
};

export default DigitalBenifit;
