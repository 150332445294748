import React from "react";
import "./index.scss";
import { Vc_Car_Repair } from "../../../../../constants/ImageConstants";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazy-load';

const VcRepairs = () => {
  return (
    <>
      <div className="auto-container">
        <div className="row clearfix">
          <div className="sec-title text-center">
            {/* <h1 className="title">Look At Our Quality Features</div> */}
            <h2>
              <font color="#ed1c24">VehicleCare</font> for Repairs 
            </h2>
          </div>

          <div className="blocks-column col-lg-6 col-md-12 col-sm-12 pt-3">
            <div className="">

              <div className="row clearfix">
              <LazyLoad>
                <img src={Vc_Car_Repair} alt="VehicleCare Car Repair" fluid />
                </LazyLoad>
              </div>
            </div>
          </div>

          <div className="title-column col-lg-6 col-md-12 col-sm-12">
            <div className="inner-column">
              
              <div className="text mb-4">
                <p>
                Welcome to VehicleCare for Repairers. We provide a platform for auto repair shops and technicians to connect with customers and expand their business. Our solution streamlines the entire process from damage assessment to repair. This makes it easier for repairers to manage their workflow and offer quick and efficient service to customers.
                </p>
                <p>
                By joining our network, repairers gain access to a wider customer base. They also benefit from our advanced management system that helps them eliminate redundancies and improve their bottom line. With VehicleCare, repairers can focus on providing top-notch auto repair services while we handle the rest.
                </p>
              </div>

              <div className="btns-box">
                <Link to={"/getintouch"} className="theme-btn btn-style-nine">
                  <span className="txt">Join Our Network</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VcRepairs;
