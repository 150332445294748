import React from "react";
import VehicleSelector from "../../../../components/common/vehicleSelectorMobile";
import Table from 'react-bootstrap/Table';
import { Trash3 } from "react-bootstrap-icons";

const MyGarage = (props) => {
  const { profileData, updateUserVehicle } = props;
  const vehicleList = profileData?.vehicles;
  const openCarSelector = () => {
    document.getElementById("custom-vehicle-selector-brand").click();
  };
  const addNewCar = (vehicleDetails) => {
    updateUserVehicle({
      task: "addUserVehicles",
      brand_id: vehicleDetails.brand.id,
      model_id: vehicleDetails.model.id,
      variant_id: vehicleDetails.variant.id,
    });
  };
  const removeVehicle = (id) => {
    updateUserVehicle({
      task: "removeUserVehicles",
      id: id,
    });
  };
  const makeDefaultVehicle = (id) => {
    updateUserVehicle({
      task: "setUserVehiclesPreffered",
      id: id,
    });
  };
  return (
    <div className="card mb-4 border-0">
      {/* <Button className="rounded-0" onClick={() => openCarSelector()}>
        Add New Car
      </Button> */}

      <button class="theme-btn btn-style-three rounded-2" onClick={() => openCarSelector()}><span class="txt">Add New Car</span></button>

      <VehicleSelector hideBar={true} navigFun={addNewCar} />
      <div className="card-body">
        {vehicleList?.map((item, index) => (
          <div className="row h-100" key={index}>

            {/* <div className="col-sm-3">
              <img src={item.icon} alt={item.model_name} className="w-50" />
            </div>

            <div className="col-sm-4 my-auto">

              {item.is_prefered === "1" && <h6 className="text-success">Default</h6>}
              <p className="text-muted fw-bold">
                {item.brand_name} {item.model_name} {item.variant_name}
              </p>
            </div>

            <div className="col-sm-5 my-auto">
              {item.is_prefered === "0" && (
                <>
                  <Button onClick={() => removeVehicle(item.id)} className="pull-right btn-danger">
                    Remove
                  </Button>
                  <Button onClick={() => makeDefaultVehicle(item.id)} className="pull-right btn-success mx-3">
                    Make Default
                  </Button>

                  <button class="theme-btn btn-style-three pull-right btn-sm" onClick={() => removeVehicle(item.id)} ><span class="txt">Remove</span></button>

                  <button class="theme-btn btn-style-nine pull-right btn-sm mx-2" onClick={() => makeDefaultVehicle(item.id)}><span class="txt">Make Default</span></button>
                </>
              )}
              
            </div> */}

          

          <Table  responsive mb-0 variant="light">
            <tbody>
              <tr className="my-auto">

                <td className="col-1 align-middle text-center">
                  <img src={item.icon} alt={item.model_name} style={{width: '40px', height: '30px'}} />
                </td>

                <td className="col-1 align-middle text-center">
                  {item.is_prefered === "1" && <h6 className="text-success fw-bold" style={{fontSize: '14px'}}>Default</h6>}
                  {/* <p className="text-muted fw-bold"> */}
                    {item.model_name} {item.variant_name}
                  {/* </p> */}
                </td>

                <td className="col-2 text-center">
                  {item.is_prefered === "0" && (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                      <Trash3 onClick={() => removeVehicle(item.id)} style={{ marginBottom: '10px'}} color="red" />
                      <button style={{ fontSize:'12px', width: '120px'}} class="theme-btn btn-style-three pull-right btn-sm px-2 py-1  rounded-2" onClick={() => makeDefaultVehicle(item.id)}><span class="txt">Make Default</span></button>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>

        </div>

        ))}


        
      </div>
    </div>
  );
};
// updateUserVehicle
export default MyGarage;
