import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Appbg, Pattern1, Pattern2, Faqbg } from "../../../../constants/ImageConstants";

import OurApp from "../../../../components/common/ourApp";

import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import Testimonial from "../../../../components/common/Testimonial";
import BlogMultiImageCarousel from "../../../../components/common/blogMultiImageCarouselMobile";
import ServicesWeOffer from "../Landing/ServicesWeOffer";
import VcBenifits from "../Landing/VcBenifits";
import WhyChooseVc from "../Landing/WhyChooseVc";
import ServiceSteps from "../Landing/ServiceSteps";
import Banner from "./Banner";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CarServicePriceList from "./CarServicePriceList";

const CarServiceNearMe = (props) => {
  const selectedCityName = props.cityListReducer.selectedCity;
  const faqList = [
    {
      id: 1,
      title: "What all do you check during inspection of our car?",
      summary:
        "We first start with a document check and test drive and then use the latest tools to assess the exterior, engine, tire, transmission, electrical, and interior. We also inspect the suspension, steering mechanism, brakes, and other accessories of your car during the test drive.",
    },
    {
      id: 2,
      title: "Will you charge for our car inspection?",
      summary: "Yes, the charges for our car inspection is Rs 399.",
    },
    {
      id: 3,
      title: "Do VehicleCare provide home inspection for car?",
      summary: "We provide Home inspection for you your car, you just have to call or WhtsApp us and our mechanic will reach you within 15 minutes.",
    },
    {
      id: 4,
      title: "How long will it take to repair my car?",
      summary: "There is time when you could not see your car for 5-6 hours. But in some cases the repair could take anywhere from 5-6 hours to a week or more.",
    },
    {
      id: 5,
      title: "Can i book my appointment thorugh mobile APP ?",
      summary: "Yes you can book your appointment through Mobile App. You can also book via email.",
    },
    {
      id: 6,
      title: "How many mechanic are there in vehiclecare ?",
      summary: "There are plenty of Mechanics in VehicleCare and our expert mechanics have more than ten years of experience.",
    },
    {
      id: 7,
      title: "What all kind of services do VehicleCare provide?",
      summary: "There's a wide range of car services which are available.We offer Car service, Wheel Care, Car AC Repair, Custom Repair and much more.",
    },
    {
      id: 7,
      title: "what sort of spare parts and engine oils are used in the servicing?",
      summary: "We use only genuine spares sourced from OEM/OES across all our workshops. Further, all products used in car services are authentic and top-rated.",
    },
  ];

  const [toggle, setToggle] = useState(0);
  let handleToggle = (id) => {
    if (toggle === id) {
      setToggle(null);
      return false;
    }
    setToggle(id);
  };
  const active = { backgroundColor: "black", color: "white" };
  const inactive = {};

  return (
    <>
      <Helmet>
        <title>Can You Search For A Car Service Near Me - Vehiclecare</title>
        <meta name="description" content="Can You Search for a Car Service Near Me We Are Provide Lowest Price Car Repair, Enjoy Hassle-Free Service With Complimentary Pickup And Drop"
        data-react-helmet="true" />

        <meta property="og:title" content="Can You Search For A Car Service Near Me - Vehiclecare" />
        <meta property="og:description" content="Can You Search for a Car Service Near Me We Are Provide Lowest Price Car Repair, Enjoy Hassle-Free Service With Complimentary Pickup And Drop" />
        <meta property="og:url" content={`https://vehiclecare.in/car-mechanic-near-me`} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="VehicleCare" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg" />
        <meta property="twitter:title" content="Can You Search For A Car Service Near Me - Vehiclecare" />
        <meta property="twitter:description" content="Can You Search for a Car Service Near Me We Are Provide Lowest Price Car Repair, Enjoy Hassle-Free Service With Complimentary Pickup And Drop" />
        <meta property="twitter:url" content={`https://vehiclecare.in/car-mechanic-near-me`} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@vehiclecare1" />
        <meta property="twitter:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg" />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner />
            </div>
          </div>
        </section>

        <div className="services-section-four pt-0 pb-lg-5 pb-sm-4">
          <Row>
            <Col>
              <div className="auto-container">
                <div className="sec-title centered">
                  <h2>Services We Offer</h2>
                  <div className="text col-8 mx-auto d-none d-md-block">
                  Find the best service packages for your car. Select from a wide range of car services, from general service to
                  <a href="https://vehiclecare.in/car-wash-near-me" target="_blank" rel="noreferrer"><font color="#ed1c24">  car wash, </font></a>
                  accidental repair to custom repair, cashless insurance claims, and much more.
                  </div>
                </div>

                <ServicesWeOffer selectedCityName={selectedCityName} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="services-section-four pt-0">
          <ServiceSteps />
        </div>

        {/* price list */}
        <div className="blog-section pt-0">
          <CarServicePriceList />
        </div>

        <div className="price-section " style={{ background: "rgb(251, 251, 251)" }}>
          <VcBenifits />
        </div>

        <div className="services-section-four">
          <div className="auto-container">
            <div className="sec-title centered">
              <h2>Why Choose VehicleCare </h2>
            </div>
          </div>
          <WhyChooseVc />
        </div>

        {/*Car Workshop Near Me? */}
        <section className="about-section pt-5 pb-5" style={{ backgroundColor: "rgb(251, 251, 251)" }}>
          <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
          <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>

          <div className="auto-container">
            <div className="upper-section">
              <div className="row clearfix d-flex justify-content-center px-2">
                <div className="content-column col-lg-12 col-md-12 col-sm-12 mb-2">
                  <div className="inner-column">
                    <div className="sec-title mb-4">
                      <h2 className="text-center">
                        Looking For Nearby <font color="#ed1c24">Car Services?</font>
                      </h2>
                      <div className="text text-left mb-0">
                        Welcome to VehicleCare where experience meets excellence. VehicleCare is your gateway for the best car service near you. Bid farewell to the tiresome hunt for "car service near
                        me" or "car service shop near me." VehicleCare revolutionizes the way you maintain your vehicle by delivering high-quality and trustworthy car maintenance directly to you.
                      </div>

                      <div className="text text-left mt-2 mb-0">
                        {" "}
                        No more scrolling through endless options or compromising on quality. Our commitment is to bring professional car services to your doorstep. Say hello to a new era of
                        hassle-free and reliable car maintenance with VehicleCare – where your satisfaction and the well-being of your vehicle take center stage.
                      </div>
                    </div>

                    <div className="detail">
                      <div className="justify-content-center col inner-box">
                        <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                          <div className="sec-title mb-4 mt-4">
                            <h2 className="text-left fs-4 text-capitalize">Why choose VehicleCare for all your car servicing needs?</h2>
                          </div>
                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                1. Certified Technicians for the Best Service:
                              </font>
                            </b>
                            <span> VehicleCare takes pride in its team of certified technicians, ensuring the best-in-class service for your vehicle.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                2. Convenient Online Booking on your fingertips:
                              </font>
                            </b>
                            <span>
                              {" "}
                              Experience the ease of scheduling car services with our user-friendly online platform and app. No more searching for the "best car service near me”, VehicleCare brings
                              the expertise to you.
                            </span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                3. Transparent Pricing:
                              </font>
                            </b>
                            <span> Say goodbye to hidden costs. VehicleCare believes in clear and transparent pricing, ensuring you get the best service without surprises.</span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                4. Multi-Brand Expertise for All Vehicles:
                              </font>
                            </b>
                            <span>
                              {" "}
                              Your vehicle is in capable hands with VehicleCare. Our skilled technicians specialize in multi-brand car services, catering to the unique needs of different car models.
                            </span>
                          </p>

                          <p>
                            <b>
                              <font color="#ed1c24" size="3">
                                5.Time-Based Delivery for Your Convenience:
                              </font>
                            </b>
                            <span>
                              {" "}
                              We value your time. With VehicleCare, enjoy the convenience of time-based delivery. No more searching for the "best car service shop near me" – our technicians will
                              arrive promptly at your given loaction, so you can get back on the road sooner.
                            </span>
                          </p>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                          <div className="sec-title mb-4 mt-4">
                            <h2 className="text-left fs-4 text-capitalize">How to Book Your Best Car Service with VehicleCare:</h2>
                          </div>

                          <p>
                            <font color="#ed1c24" size="3">
                              <b>1. Visit Our Website or download VehicleCare App:</b>{" "}
                            </font>{" "}
                            Navigate to our website from the comfort of your home or office.
                          </p>

                          <p>
                            <font color="#ed1c24" size="3">
                              <b>2. Enter basic details & Choose Your Service: </b>{" "}
                            </font>{" "}
                            Provide essential details about your car to help us tailor our services to your vehicle's requirements. Select the specific car service your vehicle needs, whether it's
                            routine maintenance, diagnostics, or specialized repairs or even insurance claims.
                          </p>

                          <p>
                            <font color="#ed1c24" size="3">
                              <b>3. Pick a Convenient Time and location:</b>{" "}
                            </font>{" "}
                            Select a time slot that fits your schedule and our skilled technicians will be at your doorstep.
                          </p>

                          <p>
                            <font color="#ed1c24" size="3">
                              <b>4. Relax and Leave the Rest to Us:</b>{" "}
                            </font>{" "}
                            Once booked, our team will take care of the rest. Experience hassle-free and reliable car services with VehicleCare. Experience peace of mind.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section  */}
        <div className={`acordian-section  ${faqList?.length < 1 && "d-none"}`} style={{ backgroundImage: `url(${Faqbg})`, backgroundSize: "cover" }}>
          <Col className={`auto-container`}>
            <div className="row clearfix">
              <div className="accordian-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    {/* <div className="title">Contact Support</div> */}
                    <h2>Frequently Asked Questions</h2>
                  </div>
                  <ul className="accordion-box">
                    {faqList?.map((value, index) => {
                      return (
                        <div className="accordion block mb-1" id="acc_faq" key={index}>
                          <div className="acc-btn" onClick={() => handleToggle(index)} style={index === toggle ? active : inactive}>
                            <h2>{value.title}{" "}</h2>
                            <div className="icon-outer">
                              <b className="icon">{index === toggle ? "-" : "+"} </b>{" "}
                            </div>{" "}
                          </div>
                          {index === toggle ? (
                            <div className="content px-2 py-0">
                              <div className="accordian-text"> {value.summary} </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="title-column col-lg-5 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <h2 className="title">Contact Support</h2>
                    <h2>Have Any Questions?</h2>
                  </div>
                  <div className="inquiry-box">
                    <div className="box-inner">
                      <div className="upper-box">
                        <div className="icon flaticon-telephone"></div>
                        <div className="inquiry-title">For Any Inquiries Call Now!</div>
                        <a href="tel:9911-020-209" className="phone">
                          9911-020-209
                        </a>
                      </div>
                      <div className="lower-box">
                        <a className="emailed" href="mailto: support@vehiclecare.in?subject=Need Help">
                          support@vehiclecare.in
                        </a>
                      </div>
                    </div>
                    <Link to={"/getintouch"} className="get-tech">
                      Get You Tech Now!
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
        {/* FAQ Section  */}

        {/* Testimonial Section  */}
        <div className="d-none d-md-block">
          <Testimonial city_name="Gurgaon" />
        </div>

        {/* Blog Section */}
        <div className="blog-section pb-0">
          {/* <IBlog /> */}
          <BlogMultiImageCarousel />
        </div>

        {/* Our App */}
        <div
          className="testimonial-section-one bg-size pb-5"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
          }}
        >
          <OurApp />
        </div>


      </Row>
      <Footer />
    </>
  );
};

function mapStateToProps(state) {
  return {
    cityListReducer: state.default.cityListReducer,
  };
}
export default connect(mapStateToProps)(CarServiceNearMe);
