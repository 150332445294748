import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import { WhoWeAreBg, Linkdin, Twitter, Facebook, InstagramIcon } from "../../../../constants/ImageConstants";
import CompanyInfo from "./CompanyInfo";
import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us - VehicleCare A One-Stop Solution for All Car Care Services</title>
        <meta name="description" content="Contact us to get more details about our products and offerings." />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        <section className="price-section mt-5">
          <div className="auto-container">
            <ContactForm />

            <div className="row clearfix">
              <div className="inner-column">
                <div className="map-boxed">
                  <div className="map-outer">
                    <iframe
                      title="storeLocater"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28057.884186320436!2d77.02186831562497!3d28.472452900000015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d194cf2e747e3%3A0x1480a5967aa1277c!2sVehicleCare!5e0!3m2!1sen!2sin!4v1675062673746!5m2!1sen!2sin"
                      width="100%"
                      height="450"
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="acordian-section Overlay" style={{ backgroundImage: `url(${WhoWeAreBg})`, backgroundSize: "cover" }}>
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-12">
                <div className="sec-title mb-3">
                  <h2 style={{ color: "#ed1c24" }}>Who We Are:</h2>
                </div>
                <div className="text">
                  we can help. our team of experts is on hand to answer your question.by using our website, you can watch many services in all over India, you can easily contact us. sorry we missed
                  you. we are currently open all over India 24/7 of all time. any problem any time, we are ready for your help, so pls contact us
                </div>

                <h4 className="text-dark mt-3 mb-2">
                  <b>Follow Us On:</b>
                </h4>
                <div className="social-icon-box">
                  <a target="_blank" href="https://www.facebook.com/vehiclecare.in" rel="noreferrer">
                    <img src={Facebook} alt="Linkdin" />
                  </a>
                  <a target="_blank" href="https://www.linkedin.com/company/13583565/admin/" rel="noreferrer">
                    <img src={Linkdin} alt=" Linkdin" />
                  </a>
                  <a target="_blank" href="https://twitter.com/vehiclecare1" rel="noreferrer">
                    <img src={Twitter} alt="Twitter" />
                  </a>
                  <a target="_blank" href="https://www.instagram.com/vehiclecare.in/?hl=en" rel="noreferrer">
                    <img src={InstagramIcon} alt="Instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Company Info Section  */}
        <section className="contact-info-section">
          <CompanyInfo />
        </section>
      </Row>
      <Footer />
    </>
  );
};

export default ContactUs;
