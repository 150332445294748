import React, { useState } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { FAQ_CITY_ID, SET_CITY_NAME } from "../../../store/types";
import "./index.scss";
import { useNavigate, useParams } from "react-router-dom";

const ChooseCity = (props) => {
  const navigate = useNavigate();
  const urlParam = useParams();
  let updatedCityList = props.cityList;
  const [searchedCity, setSearchedCity] = useState("");
  const isCloseButton = props.isCloseButton;
  const handleChange = (e) => {
    setSearchedCity(e.target.value);
  };
  updatedCityList = updatedCityList.filter((elem) => elem.name.toUpperCase().includes(searchedCity.toUpperCase()));
  const updateCityList = (data) => {
    window.sessionStorage.setItem("selectedCity", JSON.stringify(data));
    props.updateCityReducer({ type: FAQ_CITY_ID, payload: data.id });
    props.updateCityReducer({ type: SET_CITY_NAME, payload: data.name });
    props.handleClose();
    const basePath = window.location.pathname.replace(`${urlParam.city.replace(/ /g, "-")}`, "");
    navigate(`/${data.name.replace(/ /g, "-").toLowerCase()}${basePath}`.replace(/([^:]\/)\/+/g, "$1"));
    window.location.reload()
  };
  return (
    <Modal show={props.show} onHide={props.handleClose} keyboard={isCloseButton} backdrop="static" centered size="lg">
      <Modal.Header closeButton={isCloseButton} style={{ borderBottom: "none" }}>
        <Modal.Title>Choose Your City</Modal.Title>
        <div className="text-center mx-3">
          <input type="search" placeholder="Search City..." value={searchedCity} className="form-control borderBtm" onChange={(e) => handleChange(e)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row style={{ height: "70vh", overflowY: "auto" }}>
          {!updatedCityList.length ? (
            <Col>
              <h3 className="text-center text-warning mt-5">Kindly wait while fetching the list ...</h3>
            </Col>
          ) : (
            updatedCityList.map((city) => {
              return (
                <Col key={city.id} xs={6} lg={2} md={4} className="text-center mb-3" onClick={() => updateCityList(city)}>
                  <img
                    src={city.icon}
                    className="cityImg"
                    alt="NoImage"
                    style={{ borderRadius: "100%", width: "40px", height: "40px", cursor: "pointer", overflow: "clip", padding: "none", objectFit: "contain" }}
                  />
                  <br />
                  <p className="elementName">{city.name}</p>
                </Col>
              );
            })
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

function mapStateToProps(state) {
  return state.default.cityListReducer;
}
const mapDispatchToProps = (dispatch) => ({
  updateCityReducer: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChooseCity);
