import { insuranceCreateLeadRequest } from "../../server";
import { PROPOSAL_ID } from "../types";

const getInsuranceLead = (params, dispatch) => {
  insuranceCreateLeadRequest(params, (responseData) => {
    dispatch({ type: PROPOSAL_ID, payload: responseData.data.Insurance });
  });
};

export { getInsuranceLead };
