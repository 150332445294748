import React from "react";
import { connect } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { RecentBlogAction } from "../../../store/action";
import { useEffect } from "react";
import moment from "moment/moment";
import LazyLoad from 'react-lazy-load';
import { colors } from "@mui/material";

const UncontrolledExample = ({ blogList, getBlogList }) => {
  moment.locale("en");
  const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

  useEffect(() => {
    getBlogList();
    //console.log('blogList',blogList);
  }, [getBlogList]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="auto-container">
      <div className="row clearfix">
        {/* <div className="title-column col-lg-4 col-sm-12 col-sm-12">
          <div className="inner-column image">
            <div className="sec-title">
              <div className="title">Our Blog</div>
              <h2>
                {" "}
                Our Latest <br /> News & Article{" "}
              </h2>
              <p>
                We believe that educating our customers on the best practices of car care is essential to keeping their vehicles in top shape. From common car problems to DIY maintenance tips, we have
                got you covered.
              </p>
              <p>
                Our team of experts regularly updates the section with the latest news in the automotive industry and provides insights into the newest trends and technologies. Stay informed and
                up-to-date on everything related to your car by checking out our Latest News & Articles section regularly.
              </p>
            </div>

            <a href="https://vehiclecare.in/blaze/" target="_blank" className="theme-btn btn-style-nine" rel="noreferrer">
              <span className="txt">View Blogs</span>
            </a>
          </div>
        </div> */}
        <div>
          <h2 className="text-center fw-bold text-dark mb-2" style={{fontSize: '22px'}}>OUR BLOGS</h2>
        </div>

        <div className="carousel-column blocks-column col-lg-8 col-md-12 col-sm-12">
          <div className="inner-column">
            <Carousel
              responsive={responsive}
              infinite="True  beforeChange={nextSlide => this.setState({ nextSlide: nextSlide })}"
              autoPlay={true}
              swipeable="true"
              draggable={true}
              touch={true}
              removeArrowOnDeviceType={["tablet", "mobile"]}
            >
              {blogList.map((blog) => {
                return (
                  <div className="news-block" key={blog.id}>
                    <div className="inner-box">
                      <div className="upper-content">
                        <h5 style={{fontSize: '16px'}}>
                          <a style={{color: colors.common.black}} href={blog["link"]} target="_blank" rel="noreferrer">
                            {renderHTML(blog["title"]["rendered"])}
                          </a>
                        </h5>
                        <ul className="post-meta">
                          <li>
                            <span className="icon flaticon-user"></span>By {blog["_embedded"]["author"][0]["name"]}
                          </li>
                          {/* <li>
                            <span className="icon flaticon-chat-2"></span>3 comments 
                          </li> */}
                        </ul>
                      </div>
                      <div className="image">
                        <a href={blog["link"]} target="_blank" rel="noreferrer">
                        <LazyLoad>
                          <img style={{width: '90%'}} src={blog["_embedded"]["wp:featuredmedia"][0]["source_url"]} alt={blog["title"]["rendered"]} loading=" lazy" />
                          </LazyLoad>
                        </a>
                        <div style={{fontSize: '20px'}} className="post-date text-white">{moment(blog["date"]).format("DD MMM")}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return state.default.recentBlogReducer;
};
const mapDispatchToProps = (dispatch) => ({
  getBlogList: () => RecentBlogAction.getBlogList(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(UncontrolledExample);
