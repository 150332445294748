import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import { Appbg, Settings, Box, SettingsGears, MTCVC, ELV, RVSF, Pattern1, Pattern2 , Payment, Cod,PickupTruck, CustomerService, RecycleSign, File, Website, Demolish, Recovery, AirPollution, Search, BenifitsRecycling, RecycleCar} from "../../../../constants/ImageConstants";

import OurApp from "../../../../components/common/ourApp";

import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import Banner from "./Banner";

import { Helmet } from "react-helmet";

const Mtc = (props) => {
  return (
    <>

      <Helmet>
        <title>Hassle-free car scrapping services - VehicleCare</title>
        <meta
          name="description"
          content="Our dedicated team and Smart Tech services will help your car on its end-of-life journey."
        />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide" style={{background: '#a6fbfe'}}>
              <Banner />
            </div>
          </div>
        </section>

        {/* 3 point details */}
        <div className="services-section-four pb-4">
          <div className="auto-container">
            <div className="d-flex justify-content-center">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <div className="row clearfix">
                  <div className="service-block-four col-lg-4 col-md-4 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                          <img className="w-25 mx-auto pt-4" src={SettingsGears} alt="Expert & Best Vehicle Evaluation" />
                      </div>
                      <div className="lower-content">
                        <h5 className="text-center">
                        <font color="#ed1c24">Expert & Best Vehicle Evaluation </font>
                        </h5>
                        <div className="text">
                          You can expect the best and true value with a smooth transaction.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-block-four col-lg-4 col-md-4 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                          <img className="w-25 mx-auto pt-4" src={Settings} alt="Easy Vehicle Process Mapping" />
                      </div>
                      <div className="lower-content">
                        <h5 className="text-center">
                          <font color="#ed1c24">Easy Vehicle Process Mapping</font> 
                        </h5>
                        <div className="text">
                          Every process is mapped and recorded, and you are well informed about the stages.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="service-block-four col-lg-4 col-md-4 col-sm-12">
                    <div className="inner-box">
                      <div className="image">
                          <img className="w-25 mx-auto pt-4" src={Box} alt="Environment-Friendly" />
                      </div>
                      <div className="lower-content">
                        <h5 className="text-center">
                        <font color="#ed1c24">Environment-Friendly </font>
                        </h5>
                        <div className="text">
                          MTC Recycling has an environment-friendly process under best industry practices.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* MTCVC combine logo */}
        <div className="about-section pt-0">
          <div className="" style={{ background: "#e6f8ff" }}>
            <div className="auto-container">
              <div className="lower-section pb-5 border-top-0">
                <div className="row clearfix">
                  {/* <!-- Title Column --> */}
                  <div className="title-column col-lg-8 col-md-12 col-sm-12 mb-0">
                    <div className="inner-column">
                      <h2 className="text-capitalize">
                      Preserving the Past, Protecting the Future Pioneering : End of Life Vehicle Recycling <span></span>{" "}
                      </h2>
                    </div>
                  </div>
                  {/* <!-- Button Column --> */}
                  <div className="button-column col-lg-4 col-md-12 col-sm-12 mb-0">
                    <div className="inner-column">
                      <img className="w-75 mx-auto" src={MTCVC} alt="Vehicle Recycling" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* elv & rsvf */}
        <div className="services-section-four pt-4 pb-5">
          <div className="auto-container">
            <div className="upper-section">
              <div className="row clearfix mb-5 pb-5">
                <div className="content-column col-lg-6 col-md-12 col-sm-12 my-auto">
                  <div className="inner-column">
                    <div className="sec-title">
                      <h2><font color="#ed1c24">What Is End Of Life Vehicle(ELV)? </font></h2>
                    </div>
                    <div className="text">
                      An end -of-life is a vehicle that has reached the end of its useful life and is no longer roadworthy or safe use on public roads. ELVs can be Include Car, trucks, van and other types of motor vehicles. These vehicles may be damaged. have high mileage or simply have become too old to continue functioning effectively.
                    </div>
                  </div>
                </div>

                <div className="image-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <div className="image text-center">
                        <img className="w-100 mx-auto" src={ELV} alt="End Of Life Vehicle(ELV)" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row clearfix">
                <div className="image-column col-lg-6 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <div className="image text-center">
                        <img className="w-100 mx-auto" src={RVSF} alt="Vehicle Scrapping Facility (RVSF)" />
                    </div>
                  </div>
                </div>
                <div className="content-column col-lg-6 col-md-12 col-sm-12 my-auto">
                  <div className="inner-column">
                    <div className="sec-title">
                      <h2><font color="#ed1c24">What Is Registered Vehicle Scrapping Facility (RVSF) ? </font></h2>
                    </div>
                    <div className="text">
                      A registered vehicle scrapping facility is authorized by government to dismantle and dispose of end of life vehicles (ELVs) when follow strict regulation to ensure environmental safety. They also recover and recycle valuable material and components from Elvs promoting sustainability in automotive Industry. To be registered, facilities must meet specific criteria and obtain permits from the government.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="about-section pb-0 pt-0">
          <div className="auto-container">
            <div className="sec-title centered">
              <h2>Benifits Of Sustainable Recycling</h2>
            </div>

            <div className="detail pb-5">
              <div className="d-flex justify-content-center col inner-box">
                <div className="col-lg-10 col-md-10 col-sm-12 lower-content">
                  <div className="row h-100">
                    <div className="col-lg-6 col-md-6 col-sm-12 points">
                      <p><b><font color="#ed1c24" size="5">1.</font></b>Reduces landfill waste</p>
                      <p><b><font color="#ed1c24" size="5">2.</font></b>Conserves natural resources</p>
                      <p><b><font color="#ed1c24" size="5">  3.</font></b>Energy conservation</p>
                      <p><b><font color="#ed1c24" size="5">4.</font></b>Cost-effective</p>
                      <p><b><font color="#ed1c24" size="5">5.</font></b>Reduced greenhouse gas emissions</p>
                      <p><b><font color="#ed1c24" size="5">6.</font></b>Increses resources efficiency</p>
                      <p><b><font color="#ed1c24" size="5">7.</font></b>Compliance with regulations</p>
                      <p><b><font color="#ed1c24" size="5">7.</font></b>Support a circular economy</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                      <img src={BenifitsRecycling} alt="Benifits Of Sustainable Recycling" className="w-75" />
                    </div>    
                  </div>              
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* why choose us */}
        <section className="about-section pt-5" style={{ backgroundColor: "whitesmoke" }}>
            <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
            <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>

            <div className="auto-container">
              <div className="sec-title centered">
                <h2>Why Choose Us</h2>
              </div>

              <div className="detail" id="MtcWhyChooseUS">
                <div className="d-flex justify-content-center col inner-box">
                  <div className="col-lg-10 col-md-10 col-sm-12 lower-content">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 points">
                        <h5><img src={Payment} className="mb-4" alt="Instant Payment" /> Instant Payment</h5>
                        <h5><img src={Cod} className="mb-4" alt="Timely COD Delivery" /> Timely COD Delivery</h5>
                        <h5><img src={PickupTruck} className="mb-4" alt="Timely Pick-up" /> Timely Pick-up</h5>
                        <h5><img src={CustomerService} className="mb-4" alt="24/7 Custumer Support" /> 24/7 Custumer Support</h5>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 points">
                        <h5><img src={File} className="mb-4" alt="Hassel-free Documentation" /> Hassel-free Documentation</h5>
                        <h5><img src={Website} className="mb-4" alt="Live Dismantling Option" /> Live Dismantling Option</h5>
                        <h5><img src={RecycleSign} className="mb-4" alt="Lifetime Association For Reccyling Needs" /> Lifetime Association For Reccyling Needs</h5>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>

        {/* Benifits Of Sustainable Recycling */}
        <section className="about-section pt-5">
          <div className="auto-container">
            <div className="sec-title centered">
              <h2>A Hassel-Free Way To Recycle Vehicles At END-Of-Life</h2>
            </div>

            <div className="detail" id="MtcWhyChooseUS1">
              <div className="d-flex justify-content-center col inner-box">
                <div className="col-lg-10 col-md-10 col-sm-12 lower-content">
                  <div className="row clearfix h-100">
                    <div className="content-column col-lg-6 col-md-12 col-sm-12 my-auto points">
                      <h5><img src={Search} className="mb-4" alt="Detail Inspection" />  Detail Inspection</h5>
                      <h5><img src={AirPollution} className="mb-4" alt="Depollution" /> Depollution</h5>
                      <h5><img src={Website} className="mb-4" alt="Dismantling" /> Dismantling</h5>
                      <h5><img src={Recovery} className="mb-4" alt="Recovery Valuable Parts" />  Recovery Valuable Parts</h5>
                      <h5><img src={Demolish} className="mb-4" alt="Sorting & Crushing" />   Sorting & Crushing</h5>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                      <img src={RecycleCar} alt="A Hassel-Free Way To Recycle Vehicles At END-Of-Life" />
                    </div> 
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Our App */}
        <div
          className="testimonial-section-one bg-size pt-0 pb-5"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `inherit`,
            backgroundColor: `#f5f1ee`, 
          }}
        >
          <OurApp />
        </div>

        {/* City List */}
        {/* <div
          className="acordian-section"
          style={{
            backgroundImage: `url(${Citybg})`,
            backgroundSize: `inherit`,
          }}
        >
          <CityList />
        </div> */}
      </Row>
      <Footer />
    </>
  );
};
export default Mtc;
