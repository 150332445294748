import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";

const WhyChooseContentGurgaon = (props) => {

  const city_name = props.city_name;

  return (
    <>
      <Row>
        {/* gurgaon content */}
        <div id="restpagecontent">
          <div className={`auto-container hidden-rest-city ${city_name === 'Gurgaon' && ('content-show-ggn')}`}>
            <div className="upper-section">
              <div className="row clearfix">
                <div className="content-column col-lg-12 col-md-12 col-sm-12">
                  <div className="inner-column">
                    <Row className="justify-content-md-center">
                      <Col xs={12} lg={11}>
                        <div className="sec-title">
                          <h2 className="text-center">
                            {" "}
                            But, why should you choose VehicleCare for your Car related services? Let’s find out!{" "}
                          </h2>
                        </div>
                      
                        <div className="detail">
                          <div className="d-flex justify-content-center col inner-box">
                            <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                              <div className="row">
                                <Col lg={6} sm={12}>
                                  <div class="card border-dark-subtle mb-2">
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item">
                                        <b>
                                          <font color="#ed1c24" size="3">
                                            Quality services at the most affordable rates
                                          </font>
                                        </b>
                                      </li>
                                      <li class="list-group-item bg-body-tertiary">
                                        VehicleCare promises to deliver quality service at the most affordable rates every time you choose us.

                                      </li>
                                    </ul>
                                  </div>

                                  <div class="card border-dark-subtle mb-2">
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item">
                                        <b>
                                          <font color="#ed1c24" size="3">
                                            Wide range of car-related services- 

                                          </font>
                                        </b>
                                      </li>
                                      <li class="list-group-item bg-body-tertiary">
                                        We take pride in offering a wide range of car-related services. Whether it's a simple car wash or a complicated issue, we take care of all types of car needs at the most competitive price.
                                        
                                      </li>
                                    </ul>
                                  </div>

                                  <div class="card border-dark-subtle mb-2">
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item">
                                        <b>
                                          <font color="#ed1c24" size="3">
                                            Easy Online Booking

                                          </font>
                                        </b>
                                      </li>
                                      <li class="list-group-item bg-body-tertiary">
                                        Book services from anywhere in Gurgaon with a click. Visit our website or download VehicleCare’s app to book services or reach out through calls or WhatsApp.
                                      </li>
                                    </ul>
                                  </div>

                                  <div class="card border-dark-subtle mb-2">
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item">
                                        <b>
                                          <font color="#ed1c24" size="3">
                                            Workshops with sophisticated and upgraded tools & gadgets

                                          </font>
                                        </b>
                                      </li>
                                      <li class="list-group-item bg-body-tertiary">
                                        Our workshops are equipped with state-of-the-art tools and gadgets to provide you with the best quality service most effectively and efficiently. 
                                      </li>
                                    </ul>
                                  </div>

                                  <div class="card border-dark-subtle mb-2">
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item">
                                        <b>
                                          <font color="#ed1c24" size="3">
                                            24x7 customer support
                                          </font>
                                        </b>
                                      </li>
                                      <li class="list-group-item bg-body-tertiary">
                                        Our customer support service is available all the time to look into any car-related concerns you have.

                                      </li>
                                    </ul>
                                  </div>

                                </Col>

                                <Col lg={6} sm={12}>

                                  <div class="card border-dark-subtle mb-2">
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item">
                                        <b>
                                          <font color="#ed1c24" size="3">
                                            Use only genuine parts

                                          </font>
                                        </b>
                                      </li>
                                      <li class="list-group-item bg-body-tertiary">
                                        We only use genuine spare parts that are approved by the OEMs and manufacturers.
                                      </li>
                                    </ul>
                                  </div>

                                  <div class="card border-dark-subtle mb-2">
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item">
                                        <b>
                                          <font color="#ed1c24" size="3">
                                            Warranty on services

                                          </font>
                                        </b>
                                      </li>
                                      <li class="list-group-item bg-body-tertiary">
                                        We come with a standard 1000 km or 1-month warranty that is applicable across 1000+ VehicleCare workshops in Gurgaon and across India.
                                      </li>
                                    </ul>
                                  </div>

                                  <div class="card border-dark-subtle mb-2">
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item">
                                        <b>
                                          <font color="#ed1c24" size="3">
                                            Cater to Luxury Vehicles- 

                                          </font>
                                        </b>
                                      </li>
                                      <li class="list-group-item bg-body-tertiary">
                                        VehicleCare services are available for all groups of vehicles. From hatchbacks to sedans and luxury vehicles like Mercedes, Audi, and BMW, we serve a range of cars.
                                      </li>
                                    </ul>
                                  </div>

                                  <div class="card border-dark-subtle mb-2">
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item">
                                        <b>
                                          <font color="#ed1c24" size="3">
                                            Pan-India Presence
                                          </font>
                                        </b>
                                      </li>
                                      <li class="list-group-item bg-body-tertiary">
                                        VehicleCare has a pan-India presence. With a network of more than 1000+ workshops, we deliver the best quality and affordable car services at all our workshops

                                      </li>
                                    </ul>
                                  </div>                                 

                                  <div class="card border-dark-subtle mb-2">
                                    <ul class="list-group list-group-flush">
                                      <li class="list-group-item">
                                        <b>
                                          <font color="#ed1c24" size="3">
                                            Use of AI & ML 
                                          </font>
                                        </b>
                                      </li>
                                      <li class="list-group-item bg-body-tertiary">
                                        VehicleCare uses high-end technologies like AI & ML to deliver enhanced quality services. 

                                      </li>
                                    </ul>
                                  </div>
                                </Col>

                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mt-3">
                          <div className="sec-title text-left mb-4">
                            <h4 className="text-left text-dark">
                              <b>Car Repair & Service In Gurgaon - Services Offered 
                              </b>
                            </h4>
                            <p>All sorts of car-related repairs and services are available at all our Gurgaon outlets at all times. </p>
                          </div>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24" size="3">
                                Basic Service -{" "}
                              </font>
                            </b><br/>
                            In this, we offer all essential services to keep you moving. Recommended every 6 months. (5000km)
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24" size="3">
                                Standard Service -{" "}
                              </font>
                            </b><br/>
                            This includes all the essential services with an upgraded touch of VehicleCare. Recommended every 12 months. (10000km)
                          </p>

                          <p className="mb-1">
                            <b>
                              <font color="#ed1c24" size="3">
                                Comprehensive Service -{" "}
                              </font>
                            </b><br/>
                            Top-of-the-range car services that include end-to-end inspection, recommended every 24 months (20000 km)
                          </p>

                        </div>

                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>


         {/* rest page content */}
        
          <div className={`auto-container ${city_name === 'Gurgaon' && "d-none"}`}>
          <div className="upper-section">
            <div className="row clearfix">
              <div className="content-column col-lg-12 col-md-12 col-sm-12">
                <div className="inner-column">
                  <Row className="justify-content-md-center">
                    <Col xs={12} lg={11}>
                      <div className="sec-title">
                        <h2 class="text-center"> Car Service In <font color="#ed1c24"> {city_name}</font> </h2>

                        <div class="text">Are you in search of a reliable and efficient car service in {city_name}? Look no further than Vehicle Care! As your trusted Car Service Center in {city_name}, we are committed to meeting all your car maintenance requirements. At Vehicle Care, we recognize the significance of having access to a nearby mechanic, regardless of your location or workplace. Our primary aim is to provide quick and cost-effective Car services, ensuring that car maintenance remains hassle-free for you.</div>
                      </div>

                      <div className="sec-title text-left mb-4">
                        <h4 className="text-left text-dark">
                          <b>VehicleCare Features:</b>
                        </h4>
                      </div>

                      <p className="mb-1">
                        <b>
                          <font color="#ed1c24" size="3">
                            Improve Safety -{" "}
                          </font>
                        </b>
                        Protect your vehicle from expensive repair costs and
                        increase your safety feature's life span with our
                        preventive care services.
                      </p>

                      <p className="mb-1">
                        <b>
                          <font color="#ed1c24" size="3">
                            Avoid Breakdowns -{" "}
                          </font>
                        </b>
                        Let us keep an eye on your vehicle's performance to
                        make sure it stays running smoothly.
                      </p>

                      <p className="mb-1">
                        <b>
                          <font color="#ed1c24" size="3">
                            Reduce Expenses -{" "}
                          </font>
                        </b>
                        Catch potential breakdowns before they happen so you
                        can save money in the long run.
                      </p>

                      <p className="mb-1">
                        <b>
                          <font color="black" size="3">
                            Select your preferred car service - We have
                            simplified car servicing options in{" "}
                            {city_name} to help you choose the right
                            service package for your car
                          </font>
                        </b>{" "}
                        <br />
                        Our car service package includes basic, standard, and
                        comprehensive. Based on your requirement we provide
                        the right car service package that covers your ride
                        for longer miles. The basic car service package
                        includes all the necessary elements such as engine
                        oil, coolant, ac gas, etc. While the standard car
                        service package includes a wider array of overhauls
                        for vehicles with over 10000 miles. For cars with more
                        than 20000 miles on them, we recommend our
                        range-topping comprehensive service package that
                        encompasses all the essentials of car service.
                      </p>

                      <p className="mb-1">
                        <b>
                          <font color="#ed1c24" size="3">
                            Basic Service -{" "}
                          </font>
                        </b>
                        All the essentials to keep you moving - recommended
                        every 6 months (5000km).
                      </p>

                      <p className="mb-1">
                        <b>
                          <font color="#ed1c24" size="3">
                            Standard Service -{" "}
                          </font>
                        </b>
                        Get the essentials with the upgraded touch of
                        VehicleCare - recommended every 12 months (10000km).
                      </p>

                      <p className="mb-1">
                        <b>
                          <font color="#ed1c24" size="3">
                            Comprehensive Service -{" "}
                          </font>
                        </b>
                        Top-of-the-range car service that includes end-to-end
                        inspection- recommended every 24 months. (20000km).
                      </p>

                      <p className="mb-1">
                        <b>
                          <font color="black" size="3">
                            Warranty On Cars Serviced in {city_name} -{" "}
                          </font>
                        </b>
                        Timely and quality service is one of the best ways to
                        maintain a car and keep unwanted problems at bay and
                        with VehicleCare expert car service you can avoid all
                        car troubles while enjoying your peace of mind. We
                        promise an unconditional warranty of 1000 kms on all
                        our car services.
                      </p>
                    </Col>
                  </Row> 
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </Row>
    </>
  );
};

export default WhyChooseContentGurgaon;
