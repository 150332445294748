import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Pattern1, Pattern2, MerchantInsuranceImg } from "../../../../constants/ImageConstants";
import InsuranceAbout from "../Insurance/InsuranceAbout";
import HowWorks from "../Insurance/HowWorks";
import TrustedCompany from "../Insurance/trustedCompany";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const MerchantInsurance = () => {
  return (
    <>
      <Helmet>
        <title>Car Service Simplified - VehicleCare</title>
        <meta name="description" content="Avoid the hassels of an ordinary car service. VehicleCare brings you the best of car repair and maintenance services in a seamless and personalised way." />
      </Helmet>

      <Row>
        <Col>
          {/* Banner Section */}
          <section className="banner-section-three style-two text-center">
            <img src={MerchantInsuranceImg} alt="Merchant Insurance" />
            <Link to="/claim" className="theme-btn btn-style-nine mt-1">
              <span className="txt">Make a Claim</span>
            </Link>
          </section>

          {/* How it works */}
          <section className="process-section pt-5 pb-5" style={{ backgroundColor: "#F5F1EE" }}>
            <HowWorks />
          </section>

          {/* Car Damage Section */}
          <section className="about-section" style={{ background: "rgb(251, 251, 251)" }}>
            <InsuranceAbout isMerchant={true} />
          </section>

          {/* Trusted Company */}
          <section className="about-section">
            <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
            <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>
            <TrustedCompany />
          </section>
        </Col>
      </Row>
    </>
  );
};

export default MerchantInsurance;
