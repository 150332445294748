import React, { useState } from "react";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import "./index.scss";
import { BlackImagePageTittle } from "./../../../../constants/ImageConstants";
import { Link } from "react-router-dom";
import ServiceDetailTab from "./ServiceDetailTab";
import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import { getNavigateUrl } from "../../../../utils/customHelper";

const ServiceDetail = () => {
  const [tabId, setTabId] = useState(1);
  const loadTab = (val) => {
    setTabId(val);
  };
  return (
    <>
      <Header isStatic={true} />
      <div style={{ marginTop: "150px" }}></div>
      <Row>
        <Col>
          {/* page tittle */}
          <section className="page-title" style={{ backgroundImage: `url(${BlackImagePageTittle})` }}>
            <div className="auto-container">
              <div className="clearfix">
                <div className="pull-left">
                  <h3>About Us</h3>
                </div>
                <div className="pull-right">
                  <ul className="bread-crumb clearfix">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>About Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <div className="sidebar-page-container">
            <div className="auto-container">
              <div className="row clearfix"></div>
              <Tab.Container defaultActiveKey="first">
                <Row>
                  <Col lg={9} sm={12}>
                    <Tab.Content>
                      <ServiceDetailTab data={tabId} />
                    </Tab.Content>
                  </Col>
                  <Col lg={3} sm={12} className="sidebar-side left-sidebar ">
                    <aside className="sidebar sticky-top">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="first" onClick={() => loadTab(1)}>
                            Car Service \\
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second" onClick={() => loadTab(2)}>
                            Dent & Pent \\
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third" onClick={() => loadTab(3)}>
                            Brake Repairing \\
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fourth" onClick={() => loadTab(4)}>
                            Body Repairing \\
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fifth" onClick={() => loadTab(5)}>
                            Body Painting \\
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="sixth" onClick={() => loadTab(6)}>
                            Oil Changing \\
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <div className="sidebar-widget broucher-widget">
                        <div className="widget-content">
                          <div className="sidebar-title">
                            <h3>Brochures :</h3>
                          </div>

                          <div className="broucher-box">
                            <div className="broucher-inner">
                              <span className="download-icon download fa fa-download"></span>
                              Download Brochure
                            </div>
                          </div>

                          <div className="broucher-box">
                            <div className="broucher-inner">
                              <span className="download-icon download fa fa-file-pdf-o"></span>
                              Characteristics
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="sidebar-widget discount-widget">
                        <div className="widget-content" style={{ backgroundImage: "url(images/background/7.jpg)" }}>
                          <h2>
                            Join Our Special Discount Club <span>Get Unlimited Services!</span>
                          </h2>
                          <Link to={getNavigateUrl("/services/periodic-car-services")} className="theme-btn btn-style-five">
                            <span className="txt">Join Now</span>
                          </Link>
                          <div className="image">
                            <img src="images/resource/tyre-1.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </aside>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
          {/* About */}
          {/* <Row>
          
          <div className="sidebar-page-container">
            <div className="auto-container">
              <div className="row clearfix">
                <div className="content-side col-lg-8 col-md-12 col-sm-12">
                  <div className="service-detail">
                    <div className="inner-box">
                      <h2>EXPERT TIRE REPAIR</h2>
                      <h1 className="title">
                        Firestone Has Been The Nation’s Leading Tire Provider For More Then a
                        Century.
                      </h1>
                      <div className="image">
                        <img src="images/resource/service-22.jpg" alt="" />
                      </div>
                      <h3>For Tire Repair That Last Firestone’e on a Roll</h3>
                      <p>
                        It's a tough road out there, and your tires bear the brunt of potholes,
                        broken glass, nails, screws and anything else that can puncture a tire.
                        Firestone Complete Auto Care's flat tire repair services are the best in the
                        business. We've been repairing tires since 1900, and we take pride in
                        repairing America's tires. When tire damage strikes, come to Firestone
                        Complete Auto Care – the tire repair shop you can trust.
                      </p>
                      <h4>The Firestone Triple Promise</h4>
                      <p>
                        Firestone Complete Auto Care prides itself on promise that your vehicle will
                        be fixed right – the first time.
                      </p>
                      <h4>Got Questions About Flat Tire Repair? We have Answers</h4>
                      <p>
                        When you have a question about car maintenance or repair (including tire
                        repair), Completely Firestone has the answers. We're your go-to resource to
                        keep your car running newer, stronger and longer.
                      </p>
                      <div className="bold-title">Flat Tire Repeir, Tire Patches and More.</div>
                      <p>
                        If you suspect something is wrong with your vehicle tires, don't hesitate to
                        come into a Firestone Complete Auto Care. If your Tire Pressure Monitoring
                        System (TPMS) light is on, or you've hit something on the road, your tire is
                        looking flat, or maybe something just doesn't feel quite right, bring it in
                        and one of expert, ASE-certified technicians will take a look and tell you
                        if you need any tire repair services. If you keep riding on a damaged tire,
                        it can lead to additional (and more costly) rim damage and repairs, so it's
                        best to have your tires examined at the first sign of trouble.{" "}
                      </p>
                      <p>
                        The solution might be as simple as a patch and tire plug if the tire damage
                        is addressed early on. In other simple cases, a leaking tire valve may need
                        to be replaced with a new one, or the TPMS sensor needs to be reset. Come on
                        in to Firestone Complete Auto Care, we will take care of you and your
                        vehicle tire repairs.
                      </p>
                      <div className="appointment-box">
                        <div className="appointment-text">
                          *All services and prices vary by location and vehicle condition. Quote
                          provided prior to services. Call for more details or to schedule an
                          appointment.
                        </div>
                        <Link to="/appointment" className="theme-btn btn-style-two">
                          <span className="txt">Schedule Appointment</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sidebar-side left-sidebar col-lg-4 col-md-12 col-sm-12">
                  <aside className="sidebar sticky-top">
                    <div className="sidebar-widget service-widget">
                      <ul className="service-list" role="tablist">
                        <li className="current">
                          <button type="button" role="tab" value={1}>
                            Car Service{" "}
                            <span className="lines">
                              \<i>\</i>{" "}
                            </span>
                          </button>
                        </li>
                        <li>
                          <Link to="/services">
                            Dent & Paint{" "}
                            <span className="lines">
                              \<i>\</i>
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/services">
                            Brake Repairing{" "}
                            <span className="lines">
                              \<i>\</i>
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/services">
                            Body Repairing{" "}
                            <span className="lines">
                              \<i>\</i>
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/services">
                            Body Painting{" "}
                            <span className="lines">
                              \<i>\</i>
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/services">
                            Oil Changing{" "}
                            <span className="lines">
                              \<i>\</i>
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="sidebar-widget broucher-widget">
                      <div className="widget-content">
                        <div className="sidebar-title">
                          <h3>Brochures :</h3>
                        </div>

                        <div className="broucher-box">
                          <a href="#" className="overlay-link"></a>
                          <div className="broucher-inner">
                            <span className="download-icon download fa fa-download"></span>
                            Download Brochure
                          </div>
                        </div>

                        <div className="broucher-box">
                          <a href="#" className="overlay-link"></a>
                          <div className="broucher-inner">
                            <span className="download-icon download fa fa-file-pdf-o"></span>
                            Characteristics
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="sidebar-widget discount-widget">
                      <div
                        className="widget-content"
                        style={{ backgroundImage: "url(images/background/7.jpg)" }}
                      >
                        <h2>
                          Join Our Special Discount Club <span>Get Unlimited Services!</span>
                        </h2>
                        <Link to="/services" className="theme-btn btn-style-five">
                          <span className="txt">Join Now</span>
                        </a>
                        <div className="image">
                          <img src="images/resource/tyre-1.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </Row> */}
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default ServiceDetail;
