import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import './index.scss';

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text.slice(0, 120) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "... read more" : " show less"}
        </span>
      </p>
    );
  };

const DigitalBenifit = () => {
    return (
        <Row>
            <Col>
            <div className="auto-container">
                <div className="sec-title centered">
                    <h2><font color="#ed1c24">VehicleCare </font> At a Glance </h2>
                </div>
                <div className="fact-counter">
                    <div className="row clearfix">
                        <div className="column col-lg-3 col-md-6 col-6">
                            <div className="inner wow fadeInLeft"  style={{borderRadius: '10px'}} data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="content-mobile">
                                    <div className="count-box">
                                        <span className="count-text" data-speed="3000" data-stop="88">200</span>+
                                    </div>
                                    <h6 className="counter-title">Cities</h6>
                                </div>
                            </div>
                        </div>

                        <div className="column col-lg-3 col-md-6 col-6">
                            <div className="inner wow fadeInLeft" style={{borderRadius: '10px'}} data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="content-mobile">
                                    <div className="count-box alternate">
                                        <span className="count-text" data-speed="5000" data-stop="1500">1000</span>+
                                    </div>
                                    <h4 className="counter-title">Workshops</h4>
                                </div>
                            </div>
                        </div>

                        <div className="column col-lg-3 col-md-6 col-6">
                            <div className="inner wow fadeInLeft" style={{borderRadius: '10px'}} data-wow-delay="900ms" data-wow-duration="1500ms">
                                <div className="content-mobile">
                                    <div className="count-box">
                                        <span className="count-text" data-speed="3500" data-stop="180">100</span>+
                                    </div>
                                    <h4 className="counter-title">Partners</h4>
                                </div>
                            </div>
                        </div>

                        <div className="column col-lg-3 col-md-6 col-6">
                            <div className="inner wow fadeInLeft" style={{borderRadius: '10px'}} data-wow-delay="600ms" data-wow-duration="1500ms">
                                <div className="content-mobile">
                                    <div className="count-box">
                                        <span className="count-text " data-speed="2000" data-stop="838">1.5 Lac</span>+
                                    </div>
                                    <h4 className="counter-title">Vehicles Served</h4>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            </Col>
        </Row>
  );
};

export default DigitalBenifit;
