import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Appbg, Pattern1, Pattern2 } from "../../../../constants/ImageConstants";

import OurApp from "../../../../components/common/ourApp";

import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import Testimonial from "../../../../components/common/Testimonial";
import BlogMultiImageCarousel from "../../../../components/common/blogMultiImageCarouselMobile";
import ServicesWeOffer from "../Landing/ServicesWeOffer";
import VcBenifits from "../Landing/VcBenifits";
import WhyChooseVc from "../Landing/WhyChooseVc";
import ServiceSteps from "../Landing/ServiceSteps";
import Banner from "./Banner";
import { useParams } from "react-router-dom";
import { toTitleCase } from "../../../../utils/customHelper";
import { Helmet } from "react-helmet";
import CarServicePriceList from "../CarServiceNearMe/CarServicePriceList";
import BestPrice from "./BestPrice";
// import CarHistory from "../../../../components/common/CarHistoryCars24";
import WhyChooseContentGurgaon from "./WhyChooseContentGurgaon";

const CarService = () => {
  const urlParam = useParams();
  const selectedCityName = toTitleCase(urlParam.city.replace(/-/g, " "));
  return (
    <>
      <Helmet>
        <title>
          {" "}
          Book Car Repair , Car Service , Car Denting , Car Painting Services
          from your nearest workshop in {selectedCityName}
          Can You Search Premium And Expert Car Service Near Me
        </title>
        <meta
          name="description"
          content={`VehicleCare Provides the Best Auto Repair, Car Service in ${selectedCityName}, Car Maintenance, And Car Denting Painting Services. We offer Free Pickup And Drop-off as Well`}
          data-react-helmet="true"
        />

        <meta
          property="og:title"
          content={`Book Car Repair , Car Service , Car Denting , Car Painting Services from your nearest workshop in ${selectedCityName}`}
        />
        <meta
          property="og:description"
          content={`VehicleCare in ${selectedCityName} provides the best auto repair, car service, car maintenance, and car denting painting services. We offer free pickup and drop-off as well as specialised support 24 hours a day, seven days a week in ${selectedCityName}.`}
        />
        <meta
          property="og:url"
          content={`https://vehiclecare.in/${urlParam.city.replace(
            /-/g,
            " "
          )}/car-service`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="VehicleCare" />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:image"
          content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"
        />
        <meta
          property="twitter:title"
          content={`Book Car Repair , Car Service , Car Denting , Car Painting Services from your nearest workshop in ${selectedCityName}`}
        />
        <meta
          property="twitter:description"
          content={`VehicleCare in ${selectedCityName} provides the best auto repair, car service, car maintenance, and car denting painting services. We offer free pickup and drop-off as well as specialised support 24 hours a day, seven days a week in ${selectedCityName}.`}
        />
        <meta
          property="twitter:url"
          content={`https://vehiclecare.in/${urlParam.city.replace(
            /-/g,
            " "
          )}/car-service`}
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@vehiclecare1" />
        <meta
          property="twitter:image"
          content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"
        />
        <link
          rel="canonical"
          href={`https://vehiclecare.in/${urlParam.city
            .toLowerCase()
            ?.replace(" ", "-")}/car-service`}
        />
        
      </Helmet>

      <Header isStatic={true} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner />
            </div>
          </div>
        </section>

        <div className="services-section-four pt-0 pb-lg-5 pb-sm-4">
          <Row>
            <Col>
              <div className="auto-container">
                <div className="sec-title centered">
                  <h2>Services We Offer</h2>
                  <div className="text col-8 mx-auto d-none d-md-block">
                    Find the best service packages for your car. Select from a
                    wide range of car services, from general service to car
                    wash, accidental repair to custom repair, cashless insurance
                    claims, and much more.
                  </div>
                </div>

                <ServicesWeOffer selectedCityName={selectedCityName} />
              </div>
            </Col>
          </Row>
        </div>

        <section className="about-section pt-0 pb-0">
          {/* <CarHistory /> */}
        </section>

        <div className="services-section-four">
          <ServiceSteps />
        </div>

        <div className="blog-section pt-0">
          <CarServicePriceList />
        </div>

        <div
          className="price-section"
          style={{ background: "rgb(251, 251, 251)" }}
        >
          <VcBenifits />
        </div>

        <hr />

        {/*Best Prices - No Hidden Costs*/}
        <section
          className="about-section pt-5"
          style={{ backgroundColor: "rgb(251, 251, 251)" }}
        >
          <div
            className="pattern-layer-one"
            style={{ backgroundImage: `url(${Pattern1})` }}
          ></div>
          <BestPrice />
        </section>

        {/* why choose */}
        <div className="services-section-four pb-5" id="whychoosevcservice">
          <div className="auto-container mb-3">
            <Row className="justify-content-md-center">
              <Col xs={11} lg={11}>
                <div className="sec-title">
                  <h2 className="text-center">
                    Why choose VehicleCare For Car Services & Repair in{" "}
                    <font color="#ed1c24"> {selectedCityName} </font>{" "}
                  </h2>
                </div>
              </Col>
            </Row>
          </div>

          <WhyChooseVc />

          {/* this content will be shown on only gurgaon page */}
          <div className={`auto-container ${selectedCityName === 'Gurgaon' && ('content-show')}`}>
            <Row className="justify-content-md-center">
              <Col xs={11} lg={11}>
                <div className="mt-5 px-3">
                  <div className="sec-title text-left mb-4">
                    <h4 className="text-left text-dark">
                      <b>Common Car Problems for Car Owners in {selectedCityName}
                      </b>
                    </h4>
                  </div>

                  <p className="mb-1">
                    <b>
                      <font color="#ed1c24" size="3">
                      Heavy traffic jams - {" "}
                      </font>
                    </b>
                    Traffic Jams in Gurgaon are a nightmare that no car owner can escape. Due to massive traffic jams that last for hours, cars face many issues.
                  </p>

                  <p className="mb-1">
                    <b>
                      <font color="#ed1c24" size="3">
                      Scorching Summers and Too-cold Winters -{" "}
                      </font>
                    </b>
                    Both summers and winters in Gurgaon are extreme. Vehicles when subjected to such extreme temperatures, result in damaging core components.

                  </p>

                  <p className="mb-1">
                    <b>
                      <font color="#ed1c24" size="3">
                      Potholes on the road -{" "}
                      </font>
                    </b>
                     Encountering potholes on Gurgaon roads due to waterlogging or construction activities is a common site in Gurgaon. Driving vehicles very often on such roads damages the vehicle's wheels and wear-down suspension. 
                  </p>

                  <p className="mb-1">
                    <b>
                      <font color="#ed1c24" size="3">
                      Massive water-logging in rainy season -{" "}
                      </font>
                    </b>
                    Several parts of Gurgaon face the problem of water-logging during heavy rains in the Monsoon Season especially the low-lying areas and even National Highways. 

                  </p>

                </div>
              </Col>
            </Row>
          </div>

        </div>

        {/*Premium Car Service */}
        <section className="about-section pt-0">
          <div
            className="pattern-layer-one"
            style={{ backgroundImage: `url(${Pattern1})` }}
          ></div>
          <div
            className="pattern-layer-two"
            style={{ backgroundImage: `url(${Pattern2})` }}
          ></div>

          <WhyChooseContentGurgaon city_name={selectedCityName} />
        </section>

        {/* this content will be shown on all pages except the gurgaon page */}
        <section className={`about-section pt-0 ${selectedCityName === 'Gurgaon' && "d-none"}`}>
          <div className="auto-container">
            <div className="justify-content-md-center row">
              <div className="col-md-11 col-sm-12">
                <div className="sec-title centered">
                  <h2 className="title">
                    Here are all the checkpoints for auto repair & service in{" "}
                    {selectedCityName}
                  </h2>
                  <h2 className="text-center">
                    What Is Included In Car Service In {selectedCityName}
                  </h2>
                </div>

                <div className="detail">
                  <div className="d-flex justify-content-center col inner-box">
                    <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                      <div className="row">
                        <Col lg={6} sm={12}>
                          <div class="card border-dark-subtle mb-2">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <b>
                                  <font color="#ed1c24" size="3">
                                    Run Engine Diagnostics
                                  </font>
                                </b>
                              </li>
                              <li class="list-group-item bg-body-tertiary">
                                Through our special 40 point inspection, we
                                probe for every hidden problem in your car so
                                you can avoid any unplanned repairs.
                              </li>
                            </ul>
                          </div>

                          <div class="card border-dark-subtle mb-2">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <b>
                                  <font color="#ed1c24" size="3">
                                    Replace filters
                                  </font>
                                </b>
                              </li>
                              <li class="list-group-item bg-body-tertiary">
                                Air Filter, Oil Filter, Fuel Filter, Installing
                                new filters is essential to maintain a clean
                                flow of air and fuel into the combustion
                                chamber.
                              </li>
                            </ul>
                          </div>

                          <div class="card border-dark-subtle mb-2">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <b>
                                  <font color="#ed1c24" size="3">
                                    Tire Balancing and Rotation
                                  </font>
                                </b>
                              </li>
                              <li class="list-group-item bg-body-tertiary">
                                As a free add-on, wheel alignment and balancing
                                is offered on every vehicle service in{" "}
                                {selectedCityName} along with free tire pressure
                                check.
                              </li>
                            </ul>
                          </div>

                          <div class="card border-dark-subtle mb-2">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <b>
                                  <font color="#ed1c24" size="3">
                                    Replace/flush the engine fluids
                                  </font>
                                </b>
                              </li>
                              <li class="list-group-item bg-body-tertiary">
                                Oil, Brake Fluid, Power Steering fluid,
                                Windshield Washer Fluid, Transmission Fluid,
                                Antifreeze, Coolant, etc. All automotive fluids
                                are flushed or topped after inspection with the
                                right and top-quality product.
                              </li>
                            </ul>
                          </div>
                        </Col>

                        <Col lg={6} sm={12}>
                          <div class="card border-dark-subtle mb-2">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <b>
                                  <font color="#ed1c24" size="3">
                                    Check and/or change brake pads
                                  </font>
                                </b>
                              </li>
                              <li class="list-group-item bg-body-tertiary">
                                Brake pads need to be replaced at specific
                                intervals as they are an important part of the
                                braking system as a whole.
                              </li>
                            </ul>
                          </div>

                          <div class="card border-dark-subtle mb-2">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <b>
                                  <font color="#ed1c24" size="3">
                                    Check HVAC system
                                  </font>
                                </b>
                              </li>
                              <li class="list-group-item bg-body-tertiary">
                                Vents, air conditioning and heating, etc. Other
                                systems such as the electricals and air
                                conditioning are checked through specialized
                                equipment.
                              </li>
                            </ul>
                          </div>

                          <div class="card border-dark-subtle mb-2">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <b>
                                  <font color="#ed1c24" size="3">
                                    Car wash
                                  </font>
                                </b>
                              </li>
                              <li class="list-group-item bg-body-tertiary">
                                After service is completed, the vehicle is given
                                an exterior wash for a shiny finish.
                              </li>
                            </ul>
                          </div>

                          <div class="card border-dark-subtle mb-2">
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <b>
                                  <font color="#ed1c24" size="3">
                                    Maintain Car AC Health
                                  </font>
                                </b>
                              </li>
                              <li class="list-group-item bg-body-tertiary">
                                Always park your car in shaded areas so that
                                whenever you start your car and turn on the AC,
                                it will cool faster.
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        {/* Testimonial Section  */}
        <div className="d-none d-md-block">
          <Testimonial service={"Car Service"} city_name={selectedCityName} />
        </div>

        {/* Blog Section */}
        <div className="blog-section">
          {/* <IBlog /> */}
          <BlogMultiImageCarousel />
        </div>

        {/* Our App */}
        <div
          className="testimonial-section-one bg-size"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
            paddingBottom: "110px",
          }}
        >
          <OurApp />
        </div>
      </Row>

      {/* <div className="d-lg-none d-md-none d-block mt-5">
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="challan_box_mobile">
            <div>
              <h5 className="text-white" style={{ fontWeight: "bold" }}>
                Check Car History Report & buy with confidence.
              </h5>
              <div>
                <li className="challan_desc_mobile">✅ Find hidden issues with any car.</li>
                <li className="challan_desc_mobile">
                  ✅ @199 onwards!
                </li>
              </div>
            </div>
            <a
              href="https://www.cars24.com/car-service-history/?utm_source=VEHICLECARE&utm_medium=APP&utm_campaign=LISTING"
              className="btn check-btn inactive p-0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="button"
                class="btn btn-outline-light hover-btn-mobile"
                style={{ fontWeight: "bold", marginTop: "20px" }}
              >
                Check now
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="d-lg-block d-md-block d-none">
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="challan_box">
            <div>
              <h5 className="text-white" style={{ fontWeight: "bold" }}>
              Check Car History Report & buy with confidence.
              </h5>
              <div style={{ display: "flex", marginTop: "15px" }}>
                <li className="challan_desc">✅ Find hidden issues with any car.</li>
                <li className="challan_desc">✅ @199 onwards!</li>
              </div>
            </div>
            <a
              href="https://www.cars24.com/car-service-history/?utm_source=VEHICLECARE&utm_medium=APP&utm_campaign=LISTING"
              className="btn check-btn inactive"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="button"
                class="btn btn-outline-light hover-btn"
                style={{ fontWeight: "bold" }}
              >
                Check now
              </button>
            </a>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default CarService;
