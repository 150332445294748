import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { VehicleAction } from "../../../store/action";
import { MODEL_LIST, SERVICE_PACKAGES_EMPTY, SET_BRAND, SET_MODEL, SET_VARIANT } from "../../../store/types";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { getNavigateUrl } from "../../../utils/customHelper";

const majorBrands = ["26", "40", "15", "41", "16", "21", "29", "2", "25"];

const VehicleSelector = (props) => {
  const prevCountRef = useRef();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [variantList, setVariant] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [type, setType] = useState("brand");
  const { brandList, modelList } = props;
  const [vehicleData, setVehicleData] = useState({
    brandId: null,
    modelId: null,
    variantId: null,
  });
  const brandName = props?.brand?.name || "Select Your Car";
  const modelName = props?.model?.name || "";
  const variantName = props?.variant?.variant || "";

  // const brandName = props?.brand?.name || "Select Brand";
  // const modelName = props?.model?.name || "Select Model";
  // const variantName = props?.variant?.variant || "Select Variant";

  const bol = props.navigate;
  const majorBrandList = [];
  for (let i = 0; i < majorBrands.length; i++) {
    const element = majorBrands[i];
    majorBrandList.push(brandList.filter((elemn) => elemn.id === element)[0]);
  }
  brandList.filter((e) => majorBrands.indexOf(e.id) > -1);
  const otherBrandList = brandList.filter((e) => majorBrands.indexOf(e.id) === -1);
  const updatedBrandList = [...majorBrandList, ...otherBrandList];
  let newBrandList = [];
  let updatedModelList = {};
  const closeSearchScreen = () => {
    if (isSearching) {
      setSearchValue("");
      setIsSearching(false);
      setShowCloseButton(false);
      showBrandModal();
    } else {
      setShow(false);
      props?.setModal && props?.setModal(false)
    }
  };
  const setBrandData = (data) => {
    // const brandData = { id: data.brand_id, name: data.brand_name, icon: "" };
    // props.updateVehicle({ type: SET_BRAND, payload: brandData });
    setVehicleData({ ...vehicleData, brandId: data.brand_id, modelId: data.model_id });
  };
  const GetVehicleModalContent = () => {
    let content = [];
    if (type === "brand") {
      const majorList = [];
      const otherList = [];
      majorBrandList?.forEach((major_element) => {
        if (!major_element) return;
        majorList.push(
          <Col
            key={major_element.id}
            xs={3}
            lg={2}
            md={4}
            className="text-center"
            onClick={() => {
              setType("model");
              getModelList(major_element.id);
            }}
          >
            <img
              src={major_element.icon}
              className="cityImg"
              alt="NoImage"
              style={{
                width: "70px",
                height: "60px",
                // margin: "8px 0px 0px 8px",
                cursor: "pointer",
                verticalAlign: "middle",
                overflowClipBox: "content-box",
                overFlow: "clip",
                padding: "none",
                objectFit: "contain",
              }}
            ></img>
            <br />
            <p className="elementName">{major_element.name}</p>
          </Col>
        );
      });
      otherBrandList?.forEach((element) => {
        otherList.push(
          <Col
            key={element.id}
            xs={3}
            lg={2}
            md={4}
            className="text-center"
            onClick={() => {
              setType("model");
              getModelList(element.id);
            }}
          >
            <img
              src={element.icon}
              className="cityImg"
              alt="NoImage"
              style={{
                width: "60px",
                height: "60px",
                // margin: "8px 0px 0px 8px",
                cursor: "pointer",
                verticalAlign: "middle",
                overflowClipBox: "content-box",
                overFlow: "clip",
                padding: "none",
                objectFit: "contain",
              }}
            ></img>
            <br />
            <p className="elementName">{element.name}</p>
          </Col>
        );
      });
      content = (
        <>
          <Row style={{ borderBottom: "1px solid #ced4da", marginBottom: "5px" }}>{[...majorList]}</Row>
          <Row>{[...otherList]}</Row>
        </>
      );
    } else if (type === "model") {
      if (isSearching && (!modelList.length || searchValue === "")) {
        content = (
          <Col className="text-center text-secondary pt-5">
            <h6>Type your Vehicle name to start searching...</h6>
          </Col>
        );
      } else {
        modelList?.forEach((element) => {
          content.push(
            <Col
              key={element.model_id}
              xs={3}
              lg={2}
              md={4}
              className="text-center"
              onClick={() => {
                setType("variant");
                getVariantList(element.model_id);
                isSearching && setBrandData(element);
              }}
            >
              <img
                src={element.icon}
                className="cityImg"
                alt="NoImage"
                style={{
                  width: "50px",
                  height: "60px",
                  // margin: "8px 0px 0px 8px",
                  cursor: "pointer",
                  verticalAlign: "middle",
                  overflowClipBox: "content-box",
                  overFlow: "clip",
                  padding: "none",
                  objectFit: "contain",
                }}
              ></img>
              <br />
              <p className="elementName">{element.model_name}</p>
            </Col>
          );
        });
      }
    } else {
      variantList?.forEach((element) => {
        content.push(
          <Col
            key={element.id}
            xs={3}
            lg={2}
            md={4}
            className="text-center"
            onClick={() => {
              setType("variant");
              sendCarData(element.id);
            }}
          >
            <img
              src={require(`../../../assets/images/fuel/${element.variant.toUpperCase()}.png`)}
              className="cityImg"
              alt="NoImage"
              style={{
                width: "50px",
                height: "60px",
                // margin: "8px 0px 0px 8px",
                cursor: "pointer",
                verticalAlign: "middle",
                overflowClipBox: "content-box",
                overFlow: "clip",
                padding: "none",
                objectFit: "contain",
              }}
            ></img>
            <br />
            <p className="elementName">{element.variant.toUpperCase()}</p>
          </Col>
        );
      });
    }
    return <Row>{content}</Row>;
  };

  useEffect(() => {
    if(props?.open){
      showBrandModal()
    }
  }, [props?.open])
  const showBrandModal = () => {
    setType("brand");
    setShow(true);
    setTitle("Select Brand");
    setShowCloseButton(true);
  };
  const navigateToServices = (vehicleDetails) => {
    if (props.navigFun) {
      closePopup();
      props.navigFun(vehicleDetails);
      return;
    }
    navigate(getNavigateUrl("/services/periodic-car-services"));
  };
  const closePopup = () => {
    setShow(false);
    props?.setModal && props?.setModal(false)
  };
  const moveTosearchScreen = () => {
    setType("model");
    setTitle("Search Your Car");
    setIsSearching(true);
    setShowCloseButton(true);
  };
  const getModelList = (val) => {
    const selectedBrandId = val;
    setShow(true);
    setTitle("Select Model");
    setVehicleData({ ...vehicleData, brandId: selectedBrandId });
    const params = { task: "getCardModels", brand_id: selectedBrandId };
    props.getModelNames(params);
  };
  const getVariantList = (val) => {
    setShow(true);
    setTitle("Select Variant");
    const modelId = val;
    const newModelList = modelList.filter((elemn) => elemn.model_id === modelId);
    setVehicleData({ ...vehicleData, modelId: modelId });
    setVariant(newModelList?.[0].variants);
  };
  const sendCarData = (val) => {
    const variantId = val;
    newBrandList = updatedBrandList.filter((elemn) => elemn.id === vehicleData.brandId);
    props.updateVehicle({ type: SET_BRAND, payload: newBrandList[0] });
    const newModelList2 = modelList.filter((elemn1) => elemn1.model_id === vehicleData.modelId);
    const newObject = {};
    newObject.id = newModelList2?.[0].model_id;
    newObject.name = newModelList2?.[0].model_name;
    newObject.icon = newModelList2?.[0].icon;
    newObject.segment_type = newModelList2?.[0].segment_type;
    newObject.variants = newModelList2?.[0].variants;
    updatedModelList = newObject;
    props.updateVehicle({ type: SET_MODEL, payload: updatedModelList });
    const newVariantList = variantList.filter((elemn) => elemn.id === parseInt(variantId));
    props.updateVehicle({ type: SET_VARIANT, payload: newVariantList[0] });
    props.updateVehicle({ type: SERVICE_PACKAGES_EMPTY });
    const vehicleDetails = { brand: newBrandList[0], model: updatedModelList, variant: newVariantList[0] };
    window.sessionStorage.setItem("vehicleDetails", JSON.stringify(vehicleDetails));
    bol ? closePopup() : navigateToServices(vehicleDetails);
  };
  useEffect(() => {
    if (!isSearching || searchValue === prevCountRef.current) return;
    if (searchValue === "") {
      if (modelList.length) {
        props.updateVehicle({ type: MODEL_LIST, payload: [] });
      }
      return;
    }
    const getSearchModelList = (val) => {
      const params = { task: "getCardModels", search_text: val };
      props.getModelNames(params);
    };
    const delayDebounceFn = setTimeout(() => {
      getSearchModelList(searchValue);
      prevCountRef.current = searchValue;
      clearTimeout(delayDebounceFn);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, props, modelList, isSearching]);
  return (
    <>
      {/* <Row> */}
      <Col className={`${props.hideBar && "isHidden"}`}>
        <div className="appointment-form banner-form brandModalForm mb-2">
          <div className="d-none d-md-block">
            <div className="row clearfix rounded-pill">
              <div className="form-group col-lg-12 col-md-12 col-sm-12">
                <button type="button" id="custom-vehicle-selector-brand" onClick={showBrandModal} className="custom-select-box">
                  {brandName} {modelName} {variantName}
                </button>
              </div>
              <div className="form-group col-lg-4 col-md-4 col-sm-12 d-none">
                <button type="button" onClick={showBrandModal} className="custom-select-box borderRight">
                  {modelName}
                </button>
              </div>
              <div className="form-group col-lg-4 col-md-4 col-sm-12 d-none">
                <button type="button" onClick={showBrandModal} className="custom-select-box">
                  {variantName}
                </button>
              </div>
            </div>
          </div>

          <div className="row clearfix d-md-none px-2">
            <div className="form-group col-lg-12 col-md-12 col-sm-12 border">
              <button type="button" onClick={showBrandModal} className="custom-select-box">
                {brandName} {modelName} {variantName}
              </button>
            </div>
            <div className="form-group col-lg-12 col-md-12 col-sm-12 border d-none">
              <button type="button" onClick={showBrandModal} className="custom-select-box">
                {modelName}
              </button>
            </div>
            <div className="form-group col-lg-12 col-md-4 col-sm-12 border d-none">
              <button type="button" onClick={showBrandModal} className="custom-select-box">
                {variantName}
              </button>
            </div>
          </div>
        </div>
      </Col>
      <Modal id="vehicleSelectorModal" show={show} onHide={closeSearchScreen} keyboard={showCloseButton || false} backdrop="static" centered size="lg">
        <Modal.Header closeButton={showCloseButton || false} style={{ borderBottom: "none" }}>
          {title ? <Modal.Title>{title}</Modal.Title> : ""}
        </Modal.Header>
        <Modal.Body style={{ minHeight: "20rem" }}>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <input onFocus={moveTosearchScreen} onChange={(e) => setSearchValue(e.target.value)} value={searchValue} className="form-control mb-3" placeholder="Search your Car" type="text" />
            </Col>
            <Col xs={12} lg={12} md={12} style={{ overflowY: "auto", maxHeight: "70vh" }}>
              <GetVehicleModalContent />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* </Row> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return state.default.vehicleReducer;
};
const mapDispatchToProps = (dispatch) => ({
  getModelNames: (params) => VehicleAction.getModelNames(params, dispatch),
  updateVehicle: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(VehicleSelector);
