import React from "react";
import OwlCarousel from "react-owl-carousel";
import { isMobile } from "react-device-detect";
const TestimonialList = ({ reviewList,city_name}) => {
//  console.log('reviews 1',reviewList,city_name);
if(!reviewList?.length)
{
  return '';
}


if(reviewList?.[0]?.review_user_city_name !== city_name)
{
  return '';
}

//console.log('reviews',reviewList,city_name);

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1800, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

  return (
    <>
     <OwlCarousel
              className="owl-theme"
              loop
              dots={false}
              nav={false}
              autoplay={true}
              autoplayTimeout="3000"
              // autoplayHoverPause={true}
              margin={25}
              stagePadding={0}
              responsiveclassName={true}
              responsive={responsive}
              items={isMobile ? 1 : 2}
              city_name={city_name}
            >
      {reviewList?.map((value, index) => {
             return (
              <div className="item" key={index}>
                <div className="testimonial-block-four">
                  <div className="inner-box">
                    <div className="quote-icon fa fa-quote-left"></div>
                    <div className="text">
                     {value.review}
                    </div>
                    <h4> {value.review_user_name}</h4>
                    <div className="designation">{value.review_user_city_name}</div>
                    {/* <div className="author-image">
                      <img src={Authorimg1} alt="" />
                    </div> */}
                  </div>
                </div>
              </div>
       );
      })}
      </OwlCarousel>
    </>
  );
};

export default TestimonialList;


