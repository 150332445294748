import React from "react";
import { Row,Col } from "react-bootstrap";
import "./index.scss";
import { VehiclecareB1, VehiclecareB2, VehiclecareB3, VehiclecareB4, VehiclecareB5, VehiclecareB6, VehiclecareB7, VehiclecareB8, VehiclecareB9, VehiclecareB10, VehiclecareB11, VehiclecareB12, VehiclecareB13, VehiclecareB14, Appbg } from "../../../../constants/ImageConstants";
import OurApp from "../../../../components/common/ourApp";

import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import Testimonial from "../../../../components/common/Testimonial";
import BlogMultiImageCarousel from "../../../../components/common/blogMultiImageCarouselMobile";
import ServicesWeOffer from "./ServicesWeOffer";
import VcBenifits from "./VcBenifits";
import Banner from "./Banner";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";

import { connect } from "react-redux";
import { OrderAction } from "../../../../store/action";

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import LazyLoad from 'react-lazy-load';
import { getUrlSearchParams } from "../../../../utils/customHelper";

const Landing = (props) => {
  const { cityData } = props;
  const search = new URLSearchParams(window.location.search)
  const customParams = getUrlSearchParams(search);
  if(customParams[1]?.value?.toLowerCase().includes("carinfo")){
    return <></>
  }else return (
    <>
      <Helmet>
        <title>Car Service Simplified - VehicleCare landing</title>
        <meta name="description" content="Avoid the hassels of an ordinary car service. VehicleCare brings you the best of car repair and maintenance services in a seamless and personalised way." />

        <script>{`{"gtag('event', 'conversion', {'send_to': 'AW-16449867941/RZu1CNG3wo0ZEKWh9KM9'});"}`}</script>
      </Helmet>

      <Header isStatic={true} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className={`slide ${!isMobile ? "mt-0" : "pt-4"}`}>
              <Banner />
            </div>
          </div>
        </section>

        <div className="services-section-four pt-0 pb-lg-5 pb-sm-4">
          <Row>
            <Col>
              <div className="auto-container">
                <div className="sec-title centered">
                  <h2>Services We Offer</h2>
                  <div className="text col-8 mx-auto d-none d-md-block">
                  Find the best service packages for your car. Select from a wide range of car services, from general service to
                  <a href="https://vehiclecare.in/car-wash-near-me" target="_blank" rel="noreferrer"><font color="#ed1c24">  car wash, </font></a>
                  accidental repair to custom repair, cashless insurance claims, and much more.
                  </div>
                </div>

                <ServicesWeOffer />
              </div>
            </Col>
          </Row>
        </div>

        {/*brand Section*/}
        <section className="sponsors-section style-three">
          <div className="container-fluid text-center" id="splideCarousel">
            <div className="carousel-outer pb-5 d-none d-md-block">
              <Splide
                options={ {
                  updateOnMove: true,
                  type        : 'loop',
                  drag   : 'free',
                  perPage     : 7,
                  perMove     : 1,
                  focus       : 0,
                  interval: 2000,
                  speed: 400,
                  pagination : false,
                  autoplay: "play",
                  autoScroll: {
                    speed: 1,
                  },
                  
                } }
              >
              
                <SplideSlide><img src={VehiclecareB1} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB2} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB3} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB4} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB5} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB6} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB7} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB8} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB9} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB10} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB11} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB12} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB13} alt="" /></SplideSlide>
                <SplideSlide><img src={VehiclecareB14} alt="" /></SplideSlide>
                
              </Splide>
            </div>

            <div className="carousel-outer d-md-none">
              <Splide
                options={ {
                  type   : 'loop',
                  drag   : 'free',
                  focus  : 'center',
                  interval: 2000,
                  speed: 200,
                  autoplay: "play",
                  pagination : false,
                  perPage: 3,
                  autoScroll: {
                    speed: 1,
                  },
                } }
              >
              
                <SplideSlide><img src={VehiclecareB1} alt="" /></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB2} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB3} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB4} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB5} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB6} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB7} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB8} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB9} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB10} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB11} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB12} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB13} alt="" /></LazyLoad></SplideSlide>
                <SplideSlide><LazyLoad><img src={VehiclecareB14} alt="" /></LazyLoad></SplideSlide>
                
              </Splide>
            </div>
          </div>
        </section>
        {/* End brand Section */}

        <div className="price-section" style={{ background: "rgb(251, 251, 251)" }}>
          <VcBenifits />
        </div>

        {/* Testimonial Section  */}
        <div className="d-none d-md-block">
          <Testimonial city_name={cityData.selectedCity} />
        </div>

        {/* Blog Section */}
        <div className="blog-section">
          <BlogMultiImageCarousel />
        </div>

        {/* Our App */}
        <LazyLoad>
        <div
            className="testimonial-section-one bg-size pb-5"
            style={{
              backgroundImage: `url(${Appbg})`,
              backgroundSize: `cover`,
              backgroundColor: `#f5f1ee`,
            }}
          >
            <OurApp />
        </div>
        </LazyLoad>

      </Row>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) => OrderAction.createServiceQuote(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Landing);