import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import { Appbg } from "../../../../constants/ImageConstants";

import OurApp from "../../../../components/common/ourApp";

import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import Testimonial from "../../../../components/common/Testimonial";
import BlogMultiImageCarousel from "../../../../components/common/blogMultiImageCarousel";
import ServicesWeOffer from "../Landing/ServicesWeOffer";
import WhyChooseVc from "../Landing/WhyChooseVc";
import ServiceSteps from "../Landing/ServiceSteps";
import PersonalizeYourExperience from "../Landing/PersonalizeYourExperience";
import Banner from "./Banner";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

const MemberEnrollNew = (props) => {
  const partner = props.partner;
  return (
    <>
      <Helmet>
        <title>VC Prime - VehicleCare</title>
        <meta name="description" content="Avoid the hassels of an ordinary car service. VehicleCare brings you the best of car repair and maintenance services in a seamless and personalised way." />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner partner={partner} />
            </div>
          </div>
        </section>

        <div className="services-section-four pt-0">
          <ServicesWeOffer />
        </div>

        <div className="services-section-four">
          <div className="auto-container">
            <div className="sec-title centered">
              <h2>Why Choose Vehiclecare </h2>
            </div>
          </div>
          <WhyChooseVc />
        </div>

        <section className="features-section" style={{ background: "rgb(251, 251, 251)" }}>
          <PersonalizeYourExperience />
        </section>

        <div className="services-section-four">
          <ServiceSteps />
        </div>

        {/* Testimonial Section  */}
        <div className="testimonial-section-four d-none d-md-block">
          <Testimonial />
        </div>

        {/* Blog Section */}
        <div className="blog-section">
          {/* <IBlog /> */}
          <BlogMultiImageCarousel />
        </div>

        {/* Our App */}
        <div
            className="testimonial-section-one bg-size pb-5"
            style={{
              backgroundImage: `url(${Appbg})`,
              backgroundSize: `cover`,
              backgroundColor: `#f5f1ee`,
            }}
          >
            <OurApp />
        </div>

      </Row>
      <Footer />
    </>
  );
};

function mapStateToProps(state) {
  return {
    cityListReducer: state.default.cityListReducer,
  };
}
export default connect(mapStateToProps)(MemberEnrollNew);
