import React, { useEffect } from "react";
import "./index.scss";
import {
  Vc_Default_User,
  MechanicalOffer,
} from "../../../../constants/ImageConstants";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import { OrderAction, UserAction } from "../../../../store/action";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import PersonalProfile from "./personalProfile";
import MyGarage from "./myGarage";
import { Sign_Out } from "../../../../store/types";
import { useNavigate } from "react-router";

const MobileView = (props) => {
  const { userData, getUserProfile, updateUserVehicle, customDispatch } = props;
  const { full_profile, is_user_logged_in } = userData;
  const profileData = full_profile?.user || null;
  console.log(profileData);
  const navigate = useNavigate();
  useEffect(() => {
    if (!is_user_logged_in) {
      navigate("/");
      // document.getElementsByClassName("userprofile_icon")[0].click();
    }
    if (!profileData && is_user_logged_in) {
      getUserProfile({ task: "getUserCompleteProfile" });
    }
  }, [profileData, is_user_logged_in, getUserProfile, navigate]);
  const logoutAction = () => {
    const isLogout = window.confirm("Are you want to logout ?");
    if (isLogout) {
      customDispatch({ type: Sign_Out });
      navigate("/");
    }
  };
  return (
    <>
      <section>
        <Tab.Container id="left-tabs-example" defaultActiveKey="about">
          <div class="container-fluid px-0">
            <div class="profile-page tx-13">
              <div class="row justify-content-center col inner-box">
                <div class="col-md-11 col-sm-12 grid-margin justify-content-center col inner-box">
                  <div class="profile-header">
                    <div class="cover" id="profile_header_cover">
                      <div class="gray-shade"></div>
                      <div class="cover-body d-flex justify-content-between align-items-center">
                        <div>
                          <img
                            class="profile-pic"
                            src={Vc_Default_User}
                            alt="User Image"
                          />
                          <span
                            class="profile-name text-dark"
                            style={{ textTransform: "capitalize" }}
                          >
                            {profileData?.first_name} {profileData?.last_name}
                            {/* <i
                                  class="fa fa-check-circle text-dark "
                                  aria-hidden="true"
                                ></i> */}
                          </span>
                        </div>

                        <div class="d-none d-md-block">
                          <a
                            class="btn btn-secondary btn-icon-text btn-edit-profile text-white"
                            href="#"
                            data-toggle="modal"
                            data-target="#settings_modal"
                          >
                            <i class="fa fa-gear"></i>
                          </a>
                        </div>

                        <div class="d-block d-md-none ">
                          <a
                            class="btn btn-secondary btn-link btn-icon-text btn-edit-profile text-dark"
                            href="#"
                            data-toggle="modal"
                            data-target="#settings_modal"
                          >
                            <i class="fa fa-ellipsis-v"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="header-links">
                      <Nav
                        variant="pills"
                        className="d-flex align-items-center service-list"
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="about">
                            <i class="fa fa-user"></i>
                            <span class="px-1 d-none d-md-inline">About</span>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link eventKey="my_garage">
                            <i class="fa fa-car"></i>
                            <span class="px-1 d-none d-md-inline">
                              My garage
                            </span>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link eventKey="wallet">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              class="feather feather-image mr-1 icon-md"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"></path>
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
                            </svg>
                            <span class="px-1 d-none d-md-inline">Wallet</span>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link eventKey="notification">
                            <i class="fa fa-bell"></i>
                            <span class="px-1 d-none d-md-inline">
                              Notification
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </div>

                  <link
                    rel="stylesheet"
                    href="https://cdn.aglasem.com/assets/production/css/bootstrap-side-modals.css"
                  />

                  <div
                    class="modal modal-right fade "
                    id="settings_modal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="right_modal"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">Settings</h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="card rounded">
                            <div class="card-header">
                              <div class="d-flex align-items-center justify-content-between">
                                <h6 class="card-title mb-0">Close Account</h6>
                              </div>
                            </div>
                            <div class="card-body">
                              <p>
                                To close your account from VehicleCare , click
                                close account
                              </p>
                              <a
                                class="btn btn-danger btn-sm"
                                href="/user-account-deletion"
                              >
                                Close account
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer modal-footer-fixed">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container px-0">
            <div class="profile-page tx-12">
              <div class="row profile-body mx-0">
                <div class="d-none d-md-block col-md-4 col-xl-3 left-wrapper">
                  <div class="card rounded">
                    <div class="card-header">
                      <div class="d-flex align-items-center justify-content-between">
                        <h6 class="card-title mb-0 text-dark fw-bolder">
                          About{" "}
                        </h6>
                        <div class="dropdown d-none">
                          <button
                            class="btn p-0"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-more-horizontal icon-lg text-muted pb-3px"
                            >
                              <circle cx="12" cy="12" r="1"></circle>
                              <circle cx="19" cy="12" r="1"></circle>
                              <circle cx="5" cy="12" r="1"></circle>
                            </svg>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              class="dropdown-item d-flex align-items-center"
                              href="#"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-edit-2 icon-sm mr-2"
                              >
                                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                              </svg>{" "}
                              <span class="">Edit</span>
                            </a>
                            <a
                              class="dropdown-item d-flex align-items-center"
                              href="#"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-eye icon-sm mr-2"
                              >
                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                <circle cx="12" cy="12" r="3"></circle>
                              </svg>{" "}
                              <span class="">View all</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-body">
                      <div class="d-flex align-items-center justify-content-between mb-2">
                        <div class="dropdown d-none">
                          <button
                            class="btn p-0"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-more-horizontal icon-lg text-muted pb-3px"
                            >
                              <circle cx="12" cy="12" r="1"></circle>
                              <circle cx="19" cy="12" r="1"></circle>
                              <circle cx="5" cy="12" r="1"></circle>
                            </svg>
                          </button>
                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              class="dropdown-item d-flex align-items-center"
                              href="#"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-edit-2 icon-sm mr-2"
                              >
                                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                              </svg>{" "}
                              <span class="">Edit</span>
                            </a>
                            <a
                              class="dropdown-item d-flex align-items-center"
                              href="#"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-eye icon-sm mr-2"
                              >
                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                <circle cx="12" cy="12" r="3"></circle>
                              </svg>{" "}
                              <span class="">View all</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <p style={{ textTransform: "capitalize" }}>
                        Hi! I'm {profileData?.first_name}{" "}
                        {profileData?.last_name}
                      </p>
                      {/* <div class="mt-3">
                        <label class="fw-bolder text-dark mb-0 text-uppercase">
                          Joined:
                        </label>
                        <p class="text-muted">April 26, 2022</p>
                      </div> */}
                      <div class="mt-3">
                        <label class="fw-bolder text-dark mb-0 text-uppercase">
                          From:
                        </label>
                        <p class="text-muted">{profileData?.city}</p>
                      </div>
                      <div class="mt-3">
                        <label class="fw-bolder text-dark mb-0 text-uppercase">
                          Email:
                        </label>
                        <p class="text-muted">{profileData?.email} </p>
                      </div>
                      <div class="mt-3">
                        <label class="fw-bolder text-dark mb-0 text-uppercase">
                          Contact:
                        </label>
                        <p class="text-muted">+91 - {profileData?.phone}</p>
                      </div>

                      <div class="mt-3">
                        <button
                          onClick={logoutAction}
                          className="fw-bolder text-dark mb-0 text-uppercase bg-none"
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-8 col-xl-6 middle-wrapper">
                  <div class="row">
                    <Tab.Content>
                      <Tab.Pane eventKey="about">
                        <div class="col-md-12 grid-margin">
                          <div class="card rounded">
                            <div class="card-header">
                              <div class="d-flex align-items-center justify-content-between">
                                <h6 class="card-title mb-0 text-dark fw-bolder">
                                  Basic Details
                                </h6>
                                <div class="dropdown d-none">
                                  <button
                                    class="btn p-0"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-more-horizontal icon-lg text-muted pb-3px"
                                    >
                                      <circle cx="12" cy="12" r="1"></circle>
                                      <circle cx="19" cy="12" r="1"></circle>
                                      <circle cx="5" cy="12" r="1"></circle>
                                    </svg>
                                  </button>
                                  <div
                                    class="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <a
                                      class="dropdown-item d-flex align-items-center"
                                      href="#"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-edit-2 icon-sm mr-2"
                                      >
                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                      </svg>{" "}
                                      <span class="">Edit</span>
                                    </a>
                                    <a
                                      class="dropdown-item d-flex align-items-center"
                                      href="#"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-eye icon-sm mr-2"
                                      >
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                      </svg>{" "}
                                      <span class="">View all</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <PersonalProfile profileData={profileData} />
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="my_garage">
                        <MyGarage
                          updateUserVehicle={updateUserVehicle}
                          profileData={profileData}
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey="wallet">
                        <div className="card mb-4 px-2">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0 fw-bolder text-center">
                                  Coming soon
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="notification">
                        <div className="card mb-4 px-2">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <p className="mb-0 fw-bolder text-center">
                                  Coming soon
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>

                <div class="d-none d-xl-block col-xl-3 right-wrapper">
                  <div class="row">
                    <div class="col-md-12 grid-margin">
                      <div class="card rounded">
                        <div class="card-header">
                          <div class="d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0 text-dark fw-bolder">
                              Offers{" "}
                            </h6>
                            <div class="dropdown d-none">
                              <button
                                class="btn p-0"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-more-horizontal icon-lg text-muted pb-3px"
                                >
                                  <circle cx="12" cy="12" r="1"></circle>
                                  <circle cx="19" cy="12" r="1"></circle>
                                  <circle cx="5" cy="12" r="1"></circle>
                                </svg>
                              </button>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a
                                  class="dropdown-item d-flex align-items-center"
                                  href="#"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-edit-2 icon-sm mr-2"
                                  >
                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                  </svg>{" "}
                                  <span class="">Edit</span>
                                </a>
                                <a
                                  class="dropdown-item d-flex align-items-center"
                                  href="#"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-eye icon-sm mr-2"
                                  >
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                  </svg>{" "}
                                  <span class="">View all</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card-body">
                          <div class="">
                            <div class="row">
                              <img
                                src={MechanicalOffer}
                                alt="Offers"
                                className="w-100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab.Container>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) =>
    OrderAction.createServiceQuote(params, dispatch),
  getUserProfile: (params) => UserAction.getUserProfile(params, dispatch),
  updateUserVehicle: (params) => UserAction.updateUserVehicle(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(MobileView);
