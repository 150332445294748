import { createGlobalStyle } from 'styled-components';
import 'jquery-ui';

export const GlobalStyle = createGlobalStyle`
    .isHidden {
        display: none !important;
    }
    .noInternetConnection{
        &.modal-dialog {
            max-width: 430px; 
        }
        .modal-content {
            background: none;
            border: none;
            .modal-body {
                padding: 35px 20px!important;
                font-size: 20px;
                text-align: center;
                color: #000;
                background: #FFF;
                border-radius: 2px;
                img.noConnectionImg {
                    width: 65px;
                    margin-bottom: 15px;
                }
                b {
                    text-transform: uppercase;
                    font-weight: 600;
                    color: #353639;
                    display: block;
                    margin-bottom: 10px;
                }
                small {
                    color: #7C7E88;
                }
            }
        }
    }
`;
export default GlobalStyle;
