import React from "react";
import { Col, Row } from "react-bootstrap";
import "./index.scss";
import { Fleet1, Fleet2, Fleet3, Fleet4, Fleet5, Fleet6, Fleet7, Fleet8 } from "../../../../../../constants/ImageConstants";

const TrustedCompany = (props) => {
  return (
    <Row>
      <Col className="auto-container">
        <div className="sec-title centered">
          <h2 className="text-capitalize">Trusted by 200+ companies internationally</h2>
        </div>
        <div className="d-flex justify-content-center mb-5 col">
          <div className="col-lg-8 col-md-10 col-sm-12">
            <div className="row">
              <div className="row mb-4">
                <div className="col-lg-3 col-md-4 col-sm-6 text-center my-auto">
                  <div className="col-12">
                    <img src={Fleet1} alt="" className="w-75" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 text-center my-auto">
                  <div className="col-12">
                    <img src={Fleet5} alt="" className="w-100" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 text-center my-auto">
                  <div className="col-12">
                    <img src={Fleet7} alt="" className="w-100" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 text-center my-auto">
                  <div className="col-12">
                    <img src={Fleet2} alt="" className="w-75" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6 text-center">
                  <div className="col-12">
                    <img src={Fleet6} alt="" className="w-75" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 text-center my-auto">
                  <div className="col-12">
                    <img src={Fleet3} alt="" className="w-100" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 text-center">
                  <div className="col-12">
                    <img src={Fleet4} alt="" className="w-75" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 text-center my-auto">
                  <div className="col-12">
                    <img src={Fleet8} alt="" className="w-75" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default TrustedCompany;
