import { DISCOUNTS } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    discounts: {},
  },
  action = {}
) => {
  switch (action.type) {
    case DISCOUNTS:
     window.sessionStorage.setItem("discounts", JSON.stringify(action.payload));
      return { ...state, discounts: action.payload };
    default:
      return state;
  }
};
