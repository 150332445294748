import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { useNavigate } from "react-router-dom";

const HowVcHelp = ({ isMerchant = false }) => {
  // const navigate = useNavigate();
  // const customNavigation = () => {
  //   if (isMerchant) {
  //     navigate("/claim");
  //   } else {
  //     navigate("/makeclaim");
  //   }
  // };
  return (
    <Row>
      <Col>
        <div className="auto-container">
          <div className="upper-section">
            <div className="row clearfix">
              <div className="content-column col-lg-12 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title text-center">
                    <div className="title">Simplifying Cashless Car Insurance Claims with VehicleCare DCX Service</div>
                    <h2 className="">How VehicleCare can help</h2>
                  </div>
                  <div className="text">
                    At VehicleCare, we care and understand the hassle and stress associated with car insurance claims. That's why we've introduced our cutting-edge DCX, Digital Claims Experience, which is a service aimed at revolutionising and simplifying the entire car insurance claim process.
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default HowVcHelp;
