import { preLoginApiRequest } from "../../server";
import { BRAND_LIST, MODEL_LIST } from "../types";

// eslint-disable-next-line import/prefer-default-export
const getBrandNames = (params, dispatch) => {
  preLoginApiRequest(params, (responseData) => {
    dispatch({ type: BRAND_LIST, payload: responseData.data });
  });
};
const getModelNames = (params, dispatch) => {
  preLoginApiRequest(params, (responseData) => {
    dispatch({ type: MODEL_LIST, payload: responseData.data });
  });
};
export { getBrandNames, getModelNames };
