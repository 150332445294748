import React from "react";

const PersonalProfile = (props) => {
  const { profileData } = props;
  const getUserVehicle = () => {
    if (profileData && profileData.vehicles.length) {
      const defaultVehicle = profileData.vehicles.filter(
        (e) => e.is_prefered === "1"
      );
      if (defaultVehicle.length)
        return `${defaultVehicle[0].brand_name} ${defaultVehicle[0].model_name} ${defaultVehicle[0].variant_name}`;
    }
    return "-- Not Available --";
  };
  const getUserAddress = () => {
    if (profileData && profileData.address.length) {
      return "User Address";
    }
    return "-- Not Available --";
  };
  return (
    <div className="card">
      <div className="card-body">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="row py-2" style={{ width: '50vw'}}>
            <div className="col-sm-5">
              <p className="mb-0 text-danger fw-bolder">Full Name</p>
            </div>

            <div className="col-sm-7">
              <p className="text-muted mb-0">
                {profileData?.first_name} {profileData?.last_name}
              </p>
            </div>
          </div>

          <div className="row py-2" style={{ width: '50vw'}}>
            <div className="col-sm-5">
              <p className="mb-0 text-danger fw-bolder">Email</p>
            </div>

            <div className="col-sm-7">
              <p className="text-muted mb-0">{profileData?.email}</p>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="row py-2" style={{ width: '50vw'}}>
            <div className="col-sm-5">
              <p className="mb-0 text-danger fw-bolder">Phone</p>
            </div>

            <div className="col-sm-7">
              <p className="text-muted mb-0">+91{profileData?.phone}</p>
            </div>
          </div>

          <div className="row py-2" style={{ width: '50vw'}}>
            <div className="col-sm-5">
              <p className="mb-0 text-danger fw-bolder">Default Vehicle</p>
            </div>

            <div className="col-sm-7">
              <p className="text-muted mb-0">{getUserVehicle()}</p>
            </div>
          </div>
        </div>

        <div className="row py-2">
          <div className="col-sm-5">
            <p className="mb-0 text-danger fw-bolder">
              Default Address
            </p>
          </div>

          <div className="col-sm-7">
            <p className="text-muted mb-0">{getUserAddress()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalProfile;
