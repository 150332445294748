import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function ScrollToTop({ ...rest }) {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    // If the last character of the url is '/'
    if (pathname.match("/.*/$")) {
      navigate(pathname.replace(/\/+$/, ""));
    }
  }, [navigate, pathname]);

  return null;
}
