import React from "react";
import { Col, Row } from "react-bootstrap";
import "./index.scss";
import { GenuineParts, HasselFree, HonestPricing, Transparent } from "../../../../../constants/ImageConstants";
import Toast from 'react-bootstrap/Toast';

const WhyChooseVc = () => {
  return (
    <Row>
      <Col>
        <div className="auto-container">
          <div className="d-flex justify-content-center col">
            <Col xs={12} lg={11}>
              
              <Row className="mx-auto">

                <Col xs={12} lg={3} className="text-center mb-1">
                  <Toast className="border-0">
                    <Toast.Header closeButton={false}>
                      <Row className="align-items-center">
                        <Col xs={12}><img className="mx-auto w-25" src={GenuineParts} alt="Genuine Parts" /></Col>
                       
                      </Row>
                    </Toast.Header>
                    <Toast.Body className="bg-light">
                      <Col xs={12}><p className="me-auto mb-1 text-dark"><b>Genuine Parts</b></p>
                        <p className="lh-sm mb-1">Top-notch quality with OEM/OES spares only.</p>
                      </Col>
                    </Toast.Body>
                  </Toast>
                </Col>

                <Col xs={12} lg={3} className="text-center mb-1">
                  <Toast className="border-0">
                    <Toast.Header closeButton={false}>
                      <Row className="align-items-center">
                        <Col xs={12}><img className="mx-auto w-25" src={Transparent} alt="Transparency" /></Col>
                      </Row>
                    </Toast.Header>
                    <Toast.Body className="bg-light">
                      <Col xs={12}><p className="me-auto mb-1 text-dark"><b>Transparent</b></p>
                        <p className="lh-sm mb-1">24x7 assistance and live status updates.</p>
                      </Col>
                    </Toast.Body>
                  </Toast>
                </Col>

                <Col xs={12} lg={3} className="text-center mb-1">
                  <Toast className="border-0">
                    <Toast.Header closeButton={false}>
                      <Row className="align-items-center">
                        <Col xs={12}><img className="mx-auto w-25" src={HasselFree} alt="Hassel-Free" /></Col>
                      </Row>
                    </Toast.Header>
                    <Toast.Body className="bg-light">
                      <Col xs={12}><p className="me-auto mb-1 text-dark"><b>Hassel-Free</b></p>
                        <p className="lh-sm mb-1">Services delivered through bespoke solutions.</p>
                      </Col>
                    </Toast.Body>
                  </Toast>
                </Col>

                <Col xs={12} lg={3} className="text-center mb-1">
                  <Toast className="border-0">
                    <Toast.Header closeButton={false}>
                      <Row className="align-items-center">
                        <Col xs={12}><img className="mx-auto w-25" src={HonestPricing} alt="Honest Pricing" /></Col>
                      </Row>
                    </Toast.Header>
                    <Toast.Body className="bg-light">
                      <Col xs={12}><p className="me-auto mb-1 text-dark"><b> Honest Pricing</b></p>
                        <p className="lh-sm mb-1">Best quote in the industry for your car service.</p>
                      </Col>
                    </Toast.Body>
                  </Toast>
                </Col>

              </Row>
            </Col>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default WhyChooseVc;
