import React, { useEffect, useState } from "react";
import { Row, Form, Col } from "react-bootstrap";
import { connect } from "react-redux";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { setDefaultLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { addDays } from "date-fns";
import { isMobile } from "react-device-detect";
import moment from "moment";
import Cart from "../Cart";
import CustomModal from "../../../../../components/common/CustomModal";
import { OrderAction } from "../../../../../store/action";
import { CART_LIST, SERVICE_BOOKING, SHOW_HIDE_LOADER } from "../../../../../store/types";
import { Successful } from "../../../../../constants/ImageConstants";
import { auth, generateRecaptcha } from "../../../../../server/firebase";
import { signInWithPhoneNumber, signOut } from "firebase/auth";
// registerLocale("en", enGB);
setDefaultLocale("en");

const ErrorOutput = (props) => {
  let name = props.name;
  let inputValue = props.case;
  if (name === "userName") {
    if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
      return <span className="text-danger">Letters only</span>;
    }
    return <span></span>;
  }
  if (name === "userPhoneNo") {
    if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
      return <span className="text-danger">Numbers only</span>;
    }
    return <span></span>;
  }
  if (name === "userEmail") {
    if (!inputValue?.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) && inputValue?.length > 0) {
      return <span className="text-danger">Invalid email address</span>;
    }
    return <span></span>;
  }
  if (name === "car_registration") {
    if (!inputValue?.match(/^[A-Z0-9._%+-]+$/) && inputValue?.length > 0) {
      return <span className="text-danger">Invalid Registration Number</span>;
    }
    return <span></span>;
  }
  if (name === "userState") {
    if (!inputValue?.match(/^[a-zA-Z]+$/) && inputValue?.length > 0) {
      return <span className="text-danger">Letters only</span>;
    }
    return <span></span>;
  }
  if (name === "userCity") {
    if (!inputValue?.match(/^[a-z A-Z]+$/) && inputValue?.length > 0) {
      return <span className="text-danger">Letters only</span>;
    }
    return <span></span>;
  }
  if (name === "userPincode") {
    if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
      return <span className="text-danger">Numbers only</span>;
    }
    return <span></span>;
  }
  return <></>;
};

const MerchantBook = (props) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState({
    userName: "",
    userEmail: "",
    userPhoneNo: "",
    car_registration: "",
    userAddress: "",
    userState: "",
    userCity: "",
    userPincode: "",
    carNumber: "",
    carYear: "",
    visitType: "1",
    paymentMode: "ONLINE",
  });
  const update = (e) => {
    if (e.target.name === "carNumber" && e.target.value) {
      e.target.value = e.target.value.toUpperCase();
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const [show, setShow] = useState(false);
  const [isCaptchaVerifier, setIsCaptchaVerifier] = useState(false);
  const [optCode, setOptCode] = useState("");
  const [optVerified, setOptVerified] = useState(false);
  const [final, setfinal] = useState("");
  const [cartShow, setCartShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const { customDispatch, vehicleData, serviceBookingData, cartList: cartListData } = props;
  const { brand, model, variant } = vehicleData;
  const { cartList } = cartListData;
  const { serviceBookingResponse } = serviceBookingData;
  const navigate = useNavigate();
  // const handleClose = () => navigate(getNavigateUrl("/"));
  const handleCartClose = () => setCartShow(false);

  const submitData = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      // sendOtp();
      serviceCall();
    }
    setValidated(true);
  };
  const serviceCall = () => {
    const customParams = window.sessionStorage.getItem("custom_params");
    const apiParam = {
      task: "createCustomServiceBooking",
      user_name: data.userName,
      email: data.userEmail,
      phone: data.userPhoneNo,
      brand_id: brand.id,
      model_id: model.id,
      variant_id: variant.id.toString(),
      car_reg: data.car_registration,
      visit_type: data.visitType,
      service_ids: cartList,
      payment_mode: "CASH",
      visit_date: moment(selectedDate).format("DD MMMM, YYYY"),
      user_address: { address: data.userAddress, city: data.userCity, state: data.userState, pincode: data.userPincode },
      coupon_code: "",
      source: "Vc Web-hook",
    };
    if (customParams) {
      const merchantParams = JSON.parse(customParams);
      let sourceName = "";
      let sourceId = "";
      let medium = "";
      merchantParams.forEach((element) => {
        if (element.name === "merchant_name") {
          sourceName = element.value;
        }
        if (element.name === "merchant_id") {
          sourceId = element.value;
        }
        if (element.name === "utm_source") {
          sourceName = element.value;
        }
        if (element.name === "utm_medium") {
          medium = element.value;
        }
        if (element.name === "utm_campaign") {
          sourceId = element.value;
        }
      });
      if (sourceId) {
        apiParam.source = `${sourceId}-`;
      }
      if (sourceName) {
        apiParam.source += `${sourceName}`;
      }
      if (medium) {
        apiParam.source += `(${medium})`;
      }
    }
    props.createCustomServiceBooking(apiParam);
  };
  // eslint-disable-next-line no-unused-vars
  const sendOtp = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    const mynumber = `+91${data.userPhoneNo}`;
    if (!isCaptchaVerifier) generateRecaptcha(setIsCaptchaVerifier);
    const verify = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, mynumber, verify)
      .then((result) => {
        setfinal(result);
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        setShow(true);
      })
      .catch((err) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(err);
      });
  };
  const onCodeSubmit = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    final
      .confirm(optCode)
      .then((result) => {
        signOut(auth);
        serviceCall();
      })
      .catch((error) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(error);
      });
  };
  const GetModalContent = () => {
    return !optVerified ? (
      <Row>
        <Col id="otpCodeContainer" className="px-4 text-center">
          <div className="sec-title mb-3">
            <h2 className="font-weight-bold">
              <font size="4">Enter OTP Recieved on {data.userPhoneNo} </font>{" "}
            </h2>
          </div>

          <div className="styled-form login-form">
            <div className="form-group">
              <input type="text" className="form-control" maxLength={6} placeholder="Enter OTP Code" value={optCode} onChange={(event) => setOptCode(event.target.value)} />
            </div>
          </div>
          <button onClick={onCodeSubmit} className="theme-btn btn-style-seven btn-sm py-2 mt-2 mb-4 px-4">
            <span className="txt">Submit</span>
          </button>
        </Col>
      </Row>
    ) : (
      <Row>
        <div className="col-12">
          <div className="row h-100">
            <div className="col-4 text-center">
              <img src={Successful} alt="" className="w-100" />
            </div>

            <div className="col-8 text-left my-auto">
              <h4>
                <b>
                  <font color="black">Congratulations!!</font>
                </b>
              </h4>
              <h5 style={{ color: "#40c057" }}>
                <b>Your Booking is successfully Created.</b>
              </h5>
              <h6>Our Executive will shortly join on your service journey.</h6>
              <button type="button" className="theme-btn btn-style-nine my-3 mx-5" onClick={() => initFunc()}>
                <span className="txt">Home</span>
              </button>
            </div>
          </div>
        </div>
      </Row>
    );
  };
  const CartModalContent = () => {
    return (
      <Row>
        <div className="sidebar-side left-sidebar col-lg-4 col-md-12 col-sm-12">
          <Cart hideOrderBtn={true} />
        </div>
      </Row>
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initFunc = () => {
    // eslint-disable-next-line no-undef
    AndroidFunction.saveBookingData(data);
  };
  const handleClose = () => {
    optVerified ? initFunc() : setShow(false);
  };
  useEffect(() => {
    if (serviceBookingResponse !== null) {
      setShow(true);
      setOptVerified(true);
      customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
      customDispatch({ type: CART_LIST, payload: [] });
      customDispatch({ type: SERVICE_BOOKING, payload: null });
    }
  }, [serviceBookingResponse, navigate, customDispatch, data, initFunc]);
  return (
    <section className="appointment-page-section" style={{ background: "#F5F1EE" }}>
      <div className="auto-container custom-appointment-container">
        {isMobile ? <i className="fa fa-shopping-cart text-danger custom-mobile-cart pull-right" onClick={() => setCartShow(true)} aria-hidden="true"></i> : ""}
        <div className="sec-title centered">
          <div className="title">Appointment</div>
          <h1>Service Booking</h1>
        </div>

        <div className="row clearfix">
          <div className="right-sidebar col-lg-8 col-md-12 col-sm-12">
            <div className="lower-section">
              <div className="appointment-form login-form">
                <Form noValidate validated={validated} onSubmit={submitData}>
                  <div className="row clearfix">
                    <div className="form-group col-lg-4 col-md-6 col-sm-12">
                      <Form.Label>Name</Form.Label>
                      <input type="text" name="userName" aria-describedby="inputGroupPrepend" required onChange={update} value={data.userName} />
                      <Form.Control.Feedback type="invalid">Please enter your name</Form.Control.Feedback>
                      <ErrorOutput case={data.userName} name={"userName"} />
                    </div>

                    <div className="form-group col-lg-4 col-md-6 col-sm-12">
                      <Form.Label>Email </Form.Label>
                      <input type="email" name="userEmail" aria-describedby="inputGroupPrepend" required onChange={update} value={data.userEmail} />
                      <Form.Control.Feedback type="invalid">Please enter your email</Form.Control.Feedback>
                      <ErrorOutput case={data.userEmail} name={"userEmail"} />
                    </div>

                    <div className="form-group col-lg-4 col-md-6 col-sm-12">
                      <Form.Label>Phone Number</Form.Label>
                      <input type="text" maxLength={10} name="userPhoneNo" aria-describedby="inputGroupPrepend" required onChange={update} value={data.userPhoneNo} />
                      <Form.Control.Feedback type="invalid">Please enter your phone number</Form.Control.Feedback>
                      <ErrorOutput case={data.userPhoneNo} name={"userPhoneNo"} />
                    </div>

                    <div className="form-group col-lg-4 col-md-6 col-sm-12">
                      <Form.Label>Registration Number</Form.Label>
                      <input type="text" name="car_registration" required onChange={update} value={data.car_registration} />
                      <Form.Control.Feedback type="invalid">Please enter your car registration number</Form.Control.Feedback>
                      <ErrorOutput case={data.car_registration} name={"car_registration"} />
                    </div>

                    <div className="form-group col-lg-8 col-md-8 col-sm-12">
                      <Form.Label>Address</Form.Label>
                      <Form.Control type="text" name="userAddress" placeholder="Enter Your Address" aria-describedby="inputGroupPrepend" required onChange={update} value={data.userAddress} />
                      <Form.Control.Feedback type="invalid">Please enter your Address.</Form.Control.Feedback>
                      <ErrorOutput case={data.userAddress} name={"userAddress"} />
                    </div>

                    <div className="form-group col-lg-4 col-md-4 col-sm-12">
                      <Form.Label>State</Form.Label>
                      <Form.Control type="text" name="userState" placeholder="Enter State" aria-describedby="inputGroupPrepend" required onChange={update} value={data.userState} />
                      <Form.Control.Feedback type="invalid">Please enter your State</Form.Control.Feedback>
                      <ErrorOutput case={data.userState} name={"userState"} />
                    </div>

                    <div className="form-group col-lg-4 col-md-4 col-sm-12">
                      <Form.Label>City</Form.Label>
                      <Form.Control type="text" name="userCity" placeholder="Type Your City here" aria-describedby="inputGroupPrepend" required onChange={update} value={data.userCity} />
                      <Form.Control.Feedback type="invalid">Please enter your City</Form.Control.Feedback>
                      <ErrorOutput case={data.userCity} name={"userCity"} />
                    </div>

                    <div className="form-group col-lg-4 col-md-4 col-sm-12">
                      <Form.Label>Zip</Form.Label>
                      <Form.Control
                        type="text"
                        name="userPincode"
                        placeholder="Enter Your Pincode here"
                        aria-describedby="inputGroupPrepend"
                        required
                        maxLength={6}
                        onChange={update}
                        value={data.userPincode}
                      />
                      <Form.Control.Feedback type="invalid">Please enter your Pincode</Form.Control.Feedback>
                      <ErrorOutput case={data.userPincode} name={"userPincode"} />
                    </div>

                    <div className="form-group col-lg-4 col-md-4 col-sm-12">
                      <Form.Label>Preferred Date</Form.Label>
                      <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        // showTimeSelect
                        minDate={new Date()}
                        maxDate={addDays(new Date(), 7)}
                        dateFormat="MMMM d, yyyy"
                      />
                    </div>

                    <div className="form-group col-lg-8 col-md-8 col-sm-12">
                      <Form.Label>Preferred Mode</Form.Label>
                      <div className="radio-box">
                        <input type="radio" name="visitType" value="1" checked={data.visitType === "1"} onChange={update} id="drop" />
                        <Form.Label className="bg-transparent" for="drop">
                          {" "}
                          &nbsp;&nbsp;I will drop my car myself
                        </Form.Label>
                      </div>
                      <div className="radio-box bg-transparent">
                        <input type="radio" name="visitType" value="2" checked={data.visitType === "2"} onChange={update} id="pickup" />
                        <Form.Label className="bg-transparent" for="pickup">
                          {" "}
                          &nbsp;&nbsp;I want my car to be picked up
                        </Form.Label>
                      </div>
                    </div>
                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                      <button disabled={!cartList.length} className="theme-btn btn-style-seven" type="submit" name="submit-form">
                        Submit Now
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="custom-captcha-verifier"></div>
      <CustomModal show={show} modalSize={optVerified ? "lg" : "md"} content={<GetModalContent />} isCloseButton={true} handleClose={handleClose} />
      <CustomModal show={cartShow} title="Cart" content={<CartModalContent />} isCloseButton={true} handleClose={handleCartClose} />
    </section>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.cartList = state.default.cartListReducer;
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createCustomServiceBooking: (params) => OrderAction.createCustomServiceBooking(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(MerchantBook);
