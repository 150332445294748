import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Pattern1, Pattern2 } from "../../../../../constants/ImageConstants";
import TrustedCompany from "./trustedCompany";
import ThreePillars from "../../../../../components/common/threePillars";
import BannerSection from "./BannerSection";
import BenifitDigitalRepairManagement from "../../../../../components/common/benifitDigitalRepairManagement";
import NewWayHandleDamageRepair from "../../../../../components/common/newWayHandleDamageRepair";
import Header from "../../../layout/NavigationBar/Header";
import Footer from "../../../layout/NavigationBar/Footer";
import BlogMultiImageCarousel from "../../../../../components/common/blogMultiImageCarouselMobile";
import { Helmet } from "react-helmet";

const Fleet = (props) => {
  const source = props.source || "Fleet";
  return (
    <>

      <Helmet>
        <title>Digital workshop for your fleet - VehicleCare</title>
        <meta
          name="description"
          content="We provide a one-stop solution for supporting the car repair and maintenance needs of your fleet management."
        />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        <Col>
          {/* Banner Section */}
          <section className="banner-section-three style-two">
            {/* <div className="main-slider-carousel owl-carousel owl-theme"> */}
            <div className="slide">
              <BannerSection source={source} />
            </div>
            {/* </div> */}
          </section>

          {/* Trusted Company */}
          <section className="about-section pt-0">
            <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
            <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>
            <TrustedCompany />
          </section>

          {/* Innovative Approach to Car Damage Repair */}
          <section className="services-section-four" style={{ backgroundColor: "#fbfbfb" }}>
            <NewWayHandleDamageRepair />
          </section>

          {/* The Future of Cohesive Repair Handling Solution */}
          <section className="services-section-four mt-0">
            <ThreePillars />
          </section>

          {/* Benefit from digital repair management */}
          <section className="services-section-four mt-0 pb-2" style={{ background: "#fbfbfb" }}>
            <BenifitDigitalRepairManagement />
          </section>

          {/* Blog Section */}
          <div className="blog-section">
            <BlogMultiImageCarousel />
          </div>

          {/* <div className="acordian-section pt-5" style={{ backgroundImage: `url(${Citybg})`, backgroundSize: `inherit`, backgroundColor: `#f5f1ee` }}>
            <CityList />
          </div> */}
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default Fleet;
