import React, { useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { connect } from "react-redux";
import { CART_LIST } from "../../../../../store/types";
import { ServicePackagesListAction } from "../../../../../store/action";
import VehicleSelector from "../../../../../components/common/vehicleSelectorMobile";

const Cart = (props) => {
  const prevCountRef = useRef();
  const claim = props.claim;
  const hideOrderBtn = props.hideOrderBtn || false;
  const data = props.vehicleData;
  const cartList = props.cartList;
  const serviceListData = props.servicePackageList.servicePackageList;
  const servicePackageData = serviceListData.filter((data) => cartList.indexOf(data.id) > -1);
  const navigate = useNavigate();
  const makeVehicleChange = () => {
    document.getElementById("custom-vehicle-selector-brand").click();
  };
  const navigateToAppointment = () => {
    window.sessionStorage.setItem("vehicleDetails", JSON.stringify(data));
    navigate("/merchantbook");
  };

  const removeCartElement = (index) => {
    const updatedCartList = cartList.filter((elemn) => elemn !== index);
    props.updateCart({ type: CART_LIST, payload: updatedCartList });
  };

  const getTotalPrice = () => {
    let price = 0;
    servicePackageData.forEach((element) => {
      price += parseInt(element.price);
    });
    return price;
  };
  const getCartList = () => {
    const list = [];
    if (!cartList.length) {
      return <li className="text-danger">"No Items in Cart"</li>;
    }
    servicePackageData.map((items) => {
      list.push(
        <>
          <li key={items.id}>
            {hideOrderBtn ? "" : <span className="flaticon-multiply text-danger" role="button" onClick={() => removeCartElement(items.id)}></span>}
            {items.name}
            <span className="pe-3">Rs. {items.price}</span>
          </li>
        </>
      );
      return true;
    });
    return list;
  };
  useEffect(() => {
    if (prevCountRef && prevCountRef.current !== data?.variant?.id && !servicePackageData.length) {
      const params = {
        task: "getServiceData",
        variant_id: data.variant.id,
      };
      props.getServiceData(params);
      prevCountRef.current = data.variant.id;
    }
  }, [props, data, servicePackageData]);
  return (
    <>
      <Row>
        {claim ? <></> : hideOrderBtn ? <></> : <div className="sidebar-widget search-box"></div>}
        <div className="cart-section">
          <div className="car-model-box" style={{ backgroundColor: "white" }}>
            <div className="group-title mb-0">
              <h2>Your Cart</h2>
            </div>

            <div className="box-inner">
              <div className="content mb-2">
                <div className="model-icon">
                  <img
                    src={data.model?.icon}
                    alt="NoImage"
                    style={{
                      borderRadius: "50%",
                      width: "100px",
                      height: "60px",
                      cursor: "pointer",
                      verticalAlign: "middle",
                      overflowClipBox: "content-box",
                      overflow: "clip",
                      padding: "none",
                    }}
                  />
                </div>
                <h5 className="text-dark">{data.brand?.name}</h5>
                <b className="text-dark">{data.model?.name}</b> {data.variant?.variant}
                {!hideOrderBtn ? (
                  <>
                    <button className="pull-right tag bg-transparent text" onClick={makeVehicleChange}>
                      Change
                    </button>
                    <VehicleSelector hideBar={true} navigate={true} />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {claim ? (
            <></>
          ) : (
            <div className="shop-order-box">
              <ul className="order-list">
                {getCartList()}

                <li className="total cart-heading">
                  <b>
                    <font color="#ed1c24">TOTAL</font>
                  </b>
                  <span className="dark">Rs. {getTotalPrice()}</span>
                </li>
              </ul>
              {hideOrderBtn ? (
                <></>
              ) : (
                <div className="place-order">
                  <button type="button" disabled={!cartList.length} className="theme-btn btn-style-nine btn-sm px-3 py-2" onClick={() => navigateToAppointment()}>
                    <span className="txt">Place Order</span>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </Row>
    </>
  );
};
const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.cartList = state.default.cartListReducer.cartList;
  stateObj.passingData = state.default.vehicleReducer;
  stateObj.servicePackageList = state.default.servicePackagesListReducer;
  stateObj.serviceList = state.default.serviceListReducer;
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.userData = state.default.userReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  updateCart: (params) => dispatch(params),
  getServiceData: (params) => ServicePackagesListAction.getServiceData(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
