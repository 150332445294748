/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { List } from "react-bootstrap-icons";
import {
  RedLogo,
  AppStoreBlack,
  PlayStore,
} from "../../../../../constants/ImageConstants";
import "./index.scss";
import { useEffect } from "react";
import { connect } from "react-redux";
import { CityListAction, VehicleAction } from "../../../../../store/action";
import Choosecity from "../../../../../components/common/ChooseCityMobile";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import CustomModal from "../../../../../components/common/CustomModal";
import {
  FAQ_CITY_ID,
  REMOVE_VEHICLE,
  SET_CITY_NAME,
  Sign_Out,
} from "../../../../../store/types";
import {
  UserLogin,
  UserSignup,
  UserProfile,
} from "../../../screens/UserSections";
import { getNavigateUrl } from "../../../../../utils/customHelper";
import { isMobile, isIOS, isAndroid } from "react-device-detect";
import { colors } from "@mui/material";

const Header = ({
  getBrandNames,
  getCityList,
  updateReducer,
  setSheet,
  selected_vehicle,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const [showCityClose, setShowCityClose] = useState(true);
  const [showCityPop, setShowCityPop] = useState(false);
  const isNavigate = props?.isNavigate || false;
  const isStaticHeader = props.isStatic;
  const hideHeaderApp = props.hideHeaderApp;
  const cityList = props.cityListReducer.cityList;
  const selectedCityName = props.cityListReducer.selectedCity;
  const handleClose = () => {
    setShowCityClose(true);
    setShowCityPop(false);
  };
  const brandList = props.vehicleReducer.brandList;
  const { is_user_logged_in, user_profile, is_new_user } = props.userReducer;

  useEffect(() => {
    if (!brandList.length) {
      const params = { task: "getCardBrands" };
      getBrandNames(params);
    }
    if (!cityList.length) {
      const params1 = { task: "getCityList" };
      getCityList(params1);
    }
  }, [brandList, cityList, getBrandNames, getCityList]);
  useEffect(() => {
    if (!is_new_user && user_profile) {
      setShow(false);
    }
  }, [is_new_user, user_profile]);

  const urlParam = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (isStaticHeader) return false;
    if (!urlParam.city) {
      navigate(
        `/${selectedCityName.toLowerCase()}${window.location.pathname}`.replace(
          /\/+$/,
          ""
        )
      );
    } else {
      if (cityList.length) {
        const exist = cityList.filter(
          (city) =>
            city.name.toLowerCase() ===
            urlParam.city.replace(/-/g, " ").toLowerCase()
        );
        if (!exist.length) {
          // setShowCityClose(false);
          // document.getElementById("citySelector").click();
          navigate("/notfound");
        } else {
          if (urlParam.city.replace(/-/g, " ") !== selectedCityName) {
            window.sessionStorage.setItem(
              "selectedCity",
              JSON.stringify(exist[0])
            );
            updateReducer({ type: FAQ_CITY_ID, payload: exist[0].id });
            updateReducer({ type: SET_CITY_NAME, payload: exist[0].name });
            setShowCityClose(true);
          }
        }
      }
    }
  }, [
    urlParam,
    selectedCityName,
    navigate,
    cityList,
    updateReducer,
    isStaticHeader,
  ]);
  const expand = "md";

  const GetUserPopConent = () => {
    console.log("rendering");
    if (!is_user_logged_in) return <UserLogin />;

    if (is_new_user) return <UserSignup />;
    if (!is_new_user && user_profile) return <UserProfile />;
    return "";
  };
  const GetModalTitle = () => {
    if (!is_user_logged_in) return "User Login";
    if (is_new_user) return "User Signup";
    if (!is_new_user && user_profile) return "User Profile";
    return "";
  };
  const signoutOnClose = () => {
    const confirm = window.confirm("You will be logged out");
    if (confirm) {
      updateReducer({ type: Sign_Out });
      setShow(false);
    }
  };
  const modalHandleCloseFunc = () => {
    if (!is_user_logged_in) {
      setShow(false);
      isNavigate && navigate(getNavigateUrl("/"));
    } else if (is_new_user) {
      signoutOnClose();
    } else setShow(false);
  };
  const isActive = (path) => {
    return window.location.pathname.indexOf(`${path}`) > -1;
  };
  // const setMajorBrand = (path) => {
  //   updateReducer({ type: REMOVE_VEHICLE });
  //   navigate(getNavigateUrl(path));
  // };

  const setMajorBrand1 = (path) => {
    updateReducer({ type: REMOVE_VEHICLE });
    // navigate(getNavigateUrl(path));
  };
  const logoutAction = () => {
    const isLogout = window.confirm("Are you want to logout ?");
    if (isLogout) {
      props?.customDispatch({ type: Sign_Out });
      window.location = "/";
    }
  };
  function loginLogout() {
    if (is_user_logged_in) {
      logoutAction();
    } else {
      setShow(true);
    }
  }
  return (
    <>
      {isMobile && (
        <div className="sidebarmenu" style={{ width: "70vw" }}>
          {/* <div className="toggle">
            <a href="#" className="burger js-menu-toggle" data-toggle="collapse" data-target="#main-navbar">
            <span></span>
            </a>
          </div> */}

          <div className="side-inner">
            <Link to={'/'}>
              <img
                src={RedLogo}
                alt="VehicleCare Logo"
                className="img-fluid px-2"
                style={{ width: "50%" }}
              />
            </Link>

            <hr />

            {/* <div className="profile">
            <img src="/favicon.svg" alt="Image" className="img-fluid"/>
            <h3 className="name">Craig David</h3>
            <span className="country">Web Designer</span>
            </div> */}

            <div className="nav-menu">
              <ul>
                <li>
                  <Link
                    to={getNavigateUrl("/car-service")}
                    className="sidebar-link"
                  >
                    Car Service
                  </Link>
                </li>
                <li>
                  <Link
                    to={getNavigateUrl("/dent-paint")}
                    className="sidebar-link"
                  >
                    Denting & Painting
                  </Link>
                </li>

                <li>
                  <Link to="/car-insurance-claim" className="sidebar-link">
                    {" "}
                    Insurance Claim
                  </Link>
                </li>

                {/* <li>
                  <Link to={"/solutions"} className="sidebar-link">
                    {" "}
                    Solutions
                  </Link>
                </li> */}

                {/* <li className="accordion">
                  <a href="#" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" className="collapsible">
                    Segment
                  </a>
                  <div id="collapseOne" className="collapse" aria-labelledby="headingOne">
                    <div>
                      <ul>
                        <li>
                          <Link to={"/segments/fleet"} className="sidebar-link">
                            {" "}
                            Fleet
                          </Link>
                        </li>
                        <li>
                          <Link to={"/segments/lease"} className="sidebar-link">
                            Lease
                          </Link>
                        </li>
                        <li>
                          <Link to={"/segments/broker"} className="sidebar-link">
                            Broker
                          </Link>
                        </li>
                        <li>
                          <Link to={"/segments/insurer"} className="sidebar-link">
                            Insurer
                          </Link>
                        </li>
                        <li>
                          <Link to={"/segments/oem"} className="sidebar-link">
                            OEM
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li> */}
                <li className="accordion">
                  <a
                    href="#"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                    className="collapsible"
                  >
                    Major Brands
                  </a>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                  >
                    <div>
                      <ul>
                        <li>
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/maruti-suzuki-baleno-petrol`}
                            className={
                              isActive(
                                "/services/periodic-car-services/maruti-suzuki-baleno-petrol"
                              )
                                ? "active pb-0 pt-0"
                                : "inactive pb-0 pt-0"
                            }
                            onClick={() => setMajorBrand1()}
                          >
                            Maruti Suzuki
                          </Link>
                        </li>

                        <li>
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/tata-altroz-petrol`}
                            onClick={() => setMajorBrand1()}
                            className={
                              isActive(
                                "/services/periodic-car-services/tata-altroz-petrol"
                              )
                                ? "active pb-0 pt-0"
                                : "inactive pb-0 pt-0"
                            }
                          >
                            Tata
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/honda-city-petrol`}
                            onClick={() => setMajorBrand1()}
                            className={
                              isActive(
                                "/services/periodic-car-services/honda-city-petrol"
                              )
                                ? "active pb-0 pt-0"
                                : "inactive pb-0 pt-0"
                            }
                          >
                            Honda
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/toyota-innova-petrol`}
                            onClick={() => setMajorBrand1()}
                            className={
                              isActive(
                                "/services/periodic-car-services/toyota-innova-petrol"
                              )
                                ? "active pb-0 pt-0"
                                : "inactive pb-0 pt-0"
                            }
                          >
                            Toyota
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/hyundai-creta-petrol`}
                            onClick={() => setMajorBrand1()}
                            className={
                              isActive(
                                "/services/periodic-car-services/hyundai-creta-petrol"
                              )
                                ? "active pb-0 pt-0"
                                : "inactive pb-0 pt-0"
                            }
                          >
                            Hyundai
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/kia-seltos-petrol`}
                            onClick={() => setMajorBrand1()}
                            className={
                              isActive(
                                "/services/periodic-car-services/kia-seltos-petrol"
                              )
                                ? "active pb-0 pt-0"
                                : "inactive pb-0 pt-0"
                            }
                          >
                            Kia
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/mg-hector-petrol`}
                            onClick={() => setMajorBrand1()}
                            className={
                              isActive(
                                "/services/periodic-car-services/mg-hector-petrol"
                              )
                                ? "active pb-0 pt-0"
                                : "inactive pb-0 pt-0"
                            }
                          >
                            MG
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/audi-q3-petrol`}
                            onClick={() => setMajorBrand1()}
                            className={
                              isActive(
                                "/services/periodic-car-services/audi-q3-petrol"
                              )
                                ? "active pb-0 pt-0"
                                : "inactive pb-0 pt-0"
                            }
                          >
                            Audi
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/mahindra-xuv-700-petrol`}
                            onClick={() => setMajorBrand1()}
                            className={
                              isActive(
                                "/services/periodic-car-services/mahindra-xuv-700-petrol"
                              )
                                ? "active pb-0 pt-0"
                                : "inactive pb-0 pt-0"
                            }
                          >
                            Mahindra
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <a href="https://vehiclecare.in/blaze">Blog</a>
                </li>

                {/* <li>
                  <Link to={"/lubricants"} className="sidebar-link">
                    Lubricant
                  </Link>
                </li> */}

                <li>
                  <Link to={"/vehicle-ai"} className="sidebar-link">
                    VehicleAI Labs
                  </Link>
                </li>

                {/* <li>
                  <a href="https://prime.vehiclecare.in">VC-Prime</a>
                </li> */}

                <li>
                  {/* <a href="#" onClick1={() => setShow(true)} className="sidebar-link">
                    Account
                  </a> */}
                  <Link onClick={loginLogout} className="sidebar-link">
                    {is_user_logged_in ? "Logout" : "Login"}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <Row className={`${isMobile && "top-header-row"}`}>
        <Col className="vc-header-profiler main-header header-style-three alternate">
          <div className="header-lower">
            <div className="row clearfix" style={{ padding: "0px" }}>
              <Navbar
                fixed="top"
                bg="light"
                expand="lg"
                key={expand}
                style={{ padding: 0 }}
              >
                {isMobile && isIOS && !hideHeaderApp && (
                  <div className="app-download-top-header white small d-flex justify-content-between align-items-center py-2">
                    <div className="app-downlaod-top-text d-flex">
                      <img
                        src="/favicon.svg"
                        className="app-downlaod-top-header-vc-logo"
                        alt="vclogofav"
                      />
                      <div
                        className=" my-auto text-left"
                        style={{ marginLeft: "10px" }}
                      >
                        {" "}
                        <b>Download App</b>
                        <br />
                        {/* <i className="fa fa-star text-warning"></i> <b>4.8</b> */}
                        <span
                          style={{
                            fontSize: "12px",
                            width: "200px",
                            display: "block",
                            marginTop: "3px",
                            color: "black",
                          }}
                        >
                          Get upto 50% discount on all services in the App.
                        </span>
                      </div>
                    </div>

                    <div className="ml-2 col-4">
                      <a
                        href="https://apps.apple.com/in/app/vehiclecare/id1634342039"
                        className="text-white"
                        rel="nofollow"
                      >
                        <img src={AppStoreBlack} alt="appStoreImg" />
                      </a>
                    </div>
                  </div>
                )}

                {isMobile && isAndroid && !hideHeaderApp && (
                  <div className="app-download-top-header white small d-flex justify-content-between align-items-center py-2">
                    <div className="app-downlaod-top-text d-flex">
                      <img
                        src="/favicon.svg"
                        className="app-downlaod-top-header-vc-logo"
                        alt="vclogofav"
                      />
                      <div
                        className=" my-auto text-left"
                        style={{ marginLeft: "10px" }}
                      >
                        {" "}
                        <b>Download App</b>
                        <br />
                        {/* <i className="fa fa-star text-warning"></i> <b>4.8</b> */}
                        <span
                          style={{
                            fontSize: "12px",
                            width: "200px",
                            display: "block",
                            marginTop: "3px",
                            color: "black",
                          }}
                        >
                          Get upto 50% discount on all services in the App.
                        </span>
                      </div>
                    </div>

                    <div className="">
                      {" "}
                      <a
                        href="https://vcapp.onelink.me/mIML/vcwebh"
                        className="text-white"
                        rel="nofollow"
                      >
                        <img
                          src={PlayStore}
                          alt="playstoreimg"
                          className="app-download-top-icon"
                        />
                      </a>
                    </div>
                  </div>
                )}

                <Container className="px-0">
                  {isMobile && (
                    <div className="toggle" style={{ marginLeft: "10px" }}>
                      {/* <a
                        href="#"
                        className="burger js-menu-toggle"
                        data-toggle="collapse"
                        data-target="#main-navbar"
                      >
                        <span></span>
                      </a> */}
                      <List
                        className="burger js-menu-toggle"
                        data-toggle="collapse"
                        data-target="#main-navbar"
                        size={30}
                      />
                    </div>
                  )}
                  {!isStaticHeader && (
                    <Navbar.Brand
                      className="logo w-10"
                      onClick={() => navigate(getNavigateUrl("/"))}
                    >
                      <div>
                        <img
                          src={RedLogo}
                          className=" w-50 rounded-0 d-none d-md-block d-lg-block"
                          alt="VehicleCare Logo"
                          title="VehicleCare Logo"
                        />
                        <img
                          src="/favicon.svg"
                          className="app-downlaod-top-header-vc-logo"
                          alt="vclogofav"
                          style={{ marginLeft: "10px" }}
                        />
                      </div>
                    </Navbar.Brand>
                  )}

                  {!isStaticHeader ? (
                    <Button
                      className="me-auto btn text-danger fw-semibold px-1"
                      id="citySelector"
                      variant="btn-outline-light"
                      onClick={() => setShowCityPop(true)}
                      style={{ fontSize: "14px" }}
                    >
                      {selectedCityName} <i className="fa fa-chevron-down"></i>
                    </Button>
                  ) : (
                    ""
                  )}
                  {!isStaticHeader && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                      onClick={() => setSheet(true)}
                    >
                      <img
                        src={
                          selected_vehicle?.icon
                            ? selected_vehicle?.icon
                            : require("../../../../../assets/images/Image_placeholder.png")
                        }
                        width={40}
                        alt="img"
                      />
                      <span
                        style={{
                          fontWeight: "bold",
                          color: colors.common.black,
                          fontSize: "10px",
                          lineHeight: "13px",
                          marginBottom: "2px",
                        }}
                      >
                        {selected_vehicle?.model_name}
                      </span>
                    </div>
                  )}

                  {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}

                  <Navbar.Collapse
                    id="basic-navbar-nav"
                    className="justify-content-end"
                  >
                    <Nav className="mr-auto">
                      <NavLink
                        to={getNavigateUrl("/car-service")}
                        className={
                          isActive("/car-service")
                            ? "active nav-link"
                            : "inactive nav-link"
                        }
                      >
                        Car Service
                      </NavLink>

                      <NavLink
                        to="/car-insurance-claim"
                        className={
                          isActive("/car-insurance-claim")
                            ? "active nav-link"
                            : "inactive nav-link"
                        }
                      >
                        Insurance Claim
                      </NavLink>

                      <NavDropdown
                        active={isActive("/segments")}
                        title="Major Brands"
                        id="basic-nav-dropdown-major-brands"
                      >
                        <p
                          className={
                            isActive(
                              "/services/periodic-car-services/maruti-suzuki-baleno-petrol"
                            )
                              ? "active mx-auto text-center"
                              : "inactive mx-auto text-center"
                          }
                        >
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/maruti-suzuki-baleno-petrol`}
                            onClick={() => setMajorBrand1()}
                            className="text-dark"
                          >
                            Maruti Suzuki
                          </Link>
                        </p>

                        <p
                          className={
                            isActive(
                              "/services/periodic-car-services/tata-altroz-petrol"
                            )
                              ? "active mx-auto text-center"
                              : "inactive mx-auto text-center"
                          }
                        >
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/tata-altroz-petrol`}
                            onClick={() => setMajorBrand1()}
                            className="text-dark"
                          >
                            Tata
                          </Link>
                        </p>

                        <p
                          className={
                            isActive(
                              "/services/periodic-car-services/honda-city-petrol"
                            )
                              ? "active mx-auto text-center"
                              : "inactive mx-auto text-center"
                          }
                        >
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/honda-city-petrol`}
                            onClick={() => setMajorBrand1()}
                            className="text-dark"
                          >
                            Honda
                          </Link>
                        </p>
                        <p
                          className={
                            isActive(
                              "/services/periodic-car-services/toyota-innova-petrol"
                            )
                              ? "active mx-auto text-center"
                              : "inactive mx-auto text-center"
                          }
                        >
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/toyota-innova-petrol`}
                            onClick={() => setMajorBrand1()}
                            className="text-dark"
                          >
                            Toyota
                          </Link>
                        </p>
                        <p
                          className={
                            isActive(
                              "/services/periodic-car-services/hyundai-creta-petrol"
                            )
                              ? "active mx-auto text-center"
                              : "inactive mx-auto text-center"
                          }
                        >
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/hyundai-creta-petrol`}
                            onClick={() => setMajorBrand1()}
                            className="text-dark"
                          >
                            Hyundai
                          </Link>
                        </p>
                        <p
                          className={
                            isActive(
                              "/services/periodic-car-services/kia-seltos-petrol"
                            )
                              ? "active mx-auto text-center"
                              : "inactive mx-auto text-center"
                          }
                        >
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/kia-seltos-petrol`}
                            onClick={() => setMajorBrand1()}
                            className="text-dark"
                          >
                            Kia
                          </Link>
                        </p>
                        <p
                          className={
                            isActive(
                              "/services/periodic-car-services/mg-hector-petrol"
                            )
                              ? "active mx-auto text-center"
                              : "inactive mx-auto text-center"
                          }
                        >
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/mg-hector-petrol`}
                            onClick={() => setMajorBrand1()}
                            className="text-dark"
                          >
                            MG
                          </Link>
                        </p>

                        <p
                          className={
                            isActive(
                              "/services/periodic-car-services/audi-q3-petrol"
                            )
                              ? "active mx-auto text-center"
                              : "inactive mx-auto text-center"
                          }
                        >
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/audi-q3-petrol`}
                            onClick={() => setMajorBrand1()}
                            className="text-dark"
                          >
                            Audi
                          </Link>
                        </p>
                        <p
                          className={
                            isActive(
                              "/services/periodic-car-services/mahindra-xuv-700-petrol"
                            )
                              ? "active mx-auto text-center"
                              : "inactive mx-auto text-center"
                          }
                        >
                          <Link
                            to={`/${selectedCityName
                              .replace(/ /g, "-")
                              .toLowerCase()}/services/periodic-car-services/mahindra-xuv-700-petrol`}
                            onClick={() => setMajorBrand1()}
                            className="text-dark"
                          >
                            Mahindra
                          </Link>
                        </p>
                      </NavDropdown>

                      <a
                        href="https://vehiclecare.in/blaze"
                        className="inactive nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Blog
                      </a>

                      <NavLink
                        to="/vehicle-ai"
                        className={
                          isActive("/vehicle-ai")
                            ? "active nav-link"
                            : "inactive nav-link"
                        }
                      >
                        VehicleAI Labs
                      </NavLink>

                      {is_user_logged_in ? (
                        <Link
                          to={"/profile"}
                          className="btn text-white nav-link prime-btn mt-2 userprofile_icon"
                        >
                          Profile
                        </Link>
                      ) : (
                        <span
                          onClick={() => setShow(true)}
                          className="inactive btn text-white nav-link    prime-btn mt-2 userprofile_icon"
                        >
                          Login
                        </span>
                      )}
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
              <Choosecity
                isCloseButton={showCityClose}
                show={showCityPop}
                handleClose={handleClose}
              />
            </div>
          </div>
        </Col>
      </Row>
      {/* <CustomModal
        show={show}
        content={<GetUserPopConent />}
        title={<GetModalTitle />}
        handleClose={() => modalHandleCloseFunc()}
        isCloseButton={false}
        modalSize="md"
      /> */}
      <UserLogin
        show={show}
        setBottomSheet={setShow}
        disableBottomTabs={props?.disableBottomTabs}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    vehicleReducer: state.default.vehicleReducer,
    cityListReducer: state.default.cityListReducer,
    userReducer: state.default.userReducer,
  };
}
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  getBrandNames: (params) => VehicleAction.getBrandNames(params, dispatch),
  getCityList: (params) => CityListAction.getCityList(params, dispatch),
  updateReducer: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
