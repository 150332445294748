import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import "./index.scss";
import Cart from "./Cart";
import ServicePlan from "./ServicePlan";
import ServiceCateogaries from "./ServiceCateogaries";
import { connect } from "react-redux";
import { ServiceListAction, VehicleAction } from "../../../../store/action";
import CustomModal from "../../../../components/common/CustomModal";
import { useSearchParams } from "react-router-dom";
import { getUrlSearchParams } from "../../../../utils/customHelper";
import WhyChooseUs from "../../../../components/common/WhyChooseUs";
import { MerchantServiceImg } from "../../../../constants/ImageConstants";
import { Helmet } from "react-helmet";

const Services = (props) => {
  const [searchParams] = useSearchParams();
  const elementRef = useRef(null);
  const { vehicleData, CateogaryData, cartList } = props;
  const brandList = vehicleData.brandList;
  const [cartShow, setCartShow] = useState(false);
  let passingData = {};
  const handleCartClose = () => setCartShow(false);
  const customParams = getUrlSearchParams(searchParams);
  useEffect(() => {
    if (customParams.length) {
      window.sessionStorage.setItem("custom_params", JSON.stringify(customParams));
    }
    if (!CateogaryData.serviceList.length) {
      props.getServiceCateogaries({ task: "getServiceCateogaries" });
    }
    if (!brandList.length) {
      const params = { task: "getCardBrands" };
      props.getBrandNames(params);
    }
    if (vehicleData.brand == null && vehicleData.model == null && vehicleData.variant == null) {
      document.getElementById("custom-vehicle-selector-brand").click();
    }
  }, [props, CateogaryData, brandList, vehicleData, customParams]);
  if (props.variant === null) {
    passingData = {
      brand: {
        icon: "https://vehiclecare.in/images/carBrand/Hyundai.png",
        id: "16",
        name: "Hyundai",
      },
      model: {
        icon: "https://vehiclecare.in/images/car_models/hyundai-grandi10-petrol-tilted.jpg",
        id: "148",
        name: "GRAND i 10",
        segment_type: "HB",
        variants: [
          { id: 251, variant: "Diesel" },
          { id: 250, variant: "Petrol" },
          { id: 249, variant: "Cng" },
        ],
      },
      variant: { id: 250, variant: "Petrol" },
    };
  } else {
    passingData = {
      brand: vehicleData.brand,
      model: vehicleData.model,
      variant: vehicleData.variant,
    };
  }
  const CartModalContent = () => {
    return (
      <Row>
        <div className="sidebar-side left-sidebar col-lg-4 col-md-12 col-sm-12">
          <Cart passingData={passingData} />
        </div>
      </Row>
    );
  };
  return (
    <>
      <Helmet>
        <title>Car Service Simplified - VehicleCare</title>
        <meta name="description" content="Avoid the hassels of an ordinary car service. VehicleCare brings you the best of car repair and maintenance services in a seamless and personalised way." />
      </Helmet>

      <Row>
        {/* Price Section  */}
        <div>
          <img src={MerchantServiceImg} alt="" />
        </div>

        <div className="sidebar-page-container shop-single-section pt-0 pb-0" style={{ backgroundColor: "#F5F1EE" }}>
          <div className="auto-container">
            <div className="row clearfix">
              <div className="shop-page">
                <ServiceCateogaries cateogary={CateogaryData.selected_cateogary} passingData={passingData.variant} />
                <span className="custom-vehicle-info pull-left text-center mx-1">
                  {vehicleData?.brand?.name}
                  <font className="mx-2" color="#ed1c24">
                    |
                  </font>
                  {vehicleData?.model?.name}
                  <font className="mx-2" color="#ed1c24">
                    |
                  </font>
                  {vehicleData?.variant?.variant}
                </span>
                <i className={`fa fa-shopping-cart custom-mobile-cart pull-right mx-1 ${cartList.length && "filled"}`} onClick={() => setCartShow(true)} aria-hidden="true"></i>
              </div>
              <div className="right-sidebar col-lg-8 col-md-12 col-sm-12 mt-2" style={{ minHeight: "600px", overflow: "scroll" }}>
                <ServicePlan passingData={passingData.variant} />
              </div>
              <aside className="sidebar sticky-top isHidden" ref={elementRef}>
                <Cart passingData={passingData} />
              </aside>
            </div>
          </div>
        </div>
        <CustomModal show={cartShow} title="Cart" content={<CartModalContent />} isCloseButton={true} handleClose={handleCartClose} />

        <section className="features-section mb-5">
          <WhyChooseUs />
        </section>

        {/* test code  */}

        <div className={`footer-fix my-2 ${!cartList.length ? "d-none" : ""}`}>
          <div className="no-gutters">
            <button className="col-auto mx-auto bg-danger py-2 text-center text-white rounded btn btn-danger w-100" onClick={() => setCartShow(true)}>
              <h6>
                <i className="fa fa-shopping-cart custom-mobile-cart mx-1" aria-hidden="true"></i>
                View Cart ( {cartList.length} )
              </h6>
            </button>
          </div>
        </div>

        {/* test code  */}
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.cartList = state.default.cartListReducer.cartList;
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.CateogaryData = state.default.serviceListReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  getBrandNames: (params) => VehicleAction.getBrandNames(params, dispatch),
  getServiceCateogaries: (params) => ServiceListAction.getServiceCateogaries(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Services);
