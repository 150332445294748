import {
    DOCUMENT_TYPE
  } from "../types";
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default (
    state = {
      documentTypeList: [],
    },
    action = {}
  ) => {
    switch (action.type) {
      case DOCUMENT_TYPE:
        return { ...state, documentTypeList: action.payload };
      default:
        return state;
    }
  };
  