import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Appbg, Pattern1, Pattern2 } from "../../../../constants/ImageConstants";

import OurApp from "./../../../../components/common/ourApp";

import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";

import Banner from "./Banner";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import WhyChooseEv from "./WhyChooseEv";
import EvAdvantages from "./EvAdvantages";
import EvServices from "./EvServices";
import InnerHeader from "../../layout/NavigationBar/InnerHeader";
import BottomTabs from "../../layout/NavigationBar/BottomsTabs";

const Ev = (props) => {
  const { userData } = props;
  const {is_user_logged_in} = userData
  const selectedCityName = props.cityListReducer.selectedCity;
  const faqList = [
    {
      id: 1,
      title: "What all do you check during inspection of our car?",
      summary:
        "We first start with a document check and test drive and then use the latest tools to assess the exterior, engine, tire, transmission, electrical, and interior. We also inspect the suspension, steering mechanism, brakes, and other accessories of your car during the test drive.",
    },
    {
      id: 2,
      title: "Will you charge for our car inspection?",
      summary: "Yes, the charges for our car inspection is Rs 399.",
    },
    {
      id: 3,
      title: "Do VehicleCare provide home inspection for car?",
      summary: "We provide Home inspection for you your car, you just have to call or WhtsApp us and our mechanic will reach you within 15 minutes.",
    },
    {
      id: 4,
      title: "How long will it take to repair my car?",
      summary: "There is time when you could not see your car for 5-6 hours. But in some cases the repair could take anywhere from 5-6 hours to a week or more.",
    },
    {
      id: 5,
      title: "Can i book my appointment thorugh mobile APP ?",
      summary: "Yes you can book your appointment through Mobile App. You can also book via email.",
    },
    {
      id: 6,
      title: "How many mechanic are there in vehiclecare ?",
      summary: "There are plenty of Mechanics in VehicleCare and our expert mechanics have more than ten years of experience.",
    },
    {
      id: 7,
      title: "What all kind of services do VehicleCare provide?",
      summary: "There's a wide range of car services which are available.We offer Car service, Wheel Care, Car AC Repair, Custom Repair and much more.",
    },
    {
      id: 7,
      title: "what sort of spare parts and engine oils are used in the servicing?",
      summary: "We use only genuine spares sourced from OEM/OES across all our workshops. Further, all products used in car services are authentic and top-rated.",
    },
  ];

  const [toggle, setToggle] = useState(0);
  let handleToggle = (id) => {
    if (toggle === id) {
      setToggle(null);
      return false;
    }
    setToggle(id);
  };
  const active = { backgroundColor: "black", color: "white" };
  const inactive = {};

  return (
    <>
      <Helmet>
        <title>Can You Search For A Car Service Near Me - Vehiclecare</title>
        <meta name="description" content="Can You Search for a Car Service Near Me We Are Provide Lowest Price Car Repair, Enjoy Hassle-Free Service With Complimentary Pickup And Drop"
        data-react-helmet="true" />

        <meta name="robots" content="noindex,nofollow" />

        <meta property="og:title" content="Can You Search For A Car Service Near Me - Vehiclecare" />
        <meta property="og:description" content="Can You Search for a Car Service Near Me We Are Provide Lowest Price Car Repair, Enjoy Hassle-Free Service With Complimentary Pickup And Drop" />
        <meta property="og:url" content={`https://vehiclecare.in/car-mechanic-near-me`} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="VehicleCare" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg" />
        <meta property="twitter:title" content="Can You Search For A Car Service Near Me - Vehiclecare" />
        <meta property="twitter:description" content="Can You Search for a Car Service Near Me We Are Provide Lowest Price Car Repair, Enjoy Hassle-Free Service With Complimentary Pickup And Drop" />
        <meta property="twitter:url" content={`https://vehiclecare.in/car-mechanic-near-me`} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@vehiclecare1" />
        <meta property="twitter:image" content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg" />
      </Helmet>

      {/* <Header isStatic={true} /> */}
      <InnerHeader title={'EV'} />

      <Row>
        {/* Banner Section */}
        <section className="banner-section-three style-two">
          <div className="main-slider-carousel">
            <div className="slide">
              <Banner />
            </div>
          </div>
        </section>

        {/*Car Workshop Near Me? */}
        <section className="about-section pt-5 pb-5" style={{ backgroundColor: "rgb(251, 251, 251)" }}>
          <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
          <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>

          <div className="auto-container">
            <div className="upper-section">
              <div className="row clearfix d-flex justify-content-center px-2">
                <div className="content-column col-lg-12 col-md-12 col-sm-12 mb-2">
                  <div className="inner-column">
                    <div className="sec-title mb-4">
                      <h2 className="text-left">
                      Introducing IOT-Based <font color="#52ae49">EV Fleet</font> Management
                      </h2>
                      <div className="text text-left mb-0">
                        IoT, the Internet of Things has emerged as a game-changer technology in recent times and has been used increasingly in various industries. VehicleCare is leveraging the power of IoT to facilitate the effective, efficient, and economical maintenance of EV Fleets. By harassing the power of data and technology, we are offering businesses a solution through which they can optimise operations, reduce environmental impact and cater better to their customers and hence stay ahead of their competition. 
                      </div>
                    </div>

                    <div className="detail">
                      <div className="justify-content-center col inner-box">
                        <div className="col-lg-12 col-md-12 col-sm-12 lower-content">
                          <div className="sec-title mb-4 mt-4">
                            <h2 className="text-left fs-3 text-capitalize w-75">Welcome To The World Of <font color="#52ae49">EV Fleet Management With VehicleCare </font> IOT-Based Annual Maintenance Service</h2>
                            <div className="text text-left mb-0">
                            The Advent of Electric Vehicles has revolutionised transportation. With an increasing focus on sustainability and efficiency, EVs are gaining popularity every day. The number of EVs on the Indian roads is growing and hence, the need for their maintenance. VehicleCare has come up with a smart and unique IoT-based solution for the maintenance and management of EV fleets.
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="services-section-four">
          <EvServices />
        </div>

        <section className="about-section pt-5" style={{ backgroundColor: "rgb(251, 251, 251)" }}>
          <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
          <EvAdvantages />
        </section>

        <section className="about-section pt-0">
          <WhyChooseEv />
        </section>

        {/* Our App */}
        <div
          className="testimonial-section-one bg-size pb-5"
          style={{
            backgroundImage: `url(${Appbg})`,
            backgroundSize: `cover`,
            backgroundColor: `#f5f1ee`,
          }}
        >
          <OurApp />
        </div>


      </Row>
      <Footer bottomTabs={true}  />
      <BottomTabs route_name={'home'} user={is_user_logged_in} />
    </>
  );
};

function mapStateToProps(state) {
  const stateObj = {};
  stateObj.cityListReducer = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  return stateObj;
}
export default connect(mapStateToProps)(Ev);
