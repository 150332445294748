import React from "react";
import { Col, Row } from "react-bootstrap";
import './index.scss';
import { FreePickup, ThreeMonths, FortyCheckup, GenuineSpares, Doorstep, ExpertMechanic, Delivery} from "../../../../../constants/ImageConstants";

const VcBenifits = () => {
    return (
        <Row>
            <Col>
				<div className="auto-container" id="vc-benifits">
					<div className="d-flex justify-content-center col">
            			<div className="col-lg-11 col-md-11 col-sm-12">

							<div className="d-none d-md-block">
								{/* <div className="row clearfix">
									<div className="price-block col-lg-3 col-sm-6">
										<div><h2 className="fw-bold text-dark lineHeight mb-2">VehicleCare Benefits  </h2></div>
										<div className="price-inner card text-center">
											<div className="card border-0">
												<ul className="list-group list-group-flush">
													<li className="list-group-item">
														<img className="mx-auto" src={FreePickup} alt="Free Pickup and Drop" />
														free pick up and drop </li>
													<li className="list-group-item">
														<img className="mx-auto" src={FortyCheckup} alt="Forty Point Checkup" />
														40 point check up</li>
													<li className="list-group-item">
														<img className="mx-auto" src={ThreeMonths} alt="Three Months Warranty" />
														3 months warranty</li>
													<li className="list-group-item">
														<img className="mx-auto" src={GenuineSpares} alt="Genuine Spares" /> 
														genuine spares</li>
													<li className="list-group-item">
														<img className="mx-auto" src={Doorstep} alt="Doorstep Service" /> 
														doorstep service</li>
													<li className="list-group-item">
														<img className="mx-auto" src={ExpertMechanic} alt="Expert Mechanic" />
														expert mechanics</li>
													<li className="list-group-item">
														<img className="mx-auto" src={Delivery} alt="Same Day Delivery" />
														same day delivery</li>
												</ul>
												
											</div>
										</div>
									</div>
									<div className="price-block col-lg-3 col-sm-6">
										<div className="text-center"><h2 className="bg-secondary text-white mb-3 fw-bold"><font size="4"> Local Mechanic</font> </h2></div>
										<div className="price-inner card  text-center">
											
											<div className="card border-0">
												<ul className="list-group list-group-flush">
													<li className="text-center list-group-item"><i className="fa fa-check"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
												</ul>
												
											</div>
										</div>
									</div>
									<div className="price-block col-lg-3 col-sm-6">
										<div className="text-center"><h2 className="bg-secondary text-white mb-3 fw-bold"><font size="4"> Authorized Service Center</font> </h2></div>
										<div className="price-inner card text-center">
											<div className="card border-0">
												<ul className="list-group list-group-flush">
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-check"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-check"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-check"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-check"></i></li>
													<li className="text-center list-group-item"><i className="fa fa-times"></i></li>
												</ul>
												
											</div>
										</div>
									</div>
									<div className="price-block col-lg-3 col-sm-6">
										<div className="text-center vcBgColor"><h2 className="text-white mb-3 fw-bold"><font size="4"> Vehiclecare </font> </h2></div>
										<div className="price-inner card text-center">
											<div className="card border-0">
												<ul className="list-group list-group-flush">
													<li className="text-center list-group-item"><font color="#ed1c24"><i className="fa fa-check"></i></font></li>
													<li className="text-center list-group-item"><font color="#ed1c24"><i className="fa fa-check"></i></font></li>
													<li className="text-center list-group-item"><font color="#ed1c24"><i className="fa fa-check"></i></font></li>
													<li className="text-center list-group-item"><font color="#ed1c24"><i className="fa fa-check"></i></font></li>
													<li className="text-center list-group-item"><font color="#ed1c24"><i className="fa fa-check"></i></font></li>
													<li className="text-center list-group-item"><font color="#ed1c24"><i className="fa fa-check"></i></font></li>
													<li className="text-center list-group-item"><font color="#ed1c24"><i className="fa fa-check"></i></font></li>
												</ul>
												
											</div>
										</div>
									</div>
								</div> */}

								<div className="row">
									<table className="table table-bordered table-responsive benefitsTableFontSize text-center table-hover">
										<thead className="thead-dark border-light">
											<tr style={{borderBottom: '1px solid #dee2e6'}}>
												<th scope="col"><h3 className="fw-bold text-dark lineHeight mb-2">VehicleCare <br/> Benefits  </h3></th>
												<th scope="col"><h2 className="bg-secondary text-white mb-3 fw-bold"><font size="4"> Local Mechanic</font> </h2></th>
												<th scope="col"><h2 className="bg-secondary text-white mb-3 fw-bold"><font size="4"> Authorized Service Center</font> </h2></th>
												<th scope="col"><h2 className="text-white mb-3 fw-bold" style={{background: '#ed1c24'}}><font size="4"> Vehiclecare </font> </h2></th>
												
											</tr>
										</thead>
										<tbody className="bg-white">
											<tr>
												<th scope="row"><img className=" benefitsIcon" src={FreePickup} alt=""  />free pick up and drop</th>
												<td><font><i className="fa fa-check"></i></font></td>
												<td><font><i className="fa fa-close"></i></font></td>
												<td><font color="#ed1c24"><i className="fa fa-check"></i></font></td>
											</tr>
											<tr>
												<th scope="row">
												<img className=" benefitsIcon" src={FortyCheckup} alt="" />40 point check up</th>
												<td><font><i className="fa fa-close"></i></font></td>
												<td><font><i className="fa fa-close"></i></font></td>
												<td><font color="#ed1c24"><i className="fa fa-check"></i></font></td>
											</tr>
											<tr>
												<th scope="row">
												<img className=" benefitsIcon" src={ThreeMonths} alt="" />3 months warranty</th>
												<td><font><i className="fa fa-close"></i></font></td>
												<td><font><i className="fa fa-check"></i></font></td>
												<td><font color="#ed1c24"><i className="fa fa-check"></i></font></td>
											</tr>

											<tr>
												<th scope="row">
												<img className=" benefitsIcon" src={GenuineSpares} alt="" /> genuine spares</th>
												<td><font><i className="fa fa-close"></i></font></td>
												<td><font><i className="fa fa-check"></i></font></td>
												<td><font color="#ed1c24"><i className="fa fa-check"></i></font></td>
											</tr>

											<tr>
												<th scope="row">
												<img className=" benefitsIcon" src={Doorstep} alt="" /> Doorstep Service</th>
												<td><font><i className="fa fa-close"></i></font></td>
												<td><font><i className="fa fa-check"></i></font></td>
												<td><font color="#ed1c24"><i className="fa fa-check"></i></font></td>
											</tr>

											<tr>
												<th scope="row">
												<img className=" benefitsIcon" src={ExpertMechanic} alt="" />expert mechanics</th>
												<td><font><i className="fa fa-close"></i></font></td>
												<td><font><i className="fa fa-check"></i></font></td>
												<td><font color="#ed1c24"><i className="fa fa-check"></i></font></td>
											</tr>

											<tr>
												<th scope="row">
												<img className=" benefitsIcon" src={Delivery} alt="" />same day delivery</th>
												<td><font><i className="fa fa-close"></i></font></td>
												<td><font><i className="fa fa-close"></i></font></td>
												<td><font color="#ed1c24"><i className="fa fa-check"></i></font></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							<div className="row clearfix d-md-none">
								<table className="table table-bordered table-responsive benefitsTableFontSize text-center">
									<thead className="thead-dark">
										<tr>
											<th scope="col"><h6 className="mb-2">VehicleCare <br/> Benefits  </h6></th>
											<th scope="col"><h6 className="mb-2"> Local Mechanic </h6></th>
											<th scope="col"><h6 className="mb-2">Authorized Service Center </h6></th>
											<th scope="col"><h6 className="text-white mb-1 fw-bold vcBgColor p-2"><font size="4"> VC </font> </h6></th>
											
										</tr>
  									</thead>
						  			<tbody>
										<tr>
											<th scope="row"><img className=" benefitsIcon" src={FreePickup} alt=""  />free pick up and drop</th>
											<td><font><i className="fa fa-check"></i></font></td>
											<td><font><i className="fa fa-close"></i></font></td>
											<td><font color="#ed1c24"><i className="fa fa-check"></i></font></td>
										</tr>
										<tr>
											<th scope="row">
											<img className=" benefitsIcon" src={FortyCheckup} alt="" />40 point check up</th>
											<td><font><i className="fa fa-close"></i></font></td>
											<td><font><i className="fa fa-close"></i></font></td>
											<td><font color="#ed1c24"><i className="fa fa-check"></i></font></td>
										</tr>
										<tr>
											<th scope="row">
											<img className=" benefitsIcon" src={ThreeMonths} alt="" />3 months warranty</th>
											<td><font><i className="fa fa-close"></i></font></td>
											<td><font><i className="fa fa-check"></i></font></td>
											<td><font color="#ed1c24"><i className="fa fa-check"></i></font></td>
										</tr>

										<tr>
											<th scope="row">
											<img className=" benefitsIcon" src={GenuineSpares} alt="" /> genuine spares</th>
											<td><font><i className="fa fa-close"></i></font></td>
											<td><font><i className="fa fa-check"></i></font></td>
											<td><font color="#ed1c24"><i className="fa fa-check"></i></font></td>
										</tr>

										<tr>
											<th scope="row">
											<img className=" benefitsIcon" src={ExpertMechanic} alt="" />expert mechanics</th>
											<td><font><i className="fa fa-close"></i></font></td>
											<td><font><i className="fa fa-check"></i></font></td>
											<td><font color="#ed1c24"><i className="fa fa-check"></i></font></td>
										</tr>

										<tr>
											<th scope="row">
											<img className=" benefitsIcon" src={Delivery} alt="" />same day delivery</th>
											<td><font><i className="fa fa-close"></i></font></td>
											<td><font><i className="fa fa-close"></i></font></td>
											<td><font color="#ed1c24"><i className="fa fa-check"></i></font></td>
										</tr>
									</tbody>
						  		</table>
							
							</div>

						</div>
						
						
					</div>
				</div>

            </Col>
        </Row>
  );
};





export default VcBenifits;
