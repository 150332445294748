import { SHOW_HIDE_LOADER } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (
	state = { isLoaderShow: false }, action = {},
) => {
	switch (action.type) {
	case SHOW_HIDE_LOADER:
		return { ...state, isLoaderShow: action.payload };
	default:
		return state;
	}
};
