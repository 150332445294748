import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";

import Toast from 'react-bootstrap/Toast';

const EvAdvantages = () => {
  return (
    <>
      
      <Row>
        <div className="auto-container">
          <Row className="justify-content-md-center">
            <Col xs={12} lg={11}>
              <div className="sec-title centered">
                <h2>The Future is Here - Other <font color="#52ae49">Advantages</font> of IOT-Based Maintenance For <font color="#52ae49">EV Fleet</font></h2>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col xs={12} lg={11}>
              <Row>
                <Col xs={12} lg={6} className="mb-4">
                  <Toast className="border-0 w-100">
                    <Toast.Header className="bg-success" closeButton={false}>
                      <Row className="align-items-center">
                        <p className="me-auto mb-1 text-white"><b>Optimised Performance</b></p>
                      </Row>
                    </Toast.Header>
                    <Toast.Body className="bg-light">
                      <Col xs={12}>
                        <p className="lh-sm mb-1">With data-driven knowledge, fleet managers can optimise vehicle performance and energy efficiency. This increases the lifespan of the vehicle, reduces its operational cost, and enhances overall productivity.</p>
                      </Col>
                    </Toast.Body>
                  </Toast>
                </Col>

                <Col xs={12} lg={6} className="mb-2">
                  <Toast className="border-0 w-100">
                    <Toast.Header className="bg-success" closeButton={false}>
                      <Row className="align-items-center">
                        <p className="me-auto mb-1 text-white"><b>Enhanced Safety and Compliance</b></p>
                      </Row>
                    </Toast.Header>
                    <Toast.Body className="bg-light">
                      <Col xs={12}>
                        <p className="lh-sm mb-1">Real-time monitoring of vehicle performance enables proactive identification of safety issues and ensures compliance with regulatory standards. This approach not only safeguards drivers and assets but also mitigates potential liabilities for fleet operators.</p>
                      </Col>
                    </Toast.Body>
                  </Toast>
                  
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <Toast className="border-0 w-100">
                    <Toast.Header className="bg-success" closeButton={false}>
                      <Row className="align-items-center">
                        <p className="me-auto mb-1 text-white"><b>Cost Savings</b></p>
                      </Row>
                    </Toast.Header>
                    <Toast.Body className="bg-light">
                      <Col xs={12}>
                        <p className="lh-sm mb-1">The power of knowing the maintenance needs before they occur, enables the drivers or fleet managers to take precautionary steps and thus avoid big bills. The predictive maintenance feature of the IoT device helps reduce the maintenance cost thus increasing the ROI and lowering repair expenses over time.</p>
                      </Col>
                    </Toast.Body>
                  </Toast>

                  
                </Col>

                <Col xs={12} lg={6} className="mb-2">
                  <Toast className="border-0 w-100">
                    <Toast.Header className="bg-success" closeButton={false}>
                      <Row className="align-items-center">
                        {/* <Col xs={12}><img className="mx-auto w-25" src={GenuineParts} alt="Genuine Parts" /></Col> */}
                        <p className="me-auto mb-1 text-white"><b>Improved Reliability</b></p>
                      </Row>
                    </Toast.Header>
                    <Toast.Body className="bg-light">
                      <Col xs={12}>
                        <p className="lh-sm mb-1">Better-maintained vehicles are more reliable and hence more demand by the customers. A well-maintained fleet of healthy vehicles has the loyalty of its customers and builds trust and credibility for the brand.</p>
                      </Col>
                    </Toast.Body>
                  </Toast>
                  
                </Col>

              </Row>                  
            </Col>
          </Row>
        </div>
      </Row>
    
    </>
  );
};

export default EvAdvantages;
