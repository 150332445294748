import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Pattern1, Pattern2 } from "../../../../../constants/ImageConstants";
import ThreePillars from "../../../../../components/common/threePillars";
import BrokerBanner from "./BrokerBanner";
import ThreeEasySteps from "./ThreeEasySteps";
import Header from "../../../layout/NavigationBar/Header";
import Footer from "../../../layout/NavigationBar/Footer";
import BlogMultiImageCarousel from "../../../../../components/common/blogMultiImageCarouselMobile";
import TrustedCompany from "./trustedCompany";
import { Helmet } from "react-helmet";

const Broker = (props) => {
  const source = props.source || "Broker";
  return (
    <>
      <Helmet>
        <title>Digital workshop for car insurance brokers - VehicleCare</title>
        <meta
          name="description"
          content="VehicleCare provides a one-stop solution for vehicle Repair and maintenance services. Our technology platform is robust enough to connect to any existing CRM system to provide seamless service for your customers."
        />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        <Col>
          {/* Banner Section */}
          <section className="banner-section-three style-two">
            <div className="main-slider-carousel">
              <div className="slide">
                <BrokerBanner source={source} />
              </div>
            </div>
          </section>

          {/* Trusted Company */}
          <section className="about-section pt-0">
            <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
            <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>
            <TrustedCompany />
          </section>

          {/* ABout process */}
          <section className="services-section-four mt-0 pt-0">
            <ThreePillars />
          </section>

          {/* Three Easy Steps */}
          <section className="services-section-four" style={{ background: "#fbfbfb" }}>
            <ThreeEasySteps />
          </section>

          {/* Blog Section */}
          <div className="blog-section">
            <BlogMultiImageCarousel />
          </div>

          {/* <div className="acordian-section pt-5" style={{ backgroundImage: `url(${Citybg})`, backgroundSize: `inherit`, backgroundColor: `#f5f1ee` }}>
            <CityList />
          </div> */}
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default Broker;
