import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./index.scss";
import { Pattern1, Pattern2, MerchantInsuranceImg, Successful } from "./../../../../../constants/ImageConstants";
import TrustedCompany from "../../Insurance/trustedCompany";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getNavigateUrl, getUrlSearchParams } from "../../../../../utils/customHelper";
import { CreateInsuranceLeadAction, InsuranceCompanyList, VehicleAction } from "../../../../../store/action";
import { connect } from "react-redux";
import VehicleSelector from "../../../../../components/common/vehicleSelectorMobile";
import { PROPOSAL_ID, SHOW_HIDE_LOADER } from "../../../../../store/types";
import { signInWithPhoneNumber, signOut } from "firebase/auth";
import { auth, generateRecaptcha } from "../../../../../server/firebase";
import CustomModal from "../../../../../components/common/CustomModal";

const Claim = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isCaptchaVerifier, setIsCaptchaVerifier] = useState(false);
  const [optCode, setOptCode] = useState("");
  const [optVerified, setOptVerified] = useState(false);
  const [final, setfinal] = useState("");
  const { vehicleData, insuranceCompanyList, customDispatch } = props;
  const { brand, model, variant, brandList } = vehicleData;
  const customParams = getUrlSearchParams(searchParams);
  const [data, setData] = useState({
    insured_name: "",
    insured_email: "",
    insured_phone: "",
    insurance_company: "",
    insured_city: props.cityList.selectedCity,
    userVehicle: "",
  });
  const handleClose = () => navigate(getNavigateUrl("/"));
  const ErrorOutput = (props) => {
    let name = props.name;
    let inputValue = props.case;
    if (name === "insured_name") {
      if (!inputValue?.match(/^[a-zA-Z\s]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Letters only</span>;
      }
      return <span></span>;
    }
    if (name === "insured_phone") {
      if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Numbers only</span>;
      }
      return <span></span>;
    }
    if (name === "insured_email") {
      if (!inputValue?.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) && inputValue?.length > 0) {
        return <span className="text-danger">Invalid email address</span>;
      }
      return <span></span>;
    }
    if (name === "insured_city") {
      if (!inputValue?.match(/^[a-z 0-9A-Z]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Letters only</span>;
      }
      return <span></span>;
    }
    if (name === "userVehicle") {
      if (data.insured_phone && data.insured_city && vehicleData?.variant === null) {
        return <span className="text-danger">Select Your Vehicle</span>;
      }
      return <span></span>;
    }
    return <></>;
  };
  const handleClick = (e) => setData({ ...data, [e.target.name]: e.target.value });
  // eslint-disable-next-line no-unused-vars
  const sendOtp = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    const mynumber = `+91${data.insured_phone}`;
    if (!isCaptchaVerifier) generateRecaptcha(setIsCaptchaVerifier);
    const verify = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, mynumber, verify)
      .then((result) => {
        setfinal(result);
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        setShow(true);
      })
      .catch((err) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(err);
      });
  };
  const serviceQuoteCall = () => {
    const apiParam = {
      task: "createInsuranceLead",
      insured_name: data.insured_name,
      insured_email: data.insured_email,
      insured_phone: data.insured_phone,
      car_brand: brand.name,
      car_model: model.name,
      car_variant: variant.variant.toString(),
      insured_city: data.insured_city,
      insurance_company: data.insurance_company,
    };
    const merchantName = customParams.filter((e) => e.name === "partner_name" || e.name === "utm_source")?.[0].value || null;
    const merchantId = customParams.filter((e) => e.name === "partner_id" || e.name === "utm_campaign")?.[0].value || null;

    if (merchantId) {
      apiParam.partner_id = merchantId;
    }
    if (merchantName) {
      apiParam.partner_name = merchantName;
    }
    props.getInsuranceLead(apiParam);
  };
  const GetModalContent = () => {
    return !optVerified ? (
      <Row>
        <Col id="otpCodeContainer" className="px-4 text-center">
          <div className="sec-title mb-3">
            <h2 className="font-weight-bold">
              <font size="4">Enter OTP Recieved on {data.insured_phone} </font>{" "}
            </h2>
          </div>

          <div className="styled-form login-form">
            <div className="form-group">
              <input type="text" className="form-control" maxLength={6} placeholder="Enter OTP Code" value={optCode} onChange={(event) => setOptCode(event.target.value)} />
            </div>
          </div>
          <button onClick={onCodeSubmit} className="theme-btn btn-style-seven btn-sm py-2 mt-2 mb-4 px-4">
            <span className="txt">Submit</span>
          </button>
        </Col>
      </Row>
    ) : (
      <Row>
        <div className="col-12">
          <div className="row h-100">
            <div className="col-4 text-center">
              <img src={Successful} alt="" className="w-100" />
            </div>

            <div className="col-8 text-left my-auto">
              <h4>
                <b>
                  <font color="black">Congratulations!!</font>
                </b>
              </h4>
              <h5 style={{ color: "#40c057" }}>
                <b>Your Enquiry is successfully Registered.</b>
              </h5>
              <h6>Our Executive will contact you soon.</h6>
              <button type="button" className="theme-btn btn-style-nine my-3 mx-5" onClick={() => navigate(getNavigateUrl("/"))}>
                <span className="txt">Home</span>
              </button>
            </div>
          </div>
        </div>
      </Row>
    );
  };
  const onCodeSubmit = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    final
      .confirm(optCode)
      .then((result) => {
        signOut(auth);
        serviceQuoteCall();
      })
      .catch((error) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(error);
      });
  };
  const customFormCheck = () => {
    let responseIsInvalid = false;
    if (!insuranceCompanyList?.filter((e) => e.name === data.insurance_company).length) {
      data.insurance_company = "";
      responseIsInvalid = true;
    }
    return responseIsInvalid;
  };
  const submitForm = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    customFormCheck();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      // sendOtp();
      serviceQuoteCall();
    }
    setValidated(true);
  };
  useEffect(() => {
    if (!brandList.length) {
      const params = { task: "getCardBrands" };
      props.getBrandNames(params);
    }
    if (vehicleData.brand == null && vehicleData.model == null && vehicleData.variant == null) {
      document.getElementById("custom-vehicle-selector-brand").click();
    }
    if (!insuranceCompanyList.length) props.getInsuranceCompanies({ task: "getInsuranceCompanies" });
  });
  useEffect(() => {
    if (props.proposalID?.proposal_id) {
      setShow(true);
      setOptVerified(true);
      customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
      customDispatch({ type: PROPOSAL_ID, payload: null });
    }
  }, [customDispatch, props]);
  return (
    <>
      <Row>
        <Col>
          {/* Banner Section */}
          <section className="banner-section-three style-two">
            <img src={MerchantInsuranceImg} alt="Merchant Insurance" />
          </section>

          <div className="claim-section aboutus mt-5 custom-claim-form">
            <div className="">
              <div className="auto-container">
                <div className="sec-title centered">
                  <div className="title">Make a Claim</div>
                  <h1>Information</h1>
                </div>

                <div className="row clearfix">
                  <div className="appointment-form login-form">
                    <div className="right-sidebar col-lg-12 col-md-12 col-sm-12">
                      <div className="lower-section">
                        <div className="appointment-form login-form">
                          <Form noValidate validated={validated} onSubmit={submitForm}>
                            <div className="row clearfix">
                              <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                <Form.Label>Insured vehicle</Form.Label>
                                <VehicleSelector navigate={true} />
                                <ErrorOutput case={data.userVehicle} name={"userVehicle"} />
                              </div>
                              <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                <Form.Label>Insured Name</Form.Label>
                                <input type="text" name="insured_name" value={data.insured_name} aria-describedby="inputGroupPrepend" required onChange={(e) => handleClick(e)} />
                                <Form.Control.Feedback type="invalid">Please enter your name</Form.Control.Feedback>
                                <ErrorOutput case={data.insured_name} name={"insured_name"} />
                              </div>

                              <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                <Form.Label>Insured Email </Form.Label>
                                <input type="text" name="insured_email" value={data.insured_email} aria-describedby="inputGroupPrepend" required onChange={(e) => handleClick(e)} />
                                <Form.Control.Feedback type="invalid">Please enter your email</Form.Control.Feedback>
                                <ErrorOutput case={data.insured_email} name={"insured_email"} />
                              </div>

                              <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                <Form.Label>Insured Phone Number</Form.Label>
                                <input type="text" maxLength={10} name="insured_phone" value={data.insured_phone} aria-describedby="inputGroupPrepend" required onChange={(e) => handleClick(e)} />
                                <Form.Control.Feedback type="invalid">Please enter your phone number</Form.Control.Feedback>
                                <ErrorOutput case={data.insured_phone} name={"insured_phone"} />
                              </div>

                              <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                <Form.Label>Insurance Company</Form.Label>
                                <input
                                  type="text"
                                  list="insuranceCompanyList"
                                  placeholder="Select Insurance Company"
                                  aria-describedby="inputGroupPrepend"
                                  name="insurance_company"
                                  onChange={(e) => handleClick(e)}
                                  value={data.insurance_company}
                                  autoComplete="off"
                                  required
                                />
                                <datalist id="insuranceCompanyList">
                                  {insuranceCompanyList?.map((company) => {
                                    return (
                                      <option value={company.name} key={company.id}>
                                        {company.name}
                                      </option>
                                    );
                                  })}
                                </datalist>
                                <Form.Control.Feedback type="invalid">Please select Insurance Company</Form.Control.Feedback>
                              </div>
                              <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                <Form.Label>City</Form.Label>
                                <input
                                  type="text"
                                  list="citylist"
                                  value={data.insured_city}
                                  placeholder="Select City"
                                  aria-describedby="inputGroupPrepend"
                                  name="insured_city"
                                  onChange={(e) => handleClick(e)}
                                />
                                <datalist id="citylist">
                                  {props?.cityList?.cityList.map((city) => {
                                    return (
                                      <option value={city.name} key={city.id}>
                                        {city.name}
                                      </option>
                                    );
                                  })}
                                </datalist>
                                <Form.Control.Feedback type="invalid">Please select city</Form.Control.Feedback>
                                <ErrorOutput case={data.insured_city} name={"insured_city"} />
                              </div>

                              <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                <button className="theme-btn btn-style-seven" type="submit" name="submit-form">
                                  Submit Now
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Trusted Company */}
          <section className="about-section">
            <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
            <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>
            <TrustedCompany />
          </section>
        </Col>
      </Row>
      <div id="custom-captcha-verifier"></div>
      <CustomModal show={show} modalSize={optVerified ? "lg" : "md"} content={<GetModalContent />} isCloseButton={true} handleClose={handleClose} />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityList = state.default.cityListReducer;
  stateObj.insuranceCompanyList = state.default.insuranceCompanyReducer.insuranceCompanyList;
  stateObj.proposalID = state.default.createInsuranceLeadReducer.proposal_id;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  getBrandNames: (params) => VehicleAction.getBrandNames(params, dispatch),
  getInsuranceLead: (params) => CreateInsuranceLeadAction.getInsuranceLead(params, dispatch),
  getInsuranceCompanies: (params) => InsuranceCompanyList.getInsuranceCompanies(params, dispatch),
  customDispatch: (params) => dispatch(params),
});
export default connect(mapStateToProps, mapDispatchToProps)(Claim);
