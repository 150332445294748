export const SHOW_HIDE_LOADER = "SHOW_HIDE_LOADER";
export const DESTROY_SESSION = "DESTROY_SESSION";
export const BRAND_LIST = "BRAND_LIST";
export const MODEL_LIST = "MODEL_LIST";
export const SET_BRAND = "SET_BRAND";
export const SET_MODEL = "SET_MODEL";
export const SET_YEAR = "SET_YEAR";
export const SET_VARIANT = "SET_VARIANT";
export const REMOVE_VEHICLE = "REMOVE_VEHICLE";
export const CITY_LIST = "CITY_LIST";
export const HOMEPAGE_BANNER = "HOMEPAGE_BANNER";
export const SERVICE_LIST = "SERVICE_LIST";
export const FAQ_LIST = "FAQ_LIST";
export const DISCOUNTS = "DISCOUNTS";
export const REVIEW_LIST = "REVIEW_LIST";
export const CATEOGARY_ID = "CATEOGARY_ID";
export const SERVICE_PACKAGES_LIST = "SERVICE_PACKAGES_LIST";
export const SERVICE_PACKAGES_EMPTY = "SERVICE_PACKAGES_EMPTY";
export const HELP_FORM_LIST = "HELP_FORM_LIST";
export const DOCUMENT_TYPE = "DOCUMENT_TYPE";
export const Sign_Out = "Sign_Out";
export const User_Login = "User_Login";
export const New_User_Login = "New_User_Login";
export const User_SignUp = "User_SignUp";
export const CART_LIST = "CART_LIST";
export const FAQ_CITY_ID = "FAQ_CITY_ID";
export const SET_CITY_NAME = "SET_CITY_NAME";
export const BLOG_LIST = "BLOG_LIST";
export const USER_PROFILE = "USER_PROFILE";
export const TESTIMONIAL = "TESTIMONIAL";
export const APPOINTMENT_DATA = "APPOINTMENT_DATA";
export const INSURANCE_COMPANY_LIST = "INSURANCE_COMPANY_LIST";
export const INSURANCE_CLAIM_TYPE = "INSURANCE_CLAIM_TYPE";
export const PROPOSAL_ID = "PROPOSAL_ID";
export const CLEAR_DATA = "CLEAR_DATA";
export const IMAGE_URL_ARRAY = "IMAGE_URL_ARRAY";
export const FILE_URL_ARRAY = "FILE_URL_ARRAY";
export const CLEAR_FILE_URL_ARRAY = "CLEAR_FILE_URL_ARRAY";
export const SERVICE_BOOKING = "SERVICE_BOOKING";
export const SERVICE_QUOTE = "SERVICE_QUOTE";
export const PAYMENT_SESSION = "PAYMENT_SESSION";
export const PAYMENT_STATUS = "PAYMENT_STATUS";
export const MEMBER_ENROLL = "MEMBER_ENROLL";
export const MEMBER_ENROLL_CODE = "MEMBER_ENROLL_CODE";
export const UPDATE_VEHILCE = "UPDATE_VEHILCE";
export const CREATE_WARRANTY = "CREATE_WARRANTY";
export const ORDER_DATA = "ORDER_DATA";
