import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./index.scss";
import { connect } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  toTitleCase,
  getUrlSearchParams,
} from "../../../../../utils/customHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import { OrderAction } from "../../../../../store/action";
import {
  InsuranceBannerImg1,
  InsuranceBannerImg2,
  InsuranceBannerImg3,
  Successful,
} from "../../../../../constants/ImageConstants";
import VehicleSelector from "../../../../../components/common/vehicleSelectorMobile";
import { SERVICE_QUOTE, SHOW_HIDE_LOADER } from "../../../../../store/types";
import CustomModal from "../../../../../components/common/CustomModal";
import { auth, generateRecaptcha } from "../../../../../server/firebase";
import { signInWithPhoneNumber, signOut } from "firebase/auth";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import LazyLoad from "react-lazy-load";

const InsuranceBannerSection = (props) => {
  const urlParam = useParams();
  const [searchParams] = useSearchParams();
  const customParams = getUrlSearchParams(searchParams);
  const {
    cityList,
    vehicleData,
    customDispatch,
    createServiceQuote,
    serviceBookingData,
  } = props;
  const [data, setData] = useState({
    userPhone: "",
    userCity: "",
    userVehicle: "",
  });
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isCaptchaVerifier, setIsCaptchaVerifier] = useState(false);
  const [optCode, setOptCode] = useState("");
  const [optVerified, setOptVerified] = useState(false);
  const [final, setfinal] = useState("");
  const { brand, model, variant } = vehicleData;
  const navigate = useNavigate();
  const handleClose = () => {
    if (optVerified) {
      // navigate(getNavigateUrl("/"));
      window.location.reload();
    }
    setShow(false);
  };
  const handleClick = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const ErrorOutput = (props) => {
    let name = props.name;
    let inputValue = props.case;
    if (name === "userPhone") {
      if (!inputValue?.match(/^[0-9]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Numbers only</span>;
      }
      return <span></span>;
    }
    if (name === "userCity") {
      if (!inputValue?.match(/^[a-zA-Z]+$/) && inputValue?.length > 0) {
        return <span className="text-danger">Letters only</span>;
      }
      return <span></span>;
    }
    if (name === "userVehicle") {
      if (data.userPhone && data.userCity && vehicleData?.variant === null) {
        return <span className="text-danger">Select Your Vehicle</span>;
      }
      return <span></span>;
    }

    return <></>;
  };
  const GetModalContent = () => {
    console.log("repeating");
    return !optVerified ? (
      <Row>
        <Col id="otpCodeContainer" className="px-4 text-center">
          <div className="sec-title mb-3">
            <h2 className="font-weight-bold">
              <font size="4">Enter OTP Recieved on {data.userPhone} </font>{" "}
            </h2>
          </div>

          <div className="styled-form login-form">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                maxLength={6}
                placeholder="Enter OTP Code"
                value={optCode}
                onChange={(event) => setOptCode(event.target.value)}
              />
            </div>
          </div>
          <button
            onClick={onCodeSubmit}
            className="theme-btn btn-style-seven btn-sm py-2 mt-2 mb-4 px-4"
          >
            <span className="txt">Submit</span>
          </button>
        </Col>
      </Row>
    ) : (
      <Row>
        <div className="col-12">
          <div className="row h-100">
            <div className="col-4 text-center">
              <img src={Successful} alt="" className="w-100" />
            </div>

            <div className="col-8 text-left my-auto">
              <h4>
                <b>
                  <font color="black">Congratulations!!</font>
                </b>
              </h4>
              <h5 style={{ color: "#40c057" }}>
                <b>Your Enquiry is successfully Registered.</b>
              </h5>
              <h6>Our Executive will contact you soon.</h6>
              {/* <button type="button" className="theme-btn btn-style-nine my-3 mx-5" onClick={() => navigate(getNavigateUrl("/"))}>
                <span className="txt">Home</span>
              </button> */}
            </div>
          </div>
        </div>
      </Row>
    );
  };
  // eslint-disable-next-line no-unused-vars
  const sendOtp = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    const mynumber = `+91${data.userPhone}`;
    if (!isCaptchaVerifier) generateRecaptcha(setIsCaptchaVerifier);
    const verify = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, mynumber, verify)
      .then((result) => {
        setfinal(result);
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        setShow(true);
      })
      .catch((err) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(err);
      });
  };
  const serviceQuoteCall = () => {
    const apiParam = {
      task: "getServiceQuote",
      brand_id: brand.id,
      model_id: model.id,
      variant_id: variant.id.toString(),
      mobile: data.userPhone,
      city: data.userCity,
      source: "VC-InsurnaceClaim",
    };
    if (customParams) {
      let sourceName = "";
      let sourceId = "";
      let medium = "";
      customParams.forEach((element) => {
        if (element.name === "merchant_name") {
          sourceName = element.value;
        }
        if (element.name === "merchant_id") {
          sourceId = element.value;
        }
        if (element.name === "utm_source") {
          sourceName = element.value;
        }
        if (element.name === "utm_medium") {
          medium = element.value;
        }
        if (element.name === "utm_campaign") {
          sourceId = element.value;
        }
      });
      if (sourceId) {
        apiParam.source = `${sourceId}-`;
      }
      if (sourceName) {
        apiParam.source += `${sourceName}`;
      }
      if (medium) {
        apiParam.source += `(${medium})`;
      }
    }
    if (urlParam.city) {
      apiParam.city = toTitleCase(urlParam.city);
    }
    createServiceQuote(apiParam);
  };
  const onCodeSubmit = () => {
    customDispatch({ type: SHOW_HIDE_LOADER, payload: true });
    final
      .confirm(optCode)
      .then((result) => {
        signOut(auth);
        serviceQuoteCall();
      })
      .catch((error) => {
        customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
        alert(error);
      });
  };
  const submitForm = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      // sendOtp();
      serviceQuoteCall();
    }
    setValidated(true);
  };
  useEffect(() => {
    if (serviceBookingData.serviceQuoteResponse !== null) {
      setShow(true);
      setOptVerified(true);
      customDispatch({ type: SHOW_HIDE_LOADER, payload: false });
      customDispatch({ type: SERVICE_QUOTE, payload: null });
    }
  }, [serviceBookingData, navigate, customDispatch]);
  return (
    <>
      <Row>
        <Col>
          <div className="auto-container">
            <div className="content-boxed">
              <div className="">
                <div className="row clearfix">
                  <div
                    className="image-column col-lg-7 col-md-12 col-sm-12 mb-0 d-md-none"
                    id="homeBannerSplide"
                  >
                    <div className="inner-column mt-3">
                      <div className="image">
                        {/* <Splide
                          options={{
                            type: "loop",
                            gap: "1rem",
                            autoplay: true,
                            pauseOnHover: false,
                            resetProgress: false,
                          }}
                          hasTrack={true}
                        >
                          <SplideSlide>
                            <img
                              src={InsuranceBannerImg1}
                              alt="Insurance Claim"
                            />
                          </SplideSlide>
                          <SplideSlide>
                            <LazyLoad>
                              <img
                                src={InsuranceBannerImg2}
                                alt="Insurance Claim"
                              />
                            </LazyLoad>
                          </SplideSlide>
                          <SplideSlide>
                            <LazyLoad>
                              <img
                                src={InsuranceBannerImg3}
                                alt="Insurance Claim"
                              />
                            </LazyLoad>
                          </SplideSlide>
                        </Splide> */}
                        <img src={InsuranceBannerImg2} alt="Insurance Claim" style={{borderRadius: '10px'}} />
                      </div>
                    </div>
                  </div>

                  <div
                    id="landingForm"
                    className="content-column appointment-form banner-form col-lg-5 col-md-12 col-sm-12 text-left px-4"
                  >
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={submitForm}
                    >
                      {/* <h1 className="fs-2 px-4 text-capitalize d-none d-md-block mt-0">Your Car <font color="#ed1c24">Claim</font> Journey Partner</h1>
                      <h1 className="fs-2 text-capitalize d-md-none mb-3 px-0">Your Car <font color="#ed1c24">Claim</font> Journey Partner</h1> */}

                      {/* <div className="d-none d-md-block px-3 mb-3">
                        <font color="black" className="heading-font d-inline">Your Car </font> <h1 className="fs-2 text-capitalize d-inline"> <font color="#ed1c24">Claim</font> Journey Partner</h1>
                      </div> */}
                      <h1
                        className="text-center"
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          margin: "15px",
                          fontSize: "18px",
                        }}
                      >
                        Your Car <font color="#ed1c24">Claim</font> Journey
                        Partner
                      </h1>

                      {/* <div className="d-md-none px-2 mb-3">
                        <font
                          color="black"
                          className="heading-font-mobile d-inline"
                        >
                          Your Car{" "}
                        </font>
                        <h1 className="fs-2 text-capitalize d-inline">
                          {" "}
                          <font color="#ed1c24">Claim</font> Journey Partner
                        </h1>
                      </div> */}

                      <div className="d-none d-md-block px-4">
                        <VehicleSelector navigate={true} />
                      </div>

                      <div className="d-md-none">
                        <VehicleSelector navigate={true} />
                      </div>

                      <ErrorOutput
                        case={data.userVehicle}
                        name={"userVehicle"}
                      />

                      <div className="d-none d-md-block px-4">
                        <div className="row">
                          <div
                            className={`form-group mt-3 px-0 col-sm-12 ${
                              urlParam.city
                                ? "col-lg-6 col-md-6"
                                : "col-lg-6 col-md-6"
                            }`}
                          >
                            <input
                              type="text"
                              name="userPhone"
                              maxLength={10}
                              pattern="[6-9]{1}[0-9]{9}"
                              value={data.userPhone}
                              aria-describedby="inputGroupPrepend"
                              className="form-control border-0 phoneNo"
                              placeholder="Enter Your Mobile"
                              onChange={(e) => handleClick(e)}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter your phone number
                            </Form.Control.Feedback>
                            <ErrorOutput
                              case={data.userPhone}
                              name={"userPhone"}
                            />
                          </div>
                          {!urlParam.city && (
                            <div className="form-group mt-3 col-lg-6 col-md-6 col-sm-12 pr-0">
                              <input
                                type="text"
                                list="citylist"
                                value={data.userCity}
                                placeholder="Select City"
                                aria-describedby="inputGroupPrepend"
                                name="userCity"
                                className="form-control border-0 phoneNo"
                                onChange={(e) => handleClick(e)}
                                required
                              />
                              <datalist id="citylist">
                                {cityList?.cityList.map((city) => {
                                  return (
                                    <option value={city.name} key={city.id}>
                                      {city.name}
                                    </option>
                                  );
                                })}
                              </datalist>
                              <Form.Control.Feedback type="invalid">
                                Please select city
                              </Form.Control.Feedback>
                              <ErrorOutput
                                case={data.userCity}
                                name={"userCity"}
                              />
                            </div>
                          )}
                          <div className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3">
                            <button
                              className="theme-btn btn-style-nine getQuote"
                              type="submit"
                              name="submit-form"
                            >
                              <span className="txt">Book Claim</span>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="d-md-none">
                        <div className="row clearfix px-2">
                          <div
                            className={`form-group col-lg-12 col-md-12 col-sm-12 border ${
                              urlParam.city
                                ? "col-lg-6 col-md-6"
                                : "col-lg-12 col-md-12"
                            }`}
                          >
                            <input
                              type="text"
                              name="userPhone"
                              maxLength={10}
                              pattern="[6-9]{1}[0-9]{9}"
                              value={data.userPhone}
                              aria-describedby="inputGroupPrepend"
                              className="custom-select-box border-0 px-0"
                              placeholder="Enter Your Mobile"
                              onChange={(e) => handleClick(e)}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter your phone number
                            </Form.Control.Feedback>
                            <ErrorOutput
                              case={data.userPhone}
                              name={"userPhone"}
                            />
                          </div>
                          {!urlParam.city && (
                            <div className="form-group col-lg-12 col-md-12 col-sm-12 border">
                              <input
                                type="text"
                                list="citylist"
                                value={data.userCity}
                                placeholder="Select City"
                                aria-describedby="inputGroupPrepend"
                                name="userCity"
                                className="custom-select-box border-0 px-0"
                                onChange={(e) => handleClick(e)}
                                required
                              />
                              <datalist id="citylist">
                                {cityList?.cityList.map((city) => {
                                  return (
                                    <option value={city.name} key={city.id}>
                                      {city.name}
                                    </option>
                                  );
                                })}
                              </datalist>
                              <Form.Control.Feedback type="invalid">
                                Please select city
                              </Form.Control.Feedback>
                              <ErrorOutput
                                case={data.userCity}
                                name={"userCity"}
                              />
                            </div>
                          )}

                          <div className="btns-box col-lg-12 col-md-12 col-sm-12 mt-3">
                            <button
                              className="theme-btn btn-style-nine getQuote"
                              type="submit"
                              name="submit-form"
                            >
                              <span className="txt">Book Claim</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>

                  <div className="image-column col-lg-7 col-md-12 col-sm-12 mb-0 d-none d-md-block">
                    <div className="inner-column">
                      <div className="image">
                        <Splide
                          options={{
                            type: "loop",
                            gap: "1rem",
                            autoplay: true,
                            pauseOnHover: false,
                            resetProgress: false,
                            arrows: false,
                          }}
                          hasTrack={true}
                        >
                          <SplideSlide>
                            <img
                              src={InsuranceBannerImg1}
                              alt="Insurance Claim"
                            />
                          </SplideSlide>
                          <SplideSlide>
                            <LazyLoad>
                              <img
                                src={InsuranceBannerImg2}
                                alt="Insurance Claim"
                              />
                            </LazyLoad>
                          </SplideSlide>
                          <SplideSlide>
                            <LazyLoad>
                              <img
                                src={InsuranceBannerImg3}
                                alt="Accident Claim"
                              />
                            </LazyLoad>
                          </SplideSlide>
                        </Splide>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div id="custom-captcha-verifier"></div>
      <CustomModal
        show={show}
        modalSize={optVerified ? "lg" : "md"}
        content={<GetModalContent />}
        isCloseButton={true}
        handleClose={handleClose}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityList = state.default.cityListReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) =>
    OrderAction.createServiceQuote(params, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsuranceBannerSection);
