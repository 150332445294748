import axios from "axios"
import { DISCOUNTS } from "../types"

const getDiscounts = (params, dispatch) => {
    axios.post("https://api.vehiclecare.app/api/app/price-discount").then(res => {
        if (res.data?.status === 'success'){
            dispatch({type: DISCOUNTS, payload: res.data?.data})
        }
    }).catch(e => {})
}

export {getDiscounts};