import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import { colors } from "@mui/material";
import { PersonFill, Substack } from "react-bootstrap-icons";
import CustomModal from "../../../../../components/common/CustomModal";
import { UserLogin } from "../../../screens/UserSections";
import { logFirebaseEvent } from "../../../../../utils/customHelper";

const BottomTabs = ({ route_name, user, disable }) => {
  const [openLogin, setOpenLogin] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    disable && disable(openLogin);
  }, [openLogin])
  return (
    <div className="fixed">
      {openLogin ? (
       <UserLogin show={!user && openLogin} setBottomSheet={setOpenLogin} />
      ) : (
        <div className="parent">
          <Link to={"/"}>
            <div className="tab">
              <img
                src={require(route_name === "home"
                  ? "../../../../../assets/images/bottom-tab-icons/Home_01.png"
                  : "../../../../../assets/images/bottom-tab-icons/Home.png")}
                className="img"
                alt="img"
              />
              <span
                style={{
                  color: route_name === "home" ? "red" : "grey",
                  fontSize: "10px",
                }}
              >
                Home
              </span>
            </div>
          </Link>
          <Link to={"/car-insurance-claim"}>
            <div className="tab">
              <img
                src={require(route_name === "claim"
                  ? "../../../../../assets/images/bottom-tab-icons/insurance-claim.png"
                  : "../../../../../assets/images/bottom-tab-icons/insurance-claim-grey.png")}
                className="img"
                alt="img"
              />
              <span
                style={{
                  color: route_name === "claim" ? "red" : "grey",
                  fontSize: "10px",
                }}
              >
                Claim
              </span>
            </div>
          </Link>
          {/* <a className="tab" href="https://vehiclecare.in/blaze" target="_blank">
          <img
            src={require("../../../../../assets/images/bottom-tab-icons/prime_icon_grey.png")}
            className="img"
          />
          <Substack color={'darkgrey'}style={{width: '15px', height: '20px'}}/>
          <a style={{color: 'grey', fontSize: '10px'}}>Blog</a>
        </a> */}
          <a className="tab" href="tel:+91-9911-020-209" onClick={() => logFirebaseEvent('mobile_web_support')}>
            <img
              src={require("../../../../../assets/images/bottom-tab-icons/telephone.png")}
              className="img"
              alt="img"
            />
            <span style={{ color: "grey", fontSize: "10px" }}>Support</span>
          </a>
          <div
            className="tab"
            onClick={() => (user ? navigate("/profile") : setOpenLogin(true))}
          >
            {/* <img
            src={require(route_name === "profile"
              ? "../../../../../assets/images/bottom-tab-icons/Profile_01.png"
              : "../../../../../assets/images/bottom-tab-icons/Profile.png")}
            className="img"
          /> */}
            <PersonFill
              size={25}
              color={route_name === "profile" ? "red" : "darkgrey"}
            />
            <span
              style={{
                color: route_name === "profile" ? "red" : "grey",
                fontSize: "10px",
              }}
            >
              Profile
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default BottomTabs;
