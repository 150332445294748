import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import GlobalStyle from "./assets/styles/GlobalStyle";
import ClientDesktopApp from "./ClientDesktopApp";
import ClientMobileApp from "./ClientMobileApp";
import reportWebVitals from "./reportWebVitals";
import InternetConnection from "./components/InternetConnection";
import { isMobile } from "react-device-detect";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
  console.debug = () => {};
}
if (isMobile) {
  ReactDOM.render(
    <>
      <GlobalStyle />
      <ClientMobileApp />
      <InternetConnection />
    </>,
    document.getElementById("root")
  );
}else {
  ReactDOM.render(
    <>
      <GlobalStyle />
      <ClientDesktopApp />
      <InternetConnection />
    </>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
