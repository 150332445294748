import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { Pattern1, Pattern2 } from "../../../../../constants/ImageConstants";
import TrustedCompany from "../Fleet/trustedCompany";
import ThreePillars from "../../../../../components/common/threePillars";
import BannerSection from "./BannerSection";
import BenifitDigitalRepairManagement from "../../../../../components/common/benifitDigitalRepairManagement";
import NewWayHandleDamageRepair from "../../../../../components/common/newWayHandleDamageRepair";
import Header from "../../../layout/NavigationBar/Header";
import Footer from "../../../layout/NavigationBar/Footer";
import BlogMultiImageCarousel from "../../../../../components/common/blogMultiImageCarousel";
import { Helmet } from "react-helmet";

const Lease = (props) => {
  const source = props.source || "Lease";
  return (
    <>
      <Helmet>
        <title>Digital workshop for your leased cars - VehicleCare</title>
        <meta
          name="description"
          content="Our single-window service will help you keep your leased car in good condition. We provide an end-to-end solution for car repair and maintenance."
        />
      </Helmet>

      <Header isStatic={true} />

      <Row>
        <Col>
          {/* Banner Section */}
          <section className="banner-section-three style-two">
            <div className="main-slider-carousel">
              <div className="slide">
                <BannerSection source={source} />
              </div>
            </div>
          </section>

          {/* Trusted Company */}
          <section className="about-section pt-0">
            <div className="pattern-layer-one" style={{ backgroundImage: `url(${Pattern1})` }}></div>
            <div className="pattern-layer-two" style={{ backgroundImage: `url(${Pattern2})` }}></div>
            <TrustedCompany />
          </section>

          {/* Three Pillars */}
          <section className="services-section-four" style={{ background: "#fbfbfb" }}>
            <NewWayHandleDamageRepair />
          </section>

          {/* Three Pillars */}
          <section className="services-section-four mt-0">
            <ThreePillars />
          </section>

          {/* Benifit Digital Repair Management */}
          <section className="services-section-four mt-0 pb-0" style={{ background: "#fbfbfb" }}>
            <BenifitDigitalRepairManagement />
          </section>

          {/* Blog Section */}
          <div className="blog-section">
            <BlogMultiImageCarousel />
          </div>

          {/* <div className="acordian-section pt-5" style={{ backgroundImage: `url(${Citybg})`, backgroundSize: `inherit`, backgroundColor: `#f5f1ee` }}>
            <CityList />
          </div> */}
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default Lease;
