import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

const WhyChoose = (props) => {
  const data = props.vehicleData;
  const { vehicleData, cityData } = props;
  const { selectedCity } = cityData;
  const urlParam = useParams();
  const cgID = urlParam.id;
  const { brand, model, variant } = vehicleData;
  const getCustomHelmetDetails = (cgID) => {
    let returnVal = [];
    if (variant === null || variant === undefined) return returnVal;
    switch (cgID) {
      case "periodic-car-services":
        returnVal = [
          {
            MainTitle: "",
            title1: `Why Periodic Service is Essential:`,
            description1: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">
                    Ensures optimal performance and longevity.
                  </li>
                  <li className="list-group-item">
                    Detects potential issues early, avoiding costly repairs.
                  </li>
                  <li className="list-group-item">
                    Key components like the engine, brake pads, and tyres are
                    inspected.
                  </li>
                  <li className="list-group-item">
                    Regular checks on lights, brakes, and tyres ensure road
                    safety.
                  </li>
                  <li className="list-group-item">
                    Prevents unexpected breakdowns.
                  </li>
                  <li className="list-group-item">
                    Boosts resale value with a well-maintained car.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title2: `Our Service Packages:`,
            description2: (
              <>
                <font color="#ed1c24">
                  <b>Basic Car Service -</b>{" "}
                </font>
                Essential services to keep your {model?.name} running smoothly.
                <br />
                <font color="#ed1c24">
                  <b>Standard Car Service -</b>{" "}
                </font>
                Popular choice with extra services on top of the basic.
                <br />
                <font color="#ed1c24">
                  <b>Comprehensive Car Service -</b>{" "}
                </font>
                Our premium package for full inspection and safety.
                <br />
              </>
            ),
          },
          {
            title3: `Why VehicleCare?`,
            description3: (
              <>
                <font color="#ed1c24"></font>Skilled professionals, genuine
                parts, and top-quality service. Satisfaction is guaranteed, with
                warranties and high-tech tools for all services.From AC gas
                recharging to wheel balancing, we deliver happiness with care.
              </>
            ),
          },
        ];
        break;
      case "denting-painting":
        returnVal = [
          {
            MainTitle: "",
            title1: `Why Fix Dents?`,
            description1: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">
                    Dents lower your car’s resale value.
                  </li>
                  <li className="list-group-item">
                    Leaving dents leads to rust, raising repair costs.
                  </li>
                  <li className="list-group-item">
                    Restores your car's appearance and maintains its visual
                    appeal.
                  </li>
                  <li className="list-group-item">
                    Protects the structural integrity of the car.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title2: `Why VehicleCare?`,
            description2: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">
                    Denting-painting services for all car models in{" "}
                    {selectedCity}.
                  </li>
                  <li className="list-group-item">
                    Genuine, high-grade paints with advanced tools.
                  </li>
                  <li className="list-group-item">
                    Services include - Localised per-panel repairs, Full body
                    denting and painting, Custom and fibre/plastic dent removal,
                    100% colour match for a showroom-like finish.
                  </li>
                  <li className="list-group-item">
                    Paint warranties at every workshop.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title3: `The VehicleCare Promise:`,
            description3: (
              <>
                Attention to detail with manufacturer-approved paints.
                Guaranteed quality and durability with a flawless finish.
              </>
            ),
          },
        ];
        break;
      case "car-ac-services":
        returnVal = [
          {
            title1: (
              <>
                Why Choose VehicleCare for Car AC Services for Your{" "}
                {brand?.name} {model?.name} in {selectedCity}?
              </>
            ),
            description1: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <>
                    <font color="#ed1c24">
                      <b>Comfort & Health -</b>{" "}
                    </font>
                    Enjoy a comfortable drive while reducing allergens,
                    pollutants, and contaminants.
                    <br />
                    <font color="#ed1c24">
                      <b>Interior Protection -</b>{" "}
                    </font>
                    Prolong the life of your dashboard and upholstery by
                    preventing heat damage.
                    <br />
                    <font color="#ed1c24">
                      <b>Safety in Cold Weather -</b>{" "}
                    </font>
                    AC helps with defogging, improving visibility and reducing
                    accident risks.
                    <br />
                    <font color="#ed1c24">
                      <b>Efficiency -</b>{" "}
                    </font>
                    A well-maintained AC boosts energy efficiency and extends
                    the life of components.
                    <br />
                    <font color="#ed1c24">
                      <b>Early Leak Detection -</b>{" "}
                    </font>
                    Regular checks help catch refrigerant leaks early,
                    preventing costly repairs.
                    <br />
                  </>
                </ul>
              </>
            ),
          },
          {
            title2: `Services Offered:`,
            description2: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">
                    Full AC inspection, refrigerant levels, pressure testing,
                    and air filter checks.
                  </li>
                  <li className="list-group-item">
                    Cleaning of condenser, coils, blower, and filters using
                    high-pressure equipment.
                  </li>
                  <li className="list-group-item">
                    Environmentally friendly AC gas recharge.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title3: <>Technology & Expertise:</>,
            description3: (
              <>
                Advanced tools for diagnosis and repairs, including leak
                detection and recharging machines.
              </>
            ),
          },
          {
            title4: <>Warranty</>,
            description4: (
              <>
                VehicleCare provides warranty-backed services across all Gurgaon
                workshops.
              </>
            ),
          },
        ];
        break;
      case "mechanical-repairs":
        returnVal = [
          {
            MainTitle: "",
            title1: `Key Features:`,
            description1: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">Skilled professionals.</li>
                  <li className="list-group-item">Best prices with rewards.</li>
                  <li className="list-group-item">
                    100% genuine OEM/OES spares.
                  </li>
                  <li className="list-group-item">
                    State-of-the-art diagnostics.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title2: `Convenient & Affordable:`,
            description2: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">
                    Easily book through our app or website.
                  </li>
                  <li className="list-group-item">
                    Pick-up and drop service for all repairs.
                  </li>
                  <li className="list-group-item">
                    Dedicated representative to assist throughout.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title3: `Why VehicleCare?`,
            description3: (
              <>
                <font color="#ed1c24">
                  <b>{brand.name} Expertise: </b>{" "}
                </font>
                Specialized care for your {model?.name} with expert technicians.
                <br />
                <font color="#ed1c24">
                  <b>SSkilled Technicians: </b>{" "}
                </font>
                Certified, precise repairs for {brand.name} models.
                <br />
                <font color="#ed1c24">
                  <b>Transparent Pricing: </b>{" "}
                </font>
                Clear estimates are upfront, so there are no surprises.
                <br />
                <font color="#ed1c24">
                  <b>Timely Service: </b>{" "}
                </font>
                Quick repairs to minimise downtime.
                <br />
                <font color="#ed1c24">
                  <b>Warranty Assurance: </b>{" "}
                </font>
                Warranties on all parts and repairs.
                <br />
                <font color="#ed1c24">
                  <b>Genuine Spare Parts: </b>{" "}
                </font>
                Only top-quality parts are used.
                <br />
                <font color="#ed1c24">
                  <b>100% Satisfaction: </b>{" "}
                </font>
                We guarantee your satisfaction with every repair.
              </>
            ),
          },
        ];
        break;
      case "wheel-care":
        returnVal = [
          {
            title1: <>Why Choose VehicleCare for Wheel Care in Gurgaon for Your {brand?.name} {model?.name} {variant?.name} </>,
            description1: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <>
                    <font color="#ed1c24">
                      <b>Wheels Matter: -</b>{" "}
                    </font>
                    They affect safety and performance. Good tyres ensure
                    traction and stability.
                    <br />
                    <font color="#ed1c24">
                      <b>Importance of Regular Checks -</b>{" "}
                    </font>
                    Ensure proper tyre pressure and alignment. Maintain tread
                    depth for safety. Enhance vehicle stability and braking
                    efficiency. Reduce accident risks and extend tyre life.
                    <br />
                    <font color="#ed1c24">
                      <b>Comprehensive Services at VehicleCare -</b>{" "}
                    </font>
                    Laser-assisted for smooth rides. Proper tyre positioning.
                    Maintains pressure longer. Promotes even tread wear. Genuine
                    Tire Replacement with quality assured.
                    <br />
                    <font color="#ed1c24">
                      <b>Complimentary Services -</b>{" "}
                    </font>
                    Free tyre fitting with purchases.
                    <br />
                    <font color="#ed1c24">
                      <b>Leading Brands -</b>{" "}
                    </font>
                    We offer tyres from MRF, Bridgestone, and Apollo.
                    <br />
                    <font color="#ed1c24">
                      <b>Skilled Technicians -</b>{" "}
                    </font>
                    We are equipped with advanced tools. Ensure optimal
                    performance for your {model.name}.
                    <br />
                    <font color="#ed1c24">
                      <b>Tailored Care -</b>{" "}
                    </font>
                    Our Services meet Maruti Suzuki standards.
                    <br />
                  </>
                </ul>
              </>
            ),
          },
         
        ];
        break;
      case "car-cleaning":
        returnVal = [
          {
            title1: <></>,
            description1: (
              <>
                Keeping your car clean is essential. It helps maintain appearance, and resale value and prevents corrosion. Regular cleaning removes dirt and contaminants. This protects your paint and ensures a well-kept vehicle.
              </>
            ),
          },
          {
            title2: `Interior Care is Important`,
            description2: `A clean interior enhances comfort and health. Regular maintenance stops dirt buildup, allergens, and odours. It keeps your interior looking good and improves air quality.`,
          },
          {
            title3: `Why VehicleCare Stands Out`,
            description3: (
              <>
                VehicleCare is known for its attention to detail and eco-friendly products. We specialise in luxury vehicles like the Maruti Suzuki BALENO. Our service goes beyond surface cleaning. We use advanced equipment for a showroom finish.
              </>
            ),
          },
          {
            title3: `Comprehensive Services Offered`,
            description3: (
              
                <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">
                  Interior and exterior cleaning
                  </li>
                  <li className="list-group-item">
                    Car washing and waxing
                  </li>
                  <li className="list-group-item">
                  Ceramic and Teflon coating
                  </li>
                  <li className="list-group-item">
                  Polishing and buffing
                  </li>
                  <li className="list-group-item">
                  Anti-rust treatment
                  </li>
                  <li className="list-group-item">
                  Tyre dressing and alloy cleaning
                  </li>
                  <li className="list-group-item">
                  Paint protection film
                  </li>
                  <li className="list-group-item">
                  Stain removal and odour elimination
                  </li>
                  <li className="list-group-item">
                  Vacuuming
                  </li>
                  <li className="list-group-item">
                  Leather upholstery treatment
                  </li>
                  <li className="list-group-item">
                  Luxury car detailing
                  </li>
                </ul>
              </>
              
            ),
          },
        ];
        break;
      case "car-detailing-&-spa":
        returnVal = [
          {
            title1: <>VehicleCare Car Detailing </>,
            description1: (
              <>
                Car detailing is a thorough and meticulous cleaning,
                restoration, and protection process for both the interior and
                exterior of a vehicle. It goes beyond a standard car wash,
                addressing paint imperfections, interior surfaces, and other
                intricate details. Detailing enhances the vehicle's appearance,
                maintains its condition, and preserves its value.
                <br />
                <br />
                VehicleCare offers Car detailing and spa service for cars of all
                makes and models. Get your vehicle detailing and coating
                hassle-free with VehicleCare workshops in {selectedCity}. We use
                100% genuine industry grade and eco-friendly products for all
                our detailing services.
              </>
            ),
          },
          {
            title2: `Services offered under Car detailing:`,
            description2: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">Teflon Coating</li>
                  <li className="list-group-item">Ceramic Coating</li>
                  <li className="list-group-item">
                    Anti-rust Underbody Coating
                  </li>
                  <li className="list-group-item">Paint Protection Film</li>
                  <li className="list-group-item">Silencer Coating</li>
                  <li className="list-group-item">
                    Waxing/Sealant Application
                  </li>
                  <li className="list-group-item">Seat Leather Treatment</li>
                </ul>
              </>
            ),
          },
          {
            description3: (
              <>
                VehicleCare prioritizes the use of eco-friendly and safe
                detailing products to ensure effective and responsible car care.
                Customers can inquire about the specific products used during
                the detailing process. All the VehicleCare workshops in{" "}
                {selectedCity} have state of the art equipment and specialized
                tools to deliver best-in-class detailing service to its
                customers. <br />
                <br />
                VehicleCare specializes in detailing of car brands like{" "}
                {brand?.name} etc. We also give warranty on our detailing
                services, however, the warranty period varies from service to
                service.
                <br />
                <br />
                VehicleCare's Car Spa typically includes a comprehensive package
                featuring exterior services like washing, waxing, and wheel
                cleaning. Interior services cover vacuuming, dashboard cleaning,
                and window polishing. Additional offerings may include headlight
                restoration, trim treatment, and optional fragrance application,
                providing a thorough rejuvenation for both the exterior and
                interior of your car.
              </>
            ),
          },
        ];
        break;
      case "custom-repairs":
        returnVal = [
          {
            title1: <>Experience the Best Custom Repair at VehicleCare</>,
            description1: (
              <>
                VehicleCare Custom repair services are solutions tailored to
                individual customer needs ranging from bodywork to specialized
                modifications. VehicleCare conducts detailed car scanning and
                runs other diagnostics and informs you about the possible
                repairs that would be required in the future. VehicleCare custom
                repair package includes clutch and suspension works and other
                important mechanical works that are crucial for your car’s
                health and safety. Under the VehicleCare custom repair work, a
                detailed mechanical diagnostic is run for your car which is
                crucial for your car’s health as it identifies underlying
                issues, preventing potential breakdown and ensuring optimal
                performance.
              </>
            ),
          },
          {
            title2: `Services included:`,
            description2: (
              <>
                <ul className="list-group-numbered px-3 mt-2">
                  <li className="list-group-item">Engine diagnostic</li>
                  <li className="list-group-item">Brake system diagnostic</li>
                  <li className="list-group-item">
                    Clutch and suspension work
                  </li>
                  <li className="list-group-item">
                    Electrical system Diagnostic
                  </li>
                </ul>
              </>
            ),
          },
          {
            description3: (
              <>
                VehicleCare's custom repairs stand out for their excellence due
                to a combination of factors. Skilled technicians with
                brand-specific expertise ensure precise diagnostics and repairs.
                The use of genuine parts, cutting-edge equipment, and a
                commitment to transparency in service and pricing contribute to
                the high-quality standards. VehicleCare prioritizes customer
                satisfaction.
              </>
            ),
          },
        ];
        break;

      default:
        break;
    }
    return returnVal;
  };
  const faqArr = getCustomHelmetDetails(cgID);
  return (
    <Row>
      <Col>
        <div className="auto-container" id="whychoose">
          <div className="upper-section">
            <div className="row clearfix d-flex justify-content-center">
              <div className="content-column col-lg-12 col-md-12 col-sm-12 mb-2">
                <div className="inner-column">
                  <div className="sec-title mb-2">
                    <h2
                      className="text-left"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      Why Choose VehicleCare for{" "}
                      {cgID
                        .toLowerCase()
                        .split("-")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}{" "}
                      {data.brand?.name} {data.model?.name}{" "}
                      {data.variant?.variant} Car Services in Gurgaon?
                    </h2>
                  </div>

                  {faqArr?.map((value, index) => (
                    <div eventKey={index}>
                      <div
                        style={{ fontSize: "14px", textAlign: "justify" }}
                        className=" mt-3 mb-4"
                      >
                        {value.MainTitle}
                      </div>

                      <div className="sec-title mb-2">
                        <h2
                          style={{ fontSize: "16px", textAlign: "justify" }}
                          className="text-left text-capitalize"
                        >
                          {value.title1}
                        </h2>
                      </div>
                      <div
                        style={{ fontSize: "14px", textAlign: "justify" }}
                        className=" mb-4"
                      >
                        {value.description1}
                      </div>

                      <div className="sec-title mb-2">
                        <h2
                          style={{ fontSize: "16px", textAlign: "justify" }}
                          className="text-left text-capitalize"
                        >
                          {value.title2}
                        </h2>
                      </div>
                      <div
                        style={{ fontSize: "14px", textAlign: "justify" }}
                        className=" text-left mt-2 mb-4"
                      >
                        {value.description2}
                      </div>

                      <div className="sec-title mb-2">
                        <h2
                          style={{ fontSize: "16px" }}
                          className="text-left text-capitalize"
                        >
                          {value.title3}
                        </h2>
                      </div>
                      <div
                        style={{ fontSize: "14px", textAlign: "justify" }}
                        className=" text-left mt-2 mb-4"
                      >
                        {value.description3}
                      </div>

                      <div className="sec-title mb-2">
                        <h2
                          style={{ fontSize: "16px", textAlign: "justify" }}
                          className="text-left text-capitalize"
                        >
                          {value.title4}
                        </h2>
                      </div>
                      <div
                        style={{ fontSize: "14px", textAlign: "justify" }}
                        className=" text-left mt-2 mb-4"
                      >
                        {value.description4}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.cartList = state.default.cartListReducer.cartList;
  stateObj.passingData = state.default.vehicleReducer;
  stateObj.servicePackageList = state.default.servicePackagesListReducer;
  stateObj.serviceList = state.default.serviceListReducer;
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.cityData = state.default.cityListReducer;
  return stateObj;
};
export default connect(mapStateToProps)(WhyChoose);
