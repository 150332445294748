import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import { How1, How2, How3, How4, How5, How6 } from "../../../../../constants/ImageConstants";
import { Link } from "react-router-dom";

const HowWorks = () => {
  return (
    <Row>
      <Col>
        <div className="auto-container pt-4 pb-4">
          <div className="sec-title centered">
            <div className="title">Secure your peace of mind with our hassle-free insurance claim process.</div>
            <h2>How it Works</h2>
          </div>

          <div className="inner-container">
            {/* <div className="row clearfix"> */}
              <div className="row clearfix">
                <div className="service-block-four col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={"/makeclaim"}>
                        <img src={How1} alt="" className="w-50 mx-auto pt-5" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                        <Link to={"/makeclaim"}> Simple Damage Reporting</Link>
                      </h5>
                      <div className="text">
                        We understand that filing an insurance claim can be stressful, which is why we offer an easy damage intake process. Customers can quickly report damage by uploading photos
                        through our web app or partner-preferred systems.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="service-block-four col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={"/makeclaim"}>
                        <img src={How2} alt="" className="w-50 mx-auto pt-5" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                        <Link to={"/makeclaim"}> Streamlined Assessment</Link>
                      </h5>
                      <div className="text">
                        {" "}
                        Our digital assessment system ensures that all relevant data is collected and incorporated into the damage report. This allows us to accurately determine repair requirements
                        and minimize delays in the process.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="service-block-four col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={"/makeclaim"}>
                        <img src={How3} alt="" className="w-50 mx-auto pt-5" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                        <Link to={"/makeclaim"}> Precise Repair Allocation </Link>
                      </h5>
                      <div className="text">
                        With our optimized repair allocation process, repairs are steered intelligently or tendered in a vast, dynamic, and personalized repairer network. This smart 'matchmaking'
                        ensures that the most suitable body repair shop is selected for the job.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="service-block-four col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={"/makeclaim"}>
                        <img src={How4} alt="" className="w-50 mx-auto pt-5" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                        <Link to={"/makeclaim"}> Efficient Authorization </Link>
                      </h5>
                      <div className="text">
                        {" "}
                        Our claim handlers review offers and authorize repairs quickly and accurately, minimizing delays and ensuring that repairs are completed as soon as possible.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="service-block-four col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={"/makeclaim"}>
                        <img src={How5} alt="" className="w-50 mx-auto pt-5" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                        <Link to={"/makeclaim"}> Convenient Planning </Link>
                      </h5>
                      <div className="text">
                        We understand that our customers have busy schedules, which is why we offer a convenient planning process. Customers can easily plan their repair appointments on dates that fit
                        their schedule in just a few clicks.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="service-block-four col-lg-4 col-md-6 col-sm-12">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={"/makeclaim"}>
                        <img src={How6} alt="" className="w-50 mx-auto pt-5" />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <h5>
                        <Link to={"/makeclaim"}> Insightful Repairs </Link>
                      </h5>
                      <div className="text">
                        Throughout the process, customers receive updates and can provide feedback, which provides us with valuable network and NPS insight. This allows us to continually improve and
                        ensure that our customers receive the best possible service.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default HowWorks;
