import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import "./index.scss";
import { ScheduleAppointmentBanner } from "./../../../../constants/ImageConstants";
import VehicleInfo from "./VehicleInfo";
import { connect } from "react-redux";
import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import InnerHeader from "../../layout/NavigationBar/InnerHeader";
import { UserLogin, UserProfile, UserSignup } from "../UserSections";
import CustomModal from "../../../../components/common/CustomModal";

const Appointment = (props) => {
  const navigate = useNavigate();
  const { userReducer, cartList: CartListData } = props;
  const { is_user_logged_in, is_new_user, user_profile } = userReducer;
  console.log("user_profile", userReducer)
  const { cartList } = CartListData;
  const paramsData = useLocation();

  const GetUserPopConent = () => {
    console.log("rendering");
    if (!is_user_logged_in) return <UserLogin />;

    if (is_new_user) return <UserSignup />;
    if (!is_new_user && user_profile) return <UserProfile />;
    return "";
  };
  const GetModalTitle = () => {
    if (!is_user_logged_in) return "User Login";
    if (is_new_user) return "User Signup";
    if (!is_new_user && user_profile) return "User Profile";
    return "";
  };

  return (
    <>
      <Helmet>
        <title>Go ahead and book your appointment with VehicleCare</title>
        <meta
          name="description"
          content="Book your car service with vehicleCare and enjoy the dedicated support and exclusive offers."
        />
      </Helmet>

      <InnerHeader title={''} />
      <UserLogin show={!is_user_logged_in} force={true} setBottomSheet={() => {}} />
      <Row>
        <Col>
          {/* Banner Section */}
          {/* <section className="banner-section-three style-two">
            <div className="slide pb-0">
              <AppointmentBanner />
            </div>
          </section> */}

          {/* Vehicle information  */}
          <section className="appointment-page-section">
            <VehicleInfo params={paramsData} />
          </section>

          {/* Appointment Schedule Section */}
          {/* <section className="appointment-schedule-section pt-5 pb-5">
            <div className="auto-container d-flex justify-content-center">
              <Col lg={11} xs={12}>
                <div className="row clearfix">
                
                  <div className="title-column col-lg-5 col-md-12 col-sm-12 mb-0">
                    <div className="inner-column">
                      <div className="sec-title">
                        <div className="title text-capitalize">Schedule Your Appointment</div>
                        <h2>
                          Schedule Your <br /> Appointment Today
                        </h2>
                        <div className="text">Our professionals will perform diagnostic tests, fluid flush and fills, engine replacement, oil changes, and total vehicle overhauls.</div>
                      </div>
                      <div className="row clearfix">
                        <div className="column col-lg-6 col-md-6 col-sm-12">
                          <div className="schedule-info">
                            <div className="info-inner">
                              <div className="icon fa fa-phone"></div>
                              <span>Make A Call</span>
                              <a href="tel:+91-991-102-0209">+91-991-102-0209</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="image-column col-lg-7 col-md-12 col-sm-12 mb-0">
                    <div className="inner-column wow slideInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                      <img src={ScheduleAppointmentBanner} alt="" className="w-100" />
                    </div>
                  </div>
                
                </div>
              </Col>
            </div>
          </section> */}

          {/* Sponsors section */}
          <section className="sponsors-section style-three"></section>
        </Col>
      </Row>
      {/* <Footer /> */}
    </>
  );
};
const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.userReducer = state.default.userReducer;
  stateObj.cartList = state.default.cartListReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
