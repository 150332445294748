import { insuranceMediaUploadRequest } from "../../server";
import { FILE_URL_ARRAY, IMAGE_URL_ARRAY } from "../types";

const sendInsuranceMedia = (params, dispatch) => {
  insuranceMediaUploadRequest(params, (responseData) => {
    if (params.type === "document") {
      dispatch({ type: IMAGE_URL_ARRAY, payload: responseData.data });
    } else {
      dispatch({ type: FILE_URL_ARRAY, payload: responseData.data });
    }
  });
};

export { sendInsuranceMedia };
